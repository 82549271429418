import * as React from 'react';

import { ParameterValueType } from '@scholly/scholly-types';

import Select from 'react-select';
import { ScreenStrings } from '../../constants';
import customStyles from './lib/CustomStyles';
import styles from './profile.scss';
import { UserFormParamsType } from '../../types';

type OUserBasicInfoType = Pick<UserFormParamsType, 'need_merit'>;

type IProps = OUserBasicInfoType & {
	onChangeText: (value: string) => void;
	parameter_values: ParameterValueType[];
	styles?: object;
	hasError?: boolean;
};

const _isNeed = [
	{
		id: '95',
		value: 'Yes',
		bool: true,
		selected: false,
		key: 'yes',
	},
	{
		id: '96',
		value: 'No',
		bool: false,
		selected: false,
		key: 'no',
	},
];

export default class ProfileNeedBased extends React.Component<IProps> {
	_renderDropdown = () => {
		let id = this.props.need_merit;
		const need = _isNeed.find((word) => word.id === id);

		return (
			<Select
				//@ts-ignore
				testID={`form_input_need_merit`}
				hasError={this.props.hasError}
				styles={customStyles(this.props)}
				placeholder={ScreenStrings.profile.needBased}
				value={need}
				onChange={this._onChangeVal}
				options={_isNeed}
				bottom
				isScroll={true}
				getOptionLabel={(options) => options.value}
				getOptionValue={(options) => options.id}
			/>
		);
	};
	_toggleItem = (id: any, key: string) => this.props.onChangeText(id);
	_onChangeVal = (str: any) => {
		this.props.onChangeText(str?.id);
	};
	render() {
		return (
			<div className={styles.profileWrapper}>
				<h3>{ScreenStrings.profile.need}</h3>
				<div className={styles.profileContainer}>{this._renderDropdown()}</div>
			</div>
		);
	}
}
