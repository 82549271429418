import * as React from 'react';
import classNames from 'classnames';

import styles from './locked-banner.scss';

export type BannerProps = {
	header: string;
	text: string;
	isLarge: boolean;
	handler?: (e: any) => void;
};

const LockedBanner = (props: BannerProps): JSX.Element => {
	const { header, text, isLarge } = props;

	return (
		<div className={classNames(styles.lockedBanner, isLarge && styles.lockedBannerLarge)}>
			<div className={styles.icon}>
				<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="20.5" cy="20.5" r="20.5" fill="white" fillOpacity="0.3" />
					<circle cx="20.5" cy="20.5" r="12.8125" fill="url(#paint0_linear_1520_5151)" fillOpacity="0.3" />
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M16.5851 18.3687V16.5392C16.5851 15.5508 16.9975 14.6029 17.7317 13.904C18.4659 13.2051 19.4617 12.8125 20.5 12.8125C21.5383 12.8125 22.5341 13.2051 23.2683 13.904C24.0025 14.6029 24.4149 15.5508 24.4149 16.5392V18.3687H24.7708C25.9502 18.3687 26.9062 19.2788 26.9062 20.4014V24.8735C26.9062 25.9962 25.9502 26.9062 24.7708 26.9062H16.2292C15.0498 26.9062 14.0938 25.9962 14.0938 24.8735V20.4014C14.0938 19.2788 15.0498 18.3687 16.2292 18.3687H16.5851ZM18.7384 14.8623C19.2056 14.4175 19.8393 14.1677 20.5 14.1677C21.1607 14.1677 21.7944 14.4175 22.2616 14.8623C22.7288 15.307 22.9913 15.9102 22.9913 16.5392V18.3687H18.0087V16.5392C18.0087 15.9102 18.2712 15.307 18.7384 14.8623Z"
						fill="url(#paint1_linear_1520_5151)"
					/>
					<defs>
						<linearGradient
							id="paint0_linear_1520_5151"
							x1="20.5"
							y1="7.6875"
							x2="20.5"
							y2="33.3125"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="white" />
							<stop offset="1" stopColor="white" stopOpacity="0" />
						</linearGradient>
						<linearGradient
							id="paint1_linear_1520_5151"
							x1="20.5"
							y1="12.8125"
							x2="20.5"
							y2="26.9063"
							gradientUnits="userSpaceOnUse"
						>
							<stop offset="0.411458" stopColor="white" />
							<stop offset="1" stopColor="#80BFFD" />
						</linearGradient>
					</defs>
				</svg>
			</div>

			<div className={styles.innerContent}>
				<div>
					<h4>{header}</h4>
				</div>

				<div className={styles.allAccessContianer}>
					<div>
						<span>{text}</span>
					</div>
					<div className={styles.arrowContainer}>
						<svg width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="m1 1 5.968 5L1 11"
								stroke="#80BFFD"
								strokeWidth={2}
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LockedBanner;
