import React from 'react';

import styles from './scholarship.scss';
import { SubHeader } from '../../components/layouts/SubHeader';
import ReportComment from './components/ReportComment';
import { scrollTo } from '../../helpers/Functions';

const ISSUE = 'Other';
const ReportIssuePage = () => {
	React.useEffect(() => {
		scrollTo({ top: 0 });
	}, []);

	return (
		<div className={styles.reportWrapper}>
			<SubHeader title={'Report an Issue'} className={styles.subheader} />
			<div className={styles.reportContainer}>
				<ReportComment issue={ISSUE} />
			</div>
		</div>
	);
};
export default ReportIssuePage;
