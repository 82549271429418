import * as React from 'react';

import AsyncSelect from 'react-select/async';
import { ScreenStrings } from '../../constants';

import { UserContext } from '../../contexts';
import customStyles from './lib/CustomStyles';
import { getSchools } from '../../actions/parameterActions';
import styles from './profile.scss';

type Props = {
	school: { id: string; name: string } | string;
	onChangeText?: (value: any) => void;
	webStyle?: object;
	data?: any;
	hasError?: boolean;
};

const ProfileSchool = (props: Props) => {
	const user = React.useContext(UserContext);
	let school = props.school || null;

	const schools = (val, cb) => {
		if (val.length < 2) return cb([]);
		getSchools(val).then((res) => {
			if (res) {
				let {
					data: { schools },
				} = res;

				cb(schools);
			}
		});
	};

	const save = async (item: any) => {
		if (item === null || item.length < 1) return;

		try {
			await user.updateUserSchool(item);
		} catch (error) {
			console.log('Error', error);
		}
		props.onChangeText(item);
	};

	const deleteItem = async (item: any) => {
		try {
			await user.updateUserSchool(item);
		} catch (error) {
			console.log('Error', error);
		}
		props.onChangeText(item);
	};

	const handleChange = React.useCallback(
		(inputValue, { action }) => {
			switch (action) {
				case 'select-option':
					save(inputValue);
					return;
				case 'remove-value':
					deleteItem(inputValue);
					return;
				default:
			}
		},
		[deleteItem, save]
	);

	let defaultVal = !school ? true : Array.isArray(school) ? school : [school];

	return (
		<div className={styles.profileWrapper}>
			<h3>{ScreenStrings.profile.schoolIn}</h3>
			<div className={styles.profileContainer}>
				<AsyncSelect
					//@ts-ignore
					testID={`form_input_school`}
					cacheOptions
					defaultOptions={defaultVal || true}
					defaultValue={school || null}
					onChange={handleChange}
					value={school}
					loadOptions={schools}
					placeholder={ScreenStrings.profile.school}
					getOptionLabel={(option) => option.name}
					getOptionValue={(option) => option.id}
					styles={customStyles(props)}
					isMulti
					isClearable={false}
				/>
			</div>
		</div>
	);
};

export default ProfileSchool;
