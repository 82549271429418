import React, { CSSProperties } from 'react';

interface Props {
	onClick?: (val: any) => void;
	fill?: string;
	backgroundColor?: string;
	stroke?: string;
	styles?: CSSProperties;
}
export const DropdownArrow = (props: Props) => {
	const { onClick, styles } = props;
	return (
		<div className="content" onClick={onClick} style={styles}>
			<svg xmlns="http://www.w3.org/2000/svg" width="15" height="7" viewBox="0 0 15 7" fill="none">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M14.0858 0.226021C14.2473 0.370787 14.338 0.567104 14.338 0.771802C14.338 0.976499 14.2473 1.17282 14.0858 1.31758L8.05463 6.72135C7.89306 6.86607 7.67395 6.94737 7.44549 6.94737C7.21702 6.94737 6.99792 6.86607 6.83634 6.72135L0.805223 1.31758C0.648278 1.17199 0.561436 0.976987 0.563398 0.774579C0.565361 0.572172 0.655973 0.378553 0.81572 0.235424C0.975465 0.0922956 1.19156 0.0111089 1.41747 0.00935004C1.64337 0.00759119 1.86101 0.085401 2.02351 0.226021L7.44549 5.08401L12.8675 0.226021C13.029 0.0813 13.2481 -4.15019e-08 13.4766 -3.28021e-08C13.7051 -2.41023e-08 13.9242 0.0813001 14.0858 0.226021Z"
					fill="#A0A3BD"
				/>
			</svg>
		</div>
	);
};
