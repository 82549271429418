import React from 'react';
import { Button } from '@scholly/elements';
import classNames from 'classnames';

import styles from './viewMore.scss';

interface Props {
	onClick: () => void;
	text: string;
	btnLabel: string;
}

const defaultProps: Omit<Props, 'onClick' | 'text'> = {
	btnLabel: 'View More',
};
export const ViewMore = (props: Props) => {
	const { text, btnLabel, onClick } = { ...defaultProps, ...props };

	return (
		<div className={classNames(styles.viewMoreContainer)}>
			<div className={styles.textContainer}>
				<h3 className={styles.text}>{text}</h3>
			</div>
			<Button style={{ textTransform: 'uppercase' }} onClick={onClick}>
				{btnLabel}
			</Button>
		</div>
	);
};
