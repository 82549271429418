import { ProfileCard } from '@scholly/elements';
import React, { useEffect, useState } from 'react';
import styles from './profile.scss';
import ContentLoader from 'react-content-loader';
import { SubHeader } from '../layouts/SubHeader';

// TODO:
// Remove When Backend work for Profile Category has been completed

// TODO: Add Transition on Profile Card UI in scholly elements.

const categoryData = {
	categories: [
		{
			category: 'Academic',
			answers: ['GPA', 'School', 'Enrolling as', 'Other Answers'],
			questions: ['Test1', ' Test2', ' Test2', ' Test2', ' Test2', ' Test2'],
		},
		{
			category: 'Financial',
			answers: ['GPA', 'School', 'Other Answers'],
			questions: ['Test1', ' Test2', ' Test2', ' Test2', ' Test3', ' Test3', ' Test3', ' Test3', ' Test3'],
		},
		{
			category: 'Activities',
			answers: ['GPA', 'School', 'Enrolling as', 'Other Answers', ' Test2', ' Test2', ' Test2', ' Test2'],
			questions: ['Test1', ' Test2'],
		},
		{
			category: 'Personal',
			answers: [
				'Personal Answer 1',
				'Personal Answer 2',
				'Personal Answer 3',
				'Other Answers',
				'Personal Answer 3',
				'Personal Answer 3',
				'Personal Answer 3',
			],
			questions: ['Test1', ' Test2', ' Test2', ' Test2', ' Test2'],
		},
	],
};

const Profile = () => {
	const [isExpandAll, setIsExpandAll] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const toggleExpandAll = () => {
		setIsExpandAll((prevState) => !prevState);
	};

	const onEditAnswer = () => {
		// TODO:
		// Add OnEditAnswer Logic on later tickets
		alert('Editing Answer');
	};

	const onViewCategory = () => {
		// TODO:
		// Add onViewCategory Logic on later tickets
		alert('View Answer');
	};

	useEffect(() => {
		// TODO: Be sure to remove logic when Backend work is complete

		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	}, []);

	return (
		<React.Fragment>
			<SubHeader title="Profile" hideBackButton />
			<div className={styles.profileCardWrapper}>
				<div className={styles.profileCardContainer}>
					{isLoading ? (
						<ProfileLoader />
					) : (
						categoryData.categories.map((category, index) => {
							const totalQuestions = category?.answers.length + category?.questions.length;
							const progessPercent = Math.round((category?.answers.length / totalQuestions) * 100);
							return (
								<ProfileCard
									key={`${index}-${category}`}
									category={category.category}
									amountOfQuestionsToComplete={category.questions?.length}
									answers={category.answers}
									progress={progessPercent}
									onEditAnswer={onEditAnswer}
									onViewCategory={onViewCategory}
									isExpandAll={isExpandAll}
								/>
							);
						})
					)}
				</div>
				<p style={{ cursor: 'pointer' }} onClick={toggleExpandAll}>
					{isExpandAll ? `Collapse All` : `Expand All`}
				</p>
			</div>
		</React.Fragment>
	);
};

export default Profile;

const ProfileLoader: React.FC = () => {
	const rx = 5;
	const ry = 5;
	const x = 0;
	return (
		<ContentLoader height={900}>
			<rect x={x} y="0" rx={rx} ry={ry} width="100%" height="123" />
			<rect x={x} y="143" rx={rx} ry={ry} width="100%" height="123" />
			<rect x={x} y="286" rx={rx} ry={ry} width="100%" height="123" />
			<rect x={x} y="429" rx={rx} ry={ry} width="100%" height="123" />
		</ContentLoader>
	);
};
