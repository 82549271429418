import React from 'react';
import { checkFeature, OfferContext, UserContext } from '../../../contexts';
import { ExclusiveOfferCard } from './ExclusiveOfferCard';
import { Button } from '@scholly/elements';
import styles from './styles.scss';

import { useHistory } from 'react-router-dom';
import { Routes, FLAGS, ButtonStrings, ScreenStrings } from '../../../constants';
import config from '../../../config/config';
import { ScholarshipFeedIndex } from '../../../types';

import { PromoOfferCard } from './PromoOfferCard';

const LIMIT = 2;

export const ExclusiveOffers = () => {
	const { displayedOffers } = React.useContext(OfferContext);
	const user = React.useContext(UserContext);
	const history = useHistory();
	const isOfferTab = checkFeature(FLAGS.OFFER_TAB);
	const isOfferDash = checkFeature(FLAGS.OFFER_DASHBOARD);

	const handleViewAll = () => {
		if (isOfferTab) {
			const queryParams = {
				index: ScholarshipFeedIndex.OFFERS.toString(),
			};
			const queryString = new URLSearchParams(queryParams).toString();
			history.push(Routes.scholarships.feed + `?${queryString}`);
		} else {
			const windowReference = window.open();
			windowReference.location.href = `${config.offers_url}?m=login&email=${user?.user_email}`;
		}
	};

	// Not showing section when no Offers to be displayed
	if (!isOfferDash || !displayedOffers || displayedOffers.length === 0) {
		return null;
	}

	return (
		<div className={styles.container}>
			<h2 className={styles.heading}>{ScreenStrings.offers.exclusiveOffers}</h2>
			<PromoOfferCard
				offer={{
					affiliate: {
						name: 'Sallie Mae',
						avatar_img: 'salliemae-avatar.png',
					},
					display: 'Get the money you need to pay for your education',
					amount: 'College Loan',
					affiliate_subtext: 'Student Loans',
				}}
			/>
			{displayedOffers.slice(0, LIMIT).map((offer) => (
				<ExclusiveOfferCard offer={offer} />
			))}
			<Button variant={'secondary'} onClick={handleViewAll} className={styles.viewAllBtn}>
				{ButtonStrings.viewAllOffers}
			</Button>
		</div>
	);
};
