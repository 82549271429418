export * from './campaigns';
export * from './categories';
export * from './parameters';
export * from './profile';
export * from './scholarship';
export * from './questions';

export type ActionMap<M extends { [index: string]: any }> = {
	[Key in keyof M]: M[Key] extends undefined
		? {
				type: Key;
		  }
		: {
				type: Key;
				payload: M[Key];
		  };
};
