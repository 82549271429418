import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SchollyResponse } from '@scholly/core';
import moment from 'moment';
import classNames from 'classnames';
import { differenceWith, isEqual } from 'lodash';

import { ProfileProvider, useParameterState, useQuestions, SessionContext } from '../../contexts';
import { createUserParameterValues, getLocalUser, updateLocation, updateSchool, updateUser } from '../../actions';

import MultiStep, { MultiStepRef, Step } from '../../components/common/MultiStep/MultiStep';
import BasicInfo from '../../components/onboarding/BasicInfo';
import DynamicOnboarding from '../../components/onboarding/DynamicOboarding';
import OfferCompletionModal from '../../components/offer-completion-modal/offer-completion-modal';
import { SubHeader } from '../../components/layouts/SubHeader';
import TotalMatch from '../../components/total-match/total-match';

import { Routes, ScreenStrings, LIFETIME_CODE, Status, HTTPStatus } from '../../constants';
import { checkBasic, onboardingDerivedStateFromLocal, sleep } from '../../helpers/Functions';
import { isMobileDevice } from '../../helpers/checkIfDesktop';

import styles from './onboarding.scss';

const OnboardingSingle: React.FC = () => {
	const multiRef = React.useRef<MultiStepRef>(null);
	const { parametersLoadedStatus, parameterStringId, values } = useParameterState();
	const { question, required, revalidate, title } = useQuestions();
	const [allQuestions, setAllQuestions] = useState([]);
	const [currentQuestion, setCurrentQuestion] = useState(question);
	const [questionLength, setQuestionLength] = useState(1);
	const history = useHistory();
	const [displayOfferCompletionModal, setDisplayOfferCompletionModal] = useState(false);
	const [skip, setSkip] = useState(false);
	const { updateSessionUser } = React.useContext(SessionContext);
	const [header, setHeader] = React.useState(title || ScreenStrings.onboarding.basic);
	const [keys, setKeys] = React.useState<any>([]);
	const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);

	const [initialValues, setinitialValues] = React.useState({
		first_name: '',
		last_name: '',
		birthday: '',
		phone_number: '',
		sms_opt_in: false,
		profileStatus: Status.IDLE,
		hasError: [],
	});

	const string_id = currentQuestion ? parameterStringId?.get(currentQuestion.parameter_id?.toString()) : '';
	//@ts-ignore
	const handleBasicSubmit = async (values, step, action) => {
		multiRef?.current?.setNextDisable(true);
		if (parametersLoadedStatus === Status.PENDING) {
			multiRef?.current?.setNextDisable(false);
			return;
		}

		const { first_name, last_name, phone_number, birthday, sms_opt_in } = values;
		localStorage.setItem('opt_in', values?.sms_opt_in);
		const currentUser = await getLocalUser();

		let delay = 0;

		const [diffUserValues] = differenceWith(
			[
				{
					first_name,
					last_name,
					phone_number,
					birthday,
					sms_opt_in,
				},
			],
			[currentUser],
			isEqual
		);

		try {
			await sleep(delay);

			const res = await updateUser(diffUserValues);
			updateSessionUser(res?.data?.user);
			if (res.status === HTTPStatus.OK) {
				action.updateStatus(Status.RESOLVED);
				if (!title) {
					if (isMobileDevice) {
						setHeader(ScreenStrings.onboarding.mobileBasic);
					} else {
						setHeader(ScreenStrings.onboarding.personal);
					}
				}
				end(action);
			}
			if (res.status === HTTPStatus.FAIL) {
				throw new Error(res.error);
			}
			return true;
		} catch (error) {
			if (error.message) {
				multiRef.current.setErrors([error]);
			} else if (Array.isArray(error)) {
				multiRef.current.setErrors(error);
			}
			action.updateStatus(Status.REJECTED);
		} finally {
			multiRef?.current?.setNextDisable(false);
		}
	};
	const handleSubmit = async (values, string_id, action) => {
		let next = false;

		sleep(0).then(() => {
			if (string_id === 'college') {
				updateSchool({ school: values[string_id]?.id })
					.then((res) => {
						next = handleThen(res);
					})
					.finally(() => handleFinish(next, action));
			} else if (string_id === 'state') {
				updateLocation({ place_id: values[string_id]?.place_id })
					.then((res) => {
						next = handleThen(res);
					})
					.finally(() => handleFinish(next, action));
			} else if (Array.isArray(values[string_id])) {
				//@ts-ignores
				values[string_id].forEach((val, idx) => {
					createUserParameterValues({ parameter_value_id: val })
						.then((res) => {
							if (res.status !== HTTPStatus.OK) {
								console.log(res.error);
							}
							if (idx === values[string_id].length - 1) {
								next = handleThen(res);
							}
						})
						.finally(() => handleFinish(next, action));
				});
			} else if (values[string_id]) {
				createUserParameterValues({ parameter_value_id: values[string_id] })
					.then((res) => {
						next = handleThen(res);
					})
					.finally(() => handleFinish(next, action));
			}
		});
		return true;
	};

	const handleThen = (res: SchollyResponse) => {
		const currentQuestionIndex = allQuestions.findIndex((q) => q.id === currentQuestion.id);

		if (currentQuestionIndex !== allQuestions.length - 1) {
			setCurrentQuestion(allQuestions[currentQuestionIndex + 1]);
		} else {
			setCurrentQuestion(undefined);
		}

		if (res.status === HTTPStatus.OK) {
			return true;
		} else if (res.status === HTTPStatus.FAIL) {
			console.log(res.error);
		}
		return false;
	};

	const handleFinish = (next: boolean, step: any) => {
		if (next) {
			step.changeState({ profileStatus: Status.RESOLVED });
			end(step);
		}
		step.changeState({ profileStatus: Status.REJECTED });
	};

	const end = (step) => {
		if (multiRef.current && multiRef.current.btnLoading) {
			multiRef.current.setBtnLoading(false);
		}

		if (required.completed < 1) {
			revalidate(true);
		}
	};

	React.useEffect(() => {
		if (question) {
			if (!allQuestions.find((q) => q.id === question.id)) {
				const questions = allQuestions;
				questions.push(question);
				setAllQuestions(questions);
			}

			if (!currentQuestion) {
				question.string_id = parameterStringId.get(question.parameter_id.toString());
				setKeys([question.string_id, ...keys]);
				setCurrentQuestion(question);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question, parameterStringId]);

	React.useEffect(() => {
		if (required.completed === 1) {
			setIsOnboardingComplete(true);
			setHeader(ScreenStrings.onboarding.complete);
		}
	}, [required.completed]);

	React.useEffect(() => {
		(async () => {
			let i = await onboardingDerivedStateFromLocal(values);
			if (i.birthday) {
				i.birthday = moment(i.birthday).format('MM/DD/YYYY');
			} else {
				i.birthday = '01/01/1990';
			}
			if (checkBasic(i, ['phone_number'])) {
				setSkip(true);
				multiRef.current.next();
			}
			//@ts-ignore
			setinitialValues({ ...initialValues, ...i });
		})();
		multiRef?.current?.displayPagination(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleBack = () => {
		const currentQuestionIndex = allQuestions.findIndex((q) => q.id === currentQuestion.id);

		if (currentQuestionIndex > 0) {
			setCurrentQuestion(allQuestions[currentQuestionIndex - 1]);
		} else {
			multiRef.current?.setStepNumber(multiRef?.current?.stepNumber - 1);
		}
	};

	const handleSkip = () => {
		if (isOnboardingComplete) {
			history.push(Routes.dashboard);
		} else {
			setHeader(ScreenStrings.onboarding.complete);
		}
	};

	// const hideBackButton = multiRef.current?.stepNumber === 0 || isMobileDevice || isOnboardingComplete;
	const hideBackButton = true;

	useEffect(() => {
		const fetchUser = async () => {
			const user = await getLocalUser();
			const registration_code = user?.merchant?.transactions.filter((t) => t.order_type === 'joincode')[0];

			const isOfferLifeTimeMember = registration_code ? registration_code?.code === LIFETIME_CODE : false;

			if (!localStorage.getItem('offerCompletionModal')) {
				if (isOfferLifeTimeMember) {
					setDisplayOfferCompletionModal(true);
					setTimeout(() => {
						localStorage.setItem('offerCompletionModal', 'already_displayed');
					}, 1000);
				}
			}
		};

		fetchUser();
	}, []);

	useEffect(() => {
		if (!isOnboardingComplete && header !== title && title !== '') {
			setHeader(title);
		}
	}, [title, isOnboardingComplete]);

	useEffect(() => {
		let count = required?.total_questions || required?.total_count;
		if (skip) {
			setQuestionLength(count);
		} else {
			setQuestionLength(count + 1);
		}
	}, [required, skip]);

	return (
		<ProfileProvider>
			<SubHeader
				title={header}
				hideBackButton={hideBackButton}
				onBack={handleBack}
				onSkip={handleSkip}
				className={classNames(
					styles.subHeader,
					isOnboardingComplete && isMobileDevice && styles.subHeaderComplete
				)}
			/>

			<div className={styles.contentWrapper}>
				<MultiStep
					renderIf={!isOnboardingComplete}
					ref={multiRef}
					initialValues={initialValues}
					handleBack={handleBack}
					totalSteps={questionLength}
				>
					<Step key="basic_1" name="basic" onSubmit={handleBasicSubmit} skip={skip}>
						<BasicInfo multiRef={multiRef} />
					</Step>
					<Step key={`${string_id}_2`} name={string_id} onSubmit={handleSubmit}>
						<DynamicOnboarding question={currentQuestion} multiRef={multiRef} />
					</Step>
				</MultiStep>

				{isOnboardingComplete && <TotalMatch showConfetti={true} />}
			</div>
			<OfferCompletionModal
				isOpen={displayOfferCompletionModal}
				handleClose={() => setDisplayOfferCompletionModal(false)}
			/>
		</ProfileProvider>
	);
};

export default OnboardingSingle;
