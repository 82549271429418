import React from 'react';
import styles from '../offboarding.scss';
import { useHistory } from 'react-router-dom';
import { submitBugReport } from '../../../../actions';
import { Routes } from '../../../../constants';
import classNames from 'classnames';
import RightArrow from '../../../../components/common/icons/right-arrow';
import { Button } from '@scholly/elements';
const reasons = [
	{ label: 'Signing up', details: true },
	{ label: 'Scholarship not loading', details: true },
	{ label: 'Saving/Applying to scholarships', details: true },
	{ label: 'Not being matched correctly', details: true },
	{ label: 'App glitches', details: true },
	{ label: 'Too slow', details: true },
	{ label: 'Other', details: true },
];
const TechnicalIssues = ({ setIssue }) => {
	const history = useHistory();
	const { offboarding } = Routes;

	const toComments = (issue) => {
		if (issue) {
			setIssue(issue);
		}
		history.push(`${offboarding.home}/${offboarding.additionalComments}`);
	};

	const toAccountScreen = async (issue) => {
		setIssue(issue);
		await submitBugReport({ cancel_reason_id: '2', comment: '', issue });
		history.push(`${offboarding.home}/${offboarding.accountType}`);
	};

	const dismiss = () => {
		history.push(Routes.scholarships.feed);
	};

	return (
		<React.Fragment>
			<div className={classNames(styles.pauseText, styles.pauseDescription, styles.mainTitle)}>
				Select a bug issue
			</div>
			<div className={classNames(styles.cancelHolder, styles.technicalWrapper)}>
				{reasons.length &&
					reasons.map((value, index) => {
						return (
							<div
								key={index.toString()}
								test-id={`testID_techincalReasons_${value.label.replace(/ /g, '_')}`}
								style={{ borderBottom: index == reasons.length - 1 && 'none' }}
								onClick={() => {
									value.details ? toComments(value.label) : toAccountScreen(value.label);
								}}
								className={styles.cancelInnerHolder}
							>
								<div>
									<h4 className={styles.cancelReasons} key={index}>
										{value.label}
									</h4>
								</div>
								<div className={styles.cancelInnerIcon}>
									<RightArrow />
								</div>
							</div>
						);
					})}
			</div>
			<div className={classNames(styles.isFlex, styles.isFlexColumn)}>
				<Button
					className={classNames(styles.technicalButton, 'mb-3', styles.widthAuto)}
					variant={'secondary'}
					onClick={dismiss}
				>
					Not Now
				</Button>
			</div>
		</React.Fragment>
	);
};
export default TechnicalIssues;
