export default {
	menu: {
		title: 'Menu',
		scholarshipFeed: 'Scholarship Feed',
		hello: 'Hello Again',
	},
	settings: {
		title: 'Settings',
		profile: 'Profile',
		account: 'Account Details',
		faq: 'FAQs',
		categories: 'Categories',
		chatbot: 'Chat with us',
	},
	scholly: {
		heading: 'Scholly by Sallie',
		footer: 'SLM Education Services',
		platform: 'Platform',
		search: 'Search',
		editor: 'Editor',
		math: 'Math',
		roundup: 'Roundup',
	},
	version: 'Version',
};
