import { LocationType, ParameterType, ParameterValueType, SchoolType } from '@scholly/scholly-types';

export type CountScholarship = {
	saved: number;
	matched: number;
	applied: number;
};

export enum ScholarshipStatus {
	READ = 'read',
	UNREAD = 'unread',
	NEW = 'new',
}

export enum ScholarshipFeedIndex {
	MATCHED = 1,
	OFFERS = 2,
	SAVED = 3,
	APPLIED = 4,
}

export enum ScholarshipClickSourceIndex {
	DASHBOARD = 1,
	FEED = 2,
	SAVED = 3,
	APPLIED = 4,
	LANDING = 5,
	TWOK_CONFIRMATION = 6,
}

export enum ScholarshipProgress {
	SAVED = 'saved',
	APPLIED = 'applied',
	WON = 'won',
	NOT_AWARDED = 'not_awarded',
}

export enum ScholarshipSortType {
	SCHOLLY_SCORE = 'scholly_score',
	DEADLINE = 'deadline',
	NEED = 'need',
	AMOUNT_HIGH = 'amount-high',
	AMOUNT_LOW = 'amount-low',
}

export enum ScholarshipNeedMerit {
	MERIT = 'merit',
}

export type ScholarshipModel = {
	id: number;
	status: ScholarshipStatus;
	name: string;
	slug: string;
	website: string;
	application_url: string | null;
	application_type: string;
	description: string;
	reward_type: string;
	amount: number;
	awards: string;
	deadline: string;
	opens: string;
	age: { min: null; max: null };
	contact_email: string;
	contact_phone: string;
	created_time: Date;
	modified_time: Date;
	matched: {
		locations: LocationType[];
		schools: SchoolType[];
		parameters: ParameterType[];
		parameters_categories: ParameterValueType[];
	};
	needmerit: ScholarshipNeedMerit;
	scholly_score: number;
	track_url: URL;
	isNew: boolean;
	read_status: ScholarshipStatus;
	progress?: string | number | ScholarshipProgress;
	featured?: boolean;
	landing?: boolean;
	effort: string;
	provider: {
		name: string;
		logo_img: string;
	};
};

export type ScholarshipContextType = {
	scholarships: Array<object>;
	scholarshipSaved: Array<object>;
	scholarshipApplied: Array<object>;
	scholarship: ScholarshipModel;
};

export type ScholarshipObject = ScholarshipModel & {
	prevLocation: string;
};
