import * as React from 'react';

import Select from 'react-select';

import customStyles from './CustomStyles';

interface Props {
	testID: string;
	placeholder?: string;
	onChangeVal: (value: any) => void;
	options: any;
	save?: (obj: any) => void;
	styles?: object;
	value: Array<any>;
	hasError?: boolean;
	bottom?: boolean;
	searchable?: boolean;
	additionalStyles?: object;
}

const MultiSelect: React.FC<Props> = (props) => {
	const [selected, setSelected] = React.useState(null);
	const [widthWindow, setWidthWindow] = React.useState<number>();
	const handleChange = (selectedOption) => {
		setSelected(selectedOption);
		selectedOption ? props.onChangeVal(selectedOption) : props.onChangeVal([]);
	};
	React.useEffect(() => {
		setWidthWindow(window.innerWidth);
		let beginningArray = [];
		props.value &&
			props.value.forEach((element) => {
				beginningArray.push({ ...element, label: element.value });
			});
		props.value && setSelected(beginningArray);
	}, [props.value]);

	return (
		<Select
			//@ts-ignore
			testID={props.testID}
			// styles={widthWindow && customStyles(props, widthWindow)}
			defaultValue={props.value || null}
			isMulti
			closeMenuOnSelect={false}
			onInputChange={props.onChangeVal}
			onChange={handleChange}
			options={props.options}
			placeholder={props.placeholder}
			value={selected}
			//TODO: Fix overlap bug
			maxMenuHeight={150}
			styles={customStyles(props)}
			isClearable={false}
			getOptionLabel={(options) => options.value}
			getOptionValue={(options) => options.id}
		/>
	);
};

export default MultiSelect;
