import React, { useState } from 'react';

import { Input } from '@scholly/elements';
import classNames from 'classnames';
import { getLocation, querySchools } from '../../actions';
import styles from './onboarding.scss';

type Props = {
	onSelect: (value: string) => void;
	onDeselect: (value?: string) => void;
	selected: { value?: string };
	title: string;
	placeholder: string;
	single: boolean;
	type: 'state' | 'school';
};

const QuerySearch: React.FC<Props> = (props) => {
	const { title, selected, single, type } = props;
	const [visible, setVisible] = useState(false);
	const [query, setQuery] = useState('');
	const [value, setValue] = useState('');
	const [hover, setHover] = useState('');
	const [results, setResults] = React.useState([]);

	const fetchSchools = async () => {
		setValue(query);
		const res = await querySchools(query);
		const formated = res.map((r) => schoolsToAnswer(r));
		setResults(formated);
	};

	const fetchLocation = async () => {
		setValue(query);
		if (query.length > 1) {
			const res = await getLocation(query);
			if (res && Array.isArray(res?.data?.predictions)) {
				let {
					data: { predictions },
				} = res;
				//@ts-ignore
				predictions.forEach((s: { description: any }) => {
					Object.assign(s, { value: s.description });
				});
				setResults(predictions);
			}
		}
	};

	const handleSelection = (selection) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		single ? setValue(selection.value) : null;

		props.onSelect(selection);

		if (visible) {
			setResults([]);
			setVisible(false);
		}
	};

	const showFiltered = () => {
		let filtered = [];

		if (query && query.length > 1) {
			results.forEach((possibleAnswer, idx) => filtered.push(possibleChoice(possibleAnswer, idx)));
		}

		if (!visible && results.length > 0) {
			setVisible(true);
		}

		return <div className={styles.choicesBox}>{filtered}</div>;
	};

	const possibleChoice = (possibleAnswer, idx) => {
		return (
			<div
				key={idx.toString()}
				onMouseEnter={() => setHover(possibleAnswer)}
				onMouseLeave={() => setHover('')}
				onClick={() => handleSelection(possibleAnswer)}
				className={styles.possibleChoice}
				style={{ backgroundColor: hover === possibleAnswer ? '#3259F1' : null }}
			>
				<p style={{ color: hover === possibleAnswer ? '#FFFFFF' : '#4E4B66' }}>{possibleAnswer.value}</p>
			</div>
		);
	};

	const setSelectedValue = React.useCallback(() => {
		if (selected) {
			setVisible(false);
		}
	}, [selected]);

	React.useEffect(() => {
		setSelectedValue();
	}, [setSelectedValue]);

	const schoolsToAnswer = (school) => {
		return {
			id: school.id,
			value: school.name,
		};
	};

	React.useEffect(() => {
		switch (type) {
			case 'school':
				fetchSchools();
				break;
			case 'state':
				fetchLocation();
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	React.useEffect(() => {
		let timer;
		if (visible && query.length < 1 && value !== '') {
			timer = setTimeout(() => {
				setVisible(false);
			}, 300);
		}
		return () => {
			clearTimeout(timer);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query, visible]);

	React.useEffect(() => {
		if (selected && selected.value) {
			setValue(selected.value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleInputChange = (value) => setQuery(value);
	return (
		<div className={styles.onboardingFormWrapper}>
			<h2>{title}</h2>
			<div className={styles.w100} style={{ height: '44px' }}>
				{/* <div className={`${styles.w100} ${styles.flexCenterCenter} `} style={{ marginBottom: 20 }}> */}
				{/* </div> */}

				{/* For next release, THis input should be a REACT SELECT input */}
				<div className={styles.inputWrapper}>
					<Input
						className={classNames(styles.searchBox)}
						style={{ width: '100%' }}
						placeholder={props.placeholder ? props.placeholder : ''}
						value={value}
						//@ts-ignore
						onChange={handleInputChange}
					/>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="currentColor"
						viewBox="0 0 16 16"
						className={classNames(styles.chevron, visible && styles.chevronActive)}
					>
						{' '}
						<path
							fillRule="evenodd"
							d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
						/>{' '}
					</svg>
				</div>

				<div className={classNames(styles.searchList, visible && styles.show)}>{showFiltered()}</div>
			</div>
		</div>
	);
};

export default QuerySearch;
