import React, { useState } from 'react';
import styles from './hamburger-menu.scss';
import Navigation from '../Navigation';
import { Squash as Hamburger } from 'hamburger-react';

const HamburgerMenu = () => {
	const [isMenuOpen, setMenuOpen] = useState(false);

	return (
		<React.Fragment>
			<div className={styles.hamburgerMenu}>
				<Hamburger size={20} color="#fff" rounded toggled={isMenuOpen} toggle={setMenuOpen} />
			</div>
			{isMenuOpen && (
				<div
					className={`${styles.hamburgerMenuNavContainer} ${
						isMenuOpen ? styles.hamburgerMenuNavContainerOpen : ''
					}`}
				>
					<Navigation isOnhamburger />
				</div>
			)}
		</React.Fragment>
	);
};

export default HamburgerMenu;
