import React from 'react';
import classNames from 'classnames';

import { BackArrowButton } from '../../../components/button/BackArrowButton';
import { SubHeader } from '../../../components/layouts/SubHeader';

import styles from '../scholarship.scss';
import { useLocation } from 'react-router-dom';
import { Routes } from '../../../constants';

interface Props {
	handleBack?: () => void;
	handleNext: () => void;
}
export const CardNavigation = ({ handleBack, handleNext }: Props) => {
	const { state } = useLocation();

	const title = React.useCallback(() => {
		if (state?.prevLocation && state?.prevLocation === Routes.dashboard) {
			return 'Dashboard';
		} else return 'Scholarship Feed';
	}, [state]);
	return (
		<div>
			<div className={styles.cardHeader}>
				<div className={classNames(styles.isFlexRow, styles.alignItemsCenter)}>
					<BackArrowButton backAction={handleBack} styles={{ marginRight: '10px' }} />
					<div>
						<a onClick={handleBack}>{title()} /</a> Details
					</div>
				</div>
				<div className={classNames(styles.isFlexRow, styles.alignItemsCenter)}>
					<a onClick={handleNext}>Next Scholarship</a>
					<BackArrowButton
						styles={{ transform: 'rotate(180deg)', marginLeft: '10px' }}
						backAction={handleNext}
					/>
				</div>
			</div>
			<SubHeader className={styles.detailsSubHeader} onBack={handleBack} title="Scholarship Details" />
		</div>
	);
};
