import * as React from 'react';
import classNames from 'classnames';
import { Button } from '@scholly/elements';

import { deactivateAccount as deactivateYourAccount } from '../../../actions';
import { FlashContext, UserContext } from '../../../contexts';

import { CustomModal } from '../../../components/modal/Modal';

import styles from '../settings.scss';

type IPorps = {
	visible: boolean;
	onClose: () => void;
	closeDeactivateModal: () => void;
};
const DeactivateAccountForm: React.FC<IPorps> = ({ visible, onClose, closeDeactivateModal }) => {
	const flashContext = React.useContext(FlashContext);
	const userContext = React.useContext(UserContext);
	const deactivateAccount = async () => {
		try {
			await deactivateYourAccount();
			flashContext.display({ text: 'Account Successfully Deactivated', type: 'success' });
			setTimeout(() => {
				userContext.logout();
			}, 1000);
			closeDeactivateModal();
		} catch (error) {
			console.log(error);
			flashContext.display({ text: 'There has been a server error', type: 'error' });
		}
	};
	return (
		<CustomModal visible={visible}>
			<div className={classNames(styles.modalContainer, 'px-4')}>
				<div className={classNames(styles.title, 'mx-auto mt-4')}>
					<p className={styles.textLabel}>Deactivate Account</p>
				</div>

				<div className={classNames(styles.label, 'mx-auto pb-1 mb-4 has-text-centered')}>
					Are you sure you want to deactivate your account? This means you will no longer receive emails or
					updates.
				</div>

				<div className={classNames(styles.isFlex, styles.isFlexColumn)}>
					<Button onClick={onClose} variant={'primary'}>
						No
					</Button>

					<Button onClick={deactivateAccount} variant={'tetiary'}>
						Yes, Deactivate Account
					</Button>
				</div>
			</div>
		</CustomModal>
	);
};

export default DeactivateAccountForm;
