import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import classNames from 'classnames';
import { Button, ResourceCard } from '@scholly/elements';
import * as Sentry from '@sentry/react';

import { checkFeature, Feature, PromoContext, ScholarshipContext, UserContext, SessionContext } from '../../contexts';
import { getDashboard, getLocalUser } from '../../actions';

import DashboardLoader from './dashboard-loader';
import { MatchBanner, OffersBanner, OffersBannerMobile } from '../../components/banners';
import ErrorBoundary from '../../components/misc/ErrorBoundary';
import { ExclusiveOffers } from '../../components/offers/exclusive-offers/ExclusiveOffers';
import Exclusives from '../../components/exclusives';
import MultiSlider from '../../components/multi-slider/multi-slider';
import OfferCompletionModal from '../../components/offer-completion-modal/offer-completion-modal';
import { OfferModal } from '../../components/promo/offer-modal/offer-modal';
import RecentWinnerThumbnail from '../../components/recent-winner-thumbnail/recent-winner-thumbnail';
import ScholarshipCard from '../../components/ScholarshipCard';

import { FLAGS, LIFETIME_CODE, Status, HTTPStatus, Routes, STORAGE_KEYS } from '../../constants';

import { isClickWithinLastTwoWeeks } from '../../helpers/Functions';
import { useActionClick } from '../../helpers/hooks/useActionClick';
import { ScholarshipClickSourceIndex } from '../../types';

import styles from './dashboard.scss';

const objData = {
	title: '',
	data: [],
};

const options = {
	revalidateOnFocus: false,
	dedupingInterval: 10000,
};

interface Section {
	title: string;
	data: any[];
	horizontal?: boolean;
	type?: string;
	url?: string;
}

const Dashboard: FC = (props) => {
	const history = useHistory();
	const { actionClick } = useActionClick(history);
	const { isAuthenticated } = useContext(SessionContext);
	const { offerModalShowed, setOfferModalShowed } = useContext(PromoContext);
	const [sections, setSections] = useState<Section[]>([objData]);
	const [postCount, setPostCount] = useState<number>(4);
	const [isLoading, setIsLoading] = useState(true);
	const userContext = useContext(UserContext);
	const { scholarshipSaved, scholarshipApplied } = useContext(ScholarshipContext);
	const pullr = isAuthenticated === Status.RESOLVED;
	const { data } = useSWR(['dashboard', pullr], getDashboard, options);
	const { cache } = useSWRConfig();
	const [displayOfferCompletionModal, setDisplayOfferCompletionModal] = useState(false);
	const [displayOfferPromoModal, setDisplayOfferPromoModal] = useState(false);
	const isUpdateProfile = checkFeature(FLAGS.UPDATE_PROFILE);

	const setData = useCallback(async () => {
		const res = data;
		try {
			const changed = res.data
				.filter((d) => d.latest.length !== 0)
				.map((section: { latest: Array<any>; data: Array<any>; type: string }) => {
					if (section.type == 'featured_scholarships') {
						if (scholarshipSaved.length > 0) {
							scholarshipSaved.forEach((scholarship: any) => {
								section.latest = section.latest.map((item: any) => {
									if (item.id == scholarship.id) {
										return { ...item, progress: scholarship.progress };
									}
									return item;
								});
							});
						}
						if (scholarshipApplied.length > 0) {
							scholarshipApplied.forEach((scholarship: any) => {
								section.latest = section.latest.map((item: any) => {
									if (item.id == scholarship.id) {
										return { ...item, progress: scholarship.progress };
									}
									return item;
								});
							});
						}
					}

					section['horizontal'] = section.type === 'recent_winners';
					section.data = section.latest;
					return section;
				});
			setSections(changed);
		} catch (error) {
			console.error(error);
		}
	}, [data, scholarshipSaved, scholarshipApplied]);

	useEffect(() => {
		const checkUpdateProfile = async () => {
			if (isAuthenticated && isUpdateProfile && !localStorage.getItem(STORAGE_KEYS.UPDATE_PROFILE)) {
				history.push(Routes.user.updateProfile);
			}
		};
		checkUpdateProfile();
	}, [isUpdateProfile]);

	useEffect(() => {
		if (data && data.status === HTTPStatus.OK) {
			setData();
			setIsLoading(false);
		}
	}, [data, setData, scholarshipSaved, scholarshipApplied]);

	useEffect(() => {
		if (
			isAuthenticated === Status.RESOLVED &&
			!displayOfferCompletionModal &&
			!isClickWithinLastTwoWeeks(STORAGE_KEYS.OFFER_PROMO)
		) {
			setDisplayOfferPromoModal(true);
		}
	}, [displayOfferCompletionModal, isAuthenticated]);

	useEffect(() => {
		//@ts-ignore
		if (userContext.userLoaded) {
			document.body.scrollIntoView();
		}
		//@ts-ignore
	}, [userContext.userLoaded]);

	useEffect(() => {
		const fetchUser = async () => {
			const user = await getLocalUser();
			const registration_code = user?.merchant?.transactions.filter((t) => t.order_type === 'joincode')[0];

			if (registration_code && !localStorage.getItem('offerCompletionModal')) {
				setDisplayOfferCompletionModal(Boolean(registration_code?.code === LIFETIME_CODE));
				setTimeout(() => {
					localStorage.setItem('offerCompletionModal', 'already_displayed');
				}, 1000);
			}
		};

		fetchUser();
	}, []);

	const sendError = (error, errorInfo) => {
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			Sentry.captureException(error);
		});
	};

	const removeSectionByType = useCallback(
		(type) => {
			const newSections = sections.filter((section) => section.type !== type);
			setSections(newSections);
			// @ts-ignore
			cache.clear();
		},

		[cache, sections]
	);

	const handleCloseModal = () => {
		setDisplayOfferCompletionModal(false);
	};

	const handleCloseOfferPromoModal = (value: boolean) => {
		setOfferModalShowed(true);
		setDisplayOfferPromoModal(value);
	};

	const renderSectionHeader = (section) => <h2 className={styles.heading}>{section.title}</h2>;
	const renderItem = (item: any, section: any, index: number) => {
		if (section.type === 'posts') {
			const image = item.image ? item.image.find((x) => x !== undefined) : { url: '', title: '' };
			return (
				<div className={styles.resourceCardWrapper}>
					<ResourceCard
						renderIf={index < postCount}
						image={{
							src: image?.url,
						}}
						title={item.title}
						onClick={actionClick(item?.url)}
					/>
				</div>
			);
		}

		const updateScholarships = (id, scholarship) => {
			const newSectionData = section.data
				.map((item) => {
					return item.id === id ? scholarship : item;
				})
				.filter((item) => !!item);
			const updatedSections = sections.find((s) => s.type === section.type);
			updatedSections.data = newSectionData;

			if (!newSectionData?.length) {
				removeSectionByType('scholarships');
			}
		};

		if (section.type === 'scholarships' || section.type === 'featured_scholarships') {
			return (
				<div className={styles.scholarshipCardWrapper}>
					<ScholarshipCard
						scholarshipItem={item}
						isLarge
						updateNotFeedItem={updateScholarships}
						source={ScholarshipClickSourceIndex.DASHBOARD}
						position={index + 1}
					/>
				</div>
			);
		}
		return null;
	};

	const renderSectionFooter = (section) => {
		let text = 'Load More';
		let onClick = () => {};

		if (section.type === 'scholarships') {
			text = 'View All Matched Scholarships';
			//@ts-ignore
			onClick = () => history.push(Routes.scholarships.feed);
		}
		if (section.type === 'posts') {
			if (section.data.length < postCount) return null;
			onClick = () => {
				setPostCount(postCount + 4);
			};
		}

		const updateScholarships = (items) => {
			const newSectionData = items;
			const updatedSections = sections.find((s) => s.type === section.type);
			updatedSections.data = newSectionData;
			// @ts-ignore
			cache.clear();
		};

		if (section.type === 'quick_apply' && section?.data?.length !== 0) {
			return (
				<Exclusives
					data={section.data}
					updateScholarships={updateScholarships}
					removeSection={removeSectionByType}
				/>
			);
		} else if (section.type === 'recent_winners' && section?.data?.length) {
			return <RecentWinners winners={section?.data} />;
		}

		return (
			<Button variant="secondary" size="lg" onClick={onClick}>
				{text}
			</Button>
		);
	};

	const keyExtractor = (item, index) => `${item.title}-${index}`;
	const itemKeyExtractor = (item, index) => `${item.title}-${index}`;
	const isRow = (section: Section) => section?.type === 'posts' || section.horizontal;

	const postSection = sections.filter((section) => section.type === 'posts');
	const latestScholarshipSection = sections.filter((section) => section.type === 'scholarships');
	const featuredScholarshipSection = sections.filter((section) => section.type === 'featured_scholarships');

	const renderFeaturedScholarshipsSection = featuredScholarshipSection.map((section, index) => {
		return (
			<div className={styles.sectionContainer} key={keyExtractor(section, index)}>
				<div className={styles.sectionHeader}>{renderSectionHeader(section)}</div>
				<div className={classNames(styles.sectionItemContainer, isRow(section) && styles.row)}>
					{section.data.map((item, index) => (
						<div
							className={classNames(styles.sectionItem, !isRow(section) && styles.isFlex)}
							key={itemKeyExtractor(item, index)}
						>
							{renderItem(item, section, index)}
						</div>
					))}
				</div>
			</div>
		);
	});
	const renderLatestScholarshipsSection = latestScholarshipSection.map((section, index) => {
		return (
			<div className={styles.sectionContainer} key={keyExtractor(section, index)}>
				<div className={styles.sectionHeader}>{renderSectionHeader(section)}</div>
				<div className={classNames(styles.sectionItemContainer, isRow(section) && styles.row)}>
					{section.data.slice(0, postCount).map((item, index) => (
						<div
							className={classNames(styles.sectionItem, !isRow(section) && styles.isFlex)}
							key={itemKeyExtractor(item, index)}
						>
							{renderItem(item, section, index)}
						</div>
					))}
				</div>
				<div className={styles.sectionFooter}>{renderSectionFooter(section)}</div>
			</div>
		);
	});

	const renderResourcesSection = postSection.map((section, index) => {
		return (
			<div className={styles.sectionContainer} key={keyExtractor(section, index)}>
				<div className={styles.sectionHeader}>{renderSectionHeader(section)}</div>
				<div className={classNames(styles.sectionItemContainer, isRow(section) && styles.row)}>
					{section.data.slice(0, postCount).map((item, index) => (
						<div
							className={classNames(styles.sectionItem, !isRow(section) && styles.isFlex)}
							key={itemKeyExtractor(item, index)}
						>
							{renderItem(item, section, index)}
						</div>
					))}
				</div>
				<div className={styles.sectionFooter}>{renderSectionFooter(section)}</div>
			</div>
		);
	});

	return (
		<ErrorBoundary logErrorToService={sendError}>
			{isLoading || !userContext.isUserLoaded ? (
				<DashboardLoader />
			) : (
				<div className={styles.dashboardContainer}>
					<div className={styles.bannerContainer}>
						<MatchBanner className={styles.matchBannerStyle} />

						<div className={styles.bannerContainerShare}>
							<Feature name={FLAGS.OFFER_BANNER}>
								<OffersBanner className={styles.offersBanner} />
							</Feature>
						</div>
					</div>
					<div className={styles.pageContentWrapper}>
						<div className={styles.sectionlistContainer}>
							{renderFeaturedScholarshipsSection}
							{renderLatestScholarshipsSection}
							<Feature name={FLAGS.OFFER_DASHBOARD}>
								<ExclusiveOffers />
							</Feature>

							{renderResourcesSection}
						</div>
					</div>
					<Feature name={FLAGS.OFFER_TAB}>
						<OfferCompletionModal isOpen={displayOfferCompletionModal} handleClose={handleCloseModal} />
					</Feature>
					<Feature name={FLAGS.OFFER_MODAL}>
						<OfferModal onChange={handleCloseOfferPromoModal} isShow={displayOfferPromoModal} />
					</Feature>
					<Feature name={FLAGS.OFFER_BANNER}>
						<OffersBannerMobile />
					</Feature>
				</div>
			)}
		</ErrorBoundary>
	);
};
export default Dashboard;

const RecentWinners = ({ winners }: { winners: any[] }) => (
	<div className={`container`}>
		<div className={styles.multisliderWrapper}>
			<MultiSlider>
				{winners.map((winner, index) => {
					return (
						<RecentWinnerThumbnail
							variant={Number(index)}
							name={winner.name}
							campaign={winner?.campaign}
							imageUrl={winner?.url}
							key={`${winner.name}-${index}`}
						/>
					);
				})}
			</MultiSlider>
		</div>

		<div className={styles.recentWinnerTextContainer}>
			<p>
				Have you ever won a scholarship using Scholly? Email us today at{' '}
				<a href="mailto:success@myscholly.com">success@myscholly.com</a>
			</p>
		</div>
	</div>
);
