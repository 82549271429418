import React, { CSSProperties, FC } from 'react';

interface Props {
	svgStyle?: CSSProperties;
}

export const PasswordIcon: FC<Props> = (props) => {
	const { svgStyle } = props;
	return (
		<div>
			<div className="content">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="18"
					height="22"
					viewBox="0 0 18 22"
					fill="none"
					style={svgStyle}
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M8.99999 2.33268C7.21497 2.33268 5.74999 3.79766 5.74999 5.58268V8.83268H12.25V5.58268C12.25 3.79766 10.785 2.33268 8.99999 2.33268ZM14.4167 8.83268V5.58268C14.4167 2.60104 11.9816 0.166016 8.99999 0.166016C6.01835 0.166016 3.58333 2.60104 3.58333 5.58268V8.83268H2.49999C1.30338 8.83268 0.333328 9.80273 0.333328 10.9993V19.666C0.333328 20.8626 1.30338 21.8327 2.5 21.8327H15.5C16.6966 21.8327 17.6667 20.8626 17.6667 19.666V10.9993C17.6667 9.80273 16.6966 8.83268 15.5 8.83268H14.4167ZM2.49999 10.9993V19.666H15.5V10.9993H2.49999Z"
						fill="#A0A3BD"
					/>
				</svg>
			</div>
		</div>
	);
};
