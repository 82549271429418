import React from 'react';
import { Offer } from '../../../types/offers';
import styles from './styles.scss';
import config from '../../../config/config';
import classNames from 'classnames';

interface Props {
	offer: Offer;
	isFromFeed?: boolean;
}
export const ExclusiveOfferCard = ({ offer, isFromFeed }: Props) => {
	const { affiliate_subtext, display, affiliate, amount, incentive_amount } = offer;
	const { name, avatar_img, slug } = affiliate || {};
	const entryAmount = `$${amount + incentive_amount}`;

	const onClickInfo = () => {
		window.open(config.offers_url + slug, '_blank');
	};

	return (
		<div
			className={classNames(styles.exclusiveOfferCard, isFromFeed && styles.exclusiveOfferCardFeed)}
			onClick={onClickInfo}
		>
			<div>
				<img
					className={styles.exclusiveOfferCardLogo}
					src={`${config.cdn}images/offers/affiliate-imgs/${avatar_img || 'default-avatar.png'}`}
					alt="Offer Avatar"
				/>
			</div>
			<div className={styles.exclusiveOfferCardMiddle}>
				<h3 className={styles.exclusiveOfferCardMiddleTitle}>{name}</h3>
				<p className={styles.exclusiveOfferCardMiddleSubtext}>{display}</p>
			</div>
			<div>
				<p className={styles.exclusiveOfferCardAmount}>{entryAmount}</p>
				<p className={styles.exclusiveOfferCardText}>{affiliate_subtext}</p>
			</div>
		</div>
	);
};
