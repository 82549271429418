import {
	AppliedL,
	AppliedS,
	Ending2L,
	Ending2S,
	EndingL,
	EndingS,
	NaL,
	NaS,
	NewL,
	NewS,
	Temporary,
	WonL,
	WonS,
} from '@scholly/elements';

import React from 'react';
import styles from './styles.scss';

interface Props {
	variant: 'new' | 'left' | 'left_rounded' | 'applied' | 'won' | 'not_awarded' | 'temporary' | 'viewed' | 'expired';
	size?: 'l' | 's';
	text?: string;
	background?: string;
}

interface BadgeProps {
	text?: string;
	background?: string;
	size?: 'l' | 's';
}

const Badge = (props: Props) => {
	const { variant } = props;
	return (
		<>
			{variant === 'new' && <NewBadge {...props} />}
			{variant === 'viewed' && <ViewedBadge {...props} />}
			{variant === 'left' && <LeftBadge {...props} />}
			{variant === 'left_rounded' && <LeftRoundedBadge {...props} />}
			{variant === 'applied' && <AppliedBadge {...props} />}
			{variant === 'won' && <WonBadge {...props} />}
			{variant === 'not_awarded' && <NotAwardedBadge {...props} />}
			{variant === 'temporary' && <TemporaryBadge {...props} />}
			{variant === 'expired' && <TemporaryBadge {...props} />}
		</>
	);
};

// ! THESE VIEWED BADGES ARE TEMPORARY UNTIL SCHOLLY/ELEMENTS VERSION ERROR IS FIXED
const ViewedS = (props: BadgeProps): JSX.Element => {
	let { text } = props;

	return (
		<div>
			<div className={styles.viewedS}>
				<div>
					<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M9.3056 4.90581C9.3056 5.76848 8.60628 6.46779 7.74362 6.46779C6.88095 6.46779 6.18164 5.76846 6.18164 4.90581C6.18164 4.04313 6.88097 3.34375 7.74362 3.34375C8.6063 3.34375 9.3056 4.04313 9.3056 4.90581Z"
							fill={'#a0a3bd'}
						/>
						<path
							d="M7.744 0C4.312 0 1.364 2.024 0 4.928C1.36396 7.832 4.312 9.856 7.744 9.856C11.176 9.856 14.124 7.832 15.488 4.928C14.102 2.002 11.154 0 7.744 0ZM7.744 8.668C5.676 8.668 3.982 6.97404 3.982 4.906C3.982 2.838 5.67596 1.144 7.744 1.144C9.81204 1.144 11.506 2.83796 11.506 4.906C11.506 6.974 9.81204 8.668 7.744 8.668Z"
							fill={'#a0a3bd'}
						/>
					</svg>
				</div>
				<span>{text ? text : 'Viewed'}</span>
			</div>
		</div>
	);
};

// ! THESE VIEWED BADGES ARE TEMPORARY UNTIL SCHOLLY/ELEMENTS VERSION ERROR IS FIXED
const ViewedL = (props: BadgeProps): JSX.Element => {
	let { text } = props;
	return (
		<div>
			<div className={styles.viewedL}>
				<div>
					<svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M12.1877 6.4705C12.1877 7.60836 11.2791 8.53074 10.1583 8.53074C9.03749 8.53074 8.12891 7.60833 8.12891 6.4705C8.12891 5.33263 9.03751 4.41016 10.1583 4.41016C11.2792 4.41016 12.1877 5.33263 12.1877 6.4705Z"
							fill={'#a0a3bd'}
						/>
						<path
							d="M10.1581 0C5.69909 0 1.86887 2.66964 0.0966797 6.5C1.86882 10.3304 5.69909 13 10.1581 13C14.6172 13 18.4474 10.3304 20.2196 6.5C18.4189 2.64062 14.5886 0 10.1581 0ZM10.1581 11.433C7.47127 11.433 5.27033 9.19871 5.27033 6.47098C5.27033 3.7433 7.47123 1.50893 10.1581 1.50893C12.8451 1.50893 15.046 3.74325 15.046 6.47098C15.046 9.19866 12.8451 11.433 10.1581 11.433Z"
							fill={'#a0a3bd'}
						/>
					</svg>
				</div>
				<span>{text ? text : 'Viewed'}</span>
			</div>
		</div>
	);
};

const NewBadge = ({ text, size }: BadgeProps) => {
	const New = size === 's' ? NewS : NewL;
	return <New text={text} />;
};

const ViewedBadge = ({ text, size, background = 'transparent' }: BadgeProps) => {
	const Viewed = size === 's' ? ViewedS : ViewedL;
	// return <Viewed text={text} backgroundColor={background} />;
	return <Viewed text={text} />;
};

const LeftBadge = ({ text, size, background }: BadgeProps) => {
	const Left = size === 's' ? EndingS : EndingL;
	return (
		<div className={styles.leftText}>
			<Left text={text} backgroundColor={background} />
		</div>
	);
};

const LeftRoundedBadge = ({ text, size, background = 'white' }: BadgeProps) => {
	const LeftRounded = size === 's' ? Ending2S : Ending2L;
	return (
		<div className={styles.leftText}>
			<LeftRounded text={text} backgroundColor={background} />
		</div>
	);
};

const AppliedBadge = ({ text, size, background }: BadgeProps) => {
	const Applied = size === 's' ? AppliedS : AppliedL;
	return <Applied text={text} backgroundColor={background} />;
};

const WonBadge = ({ text, size, background }: BadgeProps) => {
	const Won = size === 's' ? WonS : WonL;
	return <Won text={text} backgroundColor={background} />;
};

const NotAwardedBadge = ({ text, size, background }: BadgeProps) => {
	const NotAwarded = size === 's' ? NaS : NaL;
	return <NotAwarded text={'Not Awarded'} backgroundColor={background} />;
};

const TemporaryBadge = ({ text, background }: BadgeProps) => {
	return <Temporary text={text} backgroundColor={background} />;
};
const ExpiredBadge = ({ text, background }: BadgeProps) => {
	return <Temporary text={text} backgroundColor={background} />;
};

export {
	TemporaryBadge,
	NotAwardedBadge,
	WonBadge,
	NewBadge,
	LeftRoundedBadge,
	LeftBadge,
	AppliedBadge,
	Badge,
	ExpiredBadge,
};
