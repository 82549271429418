import * as _ from 'lodash';

import { useEffect, useRef, useState } from 'react';

import config from '../config/config';
import findLast from 'lodash/findLast';
import { getLocalUser } from '../actions';
import moment from 'moment';
import { useDebounce } from 'use-debounce';

type SchollyResponse = {
	status: string;
	data: any;
	error: any;
	warning: any;
};
const api = config.API.url;

// NOTE: used for user_type parameter values. Need to move possible answers to backend, now it's hardcoded in app
export const isDEV = process.env.NODE_ENV === 'development' || api.includes('dev') || api.includes('stage');
export const userTypeParamId = isDEV ? (api.includes('stage') ? '16' : '20') : '18';

export const basicRequired = ['first_name', 'last_name', 'birthday'];
const required = [
	...basicRequired,
	'citizen',
	'gpa',
	'degree',
	'major',
	'race',
	'gender',
	'grade',
	'enrollment_status',
];

const ageAdult = 13;
const CCPA_AGE = 16;

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const responseParser = (res: SchollyResponse | any) => {
	if (res?.status !== 'OK') {
		if (Array.isArray(res?.error)) {
			if (res?.error?.length === 0) throw new Error(res.status);
			const tokenError = res.error.find((e) => e.field === 'token');
			if (tokenError) {
				throw tokenError;
			}
		}
		throw res.error || new Error('An error has occurred');
	}
	return res;
};

export const getFormatDate = (date: Date) => {
	return date.toISOString().split('T')[0];
};

export function pickedUserInfo<T extends object, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
	const result: Partial<T> = {};
	keys.forEach((key) => {
		result[key] = obj[key];
	});
	return result as Pick<T, K>;
}

export const alphaSort = (arr: Array<any>, index: any) => {
	return arr.sort(function (a, b) {
		if (a[index] < b[index]) {
			return -1;
		}
		if (a[index] > b[index]) {
			return 1;
		}
		return 0;
	});
};

const filterParamById = (parameters, id) => parameters.filter((p: { parameter_id: string }) => p.parameter_id === id);

export const userDerivedStateFromProps = (props: any) => {
	return {
		first_name: props.first_name,
		last_name: props.last_name,
		birthday: props.birthday,
		major: props.major,
		gpa: props.gpa,
		grade: props.grade,
		citizen: props.citizen,
		race: props.race,
		gender: props.gender,
		user_type: props.user_type,
		need_merit: props.need_merit.includes('Merit') ? '96' : '95',
		degree: props.degree,
		enrollment_status: props.enrollment_status,
		goal: props.goal,
		school: props.school,
		location: props.location,
		phone_number: props.phone_number,
	};
};

export const userParamCreate = (parameters: Array<any>, appParams?: any) => {
	let obj = {};

	let parameterObj = Object.assign(
		//@ts-ignore
		...parameters.map(({ string_id, parameter_id }) => ({
			[string_id]: parameter_id,
		}))
	);

	if (!parameterObj || typeof parameterObj !== 'object' || Object.keys(parameterObj).some((v) => v === 'undefined')) {
		parameterObj = Object.assign(
			//@ts-ignore
			...appParams.map(({ string_id, id }) => ({
				[string_id]: id,
			}))
		);
	}

	for (const property in parameterObj) {
		let id = parameterObj[property];

		const filtered = filterParamById(parameters, id);
		//@ts-ignore
		filtered.forEach((data) => {
			if (['need_merit', 'race', 'major', 'misc'].includes(property)) {
				//@ts-ignore
				if (obj[property]) {
					//@ts-ignore
					obj[property] = {
						//@ts-ignore
						value: [...obj[property].value, data.id],
					};
				} else {
					//@ts-ignore
					obj[property] = {
						value: [data.id],
					};
				}
			} else {
				//@ts-ignore
				obj[property] = {
					value: data.id,
				};
			}
		});
	}

	return obj;
};

export const onboardingDerivedStateFromLocal = async (params?: any) => {
	const local = await getLocalUser();
	const answers = Object.assign(
		//@ts-ignore
		...Object.keys(params).map((key) => ({
			[key]: '',
		}))
	);

	const { parameters } = local;

	parameters.forEach((element) => {
		let key = element.string_id;
		if (Object.prototype.hasOwnProperty.call(params, key)) {
			const obj = params[key];
			let found = obj.find((o) => element.id === o.id);
			answers[key] = found.id;
		}
		key = element.parameter_id;
		for (const k in params) {
			const obj = params[k];
			let found = obj.find((o) => key === o.parameter_id && element.value === o.value);
			if (found) {
				answers[k] = found.id;
			}
		}
	});

	return {
		...answers,
		email: local.email,
		first_name: local.first_name || '',
		last_name: local.last_name || '',
		phone_number: local.phone_number || '',
		birthday: local.birthday ? moment(local.birthday).format('YYYY-MM-DD') : '',
	};
};

export const parseUserParamsData = (user: any) => {
	// * NOTE: Location was removed, api does not return location in `updateParamterValues` caused error
	const { parameters, schools, location } = user;
	const miscParam = parameters.filter((p: { parameter_id: string }) => p.parameter_id === '8');
	const majorParam = parameters.filter((p: { parameter_id: string }) => p.parameter_id === '7');
	const gpaParam = parameters.find((p: { parameter_id: string }) => p.parameter_id === '4');
	const gradeLevelParam = findLast(parameters, (p: { parameter_id: string }) => p.parameter_id === '3');
	const citizenParam = parameters.find((p: { parameter_id: string }) => p.parameter_id === '10');
	const raceParam = parameters.filter((p: { parameter_id: string }) => p.parameter_id === '6');
	const genderParam = parameters.find((p: { parameter_id: string }) => p.parameter_id === '1');
	const needMeritParam = parameters.find((p: { parameter_id: string }) => p.parameter_id === '5');
	const degreeParam = parameters.find((p: { parameter_id: string }) => p.parameter_id === '9');
	const enrollmentStatusParam = parameters.find((p: { parameter_id: string }) => p.parameter_id === '13');
	const userTypeParam = parameters.find((p: { parameter_id: string }) => p.parameter_id === userTypeParamId);

	return {
		misc: miscParam ? miscParam : [],
		major: majorParam ? majorParam : [],
		gpa: gpaParam ? gpaParam.value : '',
		grade: gradeLevelParam ? gradeLevelParam.value : '',
		citizen: citizenParam ? citizenParam.value : '',
		user_type: userTypeParam ? userTypeParam.value : '',
		race: raceParam ? raceParam : [],
		gender: genderParam ? genderParam.value : '',
		need_merit: needMeritParam ? needMeritParam.id : '',
		degree: degreeParam ? degreeParam.value : '',
		enrollment_status: enrollmentStatusParam ? enrollmentStatusParam.value : '',
		school: schools,
		location: !location ? '' : location.display,
	};
};

export const commafy = (num: number) => {
	var str = num.toString().split('.');
	if (str[0].length >= 4) {
		str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
	}
	if (str[1] && str[1].length >= 5) {
		str[1] = str[1].replace(/(\d{3})/g, '$1 ');
	}
	return str.join('.');
};

export const unCommafy = (str: string) => {
	var num = str.replace(',', '');
	return parseInt(num);
};

export const missingReq = (obj: any) => {
	if (null === obj) return true;
	const { parameters, schools, location, ...rest } = obj;
	if (!checkBasic(rest) || !search(parameters)) {
		return true;
	}
	return false;
};

const search = (arr: any) => {
	if (Array.isArray(arr) && arr.length === 0) return false;
	const requiredParameters = ['4', '7', '6', '9', '10', '13', '1', '3'];
	const parameterIds = arr.filter((obj) => !['8', '12'].includes(obj.parameter_id)).map((obj) => obj.parameter_id);
	const uniqueParamIds = _.intersection(requiredParameters.sort(), parameterIds.sort());
	return _.isEqual(requiredParameters.sort(), uniqueParamIds.sort());
};

export const checkBasic = (obj: any, arr: string[] = []) => {
	for (let [key, _value] of Object.entries(obj)) {
		if ([...basicRequired, ...arr].includes(key)) {
			const newObj = (({ first_name, last_name }) => ({
				first_name,
				last_name,
			}))(obj);

			if (!check(newObj)) {
				return false;
			}
		}
	}

	return true;
};

export const check = (obj: any) => {
	for (var key in obj) {
		if (typeof obj[key] === 'object') {
			if (!check(obj[key])) return false;
		}
		if (obj[key] === null || obj[key] === '') return false;
	}

	return true;
};

export const compare = (obj1, obj2) => _.isEqual(obj1, obj2);

export const whatswhats = (obj: any) => {
	let missing = [];
	for (var key in obj) {
		if (required.includes(key)) {
			if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
				if (!check(obj[key])) missing.push(key);
			}
			if (obj[key] === null || obj[key] === '') {
				missing.push(key);
			}
			if (Array.isArray(obj[key])) {
				if (obj[key].length < 1) {
					missing.push(key);
				}
			}
			if (key === 'goal' && obj[key] === '0') {
				missing.push(key);
			}
		}
	}
	return missing;
};

// TODO: Func returns array of keys of missing fields

export function parseParametersIntoCategories(parameters: any) {
	if (typeof parameters === 'undefined') return [];
	let categories = {};

	parameters.forEach((parameter) => {
		if (parameter.category == null) return;
		categories['icon_' + parameter.category.name] = true;
	});

	return Object.keys(categories);
}

export function useSearch(
	query: string,
	request: {
		(address: string): Promise<any>;
		(school: string): Promise<any>;
		(arg0: any): { then: (arg0: (res: any) => void) => void };
	},
	delay = 50,
	neglectValue = '',
	minChar = 0
) {
	const [response, setResponse] = useState(null);
	const [debouncedQuery] = useDebounce(query, delay);
	// @ts-ignore
	useEffect(() => {
		let isActive = true;
		if (debouncedQuery !== neglectValue && debouncedQuery.length >= minChar) {
			request(debouncedQuery).then((res) => {
				if (isActive) setResponse(res);
			});
		} else {
			setResponse(null);
		}
		return () => (isActive = false);
	}, [debouncedQuery, minChar, neglectValue, request]);
	return response;
}

export function formatNumber(num: number | string) {
	return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function formatBirthday(format: string, date: string) {
	return date === '' ? date : moment(date).format(format || 'YYYY-MM-DD');
}

export function useHover() {
	const [value, setValue] = useState(null);
	const ref = useRef(null);
	const handleMouseOver = () => setValue(true);
	const handleMouseOut = () => setValue(false);
	useEffect(() => {
		const node = ref.current;

		if (node) {
			node.addEventListener('mouseover', handleMouseOver);
			node.addEventListener('mouseout', handleMouseOut);
			return () => {
				node.removeEventListener('mouseover', handleMouseOver);
				node.removeEventListener('mouseout', handleMouseOut);
			};
		}
	}, [ref]);
	return [ref, value];
}

export function uppercaseAfterDash(str: string) {
	return str
		.split('-')
		.map((e) => e.charAt(0).toUpperCase() + e.slice(1))
		.join('-')
		.split(' ')
		.map((e) => e.charAt(0).toUpperCase() + e.slice(1))
		.join(' ');
}
export function isEquivalent(a: object, b: object) {
	var aProps = Object.getOwnPropertyNames(a);
	var bProps = Object.getOwnPropertyNames(b);
	if (aProps.length != bProps.length) {
		return false;
	}
	for (var i = 0; i < aProps.length; i++) {
		var propName = aProps[i];
		if (a[propName] !== b[propName]) {
			return false;
		}
	}
	return true;
}
export function formatPhoneNumber(phoneNumber: string): string {
	const cleaned = ('' + phoneNumber).replace(/\D/g, '');
	const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		return '(' + match[1] + ') ' + match[2] + '-' + match[3];
	} else if (cleaned.length === 10) {
		// format for 10 digit phone number
		return '(' + cleaned.substr(0, 3) + ') ' + cleaned.substr(3, 3) + '-' + cleaned.substr(6);
	} else if (cleaned.length === 11 && cleaned[0] === '1') {
		// format for 11 digit US phone number with country code 1
		return '(' + cleaned.substr(1, 3) + ') ' + cleaned.substr(4, 3) + '-' + cleaned.substr(7);
	}
	return phoneNumber;
}

export function blurAll() {
	var tmp = document.createElement('input');
	document.body.appendChild(tmp);
	tmp.focus();
	document.body.removeChild(tmp);
}
export function kFormatter(num: number) {
	return Math.abs(num) > 999
		? // @ts-ignore
		  Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
		: Math.sign(num) * Math.abs(num);
}

export function isAdult(birthday: string) {
	if (birthday === '') return false;

	const birthdayInstance = moment(birthday, 'YYYY-MM-DD').toDate();
	const nowMoment = moment(new Date());
	const years = nowMoment.diff(birthdayInstance, 'years');

	return years >= CCPA_AGE;
}

export function getLastAdultBirthday() {
	let date = new Date();
	date.setFullYear(new Date().getFullYear() - ageAdult);

	return date;
}

//@ts-ignore
export function debounceClick(func, timeount = 300) {
	let timer: NodeJS.Timeout | undefined;
	return (...args: any) => {
		if (!timer) {
			//@ts-ignore
			return func.apply(this, args);
		}
		//@ts-ignore
		clearTimeout(timer);
		//@ts-ignore
		timer = setTimeout(() => {
			timer = undefined;
		}, timeount);
	};
}

export function isBottom(ref: React.RefObject<HTMLDivElement>) {
	if (!ref.current) {
		return false;
	}
	return ref.current.getBoundingClientRect().bottom <= window.innerHeight;
}

export const getParameterValueToSelect = (value, parameter_values) => {
	if (parameter_values) {
		if (Array.isArray(value)) {
			return value.map((v) => parameter_values.find((p) => p.value === v.value));
		} else return parameter_values.find((p) => p.value === value);
	}
};

export const scrollTo = (options) => {
	window.scrollTo(options);
};

export const isClickWithinLastTwoWeeks = (key: string) => {
	const TWO_WEEKS_IN_MS = 14 * 24 * 60 * 60 * 1000; // two weeks in milliseconds

	// Get the current date and time.
	const currentDate = new Date();

	// Retrieve the stored click date from AsyncStorage.
	const storedClickDate = localStorage.getItem(key);

	// If there's no stored click date, return false.
	if (!storedClickDate) {
		return false;
	}

	// Parse the stored click date string into a Date object.
	const clickDate = new Date(storedClickDate);

	// Calculate the time difference between the current date and the click date in milliseconds.
	const timeDiff = currentDate.getTime() - clickDate.getTime();

	// Check if the time difference is less than or equal to two weeks.
	return timeDiff <= TWO_WEEKS_IN_MS;
};

export const isClickWithinLastTime = (key: string, days = 1) => {
	const TWO_WEEKS_IN_MS = days * 24 * 60 * 60 * 1000; // days in milliseconds

	// Get the current date and time.
	const currentDate = new Date();

	// Retrieve the stored click date from AsyncStorage.
	const storedClickDate = localStorage.getItem(key);

	// If there's no stored click date, return false.
	if (!storedClickDate) {
		return false;
	}

	// Parse the stored click date string into a Date object.
	const clickDate = new Date(storedClickDate);

	// Calculate the time difference between the current date and the click date in milliseconds.
	const timeDiff = currentDate.getTime() - clickDate.getTime();

	// Check if the time difference is less than or equal to days.
	return timeDiff <= TWO_WEEKS_IN_MS;
};

export const storeClickDate = (key: string) => {
	const clickDate = new Date();
	localStorage.setItem(key, clickDate.toISOString());
};

export const getIsExpired = (date: Date | number) => moment(date).isBefore(moment());
