import * as React from 'react';

import * as Sentry from '@sentry/react';
import useSWR from 'swr';

import { SessionContext, UserContext } from '../contexts';

import { getParameters, getQuestions } from '../actions';
import { Status, HTTPStatus } from '../constants';
import { ParametersContextState, parametersReducer, ParametersTypes } from '../reducers';
import { QuestionModel, QuestionRequired } from '../types';

type ParameterContextProps = {
	children: JSX.Element;
};

export const ParameterContext = React.createContext<ParametersContextState | any>(null);
export const QuestionContext = React.createContext<ParametersContextState | any>(null);

export function withParameterContext<P extends ParametersContextState>(Component: React.ComponentClass): any {
	return function contextAwareComponent(props: Pick<P, Exclude<keyof P, keyof ParametersContextState>>): any {
		return (
			<ParameterContext.Consumer>
				{(values: any): any => <Component {...props} {...values} />}
			</ParameterContext.Consumer>
		);
	};
}

const initialState: ParametersContextState = {
	values: {},
	parameters: [],
	miscellaneous: [],
	isParametersLoaded: false,
	parametersLoadedStatus: Status.IDLE,
};

const options = {
	revalidateOnFocus: false,
	revalidateIfStale: true,
	dedupingInterval: 10000,
};
const init = (initialState: ParametersContextState) => initialState;
export const ParameterContextProvider: React.FC<ParameterContextProps> = (props) => {
	const [parameterStringId, setStringId] = React.useState(new Map());
	const [state, dispatch] = React.useReducer(parametersReducer, initialState, init);
	const { data } = useSWR('parameters', getParameters, options);

	const setParameterValues = async () => {
		const parameters = data.data?.parameters;
		const { values } = state;
		try {
			parameters.forEach((parameter: any) => (values[parameter.string_id] = parameter.values));

			// @ts-ignore
			let miscellaneous = parameters.find((p) => p.string_id === 'misc');
			const params = parameters.map((p) => p.active && p);
			const newPicked = new Map();
			params.forEach((element: { id: string; string_id: string }) => {
				newPicked.set(element.id, element.string_id);
			});
			setStringId(newPicked);

			dispatch({
				type: ParametersTypes.SetParameters,
				payload: {
					parameters: params,
					values,
					isParametersLoaded: true,
					status: Status.RESOLVED,
				},
			});
			dispatch({ type: ParametersTypes.SetMiscellaneous, payload: { miscellaneous } });
		} catch (error) {
			console.warn(error);
			dispatch({ type: ParametersTypes.SetStatus, payload: { status: Status.REJECTED } });
		}
	};

	React.useEffect(() => {
		if (data?.status && data?.status === HTTPStatus.OK) setParameterValues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]); // TODO Take a look at why setParameterValues can't be added

	return (
		<ParameterContext.Provider value={{ ...state, parameterStringId }}>{props.children}</ParameterContext.Provider>
	);
};

export const useParameterState = () => {
	const context = React.useContext(ParameterContext);
	if (context === undefined) {
		console.warn('useCategoriesState must be used within a CategoriesProvider');
	}
	return context;
};

export const useQuestions = () => {
	const [fetch, revalidate] = React.useState(true);
	const [profile, setProfile] = React.useState(null);
	const [required, setRequired] = React.useState<QuestionRequired>({
		current_section: 0,
		total_questions: 1,
		total_sections: 0,
		completed: null,
	});
	const [question, setQuestion] = React.useState<QuestionModel>();
	const [questions, setQuestions] = React.useState<QuestionModel[]>([]);
	const [title, setTitle] = React.useState('');
	const [section_title, setSectionTitle] = React.useState('');
	const { isAuthenticated, endSession } = React.useContext(SessionContext);
	const { setOnboardingStatus } = React.useContext(UserContext);

	React.useEffect(() => {
		if (isAuthenticated === Status.RESOLVED && fetch) {
			getQuestions()
				.then((res) => {
					if (res.status === HTTPStatus.OK) {
						setProfile(res.data.profile.completed);
						setRequired(res.data.required);
						setQuestion(res.data.question);
						setQuestions(res.data.questions);
						setOnboardingStatus(res.data.required.completed);
						setTitle(res.data.question_title);
						setSectionTitle(res.data.section_title);
					}
				})
				.catch((error: any) => {
					Sentry.captureException(error);
					if (error?.status && error?.status === 401) {
						endSession();
					}
				})
				.finally(() => {
					revalidate(false);
				});
		}
	}, [isAuthenticated, fetch]);

	return { profile, required, question, questions, revalidate, title, section_title };
};
