import { isMobileDevice, isTabletDevice } from '../../helpers/checkIfDesktop';

import ContentLoader from 'react-content-loader';
import React from 'react';
import styles from './dashboard.scss';

const DashboardLoader = () => {
	const BannerLoader = () => {
		return (
			<ContentLoader width={'100%'} height={300} className={styles.loaderBanner}>
				{isMobileDevice && (
					<>
						<rect x="0" y="82" rx="5" ry="5" width={`100%`} height={104} />
						<rect x="0" y="282" rx="5" ry="5" width={`100%`} height={104} />
					</>
				)}
				{!isMobileDevice && (
					<>
						<rect x={isTabletDevice ? '5%' : '30%'} y="82" rx="5" ry="5" width={320} height={140} />
						<rect x="50%" y="82" rx="5" ry="5" width={320} height={140} />
					</>
				)}
			</ContentLoader>
		);
	};

	const CarouselLoader = () => {
		return (
			<ContentLoader height={600} width={'100%'} className={styles.loaderCarousel}>
				<rect
					x={isMobileDevice ? '0 ' : '37%'}
					y="10"
					rx="5"
					ry="5"
					width={isMobileDevice ? '60% ' : '20%'}
					height={20}
				/>

				{isMobileDevice && <rect x="0" y="auto" rx="5" ry="5" width={`100%`} height={104} />}
				{!isMobileDevice && (
					<>
						<rect x="1%" y="50" rx="5" ry="5" width={`30%`} height={260} />
						<rect x="32%" y="50" rx="5" ry="5" width={`30%`} height={260} />
						<rect x="63%" y="50" rx="5" ry="5" width={`30%`} height={260} />
					</>
				)}
				<rect
					x={isMobileDevice ? '0 ' : '35%'}
					y="350"
					rx="5"
					ry="5"
					width={isMobileDevice ? '60% ' : '25%'}
					height={50}
				/>
				<rect
					x={isMobileDevice ? '0 ' : '35%'}
					y="420"
					rx="5"
					ry="5"
					width={isMobileDevice ? '60% ' : '25%'}
					height={50}
				/>
			</ContentLoader>
		);
	};

	return (
		<div>
			<BannerLoader />
			<CarouselLoader />
		</div>
	);
};

export default DashboardLoader;
