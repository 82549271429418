import difference from 'lodash/difference';
import values from 'lodash/values';
import { Status } from '../constants';
import { ActionMap } from '../types';

export enum SearchDispatchTypes {
	ON_CHANGE_TEXT = 'ON_CHANGE_TEXT',
	SET_SORT = 'SET_SORT',
	SET_TYPE = 'SET_TYPE',
	TOGGLE_ESSAY = 'TOGGLE_ESSAY',
	TOGGLE_EXCLUSIVE = 'TOGGLE_EXCLUSIVE',
	CLEAR = 'CLEAR',
	SET_STATUS = 'SET_STATUS',
	SET_COUNT = 'SET_COUNT',
}

export const initialFilterState: SearchState = {
	sort_by: 'amount-high',
	type: '',
	has_essay: false,
	exclusive: false,
	status: Status.IDLE,
};

export interface SearchState {
	query?: string;
	sort_by?: string;
	type?: string;
	has_essay?: boolean;
	exclusive?: boolean;
	status?: Status.IDLE;
}

type SearchPayload = {
	[SearchDispatchTypes.ON_CHANGE_TEXT]: {
		query: string;
	};
	[SearchDispatchTypes.SET_SORT]: {
		sort_by: string;
	};
	[SearchDispatchTypes.SET_TYPE]: {
		type: string;
	};
	[SearchDispatchTypes.TOGGLE_ESSAY]: boolean;
	[SearchDispatchTypes.TOGGLE_EXCLUSIVE]: boolean;
	[SearchDispatchTypes.SET_STATUS]: Status;
	[SearchDispatchTypes.CLEAR]: SearchState;
};

export type SearchActions = ActionMap<SearchPayload>[keyof ActionMap<SearchPayload>];

export const searchReducer = (state: SearchState, actions: SearchActions) => {
	switch (actions.type) {
		case SearchDispatchTypes.TOGGLE_EXCLUSIVE:
			console.log(actions.payload);
			return { ...state, exclusive: actions.payload };
		case SearchDispatchTypes.TOGGLE_ESSAY:
			return { ...state, has_essay: actions.payload };
		case SearchDispatchTypes.SET_STATUS:
			return { ...state, status: actions.payload };
		case SearchDispatchTypes.SET_TYPE:
		case SearchDispatchTypes.SET_SORT:
		case SearchDispatchTypes.ON_CHANGE_TEXT:
			return { ...state, ...actions.payload };
		case SearchDispatchTypes.CLEAR:
			return { ...initialFilterState, status: state.status };
		default:
			return state;
	}
};

export const updateCount = (obj: Omit<SearchState, 'query' | 'status'>) => {
	return difference(values(obj), values(initialFilterState)).length;
};
export const SortBy = [
	{
		label: 'Effort Low to High',
		value: 'effort-low',
	},
	{
		label: 'Effort High to Low',
		value: 'effort-high',
	},
	{
		label: 'Deadline, Ascending',
		value: 'deadline-low',
	},
	{
		label: 'Deadline, Descending',
		value: 'deadline-high',
	},
	{
		label: 'Amount High to Low',
		value: 'amount-high',
	},
	{
		label: 'Amount Low to High',
		value: 'amount-low',
	},
];
