import { getToken } from '../actions';
import config from '../config/config';
import { responseParser } from '../helpers/Functions';

const api = config.API.url;

const controller = new AbortController();
export { controller };

const unauthorizedError = {
	status: 401,
	message: 'Unauthorized',
};

const setHeaders = async (contentType: string = 'application/json') => {
	let token = (await getToken()) || null;
	return {
		'Content-Type': contentType,
		...(token ? { token } : {}),
	};
};

export const fetcher = (endpoint: string, method: string = 'GET', data: any = {}) => {
	const { signal } = controller;
	let options = {
		signal,
		method,
		headers: {
			'Content-Type': 'application/json',
		},
	};
	if (method !== 'GET') options['body'] = JSON.stringify(data);
	return fetch(api + endpoint, options)
		.then((res) => res.json())
		.then((res) => responseParser(res))
		.catch((e) => {
			console.warn(e);
			return Promise.reject(e);
		});
};

export const get = async (endpoint: string, dev: boolean = false) => {
	const { signal } = controller;
	let url = api;
	return await fetch(url + endpoint, {
		signal,
		method: 'GET',
		headers: await setHeaders(),
	})
		.then((res) => {
			if (!res.ok && res.status === 401) {
				return Promise.reject(unauthorizedError);
			}
			return res.json();
		})
		.then((res) => responseParser(res))
		.catch((e) => {
			console.warn(e);
			return Promise.reject(e);
		});
};

export const post = async (endpoint: string, data: any) => {
	const { signal } = controller;
	return await fetch(api + endpoint, {
		signal,
		method: 'POST',
		headers: await setHeaders(),
		body: JSON.stringify(data),
	})
		.then((res) => {
			if (!res.ok && res.status === 401) {
				return Promise.reject(unauthorizedError);
			}
			return res.json();
		})
		.then((res) => responseParser(res))
		.catch((e) => {
			console.warn(e);
			return Promise.reject(e);
		});
};

export const uploadFile = async (endpoint: string, localUri: string) => {
	const filename = localUri.split('/').pop();
	const match = /\.(\w+)$/.exec(filename!);
	const type = match ? `image/${match[1]}` : 'image';
	const formData = new FormData();
	//@ts-ignore
	formData.append(0, { uri: localUri, name: filename, type });
	return await fetch(api + endpoint, {
		//@ts-ignore
		signal,
		method: 'POST',
		body: formData,
		headers: await setHeaders('multipart/form-data'),
	});
};
