import React, { useState, useEffect } from 'react';
import styles from './back-to-top.scss';
import classNames from 'classnames';

const BackToTopButton = () => {
	const [isVisible, setIsVisible] = useState(false);

	const handleScroll = () => {
		const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
		setIsVisible(scrollTop > 300);
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<svg
			aria-hidden="true"
			focusable="false"
			data-prefix="fas"
			data-icon="angle-up"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 320 512"
			width="38"
			height="38"
			className={classNames(
				styles.backToTopButton,
				isVisible ? styles.backToTopButtonVisible : styles.backToTopButtonHidden
			)}
			onClick={scrollToTop}
		>
			<path
				fill="currentColor"
				d="M6.94 176L160 32.94 313.06 176c8.44 8.44 8.44 22.12 0 30.56L297.47 224c-8.44 8.44-22.12 8.44-30.56 0L160 97.06 52.03 224c-8.44 8.44-22.12 8.44-30.56 0L6.94 206.56c-8.44-8.44-8.44-22.12 0-30.56z"
			></path>
		</svg>
	);
};

export default BackToTopButton;
