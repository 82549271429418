import Option from './Option';
import React from 'react';
import styles from './selector.scss';

type Props = {
	options: Array<Opt>;
	select: (value: any) => void;
	single: boolean;
	deselect: (value: any) => void;
};

type Opt = {
	value: string;
	title: string;
	selected: boolean;
};

const Selector: React.FC<Props> = (props) => {
	return (
		<div className={styles.selectorWrapper}>
			<div className={styles.selectorContainer}>
				{props.options && props.options.length > 0
					? props.options.map((option, i) => (
							<Option
								key={option.value + i.toString()}
								selected={option.selected}
								single={props.single}
								select={option.selected ? props.deselect : props.select}
								value={option.value}
								title={option.title}
							/>
					  ))
					: 'NO OPTIONS'}
			</div>
			<div className={styles.innerGhost}></div>
		</div>
	);
};

export default Selector;
