import React from 'react';
import styles from '../offboarding.scss';
import { useHistory } from 'react-router-dom';
import { Status, Routes } from '../../../../constants';
import { UserContext } from '../../../../contexts';
import { unpauseSubscription } from '../../../../actions';
import classNames from 'classnames';
import { Button } from '@scholly/elements';
import { UnpauseImage, WelcomeBack } from './icons';

interface Props {
	successUnpause?: boolean;
}
const UnpauseMembership = ({ successUnpause }: Props) => {
	const history = useHistory();
	const [status, setStatus] = React.useState(Status.IDLE);
	const { subscription, getAndSetUser, getAndSetSubscription } = React.useContext(UserContext);

	const unpauseSub = async () => {
		try {
			setStatus(Status.PENDING);
			await unpauseSubscription(subscription.id);
			await getAndSetSubscription();
			await getAndSetUser();
			history.push(`${Routes.offboarding.home}/${Routes.offboarding.successUnpauseMembership}`);
			setStatus(Status.RESOLVED);
		} catch (err) {
			setStatus(Status.REJECTED);
			console.log(err, '<---err');
		}
	};

	if (successUnpause) {
		return <SuccessUnpause />;
	}
	return (
		<div className={styles.pauseCardContainer}>
			<div className={classNames(styles.isFlex, styles.justifyContentCenter)}>
				<UnpauseImage />
			</div>
			<div className="mt-7">
				<div className={classNames(styles.pauseText, styles.pauseTitle)}>Start matching again!</div>
			</div>
			<div className={classNames(styles.isFlex, styles.justifyContentCenter, 'mt-6')}>
				<div
					className={classNames(styles.pauseText, styles.pauseDescription, 'has-text-centered')}
					style={{ width: 300, textTransform: 'initial' }}
				>
					All Scholarships will become available to you at once!
				</div>
			</div>

			<div className="mt-6">
				<Button
					variant="primary"
					onClick={unpauseSub}
					className={styles.pauseButton}
					isLoading={status === Status.PENDING}
					disabled={!subscription}
				>
					Unpause Account
				</Button>
				<Button
					variant="secondary"
					onClick={() => {
						history.push(Routes.dashboard);
					}}
					disabled
					className={classNames(styles.pauseButton, 'my-6')}
				>
					Not Now
				</Button>
			</div>
		</div>
	);
};
export default UnpauseMembership;

const SuccessUnpause = () => {
	const history = useHistory();
	return (
		<div className={styles.pauseCardContainer}>
			<div className={classNames(styles.isFlex, styles.justifyContentCenter, 'mt-8')}>
				<WelcomeBack />
			</div>
			<div className="mt-6">
				<div className={classNames(styles.pauseText, styles.pauseTitle)}>Your back!</div>
			</div>
			<div className={classNames(styles.isFlex, styles.justifyContentCenter, 'mt-6')}>
				<div
					className={classNames(styles.pauseText, styles.pauseDescription, 'has-text-centered')}
					style={{ width: 300, textTransform: 'initial' }}
				>
					You've unlocked all matched scholarships. Let's Go!
				</div>
			</div>

			<div className="mt-6">
				<Button
					variant="primary"
					onClick={() => {
						history.push(Routes.scholarships.feed);
					}}
					className={classNames(styles.pauseButton, 'mb-6')}
				>
					View Your Scholarships
				</Button>
			</div>
		</div>
	);
};
