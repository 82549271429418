import React from 'react';
import styles from './tab.scss';

interface IProps {
	onClick: () => void;
	isSelected: boolean;
	tabHeader: string | number | React.ReactNode;
	isHide?: boolean;
}

const Tab: React.FC<IProps> = ({ isSelected, onClick, tabHeader, isHide }) => {
	const props = {
		onClick: onClick,
		role: 'tab',
		'aria-selected': isSelected,
	};

	if (isHide) {
		return null;
	}
	return (
		<button onClick={onClick} className={isSelected ? styles.selected : styles.default} {...props}>
			<p className={styles.tabHeader}>{tabHeader}</p>
		</button>
	);
};

export default Tab;
