import React from 'react';
import classNames from 'classnames';
import { Letter26px } from '@scholly/elements';
import styles from './styles.scss';

interface Props {
	onChangeText: (value: string) => void;
	value: string;
	onFocus?: () => void;
	onBlur?: () => void;
	placeholder?: string;
	testID?: string;
	isError?: boolean;
	readonly?: boolean;
}
export const EmailInput = (props: Props) => {
	const { value, onChangeText, placeholder, testID, onFocus, onBlur, isError, readonly } = props;

	const handleChange = (e) => {
		onChangeText(e.target.value);
	};
	return (
		<div className={classNames(styles.passwordWrapper)}>
			<div className={styles.svgItemStyle}>
				<Letter26px clickHandler={() => {}} />
			</div>
			<input
				className={classNames(styles.input, isError && styles.inputError, readonly && styles.inputReadOnly)}
				test-id={testID}
				onChange={handleChange}
				type={'email'}
				placeholder={placeholder}
				onFocus={onFocus}
				onBlur={onBlur}
				value={value}
				readOnly={readonly}
			/>
		</div>
	);
};
