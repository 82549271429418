import { ActionMap, ScholarshipModel } from '../types';
import { Status } from '../constants';

export enum ScholarshipsTypes {
	SetScholarship = 'SET_SCHOLARSHIP',
	SetScholarships = 'SET_SCHOLARSHIPS',
	SetStatus = 'SET_STATUS',
	Error = 'ERROR',
	Started = 'STARTED',
	Success = 'SUCCESS',
	Reset = 'IDLE',
}

export type ScholarshipsContextState = {
	scholarships: Array<object>;
	scholarshipSaved: Array<object>;
	scholarshipApplied: Array<object>;
	scholarship: ScholarshipModel;
};

type ScholarshipsPayload = {
	[ScholarshipsTypes.SetScholarships]: {
		scholarships: Array<object>;
		scholarshipSaved: Array<object>;
		scholarshipApplied: Array<object>;
	};
	[ScholarshipsTypes.SetScholarship]: {
		scholarship: ScholarshipModel;
	};
	[ScholarshipsTypes.SetStatus]: {
		status: Status;
	};
};

type StatusActionPayload = {
	[ScholarshipsTypes.Error]: { status: Status };
	[ScholarshipsTypes.Reset]: { status: Status };
	[ScholarshipsTypes.Started]: { status: Status };
	[ScholarshipsTypes.Success]: { status: Status };
};

export type ScholarshipsActions = ActionMap<ScholarshipsPayload>[keyof ActionMap<ScholarshipsPayload>];

type StatusActions = ActionMap<StatusActionPayload>[keyof ActionMap<StatusActionPayload>];

export const scholarshipsReducer = (state: ScholarshipsContextState, actions: ScholarshipsActions | StatusActions) => {
	switch (actions.type) {
		case ScholarshipsTypes.SetScholarships:
			return { ...state, ...actions.payload };
		case ScholarshipsTypes.SetStatus: {
			return {
				...state,
				categoriesLoadedStatus: actions.payload.status,
			};
		}

		default:
			return state;
	}
};
