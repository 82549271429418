import React from 'react';

import styles from './selector.scss';
import classNames from 'classnames';

type Props = {
	value: string;
	title: string;
	selected: boolean;
	select: (value: string) => void;
	single: boolean;
};

const Option: React.FC<Props> = (props) => {
	const { single, title, selected } = props;

	const multiSelect = () => {
		return (
			<div className={classNames(styles.multiSelect, selected && styles.multiSelectSelected)}>
				{selected ? <span className={styles.multiSelectCheck}>✓</span> : null}
			</div>
		);
	};

	const singleSelect = () => {
		return <div className={styles.singleSelect}>{selected ? <div className={styles.singleSelected} /> : null}</div>;
	};

	return (
		<div className={styles.optionContainer} onClick={() => props.select(props.value)}>
			<div className={styles.optionInnerContainer}>
				{single ? singleSelect() : multiSelect()}
				<p className={styles.optionTitle}>{title}</p>
			</div>
		</div>
	);
};

export default Option;
