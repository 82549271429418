import React from 'react';
import { STORAGE_KEYS, ScreenStrings } from '../../constants';
import { SubHeader } from '../../components/layouts/SubHeader';
import TotalMatch from '../../components/total-match/total-match';
import classNames from 'classnames';
import styles from './scholly-free.scss';
import { useUser } from '../../contexts';

const SchollyFree: React.FC<{}> = () => {
	const { user_id } = useUser();

	React.useEffect(() => {
		localStorage.setItem(STORAGE_KEYS.FREE_ACCOUNT_ACTIVATED + user_id, '1');
	}, [user_id]);

	return (
		<div className="schollyfree">
			<SubHeader
				title={ScreenStrings.onboarding.freeAccountActivated}
				hideBackButton
				className={classNames(styles.subHeader, styles.subHeaderFreeAccount)}
				isFreeAccountScreen
			/>
			<TotalMatch showConfetti />
		</div>
	);
};

export default SchollyFree;
