import { fetcher, get, post } from './apiActions';

export const getParameters = async () => fetcher('parameters');
export const getParameterValues = async () => fetcher('parameter-values');
export const getParameterValuesCategory = (id: string) => get(`parameter-values-category/${id}`);
export const getParameterCategories = () => get('parameter-categories');
export const getLocation = (address: string) => fetcher(`integrations/google/location?address=${address}`);
export const getSchools = (school: string) => fetcher(`schools?query=${school}`);

export const addSchool = (data: any) => post(`school`, data);

export async function querySchools(q: string) {
	if (q && q.length > 0) {
		const res = await fetcher(`schools?query=${q}`);
		if (res) {
			let {
				data: { schools },
			} = res;

			return schools;
		}
	} else {
		return [];
	}
}
