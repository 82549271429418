import { Button } from '@scholly/elements';
import React from 'react';
import classNames from 'classnames';
import styles from './style.scss';

interface Props {
	renderIf: boolean;
	stepCount: number;
	step: number;
	handleNext: () => void;
	hideBackButton?: boolean;
	handleBack: () => void;
	backDisabled: boolean;
	nextDisabled: boolean;
	containerClassName?: string;
}

const Step = ({ current }) => {
	return <div className={classNames(styles.step, current ? styles.stepActive : undefined)} />;
};

export const Pagination = ({
	renderIf,
	step,
	stepCount,
	handleBack,
	handleNext,
	backDisabled,
	nextDisabled,
	hideBackButton,
	containerClassName,
}: Props) => {
	return (
		<React.Fragment>
			<div
				className={classNames(
					styles.container,
					styles.containerButton,
					hideBackButton && styles.hiddenBackButton,
					containerClassName
				)}
			>
				{!hideBackButton && (
					<Button variant={'secondary'} onClick={handleBack} disabled={backDisabled}>
						Back
					</Button>
				)}
				<Button onClick={handleNext} disabled={nextDisabled}>
					Next
				</Button>
			</div>
			{renderIf && (
				<div className={styles.stepContainer}>
					{Array.from({ length: stepCount }, (value, index) => index + 1).map((x, i) => (
						<Step key={i.toLocaleString()} current={i === step} />
					))}
				</div>
			)}
		</React.Fragment>
	);
};

Pagination.defaultProps = {
	renderIf: true,
};
