import React from 'react';
import { TouchableOpacity } from 'react-native-web';
import styles from '../common.scss';

const AccordionSimple: React.FC<{
	onPress;
	expanded;
	header;
	content;
	contentStyles?;
	headerStyles?;
	containerStyles?;
	isScroll?;
}> = (props) => {
	const content = React.useRef(null);
	const [loaded, setLoaded] = React.useState(false);
	const [show, setShow] = React.useState(true);

	React.useEffect(() => {
		setLoaded(!loaded);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const containerStyles = {
		boxShadow: 'rgba(0, 0, 0, 0.08) 0px 2px 10px',
		borderRadius: '12px',
		display: 'flex',
		flexDirection: 'column',
		padding: 10,
		...props.containerStyles,
	};

	const contentHeight = content.current ? content.current.scrollHeight : 0;

	const styleTransition = {
		transition: 'height 500ms',
		height: props.expanded ? '100%' : 0,
		maxHeight: props.expanded ? contentHeight : 0,
		overflowY: props.isScroll && contentHeight > 260 ? 'scroll' : 'hidden',
		overflowX: 'hidden',
		padding: props.expanded ? '0 0 10px 0' : 0,
	};

	const handleScroll = React.useCallback(
		(e) => {
			const target = e.target;
			if (target.scrollHeight - target.scrollTop === target.clientHeight) {
				setShow(false);
			} else {
				if (!show) setShow(props.expanded);
			}
		},
		[props.expanded, show]
	);

	React.useEffect(() => {
		if (contentHeight > 260 === false) {
			setShow(false);
		} else {
			setShow(props.expanded);
		}
	}, [props.expanded, contentHeight]);

	return (
		<div style={containerStyles}>
			<TouchableOpacity onPress={props.onPress}>
				<div style={{ cursor: 'pointer', ...props.headerStyles }}>{props.header}</div>
			</TouchableOpacity>
			{/* @ts-ignore */}
			<div onScroll={handleScroll} onMouseUp={handleScroll} style={styleTransition}>
				<div className={styles.accordoinContent} ref={content} style={{ ...props.contentStyles }}>
					{props.content}
				</div>
			</div>

			{show && <div className={styles.ghost}></div>}
		</div>
	);
};

export default AccordionSimple;
