import React, { CSSProperties, FC } from 'react';

interface CopyIconProps {
	onClick?: (val: any) => void;
	svgStyle?: CSSProperties;
}
export const EmailIcon: FC<CopyIconProps> = (props) => {
	const { onClick, svgStyle } = props;
	return (
		<div>
			<div className="content" onClick={onClick}>
				<svg
					width="45"
					height="27"
					viewBox="0 0 26 26"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					style={svgStyle}
				>
					<path
						d="M2.70834 8.5C2.70834 7.39543 3.60377 6.5 4.70834 6.5H21.2917C22.3962 6.5 23.2917 7.39543 23.2917 8.5V17.5C23.2917 18.6046 22.3962 19.5 21.2917 19.5H4.70834C3.60377 19.5 2.70834 18.6046 2.70834 17.5V8.5Z"
						stroke="#A0A3BD"
						strokeWidth="2"
						strokeLinejoin="round"
					/>
					<path
						d="M14.9324 13.5394L14.2883 12.7745L14.9324 13.5394ZM4.76331 7.5H21.2367V5.5H4.76331V7.5ZM21.0757 7.05877L14.2883 12.7745L15.5765 14.3043L22.3639 8.58859L21.0757 7.05877ZM11.7117 12.7745L4.92435 7.05877L3.63608 8.5886L10.4235 14.3043L11.7117 12.7745ZM14.2883 12.7745C13.5438 13.4014 12.4562 13.4014 11.7117 12.7745L10.4235 14.3043C11.9124 15.5581 14.0877 15.5581 15.5765 14.3043L14.2883 12.7745ZM21.2367 7.5C21.1953 7.5 21.1379 7.48451 21.0869 7.44674C21.0413 7.41293 21.0152 7.37225 21.0018 7.33571C20.9885 7.29917 20.9823 7.2512 20.9954 7.19597C21.0101 7.13427 21.044 7.08546 21.0757 7.05877L22.3639 8.58859C23.6125 7.53718 22.869 5.5 21.2367 5.5V7.5ZM4.76331 5.5C3.13102 5.5 2.38752 7.53718 3.63608 8.5886L4.92435 7.05877C4.95604 7.08546 4.98995 7.13427 5.00458 7.19597C5.01768 7.2512 5.0115 7.29917 4.99816 7.33571C4.98482 7.37225 4.95866 7.41293 4.91306 7.44674C4.86212 7.48451 4.80475 7.5 4.76331 7.5V5.5Z"
						fill="#A0A3BD"
					/>
				</svg>
			</div>
		</div>
	);
};
