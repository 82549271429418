import React from 'react';
import Select from 'react-select';

import customStyles from '../profile/lib/CustomStyles';
import { AnswerModel } from '../../types';
import styles from './onboarding.scss';

type Props = {
	options: Array<AnswerModel>;
	onSelect: (opt: string) => void;
	onDeselect: (opt: string) => void;
	title: string;
	placeholder: string;
};

const TextSearch: React.FC<Props> = (props) => {
	const { options, onSelect, title, placeholder } = props;

	return (
		<div className={styles.onboardingFormWrapper}>
			<h2>{title}</h2>
			<div className={styles.formWrapper}>
				<Select
					styles={customStyles(props)}
					options={options}
					placeholder={placeholder}
					onChange={onSelect}
					getOptionLabel={(options) => options.value}
					getOptionValue={(options) => options.id}
					isScroll={true}
					isSearchable={true}
				/>
			</div>
		</div>
	);
};

export default TextSearch;
