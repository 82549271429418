import React, { useState } from 'react';

import { Loader } from '@scholly/scholly-ui';
import { QuestionTypes } from '../../../types';
import { MultiStepRef } from '../../../components/common/MultiStep/MultiStep';
import { useParameterState, useProfileState } from '../../../contexts';
import Selector from '../../../components/onboarding/Selector';
import Slider from '../../../components/onboarding/Slider';
import TextSearch from '../../../components/onboarding/TextSearch';
import { MultiTextSelect } from '../../../components/onboarding/MultiTextSelect';
import QuerySearch from '../../../components/onboarding/QuerySearch';
import { ProfileQuestionModel } from '../index';
import Dropdown from '../../../components/onboarding/Dropdown';

type Props = {
	multiRef?: React.MutableRefObject<MultiStepRef>;
	question: ProfileQuestionModel;
};

export const StepQuestion: React.FC<Props> = ({ question, multiRef }) => {
	const { parameterStringId } = useParameterState();
	const { changeState, data } = useProfileState();
	const [selection, setSelection] = useState('');
	const [multiSelection, setMultiSelection] = useState([]);
	const string_id = question ? parameterStringId.get(question.parameter_id.toString()) : '';

	const getIsSelected = (value: string, id: string) => {
		if (isMultiSelect()) {
			return multiSelection.includes(value) || multiSelection.includes(id);
		} else {
			return value === data[string_id] || id === data[string_id];
		}
	};

	function convertAnswerStructure(array) {
		return array.map((ele) => {
			let x = {
				value: ele.id,
				title: ele.value,
				selected: getIsSelected(ele.value, ele.id),
			};
			return x;
		});
	}

	React.useEffect(() => {
		setTimeout(() => {
			multiRef.current?.setNextDisable(!data[string_id]);
		}, 0);
		if (selection !== '') {
			changeState({ [string_id]: selection });
			setSelection('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selection, question]);

	React.useEffect(() => {
		multiRef.current?.setNextDisable(multiSelection.length === 0);
		if (multiSelection.length !== 0) changeState({ [string_id]: multiSelection });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [multiSelection, question]);

	const getForm = () => {
		if (!question) {
			return <Loader />;
		}
		switch (question.question_type) {
			case QuestionTypes.SingleSelect:
				return (
					<Selector
						options={convertAnswerStructure(question?.answers)}
						onSelect={(value: string) => {
							setSelection(value);
						}}
						onDeselect={() => setSelection('')}
						single={true}
						title={question?.question}
					/>
				);
			case QuestionTypes.SingleSelectDropdown:
				return (
					<Dropdown
						options={convertAnswerStructure(question?.answers)}
						onSelect={(value) => setMultiSelection([...multiSelection, value])}
						onDeselect={(value) => deselect(value)}
						title={question?.question}
					/>
				);
			case QuestionTypes.MultiSelect:
				return (
					<Selector
						options={convertAnswerStructure(question?.answers)}
						onSelect={(value) => setMultiSelection([...multiSelection, value])}
						onDeselect={(value) => deselect(value)}
						single={false}
						title={question?.question}
					/>
				);
			case QuestionTypes.Slider:
				const value = parseFloat(
					question.answers.find((answer) => answer.id.toString() === data[string_id])?.value
				);
				let step = 0.1;
				const max = question.answers.reduce(function (a, b) {
					if (Number(b.value)) {
						return Math.max(a, Number(b.value));
					}
					return a;
				}, -Infinity);

				const min = question.answers.reduce(function (a, b) {
					if (Number(b.value)) {
						return Math.min(a, Number(b.value));
					}
					return a;
				}, Infinity);

				const handleSelect = (value) => {
					const answer = question.answers.find((answer) => parseFloat(answer.value) === parseFloat(value));
					setSelection(answer.id.toString());
				};
				return (
					<Slider
						value={value}
						onSlide={handleSelect}
						maximum={max}
						minimum={min}
						step={step}
						title={question?.question}
					/>
				);
			case QuestionTypes.SingleSelectSearch:
				return (
					<TextSearch
						onSelect={(value) => setSelection(value)}
						onDeselect={() => setSelection('')}
						options={question?.answers}
						title={question?.question}
						placeholder={question?.placeholder}
					/>
				);
			case QuestionTypes.MultiSelectSearch:
				return (
					<MultiTextSelect
						onSelect={(value) => setMultiSelection(value)}
						onDeselect={(value) => deselect(value)}
						answers={question?.answers}
						selected={multiSelection}
						title={question?.question}
						placeholder={question?.placeholder}
					/>
				);
			case QuestionTypes.SchoolSelectSearch:
				return (
					<QuerySearch
						onSelect={(value) => setSelection(value)}
						onDeselect={() => setSelection('')}
						selected={data[string_id]}
						title={question?.question}
						placeholder={question?.placeholder}
						single={true}
						type="school"
					/>
				);
			case QuestionTypes.LocationSelectSearch:
				return (
					<QuerySearch
						onSelect={(value) => setSelection(value)}
						onDeselect={() => setSelection('')}
						selected={data[string_id]}
						title={question?.question}
						placeholder={question?.placeholder}
						single={true}
						type={string_id}
					/>
				);
		}
	};

	function isMultiSelect() {
		return (
			question.question_type === QuestionTypes.MultiSelectSearch ||
			question.question_type === QuestionTypes.MultiSelect
		);
	}

	function deselect(value: string) {
		const filtered = multiSelection.filter((e) => e !== value);
		setMultiSelection(filtered);
	}

	// function multiSelect(value: string) {
	// 	let uniq = [...new Set([...multiSelection, value])]; //removes duplicates if there are any
	// 	setMultiSelection(uniq);
	// }

	return <div style={{ width: '100%' }}>{getForm()}</div>;
};
