import { ActionRightPointer, Banner } from '@scholly/elements';
import React, { useState, useContext } from 'react';

import Dollar from '../common/icons/dollar';
import classNames from 'classnames';
import styles from './banners.scss';
import { isClickWithinLastTime, storeClickDate } from '../../helpers/Functions';
import { STORAGE_KEYS } from '../../constants/StorageKeys';
import config from '../../config/config';
import { LayoutContext, ScreenType } from '../layouts/Layout';

import { SessionContext } from '../../contexts';
interface OffersBannerProps {
	className?: string;
}
export const OffersBanner: React.FC<OffersBannerProps> = ({ className }) => {
	const [user, setUser] = useState({ email: '', first_name: '' });
	const context = useContext(SessionContext);
	const { currentSize } = useContext(LayoutContext);

	React.useEffect(() => {
		if (context?.user) {
			setUser({
				first_name: context?.user?.first_name,
				email: context?.user?.user_email,
			});
		}
	}, [context]);

	const goToPage = () => {
		const windowReference = window.open();
		windowReference.location.href = `${config.offers_url}?m=login&email=${user?.email}`;
	};

	return (
		<React.Fragment>
			<Banner.Container
				className={classNames(styles.bannerContainer, styles.offersBanner, className)}
				color="#192C78"
				onClick={goToPage}
				id={'offer-wrapper'}
			>
				<Banner.InnerContainer className={styles.innerModified}>
					<div className={styles.contentWrapper}>
						<div className={styles.offersDollar}>
							<Dollar />
						</div>
						<div className={styles.offersTextContainer}>
							<h3 className={styles.offersTitle}>Get cash now!</h3>
							<p className={styles.offersDescription}>
								{user?.first_name}, you have financial offers waiting for you. Get started earning today
							</p>
						</div>
					</div>
					<Banner.ActionCenter className={styles.offersArrow}>
						<ActionRightPointer
							height="20"
							width="19"
							viewBox="0 0 19 20"
							clickHandler={goToPage}
							clickable
							color={'white'}
						/>
					</Banner.ActionCenter>
				</Banner.InnerContainer>
			</Banner.Container>
		</React.Fragment>
	);
};

export const OffersBannerMobile = (props) => {
	const { currentSize } = React.useContext(LayoutContext);
	// used for rerender component after save dismiss date in local storage
	const [, forceUpdate] = React.useState(0);

	if (isClickWithinLastTime(STORAGE_KEYS.OFFER_MOBILE_BANNER) || ScreenType.MOBILE !== currentSize) {
		return null;
	}

	return (
		<SwipeableWrapper forceUpdate={forceUpdate}>
			<OffersBanner {...props} />
		</SwipeableWrapper>
	);
};

const SwipeableWrapper = ({ forceUpdate, children }) => {
	React.useEffect(() => {
		const list = document.getElementById('offer-mobile');

		let xDown = null;

		list.addEventListener('touchstart', handleTouchStart, false);
		list.addEventListener('touchmove', handleTouchMove, false);

		function handleTouchStart(event) {
			xDown = event.touches[0].clientX;
		}

		function handleTouchMove(event) {
			if (!xDown) {
				return;
			}

			const xDiff = xDown - event.touches[0].clientX;

			if (xDiff > 0) {
				event.preventDefault();
				this.querySelector('#offer-wrapper').style.transform = `translateX(-${xDiff}px)`;
			}
		}

		list.addEventListener('touchend', handleTouchEnd, false);

		function handleTouchEnd(event) {
			if (!xDown) {
				return;
			}

			const xDiff = xDown - event.changedTouches[0].clientX;
			if (xDiff > 80) {
				event.preventDefault();
				storeClickDate(STORAGE_KEYS.OFFER_MOBILE_BANNER);
				forceUpdate(new Date());
			} else {
				this.querySelector('#offer-wrapper').style.transform = '';
			}

			xDown = null;
		}
		return () => {
			list.removeEventListener('touchend', handleTouchEnd, false);
			list.removeEventListener('touchstart', handleTouchStart, false);
			list.removeEventListener('touchmove', handleTouchMove, false);
		};
	}, []);
	return (
		<div id="offer-mobile" className={styles.offersMobileWrapper}>
			{children}
		</div>
	);
};
