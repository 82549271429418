import { fetcher, get, post } from './apiActions';

export const getScholarships = (url: string, data?: object) => post(url, data);
export const getScholarship = (id: number) => get(`scholarship/${id}`);
export const getRemovedReasons = () => fetcher('user/scholarships/remove/reasons');
export const getDidNotApplyReasons = () => fetcher('user/scholarships/did-not-apply/reasons');
export const deleteMatchedScholarship = (params: object) => post('user/scholarships/remove', params);
export const deleteSavedScholarship = (id: number, data?: object) => post(`user/scholarship/${id}/delete`, data);
export const saveUserScholarship = (id: number, data?: object) => post(`user/scholarship/${id}`, data);
export const updateUserScholarship = (id: number, params: object) => post(`user/scholarship/${id}/update`, params);

export const sendScholarshipFeedback = (params: object) => post('user/scholarships/did-not-apply', params);

export const setScholarshipAsRead = (params: object) => post('user/scholarships/read', params);

export const getUserScholarshipTotal = () => get('user/scholarships/matched/total');
export const submitEasyApply = (user: object, form: string) => post(`form`, { ...user, form, opt_in: 'Yes' });
export const getHostedScholarships = () => get('hosted-scholarships');
