import React, { CSSProperties } from 'react';
import classNames from 'classnames';

import styles from './card.scss';

interface CardProps {
	children: React.ReactNode;
	onClick?: () => void;
	style?: CSSProperties;
	className?: string;
}
export const Card = ({ style, children, onClick, className }: CardProps) => {
	return (
		<div className={classNames(styles.cardContainer, className)} style={style} onClick={onClick}>
			{children}
		</div>
	);
};
