import { Banner, Button } from '@scholly/elements';
import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './banners.scss';
import config from '../../config/config';

interface EarnCashProps {
	onClose?: () => void;
	className?: string;
}
const EarnCash: FC<EarnCashProps> = ({ className }) => {
	const goToPage = () => {
		window.open(config.offers_url, '_blank');
	};

	return (
		<React.Fragment>
			<Banner.Container
				className={classNames(styles.bannerContainer, styles.earnBanner, className)}
				color="#3259F1"
				onClick={goToPage}
			>
				<Banner.InnerContainer className={styles.earnInner}>
					<div className={styles.contentWrapper}>
						<div>
							<h3 className={styles.earnTitle}>Make Up To $350</h3>
							<p className={styles.earnDescription}>Cash is guaranteed for every Offer completed!</p>
						</div>
					</div>
					<Button variant={'secondary'} className={styles.earnBtn}>
						EARN CASH
					</Button>
				</Banner.InnerContainer>
			</Banner.Container>
		</React.Fragment>
	);
};

export default EarnCash;
