import React, { CSSProperties, FC } from 'react';

interface SuccessIconProps {
	styles?: CSSProperties;
}

const UserBadgeBurstIcon: FC<SuccessIconProps> = (props) => {
	const { styles } = props;

	return (
		<div>
			<div className="content" style={styles}>
				<svg xmlns="http://www.w3.org/2000/svg" width="164" height="163" viewBox="0 0 164 163" fill="none">
					<path
						opacity="0.1"
						d="M96.8588 20.7653C85.0351 23.8525 73.6634 26.3482 61.6965 26.4608C41.5433 26.6501 22.5325 40.4984 14.5232 59.1705C6.51393 77.8426 9.13045 100.31 19.8168 117.47C36.562 144.338 79.5825 165.851 107.278 141.98C111.423 138.41 115.028 133.956 120.048 131.829C127.724 128.576 137.809 131.135 143.719 125.216C147.844 121.088 148.032 114.374 146.655 108.697C145.277 103.02 142.624 97.6558 141.969 91.8483C141.88 91.035 141.826 90.2253 141.808 89.4191C141.588 78.9296 147.237 68.8084 147.903 58.2074C148.682 45.8478 142.198 33.5334 132.109 26.5145C122.02 19.4956 108.766 17.6606 96.8588 20.7653Z"
						fill="#3259F1"
					/>
					<path
						d="M139.166 60.7412H24.7849C20.4555 60.7412 16.9458 64.2629 16.9458 68.6071V122.336C16.9458 126.68 20.4555 130.202 24.7849 130.202H139.166C143.495 130.202 147.005 126.68 147.005 122.336V68.6071C147.005 64.2629 143.495 60.7412 139.166 60.7412Z"
						fill="#2D50C1"
					/>
					<path
						d="M139.166 43.6292H103.677L104.226 45.28C105.021 47.6694 104.645 50.3636 103.245 52.3126C102.16 53.8234 100.571 54.6884 98.8901 54.6884H65.0582C63.3769 54.6884 61.788 53.8234 60.7032 52.3126C59.3033 50.3636 58.9274 47.6694 59.7219 45.28L60.271 43.6292H24.7871C23.7575 43.6289 22.7379 43.8321 21.7865 44.2273C20.8352 44.6224 19.9708 45.2018 19.2426 45.9322C18.5145 46.6627 17.9368 47.5299 17.5428 48.4844C17.1487 49.4389 16.9458 50.4619 16.9458 51.4951V112.324C16.9458 114.411 17.772 116.412 19.2425 117.888C20.713 119.364 22.7075 120.192 24.7871 120.192H139.166C140.195 120.192 141.215 119.989 142.166 119.593C143.117 119.198 143.982 118.618 144.71 117.888C145.438 117.157 146.015 116.29 146.409 115.335C146.803 114.381 147.005 113.357 147.005 112.324V51.4951C147.005 50.462 146.803 49.439 146.409 48.4845C146.015 47.5301 145.437 46.6628 144.709 45.9323C143.981 45.2019 143.117 44.6225 142.166 44.2273C141.215 43.8321 140.195 43.6289 139.166 43.6292Z"
						fill="#3259F1"
					/>
					<path
						d="M134.906 71.8587H80.4942C80.345 71.8587 80.2018 71.7992 80.0963 71.6933C79.9908 71.5874 79.9315 71.4438 79.9315 71.2941C79.9315 71.1443 79.9908 71.0007 80.0963 70.8948C80.2018 70.789 80.345 70.7295 80.4942 70.7295H134.897C135.046 70.7295 135.189 70.789 135.295 70.8948C135.401 71.0007 135.46 71.1443 135.46 71.2941C135.46 71.4423 135.402 71.5845 135.298 71.6901C135.195 71.7957 135.054 71.8563 134.906 71.8587V71.8587Z"
						fill="#95CBED"
					/>
					<path
						d="M134.906 84.1131H80.4942C80.345 84.1131 80.2018 84.0536 80.0963 83.9477C79.9908 83.8418 79.9315 83.6982 79.9315 83.5485C79.9315 83.3987 79.9908 83.2551 80.0963 83.1492C80.2018 83.0434 80.345 82.9839 80.4942 82.9839H134.897C135.046 82.9839 135.189 83.0434 135.295 83.1492C135.401 83.2551 135.46 83.3987 135.46 83.5485C135.46 83.6967 135.402 83.8389 135.298 83.9445C135.195 84.0502 135.054 84.1107 134.906 84.1131V84.1131Z"
						fill="#95CBED"
					/>
					<path
						d="M134.906 96.3711H80.4942C80.345 96.3711 80.2018 96.3117 80.0963 96.2058C79.9908 96.0999 79.9315 95.9563 79.9315 95.8065C79.9315 95.6568 79.9908 95.5132 80.0963 95.4073C80.2018 95.3014 80.345 95.2419 80.4942 95.2419H134.897C135.046 95.2419 135.189 95.3014 135.295 95.4073C135.401 95.5132 135.46 95.6568 135.46 95.8065C135.46 95.9547 135.402 96.0969 135.298 96.2026C135.195 96.3082 135.054 96.3688 134.906 96.3711V96.3711Z"
						fill="#95CBED"
					/>
					<path
						d="M97.8584 52.9652H66.0904C63.0745 52.9652 61.0264 49.1418 62.1832 45.6684L63.7092 41.0726C64.3191 39.241 65.7528 38.0464 67.344 38.0464H96.6026C98.1916 38.0464 99.6275 39.241 100.235 41.0726L101.763 45.6684C102.922 49.1418 100.874 52.9652 97.8584 52.9652Z"
						fill="#2D50C1"
					/>
					<path
						d="M140.058 47.8616C146.499 46.8341 147.811 45.5197 148.836 39.054C148.847 38.9877 148.882 38.9278 148.933 38.8846C148.985 38.8415 149.05 38.8179 149.117 38.8179C149.184 38.8179 149.249 38.8415 149.3 38.8846C149.352 38.9278 149.386 38.9877 149.398 39.054C150.422 45.5174 151.732 46.8318 158.176 47.8616C158.242 47.8735 158.302 47.9083 158.345 47.9599C158.388 48.0115 158.411 48.0766 158.411 48.1439C158.411 48.2112 158.388 48.2763 158.345 48.3279C158.302 48.3795 158.242 48.4143 158.176 48.4262C151.734 49.4515 150.424 50.7681 149.398 57.2339C149.386 57.3001 149.352 57.36 149.3 57.4032C149.249 57.4463 149.184 57.4699 149.117 57.4699C149.05 57.4699 148.985 57.4463 148.933 57.4032C148.882 57.36 148.847 57.3001 148.836 57.2339C147.814 50.7704 146.502 49.4538 140.058 48.4262C139.992 48.4143 139.932 48.3795 139.889 48.3279C139.846 48.2763 139.823 48.2112 139.823 48.1439C139.823 48.0766 139.846 48.0115 139.889 47.9599C139.932 47.9083 139.992 47.8735 140.058 47.8616V47.8616Z"
						fill="#FFC064"
					/>
					<path
						d="M143.497 21.8472C146.288 21.3187 147.073 20.5306 147.6 17.7302C147.613 17.6653 147.648 17.6069 147.699 17.5649C147.75 17.5229 147.814 17.5 147.88 17.5C147.946 17.5 148.01 17.5229 148.061 17.5649C148.112 17.6069 148.147 17.6653 148.16 17.7302C148.685 20.5306 149.47 21.3187 152.261 21.8472C152.326 21.8603 152.384 21.8954 152.426 21.9467C152.468 21.9979 152.491 22.0621 152.491 22.1283C152.491 22.1946 152.468 22.2588 152.426 22.3101C152.384 22.3613 152.326 22.3965 152.261 22.4095C149.47 22.9357 148.685 23.7239 148.16 26.5243C148.147 26.5892 148.112 26.6476 148.061 26.6896C148.01 26.7315 147.946 26.7545 147.88 26.7545C147.814 26.7545 147.75 26.7315 147.699 26.6896C147.648 26.6476 147.613 26.5892 147.6 26.5243C147.073 23.7239 146.288 22.9357 143.497 22.4095C143.432 22.3965 143.374 22.3613 143.332 22.3101C143.291 22.2588 143.268 22.1946 143.268 22.1283C143.268 22.0621 143.291 21.9979 143.332 21.9467C143.374 21.8954 143.432 21.8603 143.497 21.8472V21.8472Z"
						fill="#FFC064"
					/>
					<path
						d="M122.294 19.9935C123.42 19.709 123.87 19.2573 124.147 18.1327C124.162 18.071 124.198 18.0164 124.248 17.9774C124.298 17.9384 124.36 17.9172 124.424 17.9172C124.487 17.9172 124.548 17.9384 124.599 17.9774C124.649 18.0164 124.685 18.071 124.7 18.1327C124.984 19.2618 125.432 19.7135 126.553 19.9935C126.615 20.0085 126.67 20.0439 126.709 20.0941C126.748 20.1442 126.77 20.2063 126.77 20.2702C126.77 20.3341 126.748 20.3961 126.709 20.4463C126.67 20.4965 126.615 20.5319 126.553 20.5468C125.427 20.8314 124.977 21.2831 124.7 22.4077C124.685 22.4694 124.649 22.5239 124.599 22.5629C124.548 22.6019 124.487 22.6231 124.424 22.6231C124.36 22.6231 124.298 22.6019 124.248 22.5629C124.198 22.5239 124.162 22.4694 124.147 22.4077C123.863 21.2785 123.415 20.8269 122.294 20.5468C122.233 20.5319 122.177 20.4965 122.138 20.4463C122.099 20.3961 122.077 20.3341 122.077 20.2702C122.077 20.2063 122.099 20.1442 122.138 20.0941C122.177 20.0439 122.233 20.0085 122.294 19.9935Z"
						fill="#FFC064"
					/>
					<path
						d="M116.747 32.3739C132.033 31.1792 134.061 29.1444 135.252 13.8056C135.252 13.7301 135.282 13.6577 135.335 13.6044C135.388 13.551 135.46 13.521 135.535 13.521C135.611 13.521 135.683 13.551 135.736 13.6044C135.789 13.6577 135.819 13.7301 135.819 13.8056C137.01 29.1444 139.037 31.1792 154.324 32.3739C154.399 32.3739 154.471 32.4039 154.524 32.4572C154.578 32.5106 154.608 32.583 154.608 32.6584C154.608 32.7339 154.578 32.8063 154.524 32.8597C154.471 32.913 154.399 32.943 154.324 32.943C139.037 34.1377 137.01 36.1725 135.819 51.5113C135.819 51.5868 135.789 51.6592 135.736 51.7126C135.683 51.7659 135.611 51.7959 135.535 51.7959C135.46 51.7959 135.388 51.7659 135.335 51.7126C135.282 51.6592 135.252 51.5868 135.252 51.5113C134.061 36.1725 132.033 34.1377 116.747 32.943C116.672 32.943 116.6 32.913 116.546 32.8597C116.493 32.8063 116.463 32.7339 116.463 32.6584C116.463 32.583 116.493 32.5106 116.546 32.4572C116.6 32.4039 116.672 32.3739 116.747 32.3739V32.3739Z"
						fill="#FFC064"
					/>
					<path
						d="M65.3875 62.2273H34.5621C32.9053 62.2273 31.5621 63.5704 31.5621 65.2273V103.287C31.5621 104.944 32.9053 106.287 34.5621 106.287H65.3875C67.0444 106.287 68.3875 104.944 68.3875 103.287V65.2273C68.3875 63.5704 67.0444 62.2273 65.3875 62.2273Z"
						fill="white"
					/>
					<mask id="mask0_434_5035" maskUnits="userSpaceOnUse" x="31" y="62" width="38" height="45">
						<path
							d="M65.3875 62.2273H34.5621C32.9053 62.2273 31.5621 63.5704 31.5621 65.2273V103.287C31.5621 104.944 32.9053 106.287 34.5621 106.287H65.3875C67.0444 106.287 68.3875 104.944 68.3875 103.287V65.2273C68.3875 63.5704 67.0444 62.2273 65.3875 62.2273Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask0_434_5035)">
						<path
							d="M71.289 98.2132V97.6057C67.5438 99.8991 63.7607 102.158 60.013 104.378C56.7915 106.294 53.5616 108.23 50.3234 110.185H51.3322C54.3132 108.381 57.2925 106.597 60.2702 104.833C63.9246 102.653 67.6169 100.454 71.289 98.2132Z"
							fill="#2D50C1"
							fill-opacity="0.24"
						/>
						<path
							d="M71.2882 87.3051V86.7034C64.9718 90.5548 58.5099 94.2259 52.1271 97.8513C45.091 101.847 37.9605 105.899 31.0342 110.185H31.9988C38.7006 106.062 45.5836 102.152 52.3772 98.2905C58.6758 94.721 65.0509 91.1006 71.2882 87.3051Z"
							fill="#2D50C1"
							fill-opacity="0.24"
						/>
						<path
							d="M71.2898 75.5774V74.9629C62.6876 80.7412 53.6086 86.0497 44.686 91.2652C39.5903 94.2421 34.4416 97.2526 29.3585 100.364V100.96C34.5224 97.7974 39.7543 94.7378 44.9358 91.7093C53.77 86.548 62.7507 81.2938 71.2898 75.5774Z"
							fill="#2D50C1"
							fill-opacity="0.24"
						/>
						<path
							d="M71.2898 63.8385V63.2219C60.5333 70.9394 48.6491 77.6844 37.0248 84.2765C34.477 85.7236 31.9215 87.179 29.3585 88.6428V89.2193C31.9989 87.7037 34.6376 86.1998 37.2746 84.7076C48.803 78.1707 60.5913 71.4833 71.2898 63.8385Z"
							fill="#2D50C1"
							fill-opacity="0.24"
						/>
						<path
							d="M60.3877 59.8669H59.4865C49.8512 66.2892 39.5055 72.0738 29.3585 77.7437V78.3167L29.6131 78.1722C39.9841 72.3777 50.5767 66.4661 60.3877 59.8669Z"
							fill="#2D50C1"
							fill-opacity="0.24"
						/>
						<path
							d="M40.9983 59.8669C37.158 62.2519 33.2607 64.5642 29.3585 66.839V67.4146C33.5731 64.9571 37.7878 62.4546 41.9379 59.8669H40.9983Z"
							fill="#2D50C1"
							fill-opacity="0.24"
						/>
						<path
							d="M33.504 106.614C32.6798 106.613 32.0039 105.909 32.0973 105.09V105.09C32.2252 103.956 32.4588 102.837 32.7952 101.747C33.5604 99.2332 34.8431 96.9055 36.5615 94.9119C36.9511 94.4577 37.3637 94.0235 37.7977 93.611C38.8763 92.5839 40.078 91.693 41.3756 90.9586V90.9586C41.7735 90.7328 42.2699 90.7873 42.6378 91.0591C44.753 92.6219 47.3259 93.4665 49.9739 93.4545C52.6205 93.4545 54.9839 92.6781 57.098 91.1974C57.5983 90.847 58.2593 90.7756 58.7914 91.0754V91.0754C59.8433 91.6894 60.8299 92.4082 61.7357 93.2208C63.2586 94.5861 64.5466 96.1905 65.5485 97.9701C66.4414 99.5495 67.1018 101.248 67.5092 103.015C67.6697 103.702 67.7895 104.397 67.8681 105.098V105.098C67.979 105.934 67.2888 106.624 66.4461 106.624L33.504 106.614Z"
							fill="#F55273"
						/>
					</g>
					<path
						d="M50.816 91.6666C44.2361 91.6908 39.4197 87.0363 39.4706 81.1317C39.4836 79.4134 39.9217 77.7247 40.7461 76.2148C41.5706 74.705 42.756 73.4205 44.1977 72.4749C45.6393 71.5293 47.2929 70.9516 49.0121 70.793C50.7314 70.6343 52.4634 70.8996 54.0552 71.5653C55.647 72.231 57.0495 73.2767 58.1389 74.6099C59.2282 75.9431 59.9708 77.5229 60.301 79.2095C60.6312 80.8962 60.5389 82.6378 60.0322 84.2805C59.5255 85.9233 58.6201 87.4165 57.3959 88.6283C55.3532 90.6435 52.8631 91.6357 50.816 91.6666Z"
						fill="#F55273"
					/>
				</svg>
			</div>
		</div>
	);
};

export default UserBadgeBurstIcon;
