import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ProfileProvider, useScholarship, FlashContext, checkFeature } from '../../contexts';
import { Routes, FLAGS } from '../../constants';
import SingleForm from './SingleForm';
import { Profile } from '../../components/profile';

const UpdateProfile: React.FC<RouteComponentProps> = (props) => {
	const { getAndSetScholarships } = useScholarship();
	const flash = React.useContext(FlashContext);
	const path = props.location.pathname;
	const isProfileCategories = checkFeature(FLAGS.PROFILE);
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const next = () => {
		getAndSetScholarships();
		props.history.push(Routes.scholarships.feed);
	};

	return (
		<ProfileProvider>
			{isProfileCategories ? (
				<Profile />
			) : (
				<SingleForm show={isProfileCategories === false} next={next} path={path} display={flash.display} />
			)}
		</ProfileProvider>
	);
};
export default withRouter(UpdateProfile);
