import * as React from 'react';
import Select from 'react-select';
import { ParameterValueType } from '@scholly/scholly-types';

import { ScreenStrings } from '../../constants';
import customStyles from './lib/CustomStyles';
import { getParameterValueToSelect } from '../../helpers/Functions';

import styles from './profile.scss';
import { UserFormParamsType } from '../../types';

type OUserBasicType = Pick<UserFormParamsType, 'enrollment_status'>;

type IProps = OUserBasicType & {
	onChangeText?: (property: keyof OUserBasicType) => (value: string) => void;
	parameter_values: ParameterValueType[];
	styles?: object;
	hasError?: boolean;
	noLabel?: boolean;
	onChangeTextOnboarding?: (value: string) => void;
};
export default class ProfileEnrollmentStatus extends React.Component<IProps> {
	static defaultProps = {
		styles: {},
	};
	_onChangeVal =
		(property: 'enrollment_status') =>
		({ value }) => {
			this.props.onChangeText(property)(value);
		};
	_renderDropdown() {
		const enrollment_status = this.props.parameter_values;

		return (
			<Select
				//@ts-ignore
				testID={`form_input_enrollment_status`}
				hasError={this.props.hasError}
				styles={customStyles(this.props)}
				placeholder={ScreenStrings.profile.enrollmentStatus}
				value={getParameterValueToSelect(this.props.enrollment_status, enrollment_status)}
				onChange={
					this.props.onChangeTextOnboarding
						? this.props.onChangeTextOnboarding
						: this._onChangeVal('enrollment_status')
				}
				options={enrollment_status}
				getOptionLabel={(options) => options.value}
				getOptionValue={(options) => options.id}
			/>
		);
	}
	render() {
		// TODO: REMOVEZZZ
		// const degree = this.props.parameter_values.filter(word => word.parameter_id == '9');
		return (
			<div className={styles.profileWrapper}>
				<h3>{this.props.noLabel ? '' : ScreenStrings.profile.enrolledAs}</h3>
				<div className={styles.profileContainer}>{this._renderDropdown()}</div>
			</div>
		);
	}
}
