import React from 'react';
import styles from './updateProfileWelcome.scss';
import ScreenStrings from '../../../../constants/ScreenStrings';
import UserBadgeBurst from '../../../../components/common/icons/user-badge-burst';

const UpdateProfileWelcome = () => {
	return (
		<div className={styles.root}>
			<div className={styles.container}>
				<UserBadgeBurst />
				<h2 className={styles.title}>{ScreenStrings.updateProfile.welcomeTitle}</h2>
				<p className={styles.description}>{ScreenStrings.updateProfile.welcomeDescription}</p>
			</div>
		</div>
	);
};

export default UpdateProfileWelcome;
