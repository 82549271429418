import React from 'react';
import ReactDOM from 'react-dom/client';

import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import Modal from 'react-modal';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { name, version } from '../package.json';
import App from './App';
import config from './config/config';

const tagManagerArgs = {
	gtmId: config.gtm.id,
};

Sentry.init({
	dsn: config.sentry_dsn,
	environment: process.env.NODE_ENV === 'development' ? 'dev' : 'production',
	release: `${name}@${version}`,
	// @ts-ignore
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: 0.2,
	ignoreErrors: ['NOT-FOUND', 'Non-Error promise rejection captured'],
});

Modal.setAppElement('#root');

TagManager.initialize(tagManagerArgs);
ReactGA.initialize(config.ga.id, config.ga.options);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);
