import React, { useContext, useState, useEffect } from 'react';
import { StyleSheet } from 'react-native-web';
import classNames from 'classnames';
import { Button, Colors, Typography } from '@scholly/scholly-ui';
import { Button as Btn } from '@scholly/elements';
import { Status, ScreenStrings, ButtonStrings } from '../../constants';
import { ScholarshipContext } from '../../contexts';
import subStyles from './scholarship.scss';
import { ScholarshipFeedIndex } from '../../types';
import Spinner from '../misc/Loader';

interface Props {
	onPress: () => void;
	isLoading?: boolean;
	length: number;
	matchedMetaCount?: number;
}

const ListFooter = (props: Props) => {
	const context = useContext(ScholarshipContext);
	const [show, setShow] = useState(false);
	const [showLoadBtn, setShowLoadBtn] = useState(false);
	const { onPress, matchedMetaCount, length } = props;

	useEffect(() => {
		if (matchedMetaCount) {
			setShowLoadBtn(matchedMetaCount > length);
		}
		if (props.length > 0 && context.selectedIndex == ScholarshipFeedIndex.MATCHED) setShow(true);
		else setShow(false);
	}, [context.selectedIndex, length, matchedMetaCount, props.length]);

	if (!show) return null;

	const handleLoadMore = () => {
		context.paginateScholarships();
	};

	if (context.status == Status.PENDING) return <Loading />;
	if (showLoadBtn) return <LoadMoreButton onClick={handleLoadMore} />;
	if (context.status == Status.RESOLVED) {
		return (
			<div className={subStyles.listFooter}>
				<img src={ScreenStrings.scholarship.listFooterImg} className={subStyles.listFooterImg} alt="Scholly" />
				<Typography variant="caption" align="center" family="light">
					{ScreenStrings.scholarship.listFooterCopy}
				</Typography>
				<Button
					label={ButtonStrings.listFooter}
					variant="link"
					style={styles.btnStyles}
					colorType="secondary"
					compact
					onPress={onPress}
					textVariant="caption"
				/>
			</div>
		);
	}
	return null;
};

export default ListFooter;

const LoadMoreButton = ({ onClick }) => {
	return (
		<div
			className={classNames('mb-8', subStyles.isFlex, subStyles.alignItemsCenter, subStyles.justifyContentCenter)}
		>
			<Btn className={subStyles.loadMore} onClick={onClick} variant={'secondary'}>
				Load More
			</Btn>
		</div>
	);
};

const Loading = () => {
	return (
		<div className={subStyles.loader}>
			<Spinner />
		</div>
	);
};
const styles = StyleSheet.create({
	root: { flex: 1, justifyContent: 'center', alignItems: 'center' },
	imageStyles: { flexGrow: 1, width: 32, height: 32, marginBottom: 4 },
	btnStyles: { padding: 0, margin: 0 },
	textGrey: { color: Colors.greyishBrown, paddingBottom: 2 },
});
