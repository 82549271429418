import * as React from 'react';

import { SVGProps } from 'react';

const Dollar = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={20} height={38} viewBox="0 0 20 38" fill="none" {...props}>
			<path
				d="M10.738 29.96c1.667-.173 2.94-.713 3.82-1.62.88-.92 1.32-2.093 1.32-3.52 0-.627-.12-1.167-.36-1.62a3.645 3.645 0 00-.94-1.18c-.4-.347-.873-.64-1.42-.88-.533-.24-1.106-.46-1.72-.66l-.7 9.48zm-.8-22.58c-.84.08-1.566.247-2.18.5-.6.253-1.1.567-1.5.94-.386.373-.673.8-.86 1.28-.186.48-.28.98-.28 1.5 0 .6.107 1.127.32 1.58.214.453.507.853.88 1.2.387.333.834.627 1.34.88.52.253 1.074.487 1.66.7l.62-8.58zm1.76 9.36c.96.293 1.907.62 2.84.98.947.347 1.794.793 2.54 1.34a6.233 6.233 0 011.8 2.06c.467.813.7 1.84.7 3.08 0 1.2-.206 2.333-.62 3.4a8.253 8.253 0 01-1.76 2.82c-.773.813-1.726 1.473-2.86 1.98-1.12.507-2.4.807-3.84.9l-.24 3.34c-.013.267-.12.507-.32.72a.997.997 0 01-.76.32h-1.44l.32-4.44c-1.586-.173-3.053-.58-4.4-1.22-1.346-.653-2.5-1.473-3.46-2.46l1.18-1.8c.12-.16.267-.293.44-.4.174-.107.367-.16.58-.16.267 0 .56.12.88.36.334.24.727.52 1.18.84.454.307.987.613 1.6.92.627.293 1.374.5 2.24.62l.74-10.18a39.513 39.513 0 01-2.76-.94 9.542 9.542 0 01-2.44-1.4 6.663 6.663 0 01-1.74-2.18c-.44-.867-.66-1.953-.66-3.26 0-.987.194-1.947.58-2.88a7.648 7.648 0 011.68-2.5c.747-.733 1.66-1.333 2.74-1.8 1.094-.467 2.34-.727 3.74-.78l.2-2.8c.014-.267.12-.507.32-.72.2-.213.454-.32.76-.32h1.44l-.28 3.94c1.4.187 2.607.56 3.62 1.12a11.293 11.293 0 012.7 1.98l-.94 1.44c-.28.44-.606.66-.98.66-.186 0-.42-.08-.7-.24a18.08 18.08 0 00-.98-.54 13.162 13.162 0 00-1.3-.64 7.344 7.344 0 00-1.66-.48l-.68 9.32z"
				fill="#fff"
			/>
		</svg>
	);
};

export default Dollar;
