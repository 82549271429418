import React, { useContext, useRef, useEffect } from 'react';
import { getBlindOffers, getUserOffers } from '../actions/offerActions';
import useSWR from 'swr';
import { Status, HTTPStatus } from '../constants';

import { SessionContext } from '../contexts';
import { reject, get } from 'lodash/fp';
import { Offer, OfferStatus } from '../types/offers';

type ContextType = {
	offers: Array<Offer>;
};

type OfferContextProps = {
	children: JSX.Element;
};

export const OfferContext = React.createContext<ContextType | any>(null);

const options = {
	revalidateOnFocus: false,
	revalidateIfStale: false,
	dedupingInterval: 10000,
};

export const OfferContextProvider: React.FC<OfferContextProps> = (props) => {
	const { isAuthenticated } = useContext(SessionContext);

	let mounted = useRef(null);
	const pullr = mounted.current;

	useEffect(() => {
		if (isAuthenticated !== mounted.current && isAuthenticated === Status.RESOLVED) {
			mounted.current = true;
		}

		return () => (mounted.current = null);
	}, [isAuthenticated]);

	const {
		error: userOffersError,
		data: userOffersData,
		mutate: userOffersMutate,
	} = useSWR(['userOffers', pullr], getUserOffers, options);

	const {
		error: blindOffersError,
		data: blindOffersData,
		mutate: blindOffersMutate,
	} = useSWR(['blindOffers', pullr], getBlindOffers, options);

	const filterOffersByStatus = (offers, filteredStatus) => {
		return reject((offer: Offer) => filteredStatus.includes(offer.status))(offers);
	};

	const incompleteBlindOffers = filterOffersByStatus(get('data.offers')(blindOffersData), [
		OfferStatus.COMPLETED,
		OfferStatus.NOT_MATCHED,
		OfferStatus.PENDING,
	]);
	const incompleteUserOffers = filterOffersByStatus(get('data.offers')(userOffersData), [
		OfferStatus.COMPLETED,
		OfferStatus.NOT_MATCHED,
		OfferStatus.PENDING,
	]);

	const getDisplayedOffers = () => {
		return incompleteUserOffers;
	};

	useEffect(() => {
		if (
			userOffersData &&
			userOffersData.status === HTTPStatus.OK &&
			blindOffersData &&
			blindOffersData.status === HTTPStatus.OK
		) {
			mounted.current = null;
		}
	}, [userOffersData, blindOffersData]);

	return (
		<OfferContext.Provider
			value={{
				blindOffersError,
				blindOffersData: incompleteBlindOffers,
				userOffersData: incompleteUserOffers,
				userOffersError,
				userOffersMutate,
				blindOffersMutate,
				displayedOffers: getDisplayedOffers(),
			}}
		>
			{props.children}
		</OfferContext.Provider>
	);
};
