import React, { CSSProperties, FC } from 'react';

interface CopyIconProps {
	onClick: (val: any) => void;
	fill?: string;
	stroke?: string;
	svgStyle?: CSSProperties;
}
export const CopyIcon: FC<CopyIconProps> = (props) => {
	const { onClick, fill, svgStyle, stroke } = props;
	return (
		<div>
			<div className="content" onClick={onClick}>
				<svg
					width="15"
					height="19"
					viewBox="0 0 15 19"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					style={svgStyle}
				>
					<rect
						x="1"
						y="1"
						width="9.53846"
						height="13"
						rx="1"
						fill={fill || '#F5F6FA'}
						stroke={stroke || '#A0A3BD'}
						strokeWidth="2"
					/>
					<rect
						x="4.46149"
						y="4.46191"
						width="9.53846"
						height="13"
						rx="1"
						fill={fill || '#F5F6FA'}
						stroke={stroke || '#A0A3BD'}
						strokeWidth="2"
					/>
				</svg>
			</div>
		</div>
	);
};
