import * as React from 'react';

import { SVGProps } from 'react';

const Financial = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="40px" height="40px" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<circle cx={20} cy={20.958} r={20} fill="#F5F6FA" />
			<path
				d="M18.706 29.325c-.884-.1-1.742-.3-2.575-.6-.817-.3-1.509-.667-2.075-1.1V25.45h1.975v1.475c.45.283 1.033.517 1.75.7.716.167 1.425.25 2.125.25 1.133 0 1.991-.233 2.575-.7.583-.483.875-1.167.875-2.05 0-.6-.15-1.1-.45-1.5-.284-.417-.684-.767-1.2-1.05-.517-.283-1.225-.6-2.125-.95-1.25-.467-2.259-.908-3.025-1.325-.75-.433-1.334-.942-1.75-1.525-.4-.583-.6-1.292-.6-2.125 0-1.217.408-2.167 1.225-2.85.833-.7 1.925-1.125 3.275-1.275v-2.7h1.875V12.5a8.99 8.99 0 0 1 2.425.525c.766.267 1.416.592 1.95.975v2.125H22.98v-1.45c-.4-.233-.9-.408-1.5-.525a8.168 8.168 0 0 0-1.825-.2c-1.084 0-1.942.217-2.575.65-.634.433-.95 1.108-.95 2.025 0 .617.183 1.133.55 1.55.366.4.783.717 1.25.95.483.217 1.25.533 2.3.95 1.183.467 2.108.892 2.775 1.275a4.873 4.873 0 0 1 1.65 1.475c.416.583.625 1.333.625 2.25 0 1.167-.409 2.142-1.225 2.925-.817.767-1.975 1.208-3.475 1.325V32h-1.875v-2.675Z"
				fill="#3259F1"
			/>
		</svg>
	);
};

export default Financial;
