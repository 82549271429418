import React, { useMemo } from 'react';
import { getSubscriptionCancelReasons, getUserSubscription } from '../../../actions';

import AccountType from './screens/AccountType';
import AdditionalComments from './screens/AdditionalComments';
import AffiliateOffer from './screens/AffiliateOffer';
import Aspiration from './screens/Aspiration';
import CancelSubscription from './screens/CancelSubscription';
import CloseAccount from './screens/CloseAccount';
import MainScreen from './screens/MainScreen';
import PauseAccountConfirmation from '../pause-account-confirmation';
import PauseAccountSubscription from './screens/PauseAccountSubscription';
import PauseMembership from './screens/PauseMembership';
import Referral from './screens/Referral';
import { Routes } from '../../../constants';
import { SubHeader } from '../../../components/layouts/SubHeader';
import { SuccessfullyCanceled } from './screens/SuccessfullyCanceled';
import { SuccessfullyPaused } from './screens/SuccessfullyPaused';
import TechnicalIssues from './screens/TechnicalIssues';
import ThankYou from './screens/ThankYou';
import UnpauseMembership from './screens/UnpauseMembership';
import { UserContext } from '../../../contexts';
import styles from './offboarding.scss';
import { useParams } from 'react-router-dom';

const StartPage = () => {
	const [cancelReasons, setCancelReasons] = React.useState([]);
	const [headerText, setHeaderText] = React.useState('Pause Account');
	const [headerBackButton, setHeaderBackButton] = React.useState(true);
	const { subscription, setUserSubscription } = React.useContext(UserContext);
	const [issue, setIssue] = React.useState('Other');
	const { offboarding } = Routes;
	const { screen } = useParams<{ screen: string }>();
	React.useEffect(() => {
		getCancels();
	}, []);
	const getCancels = async () => {
		const { data } = await getSubscriptionCancelReasons();
		setCancelReasons(data);
		if (!subscription) {
			getSubs();
		}
	};
	const getSubs = async () => {
		try {
			const { data } = await getUserSubscription();
			setUserSubscription(data);
		} catch (err) {
			console.log(err);
		}
	};

	const getScreen = useMemo(() => {
		switch (screen) {
			case offboarding.technicalIssues: {
				setHeaderText('Cancel Subscription');
				return <TechnicalIssues setIssue={setIssue} />;
			}
			case offboarding.aspiration: {
				setHeaderText('Cancel Subscription');
				return <Aspiration />;
			}
			case offboarding.additionalComments: {
				setHeaderText('Cancel Subscription');
				return <AdditionalComments issue={issue} />;
			}
			case offboarding.pauseMembership:
				return <PauseMembership />;
			case offboarding.pauseAccount:
				setHeaderText('Pause Account');
				return <PauseAccountConfirmation />;
			case offboarding.closeAccount:
				setHeaderText('Close Account');
				return <CloseAccount />;
			case offboarding.thankYou: {
				setHeaderText('Account Closed');
				setHeaderBackButton(false);
				return <ThankYou />;
			}
			case offboarding.successfullyPaused:
				return <SuccessfullyPaused />;
			case offboarding.accountType:
				return <AccountType issue={issue} />;
			case offboarding.inviteFriends:
				return <Referral />;
			case offboarding.affiliateOffer:
				setHeaderText('Pause Account');
				return <AffiliateOffer />;
			case offboarding.cancelSubscription: {
				setHeaderText('Close Account');
				return <CancelSubscription />;
			}
			case offboarding.successfullyCanceled: {
				setHeaderText('Cancel Subscription');
				return <SuccessfullyCanceled />;
			}
			case offboarding.successUnpauseMembership: {
				setHeaderText('');
				setHeaderBackButton(false);
				return <UnpauseMembership successUnpause={true} />;
			}
			case offboarding.unpauseMembership: {
				setHeaderText('Unpause Account');
				setHeaderBackButton(false);
				return <UnpauseMembership />;
			}
			case offboarding.subscriptionComplete: {
				setHeaderText('Unpause Account');
				return <PauseAccountSubscription />;
			}
			default: {
				setHeaderText('Cancel Subscription');
				return <MainScreen cancelReasons={cancelReasons} />;
			}
		}
	}, [screen, cancelReasons]);

	return (
		<div className={styles.root}>
			<SubHeader title={headerText} className={styles.subheader} hideBackButton={!headerBackButton} />
			<div className={styles.offboardingContainer}>{getScreen}</div>
		</div>
	);
};
export default StartPage;
