import React from 'react';

import ContentLoader from 'react-content-loader';

const RefundPageLoader: React.FC = () => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<ContentLoader height={496} width={672} speed={2}>
				<rect x="0" y="0" width="670" height="496" />
			</ContentLoader>
		</div>
	);
};

export default RefundPageLoader;
