import React, { useCallback, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { isEqual } from 'lodash';

import { useProfileState } from '../../../contexts';

import { Pagination } from '../Pagination/Pagination';
import { Status } from '../../../constants';

import styles from './multi-step.scss';

export type MultiStepRef = {
	btnLoading?: boolean;
	setNextDisable?: (val: boolean) => void;
	displayPagination?: (bool: boolean) => void;
	setPaginateStepNumber?: (val: number) => void;
	setStepNumber?: (val: number) => void;
	stepNumber?: number;
	setBtnLoading?: any;
	next?: (values?: any) => void;
	previous?: (values?: any) => void;
	setErrors?: (values?: any[]) => void;
};

type IProps = {
	onSubmit?: (val: any, actions?: any) => void;
	handleBack?: () => void;
	initialValues?: any;
	children: React.ReactNode;
	style?: React.CSSProperties;
	renderIf?: boolean;
	totalSteps?: number;
};

export const Step = (props: any) => props.children;

const MultiStep = React.forwardRef<MultiStepRef, IProps>(
	({ children, initialValues, onSubmit, totalSteps, renderIf, handleBack }, ref) => {
		const [count, setCount] = useState(0);
		const [stepNumber, setStepNumber] = useState(0);
		const [paginateStepNumber, setPaginateStepNumber] = useState(0);
		const [nextDisabled, setNextDisable] = useState(false);
		const steps: Array<any> = React.Children.toArray(children);
		const [snapshot, setSnapshot] = useState(initialValues);
		const [btnLoading, setBtnLoading] = useState(false);
		const [renderPagination, displayPagination] = useState(true);

		const { changeState, data, setErrors, updateStatus } = useProfileState();

		React.useImperativeHandle(ref, () => ({
			stepNumber,
			btnLoading,
			setBtnLoading,
			setNextDisable,
			setPaginateStepNumber,
			setStepNumber,
			next,
			previous,
			setErrors,
			displayPagination,
		}));
		const countInterval = React.useRef(null);
		const step = steps[stepNumber];
		// const showBack = stepNumber > 0 && !steps[stepNumber - 1]?.props?.skip;
		const showBack = false;

		const next = useCallback(
			(values?: any) => {
				let number = !showBack ? paginateStepNumber : paginateStepNumber + 1;
				setSnapshot(data);
				setStepNumber(Math.min(stepNumber + 1, steps.length - 1));
				setPaginateStepNumber(number);
			},
			[data, stepNumber, totalSteps, paginateStepNumber, showBack]
		);

		const previous = (values?: any) => {
			let number = !showBack ? paginateStepNumber - 1 : paginateStepNumber;
			setPaginateStepNumber(Math.min(number - 1, totalSteps - 1));
			handleBack();
			setSnapshot(data);
		};

		const handleSubmit = async () => {
			changeState({ profileStatus: Status.PENDING });
			setBtnLoading(true);
			const byPass = isEqual(snapshot[step.props.name], data[step.props.name]);
			if (step.props.onSubmit) {
				step.props.onSubmit(data, step.props.name, { changeState, updateStatus, byPass }).then((res) => {
					if (res) {
						next();
					}
				});
			}
			if (onSubmit) {
				onSubmit(data, { changeState, updateStatus, byPass });
			}
		};

		useEffect(() => {
			countInterval.current = setInterval(() => setCount((old) => old + 5), 1000);
			return () => {
				clearInterval(countInterval.current); //when user exits, clear this interval.
			};
		}, []);

		useEffect(() => setSnapshot(initialValues), [initialValues]);

		useEffect(() => {
			if (step?.props?.skip && next) {
				next();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [next]);

		useEffect(() => {
			if (count >= 100) {
				setCount(100);
				clearInterval(countInterval.current);
			}
		}, [count]);

		if (!renderIf) return null;

		return (
			<div className={styles.multistepWrapper}>
				<CSSTransition
					classNames={{
						enterActive: 'container-enter',
						exitActive: 'container-exit',
					}}
					appear={false}
					timeout={400}
				>
					<div className={styles.multiStepContainer}>{step}</div>
				</CSSTransition>
				<div className={styles.paginationContainer}>
					<Pagination
						renderIf={renderPagination}
						step={paginateStepNumber}
						stepCount={totalSteps}
						//@ts-ignore
						handleNext={handleSubmit}
						handleBack={() => previous(snapshot)}
						backDisabled={!showBack}
						nextDisabled={nextDisabled}
						hideBackButton={!showBack}
					/>
				</div>
			</div>
		);
	}
);

export default MultiStep;
