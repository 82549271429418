import React, { CSSProperties, FC } from 'react';

interface Props {
	svgStyle?: CSSProperties;
	isShow?: boolean;
	onClick: (val: any) => void;
}

export const ViewPasswordIcon: FC<Props> = (props) => {
	const { svgStyle, isShow, onClick } = props;
	return (
		<div>
			<div className="content" onClick={onClick}>
				{isShow && (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="22"
						height="20"
						viewBox="0 0 22 20"
						fill="none"
						style={svgStyle}
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M3.09937 0.567301C2.6763 0.144233 1.99037 0.144233 1.56731 0.567301C1.14424 0.990369 1.14424 1.6763 1.56731 2.09937L4.19348 4.72554C2.6436 5.89132 1.29253 7.49255 0.281042 9.51552C0.128548 9.82051 0.128548 10.1795 0.281042 10.4845C2.61911 15.1606 6.77162 17.5833 11 17.5833C12.6848 17.5833 14.3575 17.1987 15.9081 16.4402L18.9006 19.4327C19.3237 19.8558 20.0096 19.8558 20.4327 19.4327C20.8558 19.0096 20.8558 18.3237 20.4327 17.9006L3.09937 0.567301ZM14.2649 14.797L12.4012 12.9333C11.9769 13.1363 11.5017 13.25 11 13.25C9.20508 13.25 7.75001 11.7949 7.75001 10C7.75001 9.49827 7.8637 9.02308 8.06674 8.5988L5.74408 6.27614C4.48676 7.16102 3.35948 8.39814 2.4738 10C4.4979 13.6608 7.78382 15.4167 11 15.4167C12.0978 15.4167 13.2038 15.2121 14.2649 14.797Z"
							fill="#A0A3BD"
						/>
						<path
							d="M18.0983 12.0534C18.6216 11.4518 19.1016 10.7678 19.5262 10C17.5021 6.33918 14.2161 4.58333 11 4.58333C10.8786 4.58333 10.7572 4.58583 10.6357 4.59084L8.70268 2.65779C9.45887 2.49739 10.2282 2.41667 11 2.41667C15.2284 2.41667 19.3809 4.83938 21.7189 9.51552C21.8714 9.82051 21.8714 10.1795 21.7189 10.4845C21.1296 11.6631 20.425 12.6986 19.633 13.5881L18.0983 12.0534Z"
							fill="#A0A3BD"
						/>
					</svg>
				)}

				{!isShow && (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="22"
						height="16"
						viewBox="0 0 22 16"
						fill="none"
						style={svgStyle}
					>
						<path
							d="M14.2501 8.00002C14.2501 9.79495 12.795 11.25 11.0001 11.25C9.20516 11.25 7.75008 9.79495 7.75008 8.00002C7.75008 6.2051 9.20516 4.75002 11.0001 4.75002C12.795 4.75002 14.2501 6.2051 14.2501 8.00002Z"
							fill="#A0A3BD"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M21.719 7.51554C19.381 2.8394 15.2285 0.416687 11.0001 0.416687C6.77169 0.416687 2.61919 2.8394 0.281119 7.51554C0.128625 7.82053 0.128625 8.17951 0.281119 8.4845C2.61919 13.1606 6.77169 15.5834 11.0001 15.5834C15.2285 15.5834 19.381 13.1606 21.719 8.4845C21.8715 8.17951 21.8715 7.82053 21.719 7.51554ZM11.0001 13.4167C7.7839 13.4167 4.49798 11.6608 2.47388 8.00002C4.49798 4.3392 7.7839 2.58335 11.0001 2.58335C14.2163 2.58335 17.5022 4.3392 19.5263 8.00002C17.5022 11.6608 14.2163 13.4167 11.0001 13.4167Z"
							fill="#A0A3BD"
						/>
					</svg>
				)}
			</div>
		</div>
	);
};
