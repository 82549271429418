import { ActionMap } from '../types';

export enum AuthTypes {
	SETUSER = 'SET_USER',
	ONCHANGE = 'ON_CHANGE',
	CANTRACK = 'CAN_TRACK',
	CLEARSTATE = 'CLEAR_STATE',
}

export enum SessionTypes {
	UPDATEMERCHANT = 'UPDATE_MERCHANT',
}

export type AuthStateType = {
	email: string;
	password: string;
	tracking?: string;
	user_email?: string;
	user_id?: string;
	first_name?: string;
	last_name?: string;
	phone_number?: string;
	profile_img?: string;
	merchant_type?: string;
	merchant_processor?: string;
	merchant_subscription_status?: string;
	missingReq?: boolean;
	referred?: boolean;
};

type AuthPayload = {
	[AuthTypes.SETUSER]: {
		user_email: string;
		user_id?: string;
		first_name?: string;
		last_name?: string;
		phone_number?: string;
		profile_img?: string;
		merchant_type?: string;
		merchant_processor?: string;
		merchant_subscription_status?: string;
		missingReq?: boolean;
		referred?: boolean;
	};
	[AuthTypes.ONCHANGE]: {
		email?: string;
		password?: string;
	};
	[AuthTypes.CLEARSTATE]: {
		email?: string;
		password?: string;
	};
	[AuthTypes.CANTRACK]: {
		tracking: string;
	};
	[SessionTypes.UPDATEMERCHANT]: {
		merchant_type?: string;
		merchant_processor?: string;
		merchant_subscription_status?: string;
	};
};

export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];

export const authReducer = (state: AuthStateType, actions: AuthActions) => {
	switch (actions.type) {
		case AuthTypes.ONCHANGE: {
			const changed = actions.payload;
			const [stateKey] = Object.keys(state).filter((key) => Object.hasOwnProperty.call(changed, key)); // NOSONAR

			return {
				...state,
				// @ts-ignore
				[stateKey]: actions.payload[stateKey],
			};
		}
		case SessionTypes.UPDATEMERCHANT:
		case AuthTypes.SETUSER:
		case AuthTypes.CANTRACK:
			return { ...state, ...actions.payload };
		case AuthTypes.CLEARSTATE:
			return {
				...actions.payload,
			};
		default:
			return state;
	}
};
