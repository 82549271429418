import { Status } from '../constants/Status';
import React from 'react';
import {
	CategoriesActions,
	CategoriesContextState,
	categoriesReducer,
	CategoriesTypes,
} from '../reducers/CategoriesReducer';
import { CategoriesModel, MiscCategory, MiscellaneousCategoryModel, SubCategoriesParams } from '../types';
import { UserContext } from './UserContext';
import * as Sentry from '@sentry/react';
import { getParameterCategories, getParameterValuesCategory } from '../actions';
import { alphaSort } from '../helpers/Functions';
import { STORAGE_KEYS } from '../constants/StorageKeys';
import Cache from '../helpers/Cache';
import { isEmpty } from 'lodash/fp';
import { SessionContext } from '../contexts';

type Dispatch = (action: CategoriesActions) => void;
type CategoriesProviderProps = { children: React.ReactNode };

const initialState: CategoriesContextState = {
	categories: [],
	categoriesLoadedStatus: Status.IDLE,
	selectedCategories: [],
	selectedSubCategories: [],
	categoryIndex: -1,
	loadCategories: () => {},
};

export const CategoriesStateContext = React.createContext<CategoriesContextState>(initialState);

const CategoriesDispatchContext = React.createContext<Dispatch | undefined>(undefined);

const init = (initialState: CategoriesContextState) => initialState;
export const CategoriesProvider = ({ children }: CategoriesProviderProps) => {
	const [state, dispatch] = React.useReducer(categoriesReducer, initialState, init);
	const { userLoadedStatus, misc } = React.useContext(UserContext);
	const { endSession } = React.useContext(SessionContext);

	const loadCategories = async () => {
		Cache.get(STORAGE_KEYS.CATEGORIES).then(async (store: any) => {
			if (isEmpty(store) == false) {
				dispatch({
					type: CategoriesTypes.SetCategories,
					payload: JSON.parse(store),
				});
				dispatch({ type: CategoriesTypes.SetStatus, payload: { status: Status.RESOLVED } });
			} else if (
				isEmpty(store) &&
				Status.RESOLVED === userLoadedStatus &&
				state.categoriesLoadedStatus === Status.IDLE
			) {
				dispatch({ type: CategoriesTypes.SetStatus, payload: { status: Status.PENDING } });
				try {
					const response = await getParameterCategories();
					const { categories } = response.data;

					let selectedCategories: CategoriesModel[] = [];

					// * ADD SUBCATEGORIES TO EACH CATEGORY SECTION
					const promises = categories.map((category: CategoriesModel) => {
						return category.sub_categories.map(async (s, index) => {
							const response = await getParameterValuesCategory(`${s.id}`);
							const data = response.data.categories;
							s.params = alphaSort(data, 'value');
							return s;
						});
					});
					await Promise.all(
						promises.map(function (innerPromiseArray) {
							return Promise.all(innerPromiseArray);
						})
					);

					const miscCategories: Array<MiscCategory> = misc.flatMap(
						(category: MiscellaneousCategoryModel) => category.categories
					);
					const param_ids = misc.map((param: SubCategoriesParams) => param.id);
					// * Set selected to selectedCategories
					for (const category of categories) {
						for (const miscCategory of miscCategories) {
							if (miscCategory.category == null) {
								if (category.id === miscCategory.id) {
									selectedCategories = [
										...selectedCategories.filter((i) => i.id !== category.id),
										category,
									];
								}
							} else if (miscCategory.category.id === category.id) {
								selectedCategories = [
									...selectedCategories.filter((i) => i.id !== category.id),
									category,
								];
							}
						}
					}

					const filteredParams = param_ids.filter(
						//@ts-ignore
						(p: SubCategoriesParams, i: number) => param_ids.indexOf(p) === i
					);

					const payload = { categories, selectedSubCategories: filteredParams, selectedCategories };

					Cache.set(STORAGE_KEYS.CATEGORIES, JSON.stringify(payload));
					dispatch({
						type: CategoriesTypes.SetCategories,
						payload,
					});
					dispatch({ type: CategoriesTypes.SetStatus, payload: { status: Status.RESOLVED } });
				} catch (err) {
					console.warn(err);
					Sentry.captureException(err);
					if (err?.status && err?.status === 401) {
						endSession();
					} else {
						dispatch({ type: CategoriesTypes.SetStatus, payload: { status: Status.REJECTED } });
					}
				}
			}
		});
	};

	return (
		<CategoriesStateContext.Provider value={{ ...state, loadCategories }}>
			<CategoriesDispatchContext.Provider value={dispatch}>{children}</CategoriesDispatchContext.Provider>
		</CategoriesStateContext.Provider>
	);
};

export const useCategoriesState = () => {
	const context = React.useContext(CategoriesStateContext);
	if (context === undefined) {
		console.warn('useCategoriesState must be used within a CategoriesProvider');
	}
	return context;
};

export const useCategoriesDispatch = () => {
	const context = React.useContext(CategoriesDispatchContext);
	if (context === undefined) {
		console.warn('useCategoriesDispatch must be used within a CategoriesProvider');
	}
	return context;
};
