import { Calendar26px, ErrorMessage, Input, InputType, Mobile26px } from '@scholly/elements';
import React, { useEffect } from 'react';
import { formatPhoneNumber, getLastAdultBirthday } from '../../helpers/Functions';
import { isBelow13, lengthValidation, validatePhone } from '../../helpers/Validation';

import { MultiStepRef } from '../common/MultiStep/MultiStep';
import { ProfileFieldsType } from '@scholly/core';
import { Status } from '../../constants';
import classNames from 'classnames';
import { isSafari } from '../../helpers/checkIfSafari';
import moment from 'moment';
import styles from './onboarding.scss';
import { useProfileState } from '../../contexts';

type Props = {
	values?: any;
	path?: any;
	multiRef?: React.MutableRefObject<MultiStepRef>;
};
enum InputName {
	'first_name' = 'first_name',
	'last_name' = 'last_name',
	'phone_number' = 'phone_number',
	'birthday' = 'birthday',
}

const BasicInfo: React.FC<Props> = ({ multiRef }) => {
	const { changeState, status, data, errors, setErrors } = useProfileState();
	const [focusInput, setFocusInput] = React.useState('');

	const hasError = (key, value) => {
		switch (key) {
			case 'first_name':
			case 'last_name':
				return lengthValidation(value, 2);
			case 'birthday':
				return !isBelow13(value, null);
			case 'phone_number':
				return validatePhone(value);
			default: {
				return true;
			}
		}
	};

	useEffect(() => {
		let isValid = true;
		if (status === Status.RESOLVED) {
			Object.entries(data).forEach((a) => {
				if (!hasError(a[0], a[1])) {
					isValid = false;
				}
				if (data.birthday === '') {
					isValid = false;
				}
			});
			multiRef.current.setNextDisable(!isValid);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		let isValid = true;
		if (errors.length > 0) {
			errors.forEach((a) => {
				if (!hasError(a[0], a[1])) {
					isValid = false;
				}
				if (data.birthday === '') {
					isValid = false;
				}
			});
			multiRef.current.setNextDisable(!isValid);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errors]);

	useEffect(() => {
		let isValid = true;
		if (errors.length > 0) {
			errors.forEach((a) => {
				if (!hasError(a[0], a[1])) {
					isValid = false;
				}
				if (data.birthday === '') {
					isValid = false;
				}
			});
			multiRef.current.setNextDisable(!isValid);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errors]);

	useEffect(() => {
		if (isSafari() && !data.birthday) {
			onChangeText('birthday')(moment(getLastAdultBirthday()).format('YYYY-MM-DD'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const onChangeText = (property: keyof ProfileFieldsType) => (value: any) => {
		if (errors.length) {
			setErrors([]);
		}

		changeState({ [property]: value });
	};

	const getErrorMessage = () => {
		const hasPhoneNumberError = errors.some((error) => error.field === 'phone_number');

		if (hasPhoneNumberError) {
			return 'Please enter a valid phone number';
		}
		return null;
	};

	return (
		<div className={styles.onboardingFormWrapper}>
			<h2>Tell us about yourself</h2>

			{/* FIRST NAME */}
			<div className={styles.formWrapper}>
				<Input
					style={{ lineHeight: '24px' }}
					placeholder="First Name *"
					value={data.first_name}
					name={InputName.first_name}
					onChange={(val) => onChangeText(InputName.first_name)(val)}
					className={classNames(styles.w100)}
					onFocus={() => setFocusInput(InputName.first_name)}
					onBlur={() => setFocusInput('')}
					showValidation={true}
					validationCriteria={
						focusInput === InputName.first_name
							? null
							: {
									inputVal: data.first_name,
									type: InputType.Name,
							  }
					}
				/>
				{/* LAST NAME */}
				<Input
					style={{ lineHeight: '24px' }}
					placeholder="Last Name *"
					value={data.last_name}
					name={InputName.last_name}
					onChange={(val) => onChangeText(InputName.last_name)(val)}
					onFocus={() => setFocusInput(InputName.last_name)}
					onBlur={() => setFocusInput('')}
					className={classNames(styles.w100)}
					showValidation={true}
					validationCriteria={
						focusInput === InputName.last_name
							? null
							: {
									inputVal: data.last_name,
									type: InputType.Name,
							  }
					}
				/>
				<div className={styles.innerFormContainer}>
					{/* DOB */}
					<Input
						placeholder="Date of Birth *"
						name={!isSafari() ? 'birthday' : 'birthday-ios'}
						variant={!isSafari() ? 'text' : 'date'}
						value={data.birthday}
						className={classNames(styles.w100, isSafari() && styles.iosDate)}
						onChange={(val) => onChangeText(InputName.birthday)(val)}
						onFocus={() => setFocusInput(InputName.birthday)}
						onBlur={() => setFocusInput('')}
						Icon={<Calendar26px />}
						showValidation={true}
						validationCriteria={
							focusInput === InputName.birthday
								? null
								: {
										inputVal: data.birthday,
										type: InputType.Birthday,
								  }
						}
					/>
					<div className={styles.phoneWrapper}>
						{/* Phone Number */}
						{getErrorMessage() && !focusInput && (
							<ErrorMessage className={classNames('validation-container', styles.errorWrapper)}>
								{getErrorMessage()}
							</ErrorMessage>
						)}
						<Input
							variant="tel"
							placeholder="+1 (000) 000-0000 *"
							name={InputName.phone_number}
							value={formatPhoneNumber(data.phone_number)}
							onFocus={() => setFocusInput(InputName.phone_number)}
							onBlur={() => setFocusInput('')}
							className={classNames(styles.w100)}
							onChange={(val) => onChangeText(InputName.phone_number)(val)}
							Icon={<Mobile26px />}
							iconWrapperStyle={{ left: 7 }}
							validationCriteria={
								focusInput === InputName.phone_number
									? null
									: {
											inputVal: data.phone_number,
											type: InputType.PhoneNumber,
									  }
							}
							showValidation
						/>
					</div>
				</div>

				<label className={styles.checkboxContainer}>
					<input
						test-id="sms-checkbox"
						onChange={() => {
							onChangeText('sms_opt_in')(!data.sms_opt_in);
						}}
						type="checkbox"
						checked={data.sms_opt_in}
					/>
					<span className={styles.checkmark} />
					<p className={styles.checkmarkText}>
						<span>
							By checking this box, I agree to receive{' '}
							<a
								target="_blank"
								href="https://myscholly.com/sms/?_gl=1*12xptb5*_gcl_au*MjE0Njg1MDMxNy4xNjgyNjAxOTc3&_ga=2.229059487.2048006049.1689547681-1013599555.1682439731"
							>
								SMS
							</a>{' '}
							messages from Scholly.
						</span>
					</p>
				</label>
			</div>
		</div>
	);
};

export default BasicInfo;
