import { Heading } from '@scholly/elements';
import * as React from 'react';
import { ScreenStrings } from '../../constants';
import styles from './error.scss';

type IProps = {
	logErrorToService: (error: any, extraInfo: any) => void;
	children: React.ReactNode;
};

class ErrorBoundary extends React.Component<IProps> {
	state = {
		hasError: false,
	};

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		// You can also log the error to an error reporting service
		// Send error to Sentry
		// @ts-ignore
		this.props.logErrorToService(error, info);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div id={styles.errorBoundary}>
					<div id={styles.imageContainer}>
						<img src={ScreenStrings.scholarship.errorBoundaryImg} alt="error" />
					</div>
					<div id={styles.textContainer}>
						<Heading variant="h1" id={styles.textGrey}>
							Aaaah! Something went wrong.
						</Heading>

						<Heading variant="h4" id={styles.textGrey}>
							Brace yourself till we get the error fixed.
						</Heading>

						<Heading variant="h4" id={styles.textGrey}>
							You may also refresh the page or try again later
						</Heading>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
