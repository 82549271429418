import React, { CSSProperties, FC } from 'react';

interface FacebookIconProps {
	onClick?: (val: any) => void;
	fill?: string;
	svgStyle?: CSSProperties;
}
export const FacebookIcon: FC<FacebookIconProps> = (props) => {
	const { onClick, fill, svgStyle } = props;
	return (
		<div>
			<div className="content" onClick={onClick}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="12"
					height="23"
					viewBox="0 0 12 23"
					fill="none"
					style={svgStyle}
				>
					<path
						d="M3.4367 17.2756C3.4367 15.8144 3.4367 14.3574 3.4367 12.8962C3.4367 12.5224 3.34325 12.3907 2.95247 12.4035C2.10293 12.429 1.28738 12.4035 0.454833 12.4035C0.144753 12.4035 -0.00815991 12.3483 0.000335427 11.9787C0.000335427 10.9253 0.000335427 9.85489 0.000335427 8.81845C0.000335427 8.51687 0.0980319 8.39369 0.425103 8.39369C1.24066 8.39369 2.05621 8.3682 2.87176 8.39369C3.36024 8.41917 3.46643 8.24077 3.44944 7.79052C3.41971 6.82629 3.44944 5.85783 3.44944 4.88936C3.47153 3.59299 3.99894 2.35649 4.9193 1.44326C5.83967 0.530038 7.08024 0.01228 8.37674 0.000288727C9.44565 -0.00475333 10.5138 0.0562463 11.5752 0.182939C11.8938 0.21692 12 0.335856 12 0.658679C11.983 1.59317 11.983 2.52765 12 3.46214C12 3.78072 11.8853 3.88691 11.5752 3.88691C10.9296 3.88691 10.3009 3.88691 9.6383 3.88691C9.43765 3.8776 9.2373 3.91062 9.05026 3.98383C8.86322 4.05703 8.69368 4.16877 8.55266 4.3118C8.41163 4.45482 8.30229 4.62593 8.23173 4.81398C8.16118 5.00204 8.13098 5.20284 8.14312 5.40333C8.14312 6.25286 8.14312 7.13637 8.14312 8.0029C8.14312 8.30873 8.23657 8.42767 8.56789 8.42767C9.53211 8.42767 10.5006 8.42767 11.469 8.42767C11.7791 8.42767 11.8938 8.49563 11.8428 8.82695C11.6673 9.90302 11.5087 10.9777 11.3671 12.0509C11.3289 12.3228 11.2184 12.4162 10.9423 12.412C10.182 12.412 9.41317 12.412 8.65283 12.412C8.26629 12.412 8.16011 12.5267 8.16435 12.9047C8.16435 15.7889 8.16435 18.6773 8.16435 21.5615C8.16435 22.0075 8.07515 22.1816 7.58242 22.1646C6.37609 22.1264 5.16975 22.1349 3.96341 22.1646C3.53864 22.1646 3.42395 22.0245 3.43245 21.6124C3.44944 20.164 3.4367 18.7198 3.4367 17.2756Z"
						fill={fill || '#A0A3BD'}
					/>
				</svg>
			</div>
		</div>
	);
};
