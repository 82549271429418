import React, { useReducer, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import { UserMerchantType } from './UserContext';

import { getLocalUser, login, logout, register, setUser } from '../actions';
import { authReducer, AuthTypes } from '../reducers';

import { Status, Routes } from '../constants';
import config from '../config/config';
import { missingReq } from '../helpers/Functions';
import omit from 'lodash/omit';

interface SessionContextType {
	email: string;
	password: string;
	referred?: any;
	user: any;
	isAuthenticated: Status;
	updateTracking: (tracking: any) => void;
	setisAuthenticated: (status: Status) => void;
	startSession: (data: any) => Promise<any>;
	startRegisteredSession: (data: any) => Promise<any>;
	endSession: () => void;
	setSessionUser: (data: any) => void;
	updateSessionUser: (user: any) => void;
	updateMerchant: (user: any) => void;
	updateProfileImage: () => Promise<void>;
}

const defaultContextValue: SessionContextType = {
	email: '',
	password: '',
	user: {},
	isAuthenticated: Status.IDLE,
	updateTracking: () => {},
	setisAuthenticated: () => {},
	startSession: async () => {},
	startRegisteredSession: async () => {},
	endSession: () => {},
	setSessionUser: () => {},
	updateSessionUser: () => {},
	updateMerchant: () => {},
	updateProfileImage: async () => {},
};

type SessionContextProps = {
	children: JSX.Element;
};

export enum UserMerchantSubscriptionStatus {
	PAUSED = 'paused',
	ACTIVE = 'active',
	PENDING_CANCELLATION = 'pending-cancellation',
}

const initialState = {
	email: '',
	password: '',
};

export const SessionContext = React.createContext<SessionContextType>(defaultContextValue);

export const SessionProvider = (props: SessionContextProps) => {
	const { same_site } = config;

	const [state, dispatch] = useReducer(authReducer, initialState);
	const [isAuthenticated, setIsAuthenticated] = useState<Status>(Status.IDLE);
	const [cookies, setCookie, removeCookie] = useCookies([same_site.name]);
	const history = useHistory();

	const updateProfileImage = async () => {};

	const startSession = async (data: any) => {
		setIsAuthenticated(Status.PENDING);
		const res = await login(data);
		setCookie(same_site.name, res.data.access_token, { domain: config.same_site.options.domain });
		setUser(res.data.user);
		updateSessionUser(res.data.user);
		return res.data.user;
	};

	const startRegisteredSession = async (data: any) => {
		setIsAuthenticated(Status.PENDING);
		const res = await register(data);

		setCookie(same_site.name, res.data.access_token, { domain: config.same_site.options.domain });
		setUser(res.data.user);

		return res.data.user;
	};

	const endSession = () => {
		logout().finally(() => {
			removeCookie(same_site.name, { domain: config.same_site.options.domain });
			// @ts-ignore
			dispatch({ type: AuthTypes.CLEARSTATE, payload: initialState });
			setIsAuthenticated(Status.IDLE);

			history.replace(Routes.login);
		});
	};
	// @ts-ignore
	const updateTracking = (tracking: typeof RESULTS) => dispatch({ type: AuthTypes.CANTRACK, payload: { tracking } });
	// @ts-ignore
	const updateSessionUser = (user: any) => dispatch({ type: AuthTypes.SETUSER, payload: user });
	// @ts-ignore
	const updateMerchant = (user: any) => dispatch({ type: SessionTypes.UPDATEMERCHANT, payload: user });

	const setSessionUser = (data: any) => {
		const hasSubscription =
			data.merchant !== undefined &&
			(data.merchant.type === UserMerchantType.subscriber ||
				data.merchant.type === UserMerchantType.pending_cancellation);

		let payload: any = {
			profile_img: '',
			user_id: data.id || '',
			user_email: data.email || '',
			first_name: data.first_name || '',
			last_name: data.last_name || '',
			phone_number: data.phone_number || '',
			merchant_type: data.merchant ? data.merchant.type : '',
			merchant_processor: hasSubscription ? data.merchant.subscriptions[0].processor : '',
			merchant_subscription_status: hasSubscription ? data.merchant.subscriptions[0].status : '',
			missingReq: missingReq(data),
		};

		//@ts-ignore
		const isProcessorStripe = data?.merchant?.subscriptions?.some((item) => item.processor === 'stripe');

		//@ts-ignore
		const isStatusPaused = data?.merchant?.subscriptions?.some((item) => {
			item.status === UserMerchantSubscriptionStatus.PAUSED;
		});

		//@ts-ignore
		if (isProcessorStripe && isStatusPaused) {
			payload.merchant_subscription_status = UserMerchantSubscriptionStatus.PAUSED;
		}

		updateSessionUser(payload);
	};

	useEffect(() => {
		const Authenticate = async () => {
			const token = await cookies[same_site.name];
			if (!!token) {
				const payload = await getLocalUser();
				setSessionUser(payload);
				setIsAuthenticated(Status.RESOLVED);
			} else {
				setIsAuthenticated(Status.REJECTED);
			}
		};
		Authenticate();
	}, []);

	const user = Object.assign({}, omit(state, ['referred', 'missingReq', 'email', 'password']));
	return (
		<SessionContext.Provider
			value={{
				email: state.email,
				password: state.password,
				//@ts-ignore
				referred: state.referred,
				user,
				isAuthenticated,
				updateTracking,
				setisAuthenticated: setIsAuthenticated,
				startSession,
				startRegisteredSession,
				endSession,
				setSessionUser,
				updateSessionUser,
				updateMerchant,
				updateProfileImage,
			}}
		>
			{props.children}
		</SessionContext.Provider>
	);
};
