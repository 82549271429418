export default {
	yes: 'Yes',
	back: 'Back',
	next: 'Next',
	no: 'No',
	noThanks: 'No Thanks',
	openDashboard: 'Go to Dashboard',
	done: 'Done',
	submit: 'Submit',
	save: 'Save',
	continue: 'Continue',
	viewScholarship: 'View Now',
	viewApplication: 'View Application',
	view: 'View',
	viewAllOffers: 'View All Offers',
	dismiss: 'Dismiss',
	remove: 'Remove',
	saveLater: 'Save for later',
	scholarships: {
		applied: 'Applied',
		saved: 'Saved',
	},
	listFooter: 'To update tap here',
	payment: 'Join Today',
	paymentFree: 'Try it Free',
	paymentStart: 'Start Free Trial',
	getStarted: 'Get Started',
	getStartedNow: 'Get Started Now',
	change: 'change',
	logout: 'Log Out',
	login: 'Login',
	signUp: 'Sign Up',
	signIn: 'Sign In',
	coninueFacebook: 'Continue with Facebook',
	restore: 'Restore Purchase',
	forgotPassword: 'Forgot Password?',
	reasonMobileOnly: 'Do on computer',
	help: 'Need help?',
	completePayment: 'Complete your payment',
	viewScholarships: 'View Scholarships',
	fiveQuickApplies: '5 Quick Applies',
	unselectAll: 'Unselect all',
	send: 'Send',
	getMoreMatches: 'Get More Matches',
	Cancel: 'Cancel',
	Apply: 'Apply',
	yesSubmitMyApplication: 'Yes! Submit My Application',
	yesIncreaseMyChances: 'Yes! Increase my chances',
	submitting: 'Submitting',
	submitMyEntry: 'Submit My Entry',
};
