import React from 'react';
import Select from 'react-select';
import styles from './onboarding.scss';
import customStyles from '../profile/lib/CustomStyles';

type Props = {
	options: Array<Opt>;
	onSelect: (opt: string) => void;
	onDeselect: (opt: string) => void;
	title: string;
};

type Opt = {
	value: string;
	title: string;
	selected: boolean;
};

const Dropdown: React.FC<Props> = (props) => {
	const { options, onSelect, title } = props;
	return (
		<div className={styles.onboardingFormWrapper}>
			<h2>{title}</h2>
			<div className={styles.formWrapper}>
				<Select
					styles={customStyles(props)}
					options={options}
					onChange={onSelect}
					getOptionLabel={(options) => options.title}
					getOptionValue={(options) => options.value}
					isScroll={true}
				/>
			</div>
		</div>
	);
};

export default Dropdown;
