import { PricingTest } from '../../constants';

const useABTest = (props, unset = false, ratio = 0.5) => {
	let solution;
	let newSol = Math.random() > ratio;
	if (newSol) {
		solution = 'quick_apply';
	} else {
		solution = 'multi_apply';
	}
	if (unset) {
		window.localStorage.setItem(props.toString(), solution.toString());
	}
	if (window.localStorage.getItem(props.toString())) {
	} else {
		window.localStorage.setItem(props.toString(), solution.toString());
	}
	return window.localStorage.getItem(props.toString());
};
const useABCDTest = (props, unset = false) => {
	let solution;
	let newSol = Math.random();

	if (newSol < 0.25) {
		solution = 'a';
	} else if (newSol < 0.5) {
		solution = 'b';
	} else if (newSol < 0.75) {
		solution = 'c';
	} else {
		solution = 'd';
	}
	if (unset) {
		window.localStorage.setItem(props.toString(), solution.toString());
	}
	if (window.localStorage.getItem(props.toString())) {
	} else {
		window.localStorage.setItem(props.toString(), solution.toString());
	}
	return window.localStorage.getItem(props.toString());
};
const useABCTest = (props, unset = false) => {
	let solution;
	let newSol = Math.floor(Math.random() * 3) + 1;
	//Make it so that control is never called
	if (newSol == 1) {
		solution = PricingTest.CONTROL;
	} else if (newSol == 2) {
		solution = PricingTest.VARIANT_A;
	} else {
		solution = PricingTest.VARIANT_B;
	}
	if (unset) {
		window.localStorage.setItem(props.toString(), solution.toString());
	}
	if (!window.localStorage.getItem(props.toString())) {
		window.localStorage.setItem(props.toString(), solution.toString());
	}
	return window.localStorage.getItem(props.toString());
};

const getABRef = (ref_value: string) => {
	return {
		ref: 'scholarship_apply',
		ref_data: window.localStorage.getItem(ref_value),
		ref_data2: 'web',
	};
};
export { useABTest, getABRef, useABCDTest, useABCTest };
