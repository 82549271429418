import * as React from 'react';

import { ProfileBasicInfo, ProfileFirstLastName, ProfileGender } from '../../../components/profile';
import { UserUpdateBirthday, UserUpdateFirstLastNameType } from '@scholly/scholly-types';

import { ParameterContext, useProfileState } from '../../../contexts';
import { Routes } from '../../../constants';
import { check } from '../../../helpers/Functions';
import isEmpty from 'lodash/isEmpty';
import { UserFormParamsType } from '../../../types';

type UserUpdateUserType = {
	user_type: string | any;
};

type PersonalFormType = UserUpdateFirstLastNameType &
	Pick<UserFormParamsType, 'gender' | 'race' | 'citizen'> &
	UserUpdateBirthday &
	UserUpdateUserType;

interface IPersonalFormProps {
	webStyle?: object;
	onChangeText: (property: keyof PersonalFormType) => (value: string) => void;
	onChangeTextMultiple?: (property: keyof PersonalFormType) => (value: string) => void;
	values?: any;
	missing: any[];
	path?: string;
	noMarginTop?: boolean;
	notSplitForm?: boolean;
	showError?: boolean;
}

const PersonalForm: React.FC<IPersonalFormProps> = ({
	webStyle,
	onChangeText,
	path,
	missing,
	notSplitForm,
	onChangeTextMultiple,
	showError,
}) => {
	const { values } = React.useContext(ParameterContext);
	const { data } = useProfileState();
	const hasError = (val: string, input: any) =>
		missing.includes(val) && (!check(input) || isEmpty(input)) && path !== Routes.user.create;

	return (
		<div style={{ margin: `40px 0` }}>
			<ProfileFirstLastName
				hasError={hasError}
				first_name={data.first_name}
				last_name={data.last_name}
				onChangeText={onChangeText}
			/>
			<ProfileBasicInfo
				styles={webStyle}
				birthday={data.birthday}
				race={data.race}
				citizen={data.citizen}
				user_type={data.user_type}
				onChangeText={onChangeText}
				onChangeTextMultiple={onChangeTextMultiple}
				path={path}
				hasError={hasError}
				showError={showError}
			/>
			<ProfileGender
				hasError={hasError('gender', data.gender)}
				styles={webStyle}
				gender={data.gender}
				parameter_values={values.gender}
				onChangeText={onChangeText}
				notBottom={notSplitForm}
			/>
		</div>
	);
};

export default PersonalForm;
