import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import Login from './Login';
import LoginSlider from './components/login-slider/login-slider';
import Nav from '../../components/nav/Nav';
import Register from './Register';
import ResetPassword from './ResetPassword';
import { SubHeader } from '../../components/layouts/SubHeader';
import UngatedFooter from '../../components/layouts/UngatedFooter';

import { checkFeature, PromoContext, FlashContext, FlashContextType } from '../../contexts';

import { FLAGS, Routes, ScreenStrings } from '../../constants';

import styles from './authentication.scss';

const Authentication: React.FC<RouteComponentProps> = (props) => {
	const flashContext: FlashContextType = React.useContext(FlashContext);
	const { setCode } = React.useContext(PromoContext);
	const [isLogin, setLogin] = useState(false);
	const [path, setPath] = useState('login');
	const [resetToken, setToken] = useState(null);
	const [loginPosition, setLPosition] = useState(styles.translateRight);
	const [promoPosition, setPPosition] = useState(styles.translateLeft);
	const [title, setTitle] = useState(null);
	const [hideBackButton, setHideBackButton] = useState(false);
	const history = useHistory();

	const queryURL = window.location.search;
	const urlParams = new URLSearchParams(queryURL);
	const isRefundPage = urlParams.get('page') === 'refund';

	const signUpTitle = checkFeature(FLAGS.SCHOLLY_FREE) ? ScreenStrings.signUp.title : ScreenStrings.signUp.oldTitle;
	useEffect(() => {
		const query = queryString.parse(props.location.search);
		if ('promo_code' in query) {
			// @ts-ignore
			setCode(query.promo_code);
			flashContext.display({
				type: 'success',
				text: 'Awesome! Your $10 discount has been applied.',
				shouldShowAsHeader: true,
			});
		}

		if ('token' in query) {
			setToken(query.token);
			setPath('reset');
		} else {
			setToken(null);
		}
	}, [props.location.search]);

	useEffect(() => {
		switch (props.location.pathname) {
			case Routes.register:
				setHideBackButton(true);
				setTitle(signUpTitle);
				setLogin(false);
				setPath('register');
				setLPosition(styles.translateLeft);
				setPPosition(styles.translateRight);
				break;
			case Routes.login:
				setHideBackButton(true);
				setTitle(isRefundPage ? ScreenStrings.login.refundTitle : ScreenStrings.login.title);
				setLogin(true);
				setPath('login');
				break;

			case Routes.resetpassword:
				setHideBackButton(false);
				setTitle(ScreenStrings.forgotPassword.title);
				setLogin(true);
				setPath('reset');
				break;
			default:
				setHideBackButton(true);
				setTitle(signUpTitle);
				setLogin(false);
				setPath('register');
				setLPosition(styles.translateLeft);
				setPPosition(styles.translateRight);
				break;
		}
	}, [props.location]);

	const toggleScreen = () => {
		if (path === 'register') {
			setLPosition(styles.translateRight);
			setPPosition(styles.translateLeft);
			props.history.replace(Routes.login);
		} else {
			setLPosition(styles.translateLeft);
			setPPosition(styles.translateRight);
			props.history.replace(Routes.register);
		}
	};

	const AuthForm = () => {
		switch (path) {
			case 'register':
				return <Register styles={styles} toggleScreen={toggleScreen} key="authregister" />;
			case 'reset':
				return <ResetPassword styles={styles} toggleScreen={toggleScreen} token={resetToken} key="authreset" />;
			default:
				return <Login styles={styles} key="authlogin" toggleScreen={toggleScreen} />;
		}
	};

	const goBack = () => {
		history.replace(Routes.login);
	};

	return (
		<div className={styles.mainContainer}>
			<div className={styles.pageContentWrapper}>
				<Nav isAuthenticationNav />
				<SubHeader className={styles.subHeader} title={title} hideBackButton={hideBackButton} onBack={goBack} />
				<div className={styles.contentWrapper}>
					<div className={styles.leftPane}>
						<div className={styles.authFormWrapper}>{AuthForm()}</div>
					</div>
					<div className={styles.rightPane}>
						<LoginSlider />
					</div>
				</div>
			</div>
			<div className={styles.footerWrapper}>
				<UngatedFooter />
			</div>
		</div>
	);
};

export default Authentication;
