import React from 'react';
import classNames from 'classnames';
import { Button, ErrorMessage, Input } from '@scholly/elements';

import { FlashContext, UserContext } from '../../contexts';

import { PasswordIcon } from '../../components/common/icons/password-icon';
import { PasswordValidation } from '../../components/auth/PasswordValidation';
import { ViewPasswordIcon } from '../../components/common/icons/view-password-icon';

import { PASSWORD_CHECKS, SINGLE_PASSWORD_CHECKS, ScreenStrings } from '../../constants';

import { passwordStrengthValidation } from '../../helpers/Validation';

import styles from './settings.scss';

const ChangePassword: React.FC = () => {
	const userContext = React.useContext(UserContext);
	const flashContext = React.useContext(FlashContext);
	const [password, setPassword] = React.useState('');
	const [newPassword, setNewPassword] = React.useState('');
	const [newRePassword, setNewRePassword] = React.useState('');
	const [isProcessing, setProcessing] = React.useState(false);
	const [showPassword, setShowPassword] = React.useState(false);
	const [showNewPassword, setShowNewPassword] = React.useState(false);
	const [showNewRePassword, setShowNewRePassword] = React.useState(false);

	const svgLockStyle = { top: 8 } as React.CSSProperties;
	const svgEyeStyle = { position: 'absolute', zIndex: 2, top: 12, right: 12 } as React.CSSProperties;

	const handleChange = (value) => setPassword(value);
	const handleNewPassword = (value) => setNewPassword(value);
	const handleReNewPassword = (value) => setNewRePassword(value);
	const isDisabled = () => {
		return (
			!password ||
			isProcessing ||
			Object.values(passwordStrengthValidation(newRePassword, newPassword)).filter((value) => !value).length !==
				0 ||
			newPassword === password
		);
	};
	const savePassword = async (e) => {
		e.preventDefault();
		setProcessing(true);

		try {
			const passwordResponse = passwordStrengthValidation(newPassword, newRePassword);
			if (newPassword === password) {
				Promise.all([setNewPassword(''), setNewRePassword('')]);
				flashContext.display({
					text: ScreenStrings.auth.newPasswordIdentical,
					type: 'error',
					disableScrollTop: true,
				});
			} else if (
				passwordResponse.isValueIdentical &&
				passwordResponse.lengthValid &&
				passwordResponse.numberValid &&
				passwordResponse.specialValid &&
				passwordResponse.upperCaseValid
			) {
				Promise.all([setPassword(''), setNewPassword(''), setNewRePassword('')]);
				await userContext.updateUserPassword({ current_password: password, new_password: newPassword });
				flashContext.display({ text: 'Password has been updated', type: 'success' });
			} else if (!passwordResponse.isValueIdentical) {
				Promise.all([setNewPassword(''), setNewRePassword('')]);
				flashContext.display({
					text: 'Passwords are not identical',
					type: 'error',
					shouldShowAsHeader: true,
				});
			} else {
				Promise.all([setNewPassword(''), setNewRePassword('')]);
				flashContext.display({
					text: 'Password must contain an upper case letter, a special character, a number and be at least 8 characters long',
					type: 'error',
					shouldShowAsHeader: true,
				});
			}
		} catch (error) {
			flashContext.display({ text: error[0].message, type: 'error' });
		}

		setProcessing(false);
	};

	const _renderList = () => {
		return (
			<div className="px-2">
				<div className={classNames(styles.title)}>Password</div>

				<form>
					<div className="mb-4 pb-1">
						{password && newPassword !== password && (
							<PasswordValidation
								messages={SINGLE_PASSWORD_CHECKS}
								testID="password_validation"
								passwordVal={password}
							/>
						)}
						{password && newPassword === password && (
							<div className={styles.validationWrapper}>
								<ErrorMessage className={styles.validationMessage}>
									{ScreenStrings.auth.newPasswordIdentical}
								</ErrorMessage>
							</div>
						)}
						<Input
							placeholder={ScreenStrings.auth.curPassword}
							name="password"
							// @ts-ignore
							type={showPassword ? 'text' : 'password'}
							value={password}
							className={classNames(styles.w100)}
							onChange={(val) => handleChange(val)}
							Icon={<PasswordIcon />}
							iconWrapperStyle={svgLockStyle}
						/>
						<ViewPasswordIcon
							onClick={() => {
								setShowPassword(!showPassword);
							}}
							svgStyle={svgEyeStyle}
							isShow={showPassword}
						/>
					</div>

					<div className="pb-1 mb-4">
						<div>
							{password && newPassword && (
								<PasswordValidation
									messages={PASSWORD_CHECKS}
									testID="password_validation"
									passwordVal={newPassword}
									repasswordVal={newRePassword}
								/>
							)}
						</div>
						<Input
							placeholder={ScreenStrings.auth.newPassword}
							name="password"
							// @ts-ignore
							type={showNewPassword ? 'text' : 'password'}
							value={newPassword}
							className={classNames(styles.w100)}
							onChange={(val) => handleNewPassword(val)}
							Icon={<PasswordIcon />}
							iconWrapperStyle={svgLockStyle}
						/>
						<ViewPasswordIcon
							onClick={() => {
								setShowNewPassword(!showNewPassword);
							}}
							svgStyle={svgEyeStyle}
							isShow={showNewPassword}
						/>
					</div>
					<div className="pb-1 mb-4">
						<Input
							placeholder={ScreenStrings.auth.rePassword}
							name="reNewPassword"
							// @ts-ignore
							type={showNewRePassword ? 'text' : 'password'}
							value={newRePassword}
							className={classNames(styles.w100)}
							onChange={(val) => handleReNewPassword(val)}
							Icon={<PasswordIcon />}
							iconWrapperStyle={svgLockStyle}
						/>
						<ViewPasswordIcon
							onClick={() => {
								setShowNewRePassword(!showNewRePassword);
							}}
							svgStyle={svgEyeStyle}
							isShow={showNewRePassword}
						/>
					</div>

					<Button
						variant="primary"
						className="px-0"
						onClick={savePassword}
						style={{ width: '100%' }}
						disabled={isDisabled()}
					>
						Update Password
					</Button>
				</form>
			</div>
		);
	};
	return <div className={styles.accordionContainer}>{_renderList()}</div>;
};
export default ChangePassword;
