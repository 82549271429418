import React, { CSSProperties, FC } from 'react';

interface TwitterIconProps {
	onClick?: (val: any) => void;
	fill?: string;
	svgStyle?: CSSProperties;
}
export const TwitterIcon: FC<TwitterIconProps> = (props) => {
	const { onClick, fill, svgStyle } = props;
	return (
		<div>
			<div className="content" onClick={onClick}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="22"
					height="17"
					viewBox="0 0 22 17"
					fill="none"
					style={svgStyle}
				>
					<path
						d="M4.03157 10.323C3.92511 10.1069 3.73533 10.1328 3.59184 10.0809C2.7844 9.839 2.07195 9.3788 1.54464 8.75859C1.01733 8.13838 0.698852 7.38602 0.629505 6.59671C0.560075 6.09093 0.629498 6.06067 1.14328 6.26817C1.61394 6.46923 2.13152 6.5555 2.64759 6.5189C1.95918 6.07835 1.40944 5.47388 1.05533 4.76813C0.766481 4.2147 0.616368 3.60711 0.616368 2.99143C0.616368 2.37575 0.766481 1.76816 1.05533 1.21473C1.22659 0.881868 1.34232 0.804058 1.64318 1.14556C3.42347 3.18768 5.9272 4.56767 8.70188 5.03615C9.22492 5.13126 9.76646 5.14855 10.2988 5.20907C10.6181 5.24365 10.7153 5.13558 10.6829 4.82865C10.4932 2.93956 11.2615 1.44384 13.0389 0.549006C14.8163 -0.345829 16.6771 -0.142653 18.2925 1.12827C18.3796 1.20491 18.4853 1.26067 18.6006 1.29078C18.7159 1.3209 18.8373 1.32447 18.9543 1.30119C19.5702 1.18251 20.1664 0.987707 20.7271 0.721921C20.944 0.58283 21.1902 0.488514 21.4492 0.445258C21.1048 1.27928 20.5298 2.01314 19.7829 2.57211C20.542 2.59805 21.2316 2.29545 22 2.08795C21.9204 2.40829 21.7281 2.69444 21.4538 2.90065C21.1793 3.19972 20.8687 3.46808 20.5281 3.70038C20.275 3.84924 20.0729 4.06274 19.9458 4.31557C19.8186 4.5684 19.7717 4.84998 19.8107 5.12693C19.7829 10.7942 15.182 15.7439 8.99811 16.7987C6.07879 17.3135 3.05862 16.8334 0.486017 15.4456C0.28442 15.3754 0.113477 15.2446 0 15.0739C1.89709 15.3923 3.85297 15.0294 5.47571 14.058C5.67754 13.9367 5.87221 13.8054 6.05891 13.6646C6.15611 13.5954 6.31812 13.5349 6.27184 13.3966C6.22555 13.2583 6.05428 13.2972 5.92931 13.2842C5.26473 13.2223 4.62512 13.0151 4.06222 12.6794C3.49933 12.3437 3.02897 11.8889 2.68925 11.3519C2.6023 11.2125 2.525 11.0681 2.45781 10.9196C2.19398 10.3663 2.20787 10.3533 2.83274 10.3792C3.23314 10.4152 3.63703 10.3963 4.03157 10.323Z"
						fill={fill || '#A0A3BD'}
					/>
				</svg>
			</div>
		</div>
	);
};
