import { LayoutContext } from './Layout';
import { Pagination } from '../common/Pagination/Pagination';
import React from 'react';
import { ButtonStrings } from '../../constants';
import className from 'classnames';
import styles from './layout.scss';

type IProps = {
	pageKey: number;
	pages: number;
	disabled: boolean;
	onNext: () => void;
	onBack?: () => void;
	progress?: boolean;
	progressValue?: number;
	backDisabled?: boolean;
	rightButtonText?: string;
	isLoading?: boolean;
	onProfile?: boolean;
	backButtonInvisible?: boolean;
};

const BottomNavigation: React.FC<IProps> = (props) => {
	const { toggle, setToggle } = React.useContext(LayoutContext);
	const { pageKey, pages } = props;
	BottomNavigation.defaultProps = {
		rightButtonText: ButtonStrings.continue,
	};
	const back = () => {
		props.onBack();
		setToggle(!toggle);
	};
	const next = () => {
		props.onNext();
		setToggle(!toggle);
	};

	return (
		<div className={className(styles.bottomNavContainer)}>
			<Pagination
				step={pageKey + 1}
				stepCount={pages}
				//@ts-ignore
				handleNext={next}
				handleBack={back}
				backDisabled={props.backDisabled}
				nextDisabled={props.disabled}
				containerClassName={styles.bottomPagination}
			/>
		</div>
	);
};
export default BottomNavigation;
