import * as React from 'react';
import { Feather } from 'react-web-vector-icons';
import { FlatList, StyleSheet, View } from 'react-native-web';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { Card, Colors } from '@scholly/scholly-ui';

import Activities from '../../components/common/icons/activities';
import Cap from '../../components/common/icons/cap';
import Financial from '../../components/common/icons/financial';
import Profile from '../../components/common/icons/profile';
import { SubHeader } from '../../components/layouts/SubHeader';

import { useCategoriesState } from '../../contexts';

import { Status, ScreenStrings } from '../../constants';
import { isMobileDevice } from '../../helpers/checkIfDesktop';

import subStyles from './categories.scss';
interface IProps {
	onPress?: () => void;
	selectedSections?: any;
	setSelectedSections?: any;
	index?: number;
	setIndex?: any;
}

const CategoryList: React.FC<IProps> = ({ onPress, selectedSections, setSelectedSections }) => {
	const { categories, selectedCategories, categoriesLoadedStatus } = useCategoriesState();
	const _renderSubCategories = (item) => {
		switch (item.id) {
			case '1':
				return 'Courses, Test Scores';
			case '2':
				return item.sub_categories
					.filter((subcategory) => subcategory.id === '8' || subcategory.id === '9')
					.map((subcagetory, index) => (index !== 0 ? ` ${subcagetory.display}` : `${subcagetory.display}`));
			case '3':
				return null;
			case '4':
				return item.sub_categories
					.filter((subcategory) => subcategory.id === '13' || subcategory.id === '17')
					.map((subcagetory, index) => (index !== 0 ? ` ${subcagetory.display}` : `${subcagetory.display}`));
		}
	};

	const _renderIcon = (item) => {
		switch (item.display) {
			case 'Financial':
				return <Financial />;
			case 'Extracurricular':
				return <Activities />;
			case 'Personal':
				return <Profile />;
			case 'Academics':
				return <Cap />;
		}
	};

	const checkMarkStyle = {
		width: 20,
		height: 20,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: 20,
	};
	const _renderItem = ({ item }) => {
		const isSelected = selectedSections.some((s) => s.id === item.id);
		const hasIcon = selectedCategories.some((s) => s.id === item.id);

		return (
			<View style={styles.cardContainer} key={item.id}>
				<Card
					testID={`testID_category_card_${item.name.replace(/ /g, '_')}`}
					style={styles.cardItem}
					isSelected={isSelected}
					onPress={() =>
						setSelectedSections((prevState) => {
							if (selectedSections.some((s) => s.id === item.id))
								return [...prevState.filter((i) => i.id !== item.id)];
							else return [...prevState, item];
						})
					}
					selectedStyles={styles.selectedStyles}
				>
					<>
						{hasIcon && (
							<View style={styles.iconContainer}>
								<Feather name="check" color="white" style={checkMarkStyle} />
							</View>
						)}
						{_renderIcon(item)}

						<h4 className={subStyles.categoryTitle}> {item.display} </h4>
						<p className={subStyles.categoryDescription}>
							{' '}
							{_renderSubCategories(item) !== null && `(${_renderSubCategories(item)}, Etc... )`}
						</p>
					</>
				</Card>
			</View>
		);
	};

	if (categoriesLoadedStatus !== Status.RESOLVED && categoriesLoadedStatus !== Status.IDLE) {
		return (
			<View style={[styles.loaderContainer, styles.marginHorizontal]}>
				<CategoryListLoader />
			</View>
		);
	}
	return (
		<View testID={`testID_categories_list`} style={styles.root}>
			<SubHeader title={ScreenStrings.categories.selectTitle} hideBackButton />
			<div className={subStyles.contentWrapper}>
				<FlatList
					data={categories}
					renderItem={_renderItem}
					keyExtractor={(categories, index) => index.toString()}
					numColumns={2}
					style={styles.container}
					columnWrapperStyle={styles.columnWrapperStyle}
					contentContainerStyle={styles.flatlistContainer}
					extraData={selectedCategories}
					ListHeaderComponent={
						<div className={subStyles.subHeaderContainer}>
							<p className={subStyles.subHeaderText}>{ScreenStrings.categories.selectBody}</p>
						</div>
					}
					ListFooterComponentStyle={{ marginTop: 24 }}
				/>
			</div>
		</View>
	);
};

export default CategoryList;

const CategoryListLoader: React.FC = () => {
	return (
		<ContentLoader height={500} width={520} backgroundColor={'#d6d6d6'}>
			<Rect x="0" y="60" rx="3" ry="3" width="100%" height={isMobileDevice ? '30' : '20'} />
			<Rect x="0" y="102" rx="3" ry="3" width={isMobileDevice ? '150' : '48%'} height="190" />
			<Rect
				x={isMobileDevice ? '168' : '52%'}
				y="102"
				rx="3"
				ry="3"
				width={isMobileDevice ? '150' : '48%'}
				height="190"
			/>
			<Rect x="0" y="318" rx="3" ry="3" width={isMobileDevice ? '150' : '48%'} height="190" />
			<Rect
				x={isMobileDevice ? '168' : '52%'}
				y="318"
				rx="3"
				ry="3"
				width={isMobileDevice ? '150' : '48%'}
				height="190"
			/>
		</ContentLoader>
	);
};

const styles = StyleSheet.create({
	root: {
		flex: 1,
		justifyContent: 'flex-start',
	},
	cardContainer: {
		flex: 1,
		marginVertical: 8,
		marginHorizontal: 8,
		borderRadius: 8,
	},
	container: {
		flex: 1,
		marginHorizontal: -8,
	},
	cardItem: {
		height: '180px',
		maxHeight: '180px',
		// REDUCES CARD PADDING PREVENTS EXTRACURRICULAR FROM WRAPPING
		paddingHorizontal: 8,
		alignItems: 'center',
		justifyContent: 'center',
	},
	columnWrapperStyle: { flex: 1, justifyContent: 'space-evenly' },
	flatlistContainer: {
		marginHorizontal: 0,
		backgroundColor: 'transparent',
	},
	headingContainer: {
		marginHorizontal: 8,
		marginBottom: 32,
	},
	marginHorizontal: { marginHorizontal: 32 },
	selectedStyles: { borderWidth: 1, borderColor: '#3259f1' },
	loaderContainer: { flex: 1, justifyContent: 'center', alignItems: 'center' },
	iconContainer: {
		backgroundColor: Colors.actionGreen,
		borderRadius: 50,
		padding: 2,
		position: 'absolute',
		top: 14,
		right: 14,
	},
});
