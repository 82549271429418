import React from 'react';
import Select from 'react-select';

import { useScholarship } from '../../contexts';
import { SearchDispatchTypes, SortBy } from '../../reducers/SearchReducers';

import { Colors } from '@scholly/scholly-ui';

import styles from './filter.scss';

const getDefaultSortOptionFromType = (sortType) => {
	let matchedOption = null;
	SortBy.forEach((option) => {
		if (sortType.includes(option.value)) {
			matchedOption = option;
		}
	});

	return matchedOption;
};

const SortFilterOptions = ({ onSubmit = null }) => {
	const { filterState, filterDispatch } = useScholarship();

	const onSortChange = (selectedItem, index) => {
		filterDispatch({ type: SearchDispatchTypes.SET_SORT, payload: { sort_by: selectedItem.value } });
		onSubmit && onSubmit();
	};

	return (
		<div className={styles.selectContainer}>
			<Select
				testID={`scholarship_feed_sort`}
				value={getDefaultSortOptionFromType(filterState.sort_by)}
				options={SortBy}
				styles={sortStyles}
				onChange={onSortChange}
				isSearchable={false}
			/>
		</div>
	);
};

export default SortFilterOptions;

const dot = (color = 'transparent') => ({
	alignItems: 'center',
	display: 'flex',
	color: '#3259f1',

	':before': {
		backgroundColor: color,
		borderRadius: 10,
		content: '"Sort By: "',
		display: 'block',
		marginRight: 8,
		color: Colors.black,
	},
});

const sortStyles = {
	singleValue: (styles) => ({ ...styles, ...dot() }),
	control: () => ({
		backgroundColor: Colors.white,
		display: 'flex',
		color: '#3259f1',
		borderRadius: 6,
		fontSize: 14,
		border: 0,
		padding: '5px 0',
		boxShadow: '0px 4px 11px rgb(0 0 0 / 8%)',
		borderColor: Colors.white,
		cursor: 'pointer',
	}),
	container: (styles, { isFocused, hasValue }) => {
		return {
			...styles,
			border: isFocused
				? `2px solid #3259F1
			`
				: hasValue
				? '1px solid #a0a3bd'
				: 0,
			borderRadius: 6,
			boxShadow: ' 0px 4px 11px rgba(0, 0, 0, 0.08)',
		};
	},
	indicatorSeparator: (styles) => {
		return {
			...styles,
			display: 'none',
		};
	},
	dropdownIndicator: (styles, { isFocused, isSelected }) => {
		return {
			...styles,
			transform: isFocused || isSelected ? 'rotate(180deg)' : null,
			transition: 'all .2s ease',
			color: '#000',
		};
	},
	option: (styles, { isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: isDisabled ? null : isSelected ? Colors.white : isFocused ? Colors.whiteTwo : null,
			color: isDisabled ? '#ccc' : isSelected ? '#000' : Colors.greyishBrown,
			cursor: isDisabled ? 'not-allowed' : 'default',
			fontSize: 14,
			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled && isSelected && Colors.greyWithOpacity,
			},
			':hover': {
				background: '#3259F1',
				borderRadius: '8px',
				color: '#fff',
			},
		};
	},
};
