import * as React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import Overlay from '../Overlay';

import style from '../common.scss';

interface IProps {
	position: 'top' | 'bottom';
	parentSelector: () => any;
	children: React.ReactNode | string;
	isOpen: boolean;
	onClose: () => void;
}

const PopoverMenu: React.FC<IProps> = (props) => {
	return (
		<Modal
			isOpen={props.isOpen}
			onRequestClose={props.onClose}
			overlayClassName={classNames(style.overlay, style[props.position])}
			shouldCloseOnOverlayClick={true}
			className={style.popoverContent}
			contentLabel="Scholly Modal"
			parentSelector={props.parentSelector}
		>
			<Overlay isOpen={props.isOpen} onClose={props.onClose} />
			<div
				className={classNames(style.arrow, {
					[style.top]: props.position === 'top',
				})}
			/>
			{props.children}
		</Modal>
	);
};

export default PopoverMenu;
