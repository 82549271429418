import * as React from 'react';
import Select from 'react-select';

import { ParameterValueType } from '@scholly/scholly-types';

import { ScreenStrings } from '../../constants';

import customStyles from './lib/CustomStyles';

import styles from './profile.scss';
import { getParameterValueToSelect } from '../../helpers/Functions';
import { UserFormParamsType } from '../../types';

type OUserBasicType = Pick<UserFormParamsType, 'gender'>;

type IProps = OUserBasicType & {
	onChangeText?: (property: keyof OUserBasicType) => (value: string) => void;
	parameter_values: ParameterValueType[];
	styles?: object;
	hasError?: boolean;
	notBottom?: boolean;
	onChangeTextOnboarding?: (value: string) => void;
	noLabel?: boolean;
};

export default class ProfileGender extends React.Component<IProps> {
	static defaultProps = {
		styles: {},
	};
	_onChangeVal =
		(property: 'gender') =>
		({ value }) => {
			this.props.onChangeText(property)(value);
		};

	render() {
		const sexuality = this.props.parameter_values;
		return (
			<div className={styles.profileWrapper}>
				<h3>{this.props.noLabel ? '' : ScreenStrings.profile.identify}</h3>
				<div className={styles.profileContainer}>
					<Select
						//@ts-ignore
						testID={`form_input_gender`}
						hasError={this.props.hasError}
						placeholder={ScreenStrings.profile.sexuality}
						styles={customStyles(this.props)}
						value={getParameterValueToSelect(this.props.gender, sexuality)}
						onChange={
							this.props.onChangeTextOnboarding
								? this.props.onChangeTextOnboarding
								: this._onChangeVal('gender')
						}
						options={sexuality}
						getOptionLabel={(options) => options.value}
						getOptionValue={(options) => options.id}
					/>
				</div>
			</div>
		);
	}
}
