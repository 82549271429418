import * as React from 'react';
import { WebLoader } from '../scholarship/AnimatedListLoader';

import styles from './scholarship-list.scss';
import { useScholarship } from '../../contexts';
type IProps = {
	data: any[];
	EmptyListComponent: JSX.Element;
	renderItem: (item: any, key: any) => JSX.Element;
	onScroll?: () => void;
	selectedScholarshipId?: number;
	showExclusive?: boolean;
};

type ListRef = {};

const ScholarshipList = React.forwardRef<ListRef, IProps>((props, ref) => {
	const { data, showExclusive } = props;
	const { showLoader, paginationLoading } = useScholarship();
	if (showLoader || paginationLoading) {
		return (
			<div className={styles.feedContainer}>
				<WebLoader showExclusive={showExclusive} />
			</div>
		);
	}

	if (data.length == 0) return props.EmptyListComponent;

	return (
		<div className={styles.scholarshipListContainer}>
			{Array.isArray(data) &&
				data.map((scholarship, index) => (
					<React.Fragment key={`${scholarship.id}-${index}`}>
						{props.renderItem(scholarship, index)}
					</React.Fragment>
				))}
		</div>
	);
});

export default ScholarshipList;
