import React, { ReactNode } from 'react';
import styles from './carousel-item.scss';

export type CarouselItemProps = {
	children: ReactNode;
};
const CarouselItem = (props: CarouselItemProps): JSX.Element => {
	const { children } = props;
	return <div className={styles.carouselItemContainer}>{children}</div>;
};
export default CarouselItem;
