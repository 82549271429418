import * as React from 'react';
import classNames from 'classnames';

interface IButtonProps {
	variant?: 'solid' | 'outlined' | 'link';
	onClick?: (event: React.MouseEvent) => void;
	href?: string;
	label: string;
	disabled?: boolean;
	className?: string;
	style?: object;
	compact?: boolean;
	fullWidth?: boolean;
	color?: 'primary' | 'secondary' | 'tertiary';
	loading?: boolean;
	type?: 'button' | 'submit' | 'reset';
	testID?: string;
}

const Button: React.FC<IButtonProps> = (props: IButtonProps) => {
	const variantClassNames = {
		solid: 'solid',
		outlined: 'outlined',
		link: 'link',
	};

	const color = props.color || 'primary';
	const colorsClassNames = {
		primary: null,
		secondary: 'secondary',
		tertiary: 'tertiary',
	};

	if (props.href) {
		return (
			<a
				data-test-id={props.testID}
				href={props.disabled ? '' : props.href}
				className={classNames(
					'self',
					variantClassNames[props.variant],
					colorsClassNames[color],
					{
						disabled: props.disabled,
						compact: props.compact,
						'w-100': props.fullWidth,
					},
					props.className
				)}
				style={props.style}
			>
				<div className={`p`}>{props.label}</div>
			</a>
		);
	}
	return (
		<button
			data-test-id={props.testID}
			onClick={props.onClick}
			className={classNames(
				'self',
				variantClassNames[props.variant],
				colorsClassNames[color],
				{
					disabled: props.disabled,
					compact: props.compact,
					'w-100': props.fullWidth,
				},
				props.className
			)}
			style={props.style}
			disabled={props.disabled}
			type={props.type || 'button'}
		>
			<div className={`p`}>{props.label}</div>
		</button>
	);
};

Button.defaultProps = {
	variant: 'solid',
};

export default Button;
