export enum Status {
	IDLE = 'idle',
	PENDING = 'pending',
	RESOLVED = 'resolved',
	REJECTED = 'rejected',
}

export enum Progress {
	saved = 0,
	applied = 2,
	won = 3,
	not_awarded = 4,
}

export enum HTTPStatus {
	OK = 'OK',
	FAIL = 'FAIL',
	NOTFOUND = 'NOT-FOUND',
	NOTOKEN = 'NO-TOKEN',
}
