import React from 'react';
import classNames from 'classnames';
import styles from './checkmark.scss';
import { BulletCheck } from '@scholly/elements';

interface ICheckmarkProps {
	text?: string;
	textStyle?: string;
}

const Checkmark: React.FC<ICheckmarkProps> = ({ text, textStyle }) => {
	return (
		<div className={styles.checkmarkWrapper}>
			<BulletCheck clickHandler={() => {}} />
			<div className={classNames(styles.defaultText, textStyle)}>{text}</div>
		</div>
	);
};

export default Checkmark;
