import * as React from 'react';

import Select from 'react-select';
import styles from './onboarding.scss';
import customStyles from '../profile/lib/CustomStyles';
import { AnswerModel, Opt } from '../../types';

type Props = {
	options: Array<Opt>;
	onSelect: (opt: Array<Opt>) => void;
	onDeselect: (opt: string) => void;
	title: string;
	placeholder: string;
};

const MultiSelect: React.FC<Props> = (props) => {
	const { options, onSelect, title, placeholder } = props;

	return (
		<div className={styles.onboardingFormWrapper}>
			<h2>{title}</h2>
			<div className={styles.formWrapper}>
				<Select
					styles={customStyles(props)}
					options={options}
					placeholder={placeholder}
					onChange={onSelect}
					getOptionLabel={(options) => options.title}
					getOptionValue={(options) => options.value}
					isScroll={true}
					isMulti={true}
				/>
			</div>
		</div>
	);
};

export default MultiSelect;

type TProps = {
	options: Array<AnswerModel>;
	onSelect: (opt: string) => void;
	onDeselect: (opt: string) => void;
	title: string;
	placeholder: string;
};
export const TextSearchComp: React.FC<TProps> = (props) => {
	const { options, onSelect, title, placeholder } = props;

	return (
		<div className={styles.onboardingFormWrapper}>
			<h2>{title}</h2>
			<div className={styles.formWrapper}>
				<Select
					styles={customStyles(props)}
					options={options}
					placeholder={placeholder}
					onChange={onSelect}
					getOptionLabel={(options) => options.value}
					getOptionValue={(options) => options.id}
					isScroll={true}
					isSearchable={true}
				/>
			</div>
		</div>
	);
};
