import * as React from 'react';

import { ParameterValueType } from '@scholly/scholly-types';

import MultiSelect from './lib/MultiSelect';
import { ScreenStrings } from '../../constants';
import customStyles from './lib/CustomStyles';
import styles from './profile.scss';
import { getParameterValueToSelect } from '../../helpers/Functions';
import { UserFormParamsType } from '../../types';

type OUserMajorType = Pick<UserFormParamsType, 'major'>;
type IProps = OUserMajorType & {
	onChangeText?: (property: keyof OUserMajorType) => (value: string) => void;
	parameter_values: ParameterValueType[];
	styles?: object;
	hasError?: boolean;
	noLabel?: boolean;
	major: any;
	additionalStyles?: object;
	notBottom?: boolean;
	showError?: boolean;
};

const ProfileMajor = (props: IProps) => {
	const [error, setError] = React.useState<boolean>(false);
	const major = props.parameter_values;

	React.useEffect(() => {
		if (props.major && !props.major.length && props.showError) {
			setError(true);
		} else {
			setError(false);
		}
	}, [props.major, props.showError]);

	return (
		<div className={styles.profileWrapper}>
			<h3>{props.noLabel ? '' : ScreenStrings.profile.studying}</h3>
			<div className={styles.profileContainer}>
				<MultiSelect
					testID={`form_input_major`}
					hasError={error}
					styles={customStyles(props)}
					//@ts-ignore
					value={getParameterValueToSelect(props.major, major)}
					placeholder={ScreenStrings.profile.major}
					options={major}
					onChangeVal={props.onChangeText('major')}
					bottom={!!!props.notBottom}
					searchable
					additionalStyles={props.additionalStyles && props.additionalStyles}
				/>
			</div>
		</div>
	);
};

export default ProfileMajor;
