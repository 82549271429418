import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Button } from '@scholly/elements';

import { FlashContext } from '../../../../contexts';

import { PayoffIcon, SchollyCashIcon } from '../../../../components/common/icons';
import { UserBadgeIcon } from '../../../../components/common/icons/user-badge';
import { Card } from '../../../../components/card/Card';

import { Routes } from '../../../../constants';
import config from '../../../../config/config';

import styles from '../offboarding.scss';

const ThankYou = () => {
	const history = useHistory();
	const flashContext = React.useContext(FlashContext);
	React.useEffect(() => {
		flashContext.display({ type: 'success', text: 'Cancellation complete' });
		// eslint-disable-next-line
	}, []);

	const openPage = (value: string) => () => {
		const regex = '^https?://';
		if (!value.match(regex)) history.push(value);
		else window.open(value, '_blank');
	};
	return (
		<div className={styles.pauseCardContainer}>
			<div className={classNames(styles.isFlex, styles.justifyContentCenter)}>
				<UserBadgeIcon />
			</div>
			<div className={'has-text-centered'}>
				<div className={classNames('mt-7')}>
					<div className={classNames(styles.pauseText, styles.thankTitle)}>
						Thank you for helping us {'\n'}improve Scholly!
					</div>
				</div>
			</div>
			<div className={classNames(styles.isFlex, 'mt-5')}>
				<div className={classNames(styles.pauseText, styles.thankDescription)}>
					Your comments and suggestions help us make Scholly a better app for over 100,000 users.
				</div>
			</div>
			<div className={classNames(styles.isFlex, 'mt-5')}>
				<div className={classNames(styles.pauseText, styles.thankDescription)}>
					Explore Scholly Offers and Payoff to help with or student loans and financial goals.
				</div>
			</div>

			<div className={classNames(styles.isFlex, styles.isFlexRow, styles.justifyContentBetween, 'mt-5')}>
				<Card onClick={openPage(config.payoff_url)} className={'mr-5'}>
					<PayoffIcon />
				</Card>
				<Card onClick={openPage(config.offers_url)}>
					<SchollyCashIcon />
				</Card>
			</div>
			<div className={'mt-5'}>
				<Button
					variant="secondary"
					onClick={openPage(Routes.scholarships.feed)}
					className={styles.pauseButton}
					style={{ marginBottom: 20 }}
				>
					Back to Main Screen
				</Button>
			</div>
		</div>
	);
};
export default ThankYou;
