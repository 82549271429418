import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';

import { useParameterState, useProfileState } from '../../contexts';
import { Opt, QuestionModel, QuestionTypes } from '../../types';
import Selector from './Selector';
import Slider from './Slider';
import { MultiStepRef } from '../common/MultiStep/MultiStep';
import TextSearch from './TextSearch';
import QuerySearch from './QuerySearch';
import Dropdown from './Dropdown';
import MultiSelect from './MultiSelect';
import styles from './onboarding.scss';

type Props = {
	question: QuestionModel;
	multiRef?: React.MutableRefObject<MultiStepRef>;
	questionSet?: any;
};

export const FieldContainer = (props: any) => <div className={styles.fieldContainer}>{props.children}</div>;

const DynamicOnboarding: React.FC<Props> = ({ question, multiRef, questionSet }) => {
	const memoQuestion: QuestionModel = React.useMemo(() => question, [question]);
	const { parameterStringId } = useParameterState();
	//@ts-ignore
	const { changeState, data } = useProfileState();
	const [selection, setSelection] = useState('');
	const [multiSelection, setMultiSelection] = useState([]);
	const string_id = memoQuestion ? parameterStringId.get(memoQuestion?.parameter_id.toString()) : '';

	function convertAnswerStructure(array) {
		return array.map((ele) => {
			let x = {
				value: ele.id,
				title: ele.value,
				selected: isMultiSelect() ? multiSelection.includes(ele.id) : ele.id === data[string_id],
			};
			return x;
		});
	}

	function isMultiSelect() {
		return (
			memoQuestion?.question_type === QuestionTypes.MultiSelectSearch ||
			memoQuestion?.question_type === QuestionTypes.MultiSelect
		);
	}

	function deselect(value: string) {
		const filtered = multiSelection.filter((e) => e !== value);
		setMultiSelection(filtered);
	}

	function multiSelectFormat(value: Opt[]) {
		if (!value) {
			setMultiSelection([]);
		} else {
			const filtered = value.map((e) => e.value);
			setMultiSelection(filtered);
		}
	}

	const value = parseFloat(
		memoQuestion?.answers.find((answer) => answer.id.toString() === data[memoQuestion?.string_id])?.value
	);
	let step = 0.1;
	const max = memoQuestion?.answers.reduce(function (a, b) {
		if (Number(b.value)) {
			return Math.max(a, Number(b.value));
		}
		return a;
	}, -Infinity);

	const min = memoQuestion?.answers.reduce(function (a, b) {
		if (Number(b.value)) {
			return Math.min(a, Number(b.value));
		}
		return a;
	}, Infinity);

	const handleSelect = (value) => {
		const answer = memoQuestion?.answers.find((answer) => parseFloat(answer.value) === parseFloat(value));
		setSelection(answer.id.toString());
	};

	React.useEffect(() => {
		//multiRef.current?.setNextDisable(multiSelection.length === 0);
		changeState({ [string_id]: multiSelection });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [multiSelection, question]);

	React.useEffect(() => {
		setTimeout(() => {
			let next = false;
			questionSet?.forEach((i, v, k) => {
				if (!data[i.string_id] || data[i.string_id]?.length === 0) {
					next = true;
				}
			});
			// multiRef.current?.setNextDisable(!data[string_id]);
			multiRef.current?.setNextDisable(next);
		}, 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	React.useEffect(() => {
		if (selection !== '') {
			changeState({ [string_id]: selection });
			setSelection('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selection, question]);

	return (
		<React.Fragment>
			{[QuestionTypes.SingleSelect].includes(memoQuestion?.question_type) && (
				<Selector
					options={convertAnswerStructure(memoQuestion?.answers)}
					onSelect={(value: string) => {
						setSelection(value);
					}}
					onDeselect={() => setSelection('')}
					single={true}
					title={memoQuestion?.question}
				/>
			)}
			{[QuestionTypes.MultiSelect].includes(memoQuestion?.question_type) && (
				<Selector
					options={convertAnswerStructure(memoQuestion?.answers)}
					onSelect={(value) => setMultiSelection([...multiSelection, value])}
					onDeselect={(value) => deselect(value)}
					single={false}
					title={memoQuestion?.question}
				/>
			)}

			{[QuestionTypes.SingleSelectDropdown].includes(memoQuestion?.question_type) && (
				<Dropdown
					options={convertAnswerStructure(memoQuestion?.answers)}
					onSelect={(value: any) => {
						setSelection(value.value);
					}}
					onDeselect={(value) => deselect(value)}
					title={memoQuestion?.question}
				/>
			)}
			{[QuestionTypes.Slider].includes(memoQuestion?.question_type) && (
				<Slider
					value={value}
					onSlide={handleSelect}
					maximum={max}
					minimum={min}
					step={step}
					title={memoQuestion?.question}
				/>
			)}
			{[QuestionTypes.SingleSelectSearch].includes(memoQuestion?.question_type) && (
				<TextSearch
					options={memoQuestion?.answers}
					onSelect={(value: any) => setSelection(value.id)}
					onDeselect={(value) => deselect(value)}
					title={memoQuestion?.question}
					placeholder={memoQuestion?.placeholder}
				/>
			)}
			{[QuestionTypes.MultiSelectSearch].includes(memoQuestion?.question_type) && (
				<MultiSelect
					options={convertAnswerStructure(memoQuestion?.answers)}
					onSelect={multiSelectFormat}
					onDeselect={(value) => deselect(value)}
					title={memoQuestion?.question}
					placeholder={memoQuestion?.placeholder || 'Filter'}
				/>
			)}
			{[QuestionTypes.SchoolSelectSearch].includes(memoQuestion?.question_type) && (
				<QuerySearch
					onSelect={(value) => setSelection(value)}
					onDeselect={() => setSelection('')}
					selected={data[memoQuestion?.string_id]}
					title={memoQuestion?.question}
					placeholder={memoQuestion?.placeholder}
					single={true}
					type="school"
				/>
			)}
			{[QuestionTypes.LocationSelectSearch].includes(memoQuestion?.question_type) && (
				<QuerySearch
					onSelect={(value) => setSelection(value)}
					onDeselect={() => setSelection('')}
					selected={data[memoQuestion?.string_id]}
					title={memoQuestion?.question}
					placeholder={memoQuestion?.placeholder}
					single={true}
					//@ts-ignore
					type={memoQuestion?.string_id}
				/>
			)}
		</React.Fragment>
	);
};

export default DynamicOnboarding;

export const QuestionLoader: React.FC = () => {
	const rx = 5;
	const ry = 5;
	const x = 0;
	return (
		<ContentLoader height={400} width={500} backgroundColor={'#d6d6d6'}>
			<rect x={x} y="0" rx={rx} ry={ry} width="60%" height="50" />
			<rect x={x} y="73" rx={rx} ry={ry} width="100%" height="123" />
		</ContentLoader>
	);
};
