import React from 'react';
import Tab from './tab/tab';

import styles from './tabs.scss';

interface IProps {
	children: React.ReactNode;
}

const Tabs: React.FC<IProps> = ({ children }) => {
	return (
		<div className={styles.container} role="tablist">
			{children}
		</div>
	);
};

export { Tab };
export default Tabs;
