import React from 'react';
import classNames from 'classnames';
import styles from './button.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	isPrimary?: boolean;
	isSecondary?: boolean;
	isDisabled?: boolean;
	isCompleted?: boolean;
	isOutlinedActive?: boolean;
	label: string;
	isOutlined?: boolean;
	isLoading?: boolean;
	showCheckmark?: boolean;
	className?: string;
}

const Button: React.FC<Props> = (props) => {
	const {
		label,
		isPrimary,
		isLoading,
		isOutlined,
		isCompleted,
		isOutlinedActive,
		isDisabled,
		showCheckmark,
		className,
		...rest
	} = props;

	const variation = () => {
		let buttonclassName = '';

		if (isPrimary) {
			buttonclassName = styles.isPrimary;
		}

		if (isOutlined) {
			buttonclassName = styles.isOutlined;
		}

		if (isOutlinedActive) {
			buttonclassName = styles.isOutlinedActive;
		}

		if (isCompleted) {
			buttonclassName = styles.isCompleted;
		}

		return buttonclassName;
	};

	return (
		<button {...rest} className={classNames(styles.button, variation(), className)} disabled={isDisabled}>
			<span className={styles.innerButton}>{label}</span>
		</button>
	);
};

export default Button;
