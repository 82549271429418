import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Landing from './Landing';
import Header from '../../components/ungated/Header';
import UngatedFooter from '../../components/layouts/UngatedFooter';
import BackToTopButton from '../../components/ungated/back-to-top';

import { PublicProvider } from '../../contexts/PublicContext';

import styles from './unauthentication.scss';

const Unauthenticated: React.FC<RouteComponentProps> = () => {
	return (
		<PublicProvider>
			<Header />
			<div className={styles.mainContainer}>
				<Landing />
			</div>
			<BackToTopButton />
			<UngatedFooter />
		</PublicProvider>
	);
};

export default Unauthenticated;
