import classNames from 'classnames';
import React, { FC } from 'react';
import style from './radio-checkbox.scss';

interface RadioCheckboxProps {
	value: number | string;
	checked: boolean;
	onChange?: () => void;
	className?: string;
	id?: string;
	disabled?: boolean;
}

const RadioCheckbox: FC<RadioCheckboxProps> = (props) => {
	const { value, checked, onChange, className, id, disabled } = props;
	return (
		<label className={classNames(style.control, style.controlCheckbox, className)} htmlFor={id}>
			<input id={id} type="checkbox" value={value} disabled={disabled} checked={checked} onChange={onChange} />
			<div className={style.controlIndicator} />
		</label>
	);
};
export default RadioCheckbox;
