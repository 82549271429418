import React from 'react';
import classNames from 'classnames';
import { Button, ErrorMessage, Input, Mobile26px } from '@scholly/elements';

import { FlashContext, UserContext } from '../../contexts';

import { validatePhone } from '../../helpers/Validation';

import styles from './settings.scss';

const ChangePhone: React.FC = () => {
	const userContext = React.useContext(UserContext);
	const flashContext = React.useContext(FlashContext);
	const [newPhone, setPhone] = React.useState('');
	const [isError, setIsError] = React.useState<boolean>(false);
	const [isProcessing, setProcessing] = React.useState(false);

	const handlePhone = (value) => {
		setPhone(value);
		setIsError(false);
	};
	const updatePhone = async () => {
		if (userContext.phone_number == newPhone) {
			flashContext.display({
				text: 'Phone number is the same to your old Phone number.',
				type: 'error',
				disableScrollTop: true,
			});
		} else {
			setProcessing(true);
			try {
				await userContext.updateUserPhoneNumber({ phone_number: newPhone });
				flashContext.display({
					text: 'Phone number changed successful.',
					type: 'success',
				});
			} catch (error) {
				flashContext.display({ text: error[0].message, type: 'error', disableScrollTop: true });
				if (error[0].message === 'Domain not allowed') {
					setIsError(true);
				}
			}
			setPhone('');
			setProcessing(false);
		}
	};

	const isDisabled = () => !validatePhone(newPhone) || isProcessing;

	return (
		<div className="px-2">
			<div className={styles.title}>Phone Number</div>

			<div className={classNames(styles.inputHeightAlign, 'pb-1')}>
				{isError && (
					<ErrorMessage className={styles.emailError}>
						We were unable to update your phone number. Please contact us at{' '}
						<div>
							<a href={'mailto:info@myscholly.com'} target="_blank" rel="noopener noreferrer">
								info@myscholly.com
							</a>{' '}
							for assistance.
						</div>
					</ErrorMessage>
				)}
				<Input
					variant="tel"
					placeholder={userContext.phone_number}
					name="phone_number"
					className={classNames(styles.w100, 'py-0 mb-4')}
					onChange={(val) => handlePhone(val)}
					Icon={<Mobile26px />}
					iconWrapperStyle={{ left: 6, top: 9 }}
					showValidation={false}
					staticPlaceholder={true}
				/>
			</div>

			<Button
				className="px-0"
				variant="primary"
				onClick={updatePhone}
				disabled={isDisabled()}
				style={{ width: '100%' }}
			>
				Update Info
			</Button>
		</div>
	);
};

export default ChangePhone;
