import React from 'react';
import Selector from '../../components/common/Selector';
import styles from './onboarding.scss';

type Props = {
	options: Array<Opt>;
	onSelect: (opt: string) => void;
	onDeselect: (opt: string) => void;
	single: boolean;
	title: string;
};

type Opt = {
	value: string;
	title: string;
	selected: boolean;
};

const Selector2: React.FC<Props> = (props) => {
	const { options, onSelect, onDeselect, title, single } = props;
	return (
		<div className={styles.onboardingFormWrapper}>
			<h2>{title}</h2>
			<div className={styles.formWrapper}>
				<Selector single={single} deselect={onDeselect} select={onSelect} options={options} />
			</div>
		</div>
	);
};

export default Selector2;
