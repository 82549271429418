import * as React from 'react';

import {
	ProfileDegree,
	ProfileEnrollmentStatus,
	ProfileGPA,
	ProfileGradeLevel,
	ProfileLocation,
	ProfileMajor,
	ProfileSchool,
} from '../../../components/profile';

import { ParameterContext, useProfileState } from '../../../contexts';
import { Routes } from '../../../constants';
import { check } from '../../../helpers/Functions';
import isEmpty from 'lodash/isEmpty';
import { UserFormParamsType } from '../../../types';

type EducationFormType = Pick<UserFormParamsType, 'grade' | 'gpa' | 'major' | 'degree' | 'enrollment_status'> & {
	school: SValueType | Array<SValueType>;
	location: string;
};

type SValueType = {
	id: string;
	name: string;
};

interface IEducationFormProps {
	webStyle?: object;
	onChangeText: (property: keyof EducationFormType) => (value: string) => void;
	onChangeTextMultiple?: (property: keyof EducationFormType) => (value: string) => void;
	missing: any[];
	path?: string;
	noMarginTop?: boolean;
	notSplitForm?: boolean;
	showError?: boolean;
}

const EducationForm: React.FC<IEducationFormProps> = ({
	webStyle,
	onChangeText,
	missing,
	path,
	notSplitForm,
	onChangeTextMultiple,
	showError,
}) => {
	const { values } = React.useContext(ParameterContext);
	const { data } = useProfileState();
	//@ts-ignore
	const hasError = (val: string, input: any) => {
		return (
			missing.includes(val) && (!check(input) || isEmpty(input) || input == 'N/A') && path !== Routes.user.create
		);
	};
	return (
		<div>
			<ProfileLocation
				hasError={hasError('location', data.location)}
				webStyle={webStyle}
				location={data.location == 'N/A' ? null : data.location}
				onChangeText={onChangeText('location')}
			/>
			<ProfileSchool
				hasError={hasError('school', data.school)}
				school={data.school}
				onChangeText={onChangeText('school')}
			/>
			<ProfileGradeLevel
				hasError={hasError('grade', data.grade)}
				styles={webStyle}
				grade={data.grade}
				parameter_values={values.grade}
				onChangeText={onChangeTextMultiple}
			/>
			<ProfileDegree
				hasError={hasError('degree', data.degree)}
				styles={webStyle}
				degree={data.degree}
				parameter_values={values.degree}
				onChangeText={onChangeText}
			/>
			<ProfileEnrollmentStatus
				hasError={hasError('enrollment_status', data.enrollment_status)}
				styles={webStyle}
				enrollment_status={data.enrollment_status}
				parameter_values={values.enrollment_status}
				onChangeText={onChangeText}
			/>
			<ProfileMajor
				hasError={hasError('major', data.major)}
				styles={webStyle}
				major={data.major}
				parameter_values={values.major}
				onChangeText={onChangeTextMultiple}
				notBottom={notSplitForm}
				showError={showError}
			/>
			<ProfileGPA
				hasError={hasError('gpa', data.gpa)}
				styles={webStyle}
				gpa={data.gpa}
				parameter_values={values.gpa}
				onChangeText={onChangeText}
				notBottom={notSplitForm}
			/>
		</div>
	);
};

export default EducationForm;
