import React from 'react';

import styles from './offer-modal.scss';
import { CustomModal } from '../../modal/Modal';
import screenStrings from '../../../constants/ScreenStrings';
import ButtonStrings from '../../../constants/ButtonStrings';
import { WhiteOffers } from '../../common/icons';
import CloseButton from '../../modal/close-button/close-button';
import { UserContext } from '../../../contexts';
import config from '../../../config/config';
import { storeClickDate } from '../../../helpers/Functions';
import { STORAGE_KEYS } from '../../../constants/StorageKeys';

interface Props {
	onChange: (value: boolean) => void;
	isShow: boolean;
}
export const OfferModal = ({ onChange, isShow }: Props) => {
	const user = React.useContext(UserContext);
	const handleClose = () => {
		onChange(false);
		storeClickDate(STORAGE_KEYS.OFFER_PROMO);
	};

	const handleCTAPress = () => {
		const windowReference = window.open();
		windowReference.location.href = `${config.offers_url}?m=login&email=${user?.user_email}`;
		handleClose();
	};
	return (
		<div className={styles.container}>
			<CustomModal visible={isShow} overlayStyle={styles.overlay}>
				<div className={styles.pressable}>
					<div className={styles.mainContainer}>
						<div className={styles.modalCloseIcon}>
							<CloseButton onClick={handleClose} />
						</div>
						<div className={styles.innerContainer}>
							<div className={styles.text}>
								<WhiteOffers />
							</div>
							<div className={styles.title}>{screenStrings.promo.offerText}</div>
							<div className={styles.description}>{screenStrings.promo.offerDescription}</div>
							<div className={styles.cta} onClick={handleCTAPress}>
								<div className={styles.titleButton}>{ButtonStrings.getStarted}</div>
							</div>
						</div>
						<div className={styles.coinsImage} />
					</div>
				</div>
			</CustomModal>
		</div>
	);
};
