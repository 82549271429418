import * as React from 'react';

import styles from './loader.scss';

const Loader = () => {
	return (
		<div className={styles.ldsRoller}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};

export default Loader;
