import React, { useContext, useState } from 'react';
import { differenceWith, isEqual } from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { SchollyResponse } from '@scholly/core';

import {
	FreeProps,
	ProfileProvider,
	UserContext,
	checkFreeHOC,
	useParameterState,
	useQuestions,
	SessionContext,
} from '../../contexts';
import { getLocalUser, updateLocation, updateParameterValues, updateSchool, updateUser } from '../../actions';

import { LIFETIME_CODE, Status, HTTPStatus, Routes, ScreenStrings } from '../../constants';
import { QuestionModel } from '../../types';

import MultiStep, { MultiStepRef, Step } from '../../components/common/MultiStep/MultiStep';
import BasicInfo from '../../components/onboarding/BasicInfo';
import DynamicOnboarding, { FieldContainer, QuestionLoader } from '../../components/onboarding/DynamicOboarding';
import OfferCompletionModal from '../../components/offer-completion-modal/offer-completion-modal';
import TotalMatch from '../../components/total-match/total-match';
import { SubHeader } from '../../components/layouts/SubHeader';

import { onboardingDerivedStateFromLocal, sleep, pickedUserInfo, basicRequired } from '../../helpers/Functions';
import { isMobileDevice } from '../../helpers/checkIfDesktop';

import styles from './onboarding.scss';

const Onboarding: React.FC<FreeProps> = ({ isFree }) => {
	const multiRef = React.useRef<MultiStepRef>(null);
	const { parametersLoadedStatus, parameterStringId, values } = useParameterState();
	const { questions, required, revalidate, section_title } = useQuestions();
	const { getAndSetUser } = useContext(UserContext);
	const [allQuestions, setAllQuestions] = useState(new Map());
	const [currentQuestions, setCurrentQuestions] = useState(questions || []);
	const [displayOfferCompletionModal, setDisplayOfferCompletionModal] = useState(false);
	const [keys, setKeys] = React.useState<any>([]);
	const history = useHistory();
	const { updateSessionUser } = React.useContext(SessionContext);

	const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);
	const [title, setTitle] = React.useState(ScreenStrings.onboarding.basic);
	const [initialValues, setinitialValues] = React.useState({
		first_name: '',
		last_name: '',
		birthday: '',
		phone_number: '',
		sms_opt_in: false,
		profileStatus: Status.IDLE,
		hasError: [],
	});

	// const string_id = currentQuestion ? parameterStringId.get(currentQuestion.parameter_id.toString()) : '';

	//@ts-ignore
	const handleBasicSubmit = async (values, step, action) => {
		multiRef?.current?.setNextDisable(true);
		if (parametersLoadedStatus === Status.PENDING) {
			multiRef?.current?.setNextDisable(false);
			return;
		}

		const { first_name, last_name, phone_number, birthday, sms_opt_in } = values;
		const localUser = await getLocalUser();

		localStorage.setItem('opt_in', values?.sms_opt_in);

		const currentUser = pickedUserInfo(localUser, [...basicRequired, 'phone_number']);

		let delay = 0;

		const [diffUserValues] = differenceWith(
			[
				{
					first_name,
					last_name,
					phone_number,
					birthday,
					sms_opt_in,
				},
			],
			[currentUser],
			isEqual
		);

		try {
			await sleep(delay);

			const res = await updateUser(diffUserValues);
			updateSessionUser(res?.data?.user);

			if (res.status === HTTPStatus.OK) {
				action.updateStatus(Status.RESOLVED);
				end(action);
			}
			if (res.status === HTTPStatus.FAIL) {
				throw new Error(res.error);
			}
			return true;
		} catch (error) {
			if (error.message) {
				multiRef.current.setErrors([error]);
			} else if (Array.isArray(error)) {
				multiRef.current.setErrors(error);
			}
			action.updateStatus(Status.REJECTED);
		} finally {
			multiRef?.current?.setNextDisable(false);
		}
	};

	const handleSubmit = async (values, string_id, action) => {
		let next = false;
		let college, state;
		const data = pickedUserInfo(values, keys);
		if (data.hasOwnProperty('college') && data['college']) {
			college = data['college'];
			delete data['college'];
		}
		if (data.hasOwnProperty('state') && data['state']) {
			state = data['state'];
			delete data['state'];
		}

		sleep(0)
			.then(() => {
				if (college) {
					updateSchool({ school: college?.id }).then((res) => {
						let q = questions.find((q) => q.string_id === 'college');
					});
				}
			})
			.then(() => {
				if (state) {
					updateLocation({ place_id: state?.place_id }).then((res) => {
						let q = questions.find((q) => q.string_id === 'state');
					});
				}
			})
			.then(() => {
				updateParameterValues({ parameter_values: data })
					.then((res) => {
						next = handleThen(res);
					})
					.finally(() => handleFinish(next, action));
			});
		return true;
	};

	const handleThen = (res: SchollyResponse) => {
		if (res.status === HTTPStatus.OK) {
			questions?.forEach((q, idx) => {});
			return true;
		} else if (res.status === HTTPStatus.FAIL) {
			console.log(res.error);
		}
		return false;
	};

	const handleFinish = (next: boolean, step: any) => {
		if (next) {
			step.changeState({ profileStatus: Status.RESOLVED });
			end();
		}
		step.changeState({ profileStatus: Status.REJECTED });
	};

	const end = (step?: any) => {
		if (multiRef.current && multiRef.current.btnLoading) {
			multiRef.current.setBtnLoading(false);
		}
		if (!step && required.completed < 1) {
			revalidate(true);
		}
	};

	const handleBack = () => {
		// @ts-ignore
		if (multiRef.current?.setStepNumber > 2) {
			setCurrentQuestions(allQuestions.get(required.current_section - 1));
		} else {
			multiRef.current?.setStepNumber(multiRef.current?.stepNumber - 1);
		}
	};

	const handleSkip = () => {
		if (isOnboardingComplete) {
			history.push(Routes.dashboard);
		} else {
			setTitle(ScreenStrings.onboarding.complete);
		}
	};

	const hideBackButton = multiRef.current?.stepNumber === 0 || isMobileDevice || isOnboardingComplete;

	React.useEffect(() => {
		if (currentQuestions?.length >= 0) {
			let string_ids = [];
			let current = questions.map((q: QuestionModel) => {
				q.string_id = parameterStringId.get(q.parameter_id.toString());
				string_ids.push(q.string_id);
				return q;
			});
			setKeys(string_ids);
			setCurrentQuestions(current);
			if (!allQuestions.has(required.current_section)) {
				const mapped = new Map(allQuestions);
				mapped.set(required.current_section, current);
				setAllQuestions(mapped);
			}
		}
		return () => {
			setAllQuestions(new Map());
			setKeys([]);
			setCurrentQuestions([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questions, parameterStringId]);

	React.useEffect(() => {
		if (required.completed === 1) {
			(async () => {
				await getAndSetUser();
				// @ts-ignore
				const user = await getLocalUser();
			})();

			setIsOnboardingComplete(true);
			setTitle(ScreenStrings.onboarding.complete);
		}
	}, [required.completed]);

	React.useEffect(() => {
		(async () => {
			let i = await onboardingDerivedStateFromLocal(values);
			if (i.birthday) {
				i.birthday = moment(i.birthday).format('MM/DD/YYYY');
			} else {
				i.birthday = '01/01/1990';
			}
			//@ts-ignore
			setinitialValues({ ...initialValues, ...i });
		})();
		multiRef.current?.displayPagination(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		const fetchUser = async () => {
			try {
				const user = await getLocalUser();

				const registration_code = user?.merchant?.transactions.filter((t) => t.order_type === 'joincode')[0];
				const isOfferLifeTimeMember = registration_code ? registration_code?.code === LIFETIME_CODE : false;

				if (!localStorage.getItem('offerCompletionModal')) {
					if (isOfferLifeTimeMember) {
						setDisplayOfferCompletionModal(true);
						setTimeout(() => {
							localStorage.setItem('offerCompletionModal', 'already_displayed');
						}, 1000);
					}
				}
			} catch (error) {
				console.error('Error fetching user:', error);
			}
		};

		fetchUser();
	}, []);

	React.useEffect(() => {
		if (!isOnboardingComplete && title !== section_title) setTitle(section_title);
	}, [section_title, isOnboardingComplete]);

	return (
		<ProfileProvider>
			<SubHeader
				title={title}
				hideBackButton={hideBackButton}
				onBack={handleBack}
				onSkip={handleSkip}
				className={classNames(
					styles.subHeader,
					isOnboardingComplete && isMobileDevice && styles.subHeaderComplete
				)}
			/>

			<div className={styles.contentWrapper}>
				<MultiStep
					renderIf={!isOnboardingComplete}
					ref={multiRef}
					initialValues={initialValues}
					handleBack={handleBack}
					totalSteps={required.total_sections + 1}
				>
					<Step key="basic_1" name="basic" onSubmit={handleBasicSubmit}>
						<BasicInfo multiRef={multiRef} />
					</Step>
					<OnboardingStep
						key={`onboarding_${required.current_section}`}
						keys={keys}
						questions={currentQuestions}
						info={required}
						onSubmit={handleSubmit}
						multiRef={multiRef}
					/>
				</MultiStep>

				{isOnboardingComplete && <TotalMatch showConfetti />}
			</div>
			<OfferCompletionModal
				isOpen={displayOfferCompletionModal}
				handleClose={() => setDisplayOfferCompletionModal(false)}
			/>
		</ProfileProvider>
	);
};

export default checkFreeHOC(Onboarding);

const OnboardingStep = ({ onSubmit, info, questions, keys, multiRef }) => {
	if (!questions) {
		return <QuestionLoader />;
	}

	return (
		<Step key={`onboarding_${info.current_section}`} keys={keys} name={info.current_section} onSubmit={onSubmit}>
			{questions.map((question: QuestionModel, index: React.Key | null | undefined) => (
				<FieldContainer key={index}>
					<DynamicOnboarding question={question} multiRef={multiRef} questionSet={questions} />
				</FieldContainer>
			))}
		</Step>
	);
};
