import * as React from 'react';
import { Loader, Typography } from '@scholly/scholly-ui';

import { useHistory, useParams } from 'react-router-dom';

import MenuItem from '../../components/common/MenuItem';
import config from '../../config/config';

import { Status, Routes } from '../../constants';
import { BrandingContext, FlashContext, FlashContextType, UserContext, SessionContext } from '../../contexts';
import style from './branding.scss';
import RegisterForm from './RegisterFrom';

const Register: React.FC = () => {
	const year = new Date().getFullYear();
	const [loading, setLoading] = React.useState(false);
	const context = React.useContext(BrandingContext);
	const flashContext: FlashContextType = React.useContext(FlashContext);
	const session = React.useContext(SessionContext);
	const { loadUser } = React.useContext(UserContext);
	const disableHasErrors = { disableHasErrors: true };

	let history = useHistory();
	let { brand } = useParams<{ brand: any }>();

	React.useLayoutEffect(() => {
		if (brand && context.status === Status.IDLE) {
			context.getBrand(brand);
		}
	}, [brand, context]);

	const onRegister = async (data: any) => {
		setLoading(true);
		try {
			const res = await session.startRegisteredSession(data);

			loadUser(res);
			session.setisAuthenticated(Status.RESOLVED);

			history.push(Routes.user.create, disableHasErrors);
		} catch (error) {
			if (error.message) {
				flashContext.display({ type: 'error', text: error.message, shouldShowAsHeader: true });
			} else if (Array.isArray(error)) {
				error.forEach((err) =>
					flashContext.display({ type: 'error', text: err.message, shouldShowAsHeader: true })
				);
			}
			setLoading(false);
		}
	};

	let baseLayoutStyle = {};
	if (context.landing_bg_img) {
		if (!context.landing_bg_img) return;
		baseLayoutStyle = {
			background: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url("${
				!context.landing_bg_img.includes('https://') ? config.cdn : ''
			}${context.landing_bg_img}") #818181 no-repeat center center fixed`,
			backgroundSize: 'cover',
		};
	}
	if (!context.active)
		return (
			<div className={style.loadingLayout}>
				<Loader />
			</div>
		);

	return (
		<div className={style.baseLayout} style={baseLayoutStyle}>
			<div className={style.content}>
				{context.pretext && (
					<div className={style.preText} dangerouslySetInnerHTML={{ __html: context.pretext }} />
				)}

				<img
					src={`${
						context.logo_img &&
						(!context.logo_img.includes('https://') ? config.cdn : '') + context.logo_img
					}`}
					alt={context.display_name}
					title={context.display_name}
					className={style.logo}
				/>

				<RegisterForm
					loading={loading}
					onSignUp={onRegister}
					display={flashContext.display}
					isSubmitted={session.isAuthenticated === Status.RESOLVED}
				/>
			</div>
			<footer className={style.brandingFooter}>
				<div>
					<div className={style.copyright}>
						<p>
							{year} &#169; Copyright <a href={config.homepage}>Scholly, Inc</a>.
						</p>
					</div>

					<div className={style.menu}>
						<ul>
							<li>
								<MenuItem active={false} href={config.contact_url}>
									<Typography variant="h5">Contact Us</Typography>
								</MenuItem>
							</li>
							<li>
								<MenuItem active={false} href={config.terms_url}>
									<Typography variant="h5">Terms of Service</Typography>
								</MenuItem>
							</li>
						</ul>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Register;
