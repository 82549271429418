import React from 'react';
import { Colors, Button, Link } from '@scholly/scholly-ui';
import styles from './common.scss';
import classNames from 'classnames';
import { isMobileDevice } from '../../helpers/checkIfDesktop';
import { FlashContext, UserContext } from '../../contexts';
import moment from 'moment';
import config from '../../config/config';
import { getUserFeaturedCampaign, submitEasyApply } from '../../actions';
import { STORAGE_KEYS } from '../../constants/StorageKeys';

interface InstantApplyProps {
	rightArrowClick?: () => void;
	label: string;
	oldRegister?: boolean;
	image?: string;
	secondLabel?: string;
	secondButtonClick?: () => void;
	onlyIfID?: boolean;
	scholarship?: string;
	bypassIfNothing?: boolean;
	bypassFunction?: () => void;
	delay?: number;
}

const InstantApply = (props: InstantApplyProps) => {
	const flashContext = React.useContext(FlashContext);
	const user = React.useContext(UserContext);
	const [topMargin, setTopMargin] = React.useState('-100vh');
	const [image, setImage] = React.useState(null);
	const [name, setName] = React.useState(null);
	const [backgroundImage, setBackgroundImage] = React.useState(null);
	const [backgroundColor] = React.useState(Colors.red);
	const [termsUrl, setTermsUrl] = React.useState(config.terms_url);
	const [close, setClose] = React.useState(false);
	const [show, setShow] = React.useState(false);
	const timer = React.useRef(null);

	const rightArrowClickHandle = () => {
		const userBirthday = user.birthday ? user.birthday.split('-') : null;
		const userBirthdayFinal = userBirthday ? `${userBirthday[1]}/${userBirthday[2]}/${userBirthday[0]}` : null;
		const userObj = {
			email: user.user_email,
			first_name: user.first_name,
			last_name: user.last_name,
			birthday: userBirthdayFinal,
			state: user.location.split(',')[1],
			grade_level: user.grade,
		};
		localStorage.setItem('instant_apply', moment(new Date()).format('lll'));
		submitEasyApply(userObj, name);
		setClose(true);
		setTimeout(() => {
			flashContext.display({ text: 'You have successfully applied!', type: 'success' });
		}, 1500);
		enableScroll();
		props.rightArrowClick && props.rightArrowClick();
	};

	const secondButtonClickHandle = () => {
		localStorage.setItem('instant_apply', moment(new Date()).format('lll'));
		enableScroll();
		setClose(true);
		props.secondButtonClick();
	};

	const disableScroll = () => {
		document.body.classList.add('stop-scrolling');
	};

	const enableScroll = () => {
		if (document.body.classList.contains('stop-scrolling')) document.body.classList.remove('stop-scrolling');
	};

	const getCampaign = async () => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		let id;
		//Existing users might already have this in their local storage as 'null'
		if (
			!!localStorage.getItem(STORAGE_KEYS.FEATURED_CAMPAIGN) &&
			localStorage.getItem(STORAGE_KEYS.FEATURED_CAMPAIGN) != 'null'
		) {
			id = localStorage.getItem(STORAGE_KEYS.FEATURED_CAMPAIGN);
		} else if (urlParams.get(STORAGE_KEYS.FEATURED_CAMPAIGN_ID)) {
			id = urlParams.get(STORAGE_KEYS.FEATURED_CAMPAIGN_ID);
		} else {
			if (props.onlyIfID) {
				return;
			}
		}
		let featured = await getUserFeaturedCampaign();

		if (Array.isArray(featured.data) && featured.data.length < 1) {
			clearTimeout(timer.current);
			setClose(false);
			enableScroll();
			localStorage.removeItem(STORAGE_KEYS.FEATURED_CAMPAIGN);
			props.bypassFunction && props.bypassFunction();
			return;
		}

		let selected = featured.data.find((x) => x.id == id);

		if (!selected) {
			selected = featured.data[0];
		}

		if (selected?.name) {
			setName(selected?.name);
		}

		if (selected?.terms_url) {
			setTermsUrl(selected.terms_url);
		}

		for (let asset of selected.assets) {
			if (asset.type == 'web' && !asset.name.includes('background')) {
				setImage(asset.url);
			} else if (!isMobileDevice && asset.type == 'web' && asset.name.includes('background')) {
				setBackgroundImage(asset.url);
			} else if (isMobileDevice && asset.type == 'ios' && asset.name.includes('background')) {
				setBackgroundImage(asset.url);
			}
		}

		localStorage.removeItem(STORAGE_KEYS.FEATURED_CAMPAIGN);
	};

	React.useEffect(() => {
		if (!localStorage.getItem('instant_apply')) {
			timer.current = setTimeout(() => {
				setShow(true);
			}, props.delay);
		} else if (localStorage.getItem('instant_apply')) {
			let then = moment(localStorage.getItem('instant_apply')).add(1, 'days');
			let now = moment(new Date());
			if (then.isBefore(now)) {
				localStorage.removeItem('instant_apply');
				timer.current = setTimeout(() => {
					setShow(true);
				}, props.delay);
			}
		}
		return clearTimeout(timer.current);
	}, []);

	React.useEffect(() => {
		image && setTopMargin('75px');
	}, [image]);

	React.useEffect(() => {
		close && setTopMargin('-100vh');
	}, [close]);

	React.useEffect(() => {
		if (show) disableScroll();
		else enableScroll();
	}, [show]);

	React.useEffect(() => {
		getCampaign();
		return () => enableScroll();
	}, []);

	if (!show) return null;

	return (
		<div
			className={classNames(styles.innerDiv, styles.red)}
			style={{
				top: topMargin,
				zIndex: 9999,
				transitionDuration: '1.5s',
				backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
				backgroundColor: backgroundColor,
				backgroundSize: 'cover',
				display: 'flex',
				minHeight: '100vh',
			}}
			onKeyPress={(e) => {
				if (e.key == 'Enter') {
					rightArrowClickHandle();
				}
			}}
		>
			<div className={styles.instantApplyDiv} style={{ width: '100vw' }}>
				<div className={styles.imageHolder}>
					<img
						className={styles.instantApplyImage}
						src={image == 'default' ? `${config.cdn}images/bts_web_quickapply_nopadding.png` : image}
						alt=""
					/>
				</div>
				<div className={styles.successButtonHolder}>
					<div style={{ display: 'grid', placeItems: 'center' }} className={styles.successButtonWidth}>
						<Button
							compact
							testID={`quick_apply_profile_button`}
							style={{ width: '50%', backgroundColor: 'white' }}
							textStyles={{ color: 'red' }}
							label={props.label}
							onPress={() => {
								rightArrowClickHandle();
							}}
						/>
						{props.secondLabel && (
							<Button
								compact
								testID={`quick_apply_secondary_button`}
								style={{ width: '50%', backgroundColor: 'white', marginTop: 10 }}
								textStyles={{ color: 'red' }}
								label={props.secondLabel}
								onPress={() => {
									secondButtonClickHandle();
								}}
							/>
						)}

						{isMobileDevice ? (
							<Link
								testID={`quick_apply_link_terms`}
								style={{ marginTop: 15 }}
								styleText={{ color: Colors.white }}
								variant={'caption'}
								text={'Terms and Conditions'}
								url={termsUrl}
							/>
						) : (
							<a
								test-id="quick_apply_link_terms"
								href={termsUrl}
								target="_blank"
								style={{ marginTop: 15, color: Colors.white }}
							>
								Terms and Conditions
							</a>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

InstantApply.defaultProps = {
	delay: 0,
};
export default InstantApply;
