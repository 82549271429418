import React, { useState } from 'react';
import * as Sentry from '@sentry/react';

import {
	FeaturedCampaignModel,
	ScholarshipContextType,
	ScholarshipFeedIndex,
	ScholarshipModel,
	ScholarshipProgress,
} from '../types';
import {
	deleteMatchedScholarship,
	deleteSavedScholarship,
	getDidNotApplyReasons,
	getRemovedReasons,
	getScholarship,
	getScholarships,
	getUserScholarshipTotal,
	saveUserScholarship,
	sendScholarshipFeedback,
	setScholarshipAsRead,
	submitEasyApply,
	updateUserScholarship,
} from '../actions/scholarshipActions';
import {
	doApplyScholarships,
	getCampaignsAppliedScholarships,
	getLocalUser,
	getUserFeaturedCampaign,
} from '../actions';
import { initialFilterState, searchReducer, SearchDispatchTypes, SearchState } from '../reducers/SearchReducers';

import { Status, Routes, ButtonStrings, ScreenStrings } from '../constants';
import { getActivePromos } from '../actions/brandingActions';
import { useDebouncedCallback } from 'use-debounce';

import { SessionContext } from '../contexts';
import { uniqBy } from 'lodash';

enum QueueMethod {
	APPLIED = 'applied',
	SAVED = 'saved',
	UPDATE = 'update',
	READ = 'read',
	FEEDBACK = 'feedback',
	REMOVE_SAVED = 'remove_saved',
	REMOVE_MATCHED = 'remove_matched',
}

type ScholarshipFeedbackType = { scholarship_id: number; scholarship_did_not_apply_reason_id: number };

type QueueScholarship = ScholarshipProgressType | ScholarshipModel | ScholarshipRemoveType | ScholarshipFeedbackType;

export type ScholarshipContextFunctionsType = {
	getPromos: () => void;
	getHostedScholarships: () => void;
	getScholarship: (slug: string) => void;
	getAndSetScholarships: (sort?: string) => void;
	paginateScholarships: (arr: []) => void;
	sortScholarships: (sort: string) => void;
	saveScholarship: (item: any) => void;
	appliedScholarship: (item: any, params: any) => void;
	updateScholarship: (item: any) => void;
	setScholarshipRead: (item: any) => void;
	removeSavedScholarship: (params: any) => void;
	setScholarship: (item: any) => void;
	setStatus: (str: any) => void;
	setfeaturedCampaignId: (str: any) => void;
	getScholarshipTotal: () => void;
	openFeedback: (visible: boolean, isDismiss?: boolean) => void;
	closeFeedback: (visible: boolean) => void;
	setselectedIndex: React.Dispatch<React.SetStateAction<number>>;
};

type ScholarshipContextStateType = {
	status: string;
	scholarshipsLoaded: boolean;
	scholarshipsLoading: boolean;
	selectedIndex: number;
	buttons: Array<any>;
};

type ScholarshipProgressType = { id: number; progress?: string };
type ScholarshipRemoveType = { scholarship_id: number; scholarship_remove_reason_id: number; other?: string };

type ContextType = ScholarshipContextType & ScholarshipContextStateType & ScholarshipContextFunctionsType;

type ScholarshipContextProps = {
	isUserLoaded?: boolean;
	children: JSX.Element | JSX.Element[];
};

const initialState = {
	scholarships: [],
	scholarshipSaved: [],
	scholarshipApplied: [],
};

const feedbackState = {
	visible: false,
	dismissed: false,
	title: '',
};

const initFilter = (initialFilterState: SearchState) => initialFilterState;

export const progressTypeToApplied = [
	ScholarshipProgress.APPLIED,
	ScholarshipProgress.WON,
	ScholarshipProgress.NOT_AWARDED,
];
export const ScholarshipContext = React.createContext<ContextType | any>({});

export const useScholarship = () => {
	const context = React.useContext<any>(ScholarshipContext);
	if (context === undefined) {
		console.warn('useScholarship must be used within a ScholarshipContextProvider');
	}
	return context;
};

export const defaultSort: string = 'scholly_score&sort_order=desc';

export const ScholarshipContextProvider = (props: ScholarshipContextProps) => {
	const [filterState, filterDispatch] = React.useReducer(searchReducer, initialFilterState, initFilter);
	const { isAuthenticated, endSession } = React.useContext(SessionContext);
	const [state, setState] = useState(initialState);
	const [selectedIndex, setSelectedIndex] = useState(ScholarshipFeedIndex.MATCHED);
	const [status, setStatus] = useState(Status.IDLE);
	const [showUserNew] = useState(true);
	const [showUserSaved] = useState(true);
	const [showLoader, setShowLoader] = useState(true);
	const [scholarship, setScholarship] = useState<ScholarshipModel>(null);
	const [featuredCampaignId, setFeaturedCampaignId] = useState(null);
	const [feedback, setFeedback] = useState<any>(feedbackState);
	const [removeReasons, setRemoveReasons] = useState([]);
	const [hostedScholarshipApplied, setHostedScholarshipApplied] = useState([]);
	const [noApplyReasons, setNoApplyReasons] = useState([]);
	const [matchedMeta, setMatchedMeta] = useState<any>({});
	const [dismissed, setDismissed] = useState(new Map());
	const [removed, setRemoved] = useState(new Map());
	const [saved, setSaved] = useState(new Map());
	const [sortType, setSortType] = useState('user/scholarships/feed/search?sort_by=amount&sort_order=desc');
	const [hostedScholarships] = useState([]);
	const [activePromos, setActivePromos] = useState([]);
	const initialAppliedTotal = React.useRef(null);
	const [featuredCampaign, setFeaturedCampaign] = useState<FeaturedCampaignModel>(null);
	const [scholarshipTotal, setScholarshipTotal] = useState(null);
	const [sortOptions] = useState({
		label: 'Scholly Score',
		scholarshipsType: 'matched',
		value: 'scholly_score',
	});
	const [paginationLoading, setPaginationLoading] = React.useState<boolean>(true);

	React.useEffect(() => {
		getPromos();
		getAndSetReasons();
		return () => {
			setActivePromos([]);
			setRemoveReasons([]);
			setNoApplyReasons([]);
		};
	}, []);
	React.useEffect(() => {
		if (isAuthenticated === Status.IDLE && !Object.is(initialState, state)) {
			resetState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated]);

	React.useEffect(() => {
		if (filterState.status === Status.PENDING) {
			sortScholarships({ value: filterState.sort_by, scholarshipsType: 'matched' });
		} else if (filterState.status === Status.IDLE && status === Status.RESOLVED) {
			console.log('fetch scholarships');
			getAndSetScholarships();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterState.status]);

	React.useEffect(() => {
		switch (window.location.pathname) {
			case Routes.scholarships.applied:
				setSelectedIndex(ScholarshipFeedIndex.APPLIED);
				break;
			case Routes.scholarships.saved:
				setSelectedIndex(ScholarshipFeedIndex.SAVED);
				break;

			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.pathname]);

	const getAndSetReasons = async () => {
		try {
			const {
				data: { remove_reasons },
			} = await getRemovedReasons();
			const {
				data: { did_not_apply_reasons },
			} = await getDidNotApplyReasons();

			const flitered = did_not_apply_reasons.filter((r) => r.label.includes('computer') === false);

			setRemoveReasons(remove_reasons);
			setNoApplyReasons(flitered);
		} catch (error) {}
	};
	const getPromos = async () => {
		const promoResponse = await getActivePromos();
		setActivePromos(promoResponse);
	};
	const getScholarshipTotal = async () => {
		const total = await getUserScholarshipTotal();
		setScholarshipTotal(total.data.scholarships.amount);
		localStorage.setItem('scholarshipTotal', total.data.scholarships.amount);
	};

	const getAndSetScholarships = async (scholarshipsType?: string) => {
		setStatus(Status.PENDING);
		setShowLoader(true);

		let url, purl;
		if (scholarshipsType) {
			url = `user/scholarships/feed/search?sort_by=${scholarshipsType}`;
			purl = `user/scholarships/saved/search?sort_by=${scholarshipsType}&per_page=120`;
		} else {
			url = sortType;
			purl = `user/scholarships/saved/search?per_page=120`;
		}

		try {
			const response = await getScholarships(url, { showUserNew, showUserSaved });
			const progressRes = await getScholarships(purl);
			const campaignRes = await getCampaignsAppliedScholarships();

			const values = await Promise.all([response, progressRes, campaignRes]);
			const progressScholarships = values[1].data.scholarships.records;

			if (values) {
				let scholarships = values[0].data.scholarships.records.filter((s) => !dismissed.get(s.id));

				if (scholarships.length > 3) {
					const promo = { id: 'promo', isPromo: true };
					scholarships.splice(4, 0, promo);
				}

				const saved = progressScholarships.filter((scholarship: any) => {
					return scholarship.progress == ScholarshipProgress.SAVED;
				});
				const applied = progressScholarships.filter((scholarship: any) =>
					progressTypeToApplied.includes(scholarship.progress)
				);

				const hostedApplied = values[2].data.filter((scholarship: any) => !!scholarship.active);
				setHostedScholarshipApplied(uniqBy(hostedApplied, 'id'));

				if (featuredCampaignId) {
					let campaign: FeaturedCampaignModel;
					const campaignRes = await getUserFeaturedCampaign();

					const isCampaignActive = campaignRes.data?.some((c) => c.id === featuredCampaignId);

					if (isCampaignActive) {
						campaign = campaignRes.data?.find((c, i) => c.id === featuredCampaignId);
					} else {
						campaign = campaignRes.data?.[0];
					}

					setFeaturedCampaign(campaign);
					setState({
						scholarships: [campaign, ...scholarships],
						scholarshipSaved: saved,
						scholarshipApplied: applied,
					});
				} else {
					setState({
						scholarships,
						scholarshipSaved: saved,
						scholarshipApplied: applied,
					});
				}
				setMatchedMeta(values[0].data.scholarships.metadata);
				setStatus(Status.RESOLVED);
				setShowLoader(false);

				if (scholarshipsType && filterState.status === Status.PENDING) {
					// @ts-ignore
					filterDispatch({ type: SearchDispatchTypes.SET_STATUS, payload: Status.RESOLVED });
				}

				if (paginationLoading) setPaginationLoading(false);
				return {
					scholarships,
					scholarshipSaved: saved,
					scholarshipApplied: applied,
				};
			}
		} catch (error) {
			console.log(error);
			if (error?.status && error?.status === 401) {
				endSession();
			} else {
				setStatus(Status.REJECTED);
				setShowLoader(false);
			}

			if (scholarshipsType && filterState.status === Status.PENDING) {
				// @ts-ignore
				filterDispatch({ type: SearchDispatchTypes.SET_STATUS, payload: Status.RESOLVED });
			}
		}
	};

	const [paginateScholarships] = useDebouncedCallback(
		async (arr: []) => {
			if (status === Status.PENDING || (matchedMeta.links && matchedMeta.links.next === null)) return;
			if (matchedMeta.total_count > state.scholarships.length) {
				const url = matchedMeta.links.next;

				setStatus(Status.PENDING);
				try {
					const res = await getScholarships(matchedMeta.links.next);
					let scholarships = res.data.scholarships;
					if (matchedMeta.links.self !== url) {
						setState((prev) => {
							const uniqueScholarships = Array.from([
								...prev.scholarships,
								...scholarships.records,
							]).reduce((acc, current) => {
								const x = acc.find((item) => item.id === current.id);
								if (!x) {
									return acc.concat([current]);
								} else {
									return acc;
								}
							}, []);
							return {
								scholarshipApplied: prev.scholarshipApplied,
								scholarshipSaved: prev.scholarshipSaved,
								scholarships: uniqueScholarships,
							};
						});
						setMatchedMeta({ ...scholarships.metadata });
					}

					if (scholarships.records.length == 0) {
						setStatus(Status.IDLE);
					} else {
						setStatus(Status.RESOLVED);
						setPaginationLoading(false);
					}
				} catch (error) {
					console.log(error);
					setStatus(Status.REJECTED);
				}
			}
		},
		200,
		{ maxWait: 1000 }
	);

	const [sortScholarships] = useDebouncedCallback(
		async ({ value, scholarshipsType }) => {
			if (!showLoader) setShowLoader(true);
			let type;
			setSortType(value);
			switch (value) {
				case 'deadline-low':
					type = 'deadline&sort_order=asc';
					break;
				case 'deadline-high':
					type = 'deadline&sort_order=desc';
					break;
				case 'need':
					type = 'need&sort_order=asc';
					break;
				case 'amount-high':
					type = 'amount&sort_order=desc';
					break;
				case 'amount-low':
					type = 'amount&sort_order=asc';
					break;
				case 'effort-low':
					type = 'effort&sort_order=asc';
					break;
				case 'effort-high':
					type = 'effort&sort_order=desc';
					break;
				default:
					type = 'amount&sort_order=desc';
					break;
			}

			setStatus(Status.IDLE);
			try {
				await getAndSetScholarships(type);
				setStatus(Status.RESOLVED);
				setShowLoader(false);
			} catch (error) {
				console.log(error.message);
				setStatus(Status.REJECTED);
				setShowLoader(false);
			}
		},
		200,
		// The maximum time func is allowed to be delayed before it's invoked:
		{ maxWait: 2000 }
	);

	const getScholarshipAsync = async (slug: string) => {
		let scholarships = state.scholarships;
		if (state.scholarships.length === 0) {
			const result = await getAndSetScholarships();
			scholarships = [...result.scholarships, ...result.scholarshipApplied, ...result.scholarshipSaved];
		}
		let found = scholarships.find((s: any) => s.slug === slug);
		try {
			const { data } = await getScholarship(found.id);
			setScholarship(found ? found : data.scholarship);
		} catch (error) {
			setScholarship(found);
		}
	};

	const saveScholarship = (item: ScholarshipProgressType) => {
		const { id } = item;
		if (!item.progress) {
			const newmap = new Map(saved);
			newmap.set(id, !saved.get(id));
			setSaved(newmap);
		}
		if (state.scholarshipSaved.includes(item)) return;
		saveOrUpdateUserScholarships(item, { progress: 'saved' });
		item.progress = 'saved';
		const filteredData = state.scholarshipApplied.filter((item) => item.id !== id);
		try {
			setState((prevState) => ({
				...prevState,
				scholarshipSaved: getSortedSavedScholarship([...prevState.scholarshipSaved, item]),
				scholarshipApplied: filteredData,
			}));
		} catch (error) {
			console.log(error);
			Sentry.captureException(error);
		}
	};

	const getSortedSavedScholarship = (scholarshipSaved) => {
		let sortedScholarshipSaved = scholarshipSaved;
		switch (sortType) {
			case 'scholly_score':
				sortedScholarshipSaved = scholarshipSaved.sort((a, b) => b.scholly_score - a.scholly_score);
				break;
			case 'deadline':
				sortedScholarshipSaved = scholarshipSaved.sort((a, b) => a.deadline.localeCompare(b.deadline));
				break;
			case 'need':
				sortedScholarshipSaved = scholarshipSaved.sort((a) => a.deadline.localeCompare('need'));
				break;
			case 'amount-high':
				sortedScholarshipSaved = scholarshipSaved.sort((a, b) => b.amount - a.amount);
				break;
			case 'amount-low':
				sortedScholarshipSaved = scholarshipSaved.sort((a, b) => a.amount - b.amount);
				break;
		}
		return sortedScholarshipSaved;
	};

	// * USED ONLY FOR FEATUREDSCHOLARSHIPCARD
	const removeFeaturedCampaign = (item: FeaturedCampaignModel) => {
		const filterData = state.scholarships.filter((scholarship) => scholarship.id !== item.id);
		setState((prevState) => ({
			scholarshipSaved: prevState.scholarshipSaved,
			scholarshipApplied: prevState.scholarshipApplied,
			scholarships: filterData,
		}));
	};

	const appliedScholarship = (item: ScholarshipProgressType) => {
		if (state.scholarshipApplied.includes(item)) return;
		const { id } = item;
		item.progress = ScholarshipProgress.APPLIED;
		try {
			saveUserScholarship(item.id, { progress: item.progress });
			const filterData = state.scholarships.filter((item) => item.id !== id);
			setState({
				...state,
				scholarshipApplied: [...state.scholarshipApplied, item],
				scholarships: filterData,
			});
		} catch (error) {
			console.log(error);
			Sentry.captureException(error);
		}
	};

	const appliedHostedScholarship = async (items: Array<ScholarshipProgressType>) => {
		try {
			const appliedIds = [];
			items.forEach((scholarship) => {
				appliedIds.push((scholarship as ScholarshipProgressType).id);
				scholarship.progress = ScholarshipProgress.APPLIED;
			});
			await doApplyScholarships({ hosted_scholarship_ids: appliedIds });
			const unique = items.filter(
				(item) => !state.scholarshipApplied.find((scholarship) => scholarship.id === item.id)
			);
			setState({
				...state,
				scholarshipApplied: [...state.scholarshipApplied, ...unique],
			});
		} catch (error) {
			console.log(error);
			Sentry.captureException(error);
		}
	};

	const updateScholarship = (item: any, params: any) => {
		const { id } = item;

		saveOrUpdateUserScholarships(item, params);
		const updatedItem = { ...item, ...params };
		const scholarshipsIndex = state.scholarships.findIndex((item) => item.id === id);
		const newScholarships = [...state.scholarships];
		if (scholarshipsIndex != -1) newScholarships[scholarshipsIndex] = updatedItem;
		if (params.progress === ScholarshipProgress.SAVED) {
			if (state.scholarshipSaved.includes(item)) return;
			const filteredData = state.scholarshipApplied.filter((item) => item.id !== id);
			setState({
				...state,
				scholarships: newScholarships,
				scholarshipSaved: [...state.scholarshipSaved, updatedItem],
				scholarshipApplied: filteredData,
			});
		} else if (progressTypeToApplied.includes(params.progress)) {
			const filteredData = state.scholarshipSaved.filter((item) => item.id !== id);
			const applieds = state.scholarshipApplied.filter((applied) => applied.id !== item.id);
			setState({
				...state,
				scholarships: newScholarships,
				scholarshipApplied: [...applieds, updatedItem],
				scholarshipSaved: filteredData,
			});
		}
	};

	const saveOrUpdateUserScholarships = (item, params) => {
		const { id } = item;
		if (!item.progress) {
			saveUserScholarship(id, params);
		} else {
			updateUserScholarship(id, params);
		}
	};
	const setScholarshipRead = async (id: any) => {
		const { scholarships } = state;
		try {
			await setScholarshipAsRead({ id });
			const scholarshipsIndex = scholarships.findIndex((item) => item.id === id);
			const newScholarships = [...scholarships];
			if (scholarshipsIndex != -1) newScholarships[scholarshipsIndex].read_status = 'read';
			setState({ ...state, scholarships: newScholarships });
		} catch (error) {
			console.log(error);
		}
	};

	const removeSavedScholarship = (item: ScholarshipProgressType) => {
		saved.clear();
		const { id } = item;

		if (status === Status.PENDING) {
			handleScholarshipQueue(item, QueueMethod.REMOVE_SAVED);
		} else deleteSavedScholarship(id);
		const filteredData = state.scholarshipSaved.filter((item) => item.id !== id);

		item.progress = null;
		const scholarshipsIndex = state.scholarships.findIndex((item) => item.id === id);
		const newScholarships = [...state.scholarships];
		if (scholarshipsIndex != -1) newScholarships[scholarshipsIndex].progress = null;
		setState((prev) => {
			return {
				scholarshipApplied: prev.scholarshipApplied,
				scholarships: prev.scholarships,
				scholarshipSaved: filteredData,
			};
		});
	};

	const removeMatchedScholarship = async (params: ScholarshipRemoveType, withDelay = false) => {
		const { scholarship_id } = params;
		const scholarshipToRemove = [...state.scholarshipApplied, ...state.scholarshipSaved].find(
			(item) => item.id === scholarship_id
		);

		// Clear progress for removed scholarship
		if (scholarshipToRemove?.progress) {
			removeSavedScholarship(scholarshipToRemove);
		}

		const delay = status === Status.PENDING ? 0 : 500;
		const newmap = new Map();
		newmap.set(scholarship_id, !removed.get(scholarship_id));
		setRemoved(newmap);

		if (status === Status.PENDING) {
			handleScholarshipQueue(params, QueueMethod.REMOVE_MATCHED);
		} else if (withDelay) {
			await deleteMatchedScholarship(params);
		} else deleteMatchedScholarship(params);

		const filteredScholarships = state.scholarships.filter((item) => item.id !== scholarship_id);
		const filteredApplied = state.scholarshipApplied.filter((item) => item.id !== scholarship_id);
		const filteredSaved = state.scholarshipSaved.filter((item) => item.id !== scholarship_id);
		const scholarships = adjustPromo(filteredScholarships);

		setTimeout(() => {
			setState({
				scholarships,
				scholarshipApplied: filteredApplied,
				scholarshipSaved: filteredSaved,
			});
		}, delay);
	};

	const scholarshipFeedback = async (scholarship_id: number, reason_id: number) => {
		try {
			await sendScholarshipFeedback({
				scholarship_id: scholarship_id,
				scholarship_did_not_apply_reason_id: reason_id,
			});
			return { text: 'All set! Feedback was sent', type: 'success', disableScrollTop: true };
		} catch (error) {
			return { text: error.message, type: 'error' };
		}
	};

	const adjustPromo = (scholarships: Array<any>) => {
		if (scholarships.length > 3) {
			const find = scholarships.findIndex((f) => f.id === 'promo');
			if (find !== -1) scholarships.splice(4, 0, scholarships.splice(find, 1)[0]);
		}
		return scholarships;
	};

	const resetState = () => {
		setSortType('user/scholarships/matched/search?sort_by=amount&sort_order=desc');
		setState(initialState);
	};

	const handleScholarshipQueue = async (scholarship: QueueScholarship, method: QueueMethod) => {
		//@ts-ignore
		const checkID = scholarship.scholarship_id || scholarship.id;

		const newmap = new Map();
		newmap.set(checkID, !dismissed.get(checkID));
		setDismissed(newmap);
		let queue = [];
		const stored = await localStorage.getItem(`queue.${method}`);
		if (stored) {
			const parsed = JSON.parse(stored);
			queue = [...parsed, scholarship];
		} else queue = [scholarship];
		localStorage.setItem(`queue.${method}`, JSON.stringify(queue));
	};

	const updateScholarshipQueue = async () => {
		if (status !== Status.RESOLVED) return;
		let keys = Object.keys(localStorage).filter((k) => k.includes('queue.'));
		for (let key of keys) {
			let value: any = localStorage.getItem(key);
			if (value) {
				const parsed = JSON.parse(value);
				switch (key) {
					case `queue.${QueueMethod.APPLIED}`:
						parsed.forEach((element: ScholarshipProgressType) => appliedScholarship(element));
						break;
					case `queue.${QueueMethod.SAVED}`:
						parsed.forEach((element: ScholarshipProgressType) => saveScholarship(element));
						break;
					case `queue.${QueueMethod.UPDATE}`:
						parsed.forEach((element: ScholarshipProgressType) =>
							updateUserScholarship(element.id, { progress: element.progress })
						);
						break;
					case `queue.${QueueMethod.READ}`:
						parsed.forEach((element: ScholarshipProgressType) => setScholarshipRead(element.id));
						break;
					case `queue.${QueueMethod.REMOVE_MATCHED}`:
						parsed.forEach((element: ScholarshipRemoveType) => removeMatchedScholarship(element));
						break;
					case `queue.${QueueMethod.REMOVE_SAVED}`:
						parsed.forEach((element: ScholarshipProgressType) => removeSavedScholarship(element));
						break;
					case `queue.${QueueMethod.FEEDBACK}`:
						parsed.forEach((element: ScholarshipFeedbackType) => sendScholarshipFeedback(element));
						break;
					default:
						break;
				}
				localStorage.removeItem(key);
			}
		}
	};

	const buttons = [
		`${!showLoader && state.scholarshipSaved ? state.scholarshipSaved.length : ''} ${
			ButtonStrings.scholarships.saved
		}`,
		`${!showLoader && state.scholarshipApplied ? state.scholarshipApplied.length : ''} ${
			ButtonStrings.scholarships.applied
		}`,
	];

	const openFeedback = (visible: boolean, isDismiss?: boolean) => {
		if (isDismiss) {
			setFeedback({
				visible,
				dismissed: isDismiss,
				title: ScreenStrings.scholarship.dismissScholarship,
			});
			return;
		}
		setFeedback({
			visible,
			dismissed: false,
			title: ScreenStrings.scholarship.didYouApply,
		});
	};

	const closeFeedback = (visible: boolean) => openFeedback(visible);

	const handleEasyApply = async (scholarship) => {
		const local = await getLocalUser();

		const userBirthday = local.birthday ? local.birthday.split('-') : null;
		const userBirthdayFinal = userBirthday ? `${userBirthday[1]}/${userBirthday[2]}/${userBirthday[0]}` : null;
		const grade_level =
			local.parameters.length > 0 ? local.parameters.find((p) => p.parameter_id == 3).value : null;

		const userQuickApply = {
			first_name: local.first_name,
			last_name: local.last_name,
			email: local.email,
			birthday: userBirthdayFinal,
			grade_level: grade_level,
		};
		const hostedApplied = [...hostedScholarshipApplied, scholarship];
		setHostedScholarshipApplied(uniqBy(hostedApplied, 'id'));
		return submitEasyApply(userQuickApply, scholarship.name);
	};
	React.useEffect(() => {
		// UPDATES INITIALAPPLIEDTOTAL AS IT'S 0 ON INITIAL RENDER
		initialAppliedTotal.current = state.scholarshipApplied.length;
		updateScholarshipQueue();
		setTimeout(() => {
			dismissed.clear();
		}, 500);
	}, [dismissed, state.scholarshipApplied.length, updateScholarshipQueue]);

	return (
		<ScholarshipContext.Provider
			value={{
				...state,
				feedback,
				initialAppliedTotal,
				selectedIndex,
				status,
				showLoader,
				activePromos,
				sortType,
				saved,
				removed,
				dismissed,
				scholarship,
				removeReasons,
				noApplyReasons,
				matchedMeta,
				buttons,
				filterState,
				setStatus,
				setfeaturedCampaignId: setFeaturedCampaignId,
				getAndSetScholarships,
				paginateScholarships,
				saveScholarship,
				appliedScholarship,
				appliedHostedScholarship,
				updateScholarship,
				removeSavedScholarship,
				removeMatchedScholarship,
				sortScholarships,
				scholarshipFeedback,
				setScholarshipRead,
				setScholarship,
				getPromos,
				getScholarship: getScholarshipAsync,
				featuredCampaign,
				filterDispatch,
				removeFeaturedCampaign,
				resetState,
				getScholarshipTotal,
				scholarshipTotal,
				hostedScholarships,
				sortOptions,
				paginationLoading,
				openFeedback,
				closeFeedback,
				setselectedIndex: setSelectedIndex,
				handleEasyApply,
				hostedScholarshipApplied,
			}}
		>
			{props.children}
		</ScholarshipContext.Provider>
	);
};

export const onShareScholarship = () => {
	// Sharing Scholarshop
};

export const onSaveScholarship = () => {
	// Saving Sholarship
};
