import * as React from 'react';
import Button from '../button/Button';
import { ErrorMessage } from '@scholly/elements';
import { Input, Validation } from '@scholly/scholly-ui';
import { EmailIcon } from '../common/icons';

import { ScreenStrings, ButtonStrings } from '../../constants';
import { validateEmail } from '../../helpers/Validation';

type IProps = {
	onReset: (e: string) => void;
	isResetValid: boolean;
	errorMsg: string | null;
	successMsg?: string | null;
};

const ForgotPasswordForm: React.FC<IProps> = ({ isResetValid, errorMsg, successMsg, onReset }) => {
	const [email, setEmail] = React.useState('');
	const [isSubmitted, setIsSubmitted] = React.useState(false);

	const reset = async () => {
		setIsSubmitted(true);
		await onReset(email);
		await setIsSubmitted(false);
	};

	const isDisabled = () => !validateEmail(email);
	return (
		<React.Fragment>
			{errorMsg && (
				<ErrorMessage style={{ width: 'auto', display: 'flex', marginBottom: 17, padding: '15px' }}>
					{errorMsg}
				</ErrorMessage>
			)}
			<div style={{ flexDirection: 'column', width: '100%', position: 'relative' }}>
				<EmailIcon svgStyle={{ position: 'absolute', zIndex: 2, top: 10 }} />
				<Input
					rootContainerStyles={{
						height: 48,
						width: '100%',
						backgroundColor: '#FFFFFF',
						borderRadius: 6,
						shadowRadius: 1,
						shadowOpacity: 0.05,
						borderColor: errorMsg ? '#F85272' : 'white',
						borderWidth: 2,
					}}
					inputContainerStyles={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						paddingLeft: 30,
						height: 44,
						backgroundColor: '#FFFFFF',
						borderRadius: 6,
						borderColor: 'white',
					}}
					style={{ fontFamily: 'Poppins' }}
					keyboardType="email-address"
					placeholder={ScreenStrings.auth.email}
					value={email}
					onChangeText={setEmail}
					textContentType="emailAddress"
					onSubmitEditing={() => {
						!isDisabled() && reset();
					}}
					testID={'testID_forgot_password_input'}
					returnKeyType="done"
				/>
			</div>
			<SuccessMessage successMsg={successMsg} />

			<Button
				fullWidth
				variant="solid"
				label={ButtonStrings.send}
				onClick={reset}
				disabled={isDisabled() || isSubmitted}
				style={{
					minHeight: 48,
					marginBottom: 24,
					boxSizing: 'border-box',
					width: '100%',
					backgroundColor: isDisabled() ? '#C9D4FB' : '#3259F1',
					cursor: 'pointer',
					justifySelf: 'center',
					color: isDisabled() ? '#98ACF8' : '#ffffff',
					border: 'none',
					borderRadius: 6,
				}}
				testID={'testID_forgot_password_button'}
			/>
		</React.Fragment>
	);
};

export default ForgotPasswordForm;

const SuccessMessage = ({ successMsg }) => {
	return (
		<div style={{ marginBottom: 16, position: 'absolute', top: 207 }}>
			<Validation message={successMsg} isError={false} />
		</div>
	);
};
