import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { UserContext, useScholarship, SessionContext } from '../../contexts';
import config from '../../config/config';
import { ScholarshipFeedIndex } from '../../types';
import { Status, Routes, DrawerStrings } from '../../constants';

import styles from './nav.scss';

const logoTheme = {
	defaultTheme: {
		srcWhite: `${config.cdn}images/scholly-by-sallie-white.png`,
		srcBlue: `${config.cdn}images/scholly-by-sallie-blue.png`,
	},
	signedin: {
		src: `${config.cdn}images/logo-white.png`,
		class: undefined,
	},
	loggedout: {
		src: `${config.cdn}images/logo-new-red.png`,
		class: styles.logoContainer,
	},
};

const Logo = () => {
	const history = useHistory();
	const location = useLocation();
	const { isAuthenticated } = React.useContext(SessionContext);
	const { selectedIndex, setselectedIndex } = useScholarship();
	const { isOnboardingCompleted } = React.useContext(UserContext);

	const onClick = (e) => {
		if (isAuthenticated === Status.RESOLVED && isOnboardingCompleted) {
			e.preventDefault();
			if ([ScholarshipFeedIndex.APPLIED, ScholarshipFeedIndex.SAVED].includes(selectedIndex))
				setselectedIndex(ScholarshipFeedIndex.MATCHED);
			else if (location.pathname !== Routes.scholarships.feed) history.push(Routes.scholarships.feed);
		}
	};

	return (
		<a
			target="_blank"
			onClick={onClick}
			className={styles.logoAnchor}
			href={config.homepage}
			aria-label={`${DrawerStrings.scholly.heading} Home Page`}
		>
			<img
				className={styles.logoImg}
				src={logoTheme.defaultTheme.srcWhite}
				alt={`${DrawerStrings.scholly.heading} Home`}
				title={DrawerStrings.scholly.heading}
			/>
		</a>
	);
};

export default Logo;
