import React from 'react';
import * as Sentry from '@sentry/react';
import { Button } from '@scholly/elements';
import difference from 'lodash/difference';
import values from 'lodash/values';

import ErrorBoundary from '../../components/misc/ErrorBoundary';
import { SearchDispatchTypes, initialFilterState } from '../../reducers/SearchReducers';
import SortFilterOptions from './sort-filter-options';
import styles from './filter.scss';
import { useScholarship } from '../../contexts';
import { Status, ButtonStrings } from '../../constants';

const FilterScreen = ({ onClose }) => {
	const { filterState, filterDispatch } = useScholarship();
	const [isDisabled, setDisabled] = React.useState(true);
	const sendError = (error, errorInfo) => {
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			Sentry.captureException(error);
		});
	};

	const onClear = () => {
		filterDispatch({ type: SearchDispatchTypes.CLEAR });
	};

	const onApply = () => {
		if (isDisabled) {
			return;
		}

		filterDispatch({ type: SearchDispatchTypes.SET_STATUS, payload: Status.PENDING });
		onClose();
	};

	React.useEffect(() => {
		if (difference(values(filterState), values(initialFilterState)).length === 0) {
			if (!isDisabled) {
				setDisabled(true);
			}
		} else {
			setDisabled(false);
		}
	}, [isDisabled, filterState]);

	return (
		<ErrorBoundary logErrorToService={sendError}>
			<div className={styles.screen}>
				<div role="button" onClick={onClear} className={styles.clearText}>
					<span>Clear Filter</span>
				</div>
				<div>
					<SortFilterOptions />
				</div>
				<div className={styles.btnGroup}>
					<Button style={{ boxSizing: 'border-box' }} variant="secondary" onClick={onClose}>
						{ButtonStrings.Cancel}
					</Button>
					<Button
						style={{ boxSizing: 'border-box' }}
						variant="primary"
						onClick={onApply}
						disabled={isDisabled}
					>
						{ButtonStrings.Apply}
					</Button>
				</div>
			</div>
		</ErrorBoundary>
	);
};

export default FilterScreen;
