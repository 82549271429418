import React from 'react';
import classNames from 'classnames';
import styles from '../common.scss';
import { NavLink } from 'react-router-dom';

interface IPopoverMenuProps {
	testID?: string;
	children: React.ReactNode | string;
	active: boolean;
	href?: string;
	disabled?: boolean;
	onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ABSOLUTE_URL_REGEX = /^https?:\/\//i;

const MenuItem: React.FC<IPopoverMenuProps> = (props: IPopoverMenuProps) => {
	if (props.href && ABSOLUTE_URL_REGEX.test(props.href)) {
		return (
			//@ts-ignore
			<a data-testid={props.testID} href={props.href} className={styles.menuItem} target="_blank">
				<span>{props.children}</span>
			</a>
		);
	} else if (props.href) {
		return (
			<NavLink
				//@ts-ignore
				testID={props.testID}
				to={props.href}
				onClick={(e) => {
					props.disabled && e.preventDefault();
				}}
				className={classNames(styles.menuItem, props.active && styles.active)}
			>
				<span>{props.children}</span>
			</NavLink>
		);
	}

	return (
		//@ts-ignore
		<div data-testid={props.testID} className={styles.menuItem} onClick={props.onClick}>
			<span>{props.children}</span>
		</div>
	);
};

export default MenuItem;
