import { ActionRightPointer, Banner } from '@scholly/elements';
import React, { FC } from 'react';
import { ScholarshipContext, useQuestions } from '../../contexts';

import Folder from '../common/icons/folder';
import { Routes } from '../../constants';
import classNames from 'classnames';
import styles from './banners.scss';
import { useHistory } from 'react-router-dom';

interface MatchBannerProps {
	onClose?: () => void;
	className?: string;
}
const MatchBanner: FC<MatchBannerProps> = ({ className }) => {
	const history = useHistory();
	const { profile } = useQuestions();
	const { scholarshipTotal, getScholarshipTotal } = React.useContext(ScholarshipContext);
	const [percentage, setPercentage] = React.useState(0);
	const [matches, setMatches] = React.useState('');
	const [visible, setVisible] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState<boolean | null>(null);

	const goToPage = () => {
		const to = Routes.categories;
		history.push(to);
	};

	React.useEffect(() => {
		if (profile === 1) {
		} else {
			setPercentage(Math.round(profile * 100));
		}
		setVisible(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile]);
	React.useEffect(() => {
		if (!scholarshipTotal) {
			getScholarshipTotal();
		} else if (scholarshipTotal > 0) {
			setMatches(scholarshipTotal.toLocaleString());
		}
	}, [getScholarshipTotal, scholarshipTotal]);

	React.useEffect(() => {
		if (scholarshipTotal === 0) {
			setIsLoading(true);
		} else {
			setIsLoading(false);
		}
	}, [scholarshipTotal, matches]);

	if (!visible) return null;
	return (
		<React.Fragment>
			{!isLoading && (
				<Banner.Container
					className={classNames(styles.bannerContainer, styles.matchBanner, className)}
					color="white"
					onClick={goToPage}
				>
					<Banner.InnerContainer className={styles.innerModified}>
						<div className={styles.contentWrapper}>
							<div className={styles.progressBarWrapper}>
								<Folder />
							</div>
							<div className={styles.textContainer}>
								<h3>${matches} In Matches</h3>
								<p className={styles.completeText}>
									Update your profile to match to more scholarships.
								</p>
							</div>
						</div>
						<Banner.ActionCenter>
							<ActionRightPointer
								height="20"
								width="19"
								viewBox="0 0 19 20"
								clickHandler={goToPage}
								clickable
							/>
						</Banner.ActionCenter>
					</Banner.InnerContainer>
				</Banner.Container>
			)}
		</React.Fragment>
	);
};

export default MatchBanner;
