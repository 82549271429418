import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Button } from '@scholly/elements';

import { UserContext } from '../../../../contexts';

import { Card } from '../../../../components/card/Card';
import { PayoffIcon, SchollyCashIcon } from '../../../../components/common/icons';

import { Routes } from '../../../../constants';
import config from '../../../../config/config';

import styles from '../offboarding.scss';

export const SuccessfullyCanceled = () => {
	const { subscription } = React.useContext(UserContext);
	const history = useHistory();
	const formattedDate = moment(subscription?.next_billing_date).diff(moment(), 'days');

	const openMainScreen = () => {
		history.push(Routes.dashboard);
	};

	const openPage = (value: string) => () => {
		const regex = '^https?://';
		if (!value.match(regex)) history.push(value);
		else window.open(value, '_blank');
	};

	return (
		<div className={classNames(styles.pauseCardContainer, 'mt-7')}>
			<div className={'has-text-centered'}>
				<div className={classNames('mt-5')}>
					<div className={classNames(styles.pauseText, styles.pauseTitle)}>
						Your Subscription has been successfully canceled
					</div>
				</div>
			</div>
			<div className={classNames(styles.isFlex, 'mt-5')}>
				<div className={classNames(styles.pauseText, styles.pauseDescription, styles.cancelDescription)}>
					<span>Your have {formattedDate} days left</span> with your current subscription.
				</div>
			</div>

			<div className={classNames(styles.isFlex, styles.justifyContentCenter, 'mt-5')}>
				<div className={classNames(styles.pauseText, styles.pauseDescription, styles.cancelDescription)}>
					Explore Scholly Offers and Payoff to help with or student loans and financial goals.
				</div>
			</div>
			<div className={classNames(styles.isFlex, styles.isFlexRow, styles.justifyContentBetween, 'mt-5')}>
				<Card onClick={openPage(config.payoff_url)} className={'mr-5'}>
					<PayoffIcon />
				</Card>
				<Card onClick={openPage(config.offers_url)}>
					<SchollyCashIcon />
				</Card>
			</div>
			<div className={classNames('mt-5')}>
				<Button
					variant="secondary"
					onClick={() => {
						openMainScreen();
					}}
					className={classNames(styles.pauseButton, 'mb-5')}
				>
					Go to Main Screen
				</Button>
			</div>
		</div>
	);
};
