import * as React from 'react';
import { Input, InputType } from '@scholly/elements';
import Select from 'react-select';
import { UserUpdateBirthday } from '@scholly/scholly-types';

import { ScreenStrings } from '../../constants';

import { getParameterValueToSelect } from '../../helpers/Functions';

import MultiSelect from './lib/MultiSelect';
import customStyles from './lib/CustomStyles';

import styles from './profile.scss';
import { isSafari } from '../../helpers/checkIfSafari';
import classNames from 'classnames';
import { ParameterContext } from '../../contexts';
import { UserFormParamsType } from '../../types';

type WebInputType = React.SyntheticEvent<React.FocusEvent>;

type UserUpdateUserType = {
	user_type: string | any;
};

type OUserBasicInfoType = Pick<UserFormParamsType, 'race' | 'citizen'> & UserUpdateBirthday & UserUpdateUserType;

type IProps = OUserBasicInfoType & {
	onChangeText: (property: keyof OUserBasicInfoType) => (value: string) => void;
	onChangeTextMultiple?: (property: keyof OUserBasicInfoType) => (value: string) => void;
	scrollToFocus?: WebInputType;
	styles?: object;
	path?: string;
	hasError?: (value: string, input: any) => boolean;
	showError?: boolean;
};

const ProfileBasicInfo: React.FC<IProps> = (props) => {
	const { values } = React.useContext(ParameterContext);
	const ethnicity = values.race;
	const citizen = values.citizen;
	const user_type = values.user_type;

	const _onChangeVal =
		(property: 'race' | 'citizen' | 'birthday' | 'user_type') =>
		({ value }) => {
			props.onChangeText(property)(value);
		};

	return (
		<div className={styles.profileWrapper}>
			<h3>{ScreenStrings.profile.iAm}</h3>
			<div className={styles.profileContainer}>
				<Input
					name={!isSafari() ? 'birthday' : 'birthday-ios'}
					variant={'date'}
					className={classNames(styles.profileInput, isSafari() && styles.iosDate)}
					// @ts-ignore
					onChange={props.onChangeText('birthday')}
					showValidation={true}
					value={props.birthday}
					validationCriteria={{
						inputVal: props.birthday,
						type: InputType.Birthday,
					}}
				/>

				<Select
					styles={customStyles(props)}
					placeholder={ScreenStrings.profile.isParent}
					options={user_type}
					value={getParameterValueToSelect(props.user_type, user_type)}
					hasError={Boolean(!props.user_type) && props.showError}
					onChange={_onChangeVal('user_type')}
					getOptionLabel={(options) => options.value}
					getOptionValue={(options) => options.id}
					isScroll={true}
				/>

				<MultiSelect
					testID="form_input_ethnicity"
					styles={customStyles(props)}
					placeholder={ScreenStrings.profile.ethnicity}
					options={ethnicity}
					value={getParameterValueToSelect(props.race, ethnicity)}
					hasError={props.hasError('race', props.race)}
					onChangeVal={props.onChangeTextMultiple('race')}
					bottom
					searchable
				/>

				<Select
					styles={customStyles(props)}
					placeholder={ScreenStrings.profile.citizen}
					options={citizen}
					value={getParameterValueToSelect(props.citizen, citizen)}
					hasError={props.hasError('citizen', props.citizen)}
					onChange={_onChangeVal('citizen')}
					getOptionLabel={(options) => options.value}
					getOptionValue={(options) => options.id}
				/>
			</div>
		</div>
	);
};
export default ProfileBasicInfo;
