import { fetcher } from './apiActions';

export const getBranding = (slug: string) => fetcher(`branding-site/${slug}`);
export const getActivePromos = async () => {
	const allPromos = await fetcher(`promo/in-feeds`);
	const activePromos = allPromos.data.filter(function (promo) {
		return promo.status == 'active';
	});
	return activePromos;
};
