import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Button } from '@scholly/elements';

import { cancelSubscription } from '../../../../actions';
import { FlashContext, UserContext } from '../../../../contexts';

import RightArrow from '../../../../components/common/icons/right-arrow';

import { Routes } from '../../../../constants';

import styles from '../offboarding.scss';

type Reason = {
	id: string | number;
	label: string;
	type: string;
};

interface MainProps {
	cancelReasons: Array<Reason>;
}
const MainScreen = ({ cancelReasons }: MainProps) => {
	// @ts-ignore
	const [reasons, setReasons] = useState(cancelReasons);
	const { subscription, getAndSetSubscription } = React.useContext(UserContext);
	const flashContext = React.useContext(FlashContext);
	const history = useHistory();
	const [loading, setLoading] = React.useState<boolean>(false);

	const [modalIsVisible, setModalIsVisible] = useState(false);

	setModalIsVisible(!modalIsVisible);

	useEffect(() => {
		const isHasTechnical = cancelReasons?.find((reason) => reason.label.includes('Technical'));
		if (cancelReasons && !isHasTechnical) {
			cancelReasons.push({ id: cancelReasons.length, type: 'option', label: 'Technical Issues' });
		}
		cancelReasons = cancelReasons.filter((v, i, a) => a.findIndex((t) => t.label === v.label) === i);
		setReasons(cancelReasons);
	}, [cancelReasons]);

	const isTechnical = (value: string) => {
		return value.includes('Technical');
	};
	const isFinancial = (value: string) => {
		return value.includes('Financial') || value.includes('subscription');
	};
	const { offboarding } = Routes;

	const cancel = async () => {
		try {
			setLoading(true);
			await cancelSubscription(subscription);
			await getAndSetSubscription();
			flashContext.display({ type: 'success', text: 'Subscription canceled successfully' });
			history.push(`${offboarding.home}/${offboarding.thankYou}`);
			setLoading(false);
		} catch (error) {
			console.log(error, '<---error');
		}
	};

	const dismiss = () => {
		history.push(Routes.scholarships.feed);
	};

	const goToScreen = (value) => {
		if (isTechnical(value)) {
			history.push(`${offboarding.home}/${offboarding.technicalIssues}`);
		} else if (isFinancial(value)) {
			if (subscription.processor == 'stripe') {
				history.push(`${offboarding.home}/${offboarding.pauseMembership}`);
			} else {
				history.push(`${offboarding.home}/${offboarding.aspiration}`);
			}
		} else {
			history.push(`${offboarding.home}/${offboarding.accountType}`);
		}
	};

	return (
		<React.Fragment>
			<div className={classNames(styles.pauseText, styles.pauseDescription, styles.mainTitle)}>
				Why are you canceling your {'\n'}
				Scholly Subscription?
			</div>
			{cancelReasons.length > 1 && (
				<div className={styles.cancelHolder}>
					{cancelReasons
						.filter((v, i, a) => a.findIndex((t) => t.label === v.label) === i)
						.map((value, index) => {
							return (
								<div
									key={index.toString()}
									test-id={`testID_cancelReason_${value.label.replace(/ /g, '_')}`}
									style={{ borderBottom: index == cancelReasons.length - 1 && 'none' }}
									onClick={() => {
										goToScreen(value.label);
									}}
									className={styles.cancelInnerHolder}
								>
									<div style={{ textAlign: 'left' }}>
										<h4 className={styles.cancelReasons} key={index}>
											{value.label}
										</h4>
									</div>
									<div className={styles.cancelInnerIcon}>
										<RightArrow />
									</div>
								</div>
							);
						})}
				</div>
			)}
			<div className={classNames(styles.isFlex, styles.isFlexColumn)}>
				<Button
					isLoading={loading}
					className={classNames('mt-7', styles.widthAuto)}
					variant={'secondary'}
					onClick={cancel}
				>
					Continue Canceling Subscription
				</Button>
				<Button
					isLoading={loading}
					className={classNames('mt-4', styles.widthAuto)}
					variant={'secondary'}
					onClick={dismiss}
				>
					Not Now
				</Button>
			</div>
		</React.Fragment>
	);
};
export default MainScreen;
