import React from 'react';

export const WelcomeBack = () => {
	return (
		<div>
			<svg xmlns="http://www.w3.org/2000/svg" width="224" height="230" viewBox="0 0 224 230" fill="none">
				<path
					d="M37.2735 84.9154L33.584 81.6178L101.142 5.84569C102.751 4.03328 104.722 2.5785 106.927 1.57503C109.132 0.571569 111.523 0.0416785 113.945 0.0195312H114.026C116.434 0.0298026 118.814 0.542622 121.013 1.52521C123.212 2.50781 125.182 3.93861 126.797 5.72628L192.482 77.9706L188.821 81.2799L123.137 9.03553C121.99 7.75228 120.586 6.72569 119.016 6.02292C117.447 5.32015 115.746 4.95701 114.026 4.95722H113.965C112.233 4.96568 110.522 5.34276 108.947 6.06346C107.371 6.78415 105.966 7.83199 104.826 9.13748L37.2735 84.9154Z"
					fill="#1A2E7D"
				/>
				<path
					d="M216.435 181.781L213.72 182.477C213.319 182.581 212.907 182.636 212.492 182.641L215.207 181.944C215.621 181.939 216.034 181.885 216.435 181.781Z"
					fill="#1A2E7D"
				/>
				<path
					d="M12.5496 82.2353L9.83482 82.9315C9.80559 81.8112 10.1313 80.7104 10.7653 79.7868C11.3994 78.8632 12.3092 78.1642 13.3643 77.79L16.0791 77.0938C15.025 77.4689 14.1165 78.1683 13.4836 79.0919C12.8507 80.0154 12.5258 81.1157 12.5554 82.2353"
					fill="#1A2E7D"
				/>
				<path
					d="M16.0732 77.094L13.3584 77.7903C13.4922 77.7436 13.6319 77.7028 13.7716 77.665L16.4864 76.9688C16.3467 77.0037 16.1954 77.0474 16.0732 77.094Z"
					fill="#1B3082"
				/>
				<path
					d="M215.206 181.941L212.489 182.641L17.167 184.913L19.8818 184.217L215.206 181.941Z"
					fill="#192D79"
				/>
				<path
					d="M14.4993 178.912L11.7845 179.608L9.83496 82.9345L12.5497 82.2383L14.4993 178.912Z"
					fill="#192D79"
				/>
				<path
					d="M213.038 74.5352C214.453 74.5327 215.811 75.0886 216.819 76.0822C217.826 77.0757 218.402 78.4269 218.421 79.8428L220.371 176.516C220.392 177.213 220.274 177.907 220.025 178.558C219.776 179.21 219.4 179.805 218.92 180.31C218.44 180.815 217.864 181.219 217.227 181.5C216.589 181.781 215.903 181.933 215.206 181.946L19.8817 184.218C18.4673 184.221 17.109 183.665 16.1014 182.671C15.0937 181.678 14.5178 180.327 14.4987 178.911L12.5492 82.2374C12.5286 81.5392 12.6468 80.8439 12.8968 80.1918C13.1468 79.5398 13.5236 78.9439 14.0054 78.4388C14.4872 77.9336 15.0644 77.5293 15.7035 77.2493C16.3426 76.9692 17.0309 76.819 17.7285 76.8074L213.038 74.5352Z"
					fill="#2E51DC"
				/>
				<path
					d="M19.882 184.218L17.1672 184.914C15.7528 184.916 14.3945 184.361 13.3869 183.367C12.3792 182.373 11.8033 181.022 11.7842 179.606L14.499 178.91C14.5181 180.326 15.094 181.677 16.1016 182.671C17.1093 183.664 18.4676 184.22 19.882 184.218Z"
					fill="#1A2E7D"
				/>
				<g opacity="0.3">
					<path
						opacity="0.3"
						d="M13.1139 122.203L12.9248 121.982C28.5355 108.841 45.8484 96.539 62.5881 84.642C66.8189 81.6386 71.1893 78.5245 75.4695 75.457L75.6383 75.6959C71.3581 78.7692 66.9877 81.8745 62.7569 84.8808C46.0201 96.7808 28.713 109.077 13.1139 122.203Z"
						fill="#F7F7F7"
					/>
					<path
						opacity="0.3"
						d="M13.193 145.509L12.9922 145.297C32.1586 127.014 54.9273 110.835 76.9481 95.1917C86.0032 88.7597 95.3668 82.1033 104.352 75.3828L104.527 75.6188C95.5384 82.3188 86.1749 88.984 77.1169 95.4277C55.1048 111.053 32.3448 127.227 13.193 145.509Z"
						fill="#F7F7F7"
					/>
					<path
						opacity="0.3"
						d="M13.2768 170.852L13.0586 170.659C34.8263 145.84 63.5425 125.434 91.313 105.698C105.07 95.9215 119.296 85.8131 132.494 75.2969L132.678 75.5241C119.47 86.0461 105.242 96.1574 91.4817 105.937C63.7229 125.655 35.0213 146.05 13.2768 170.852Z"
						fill="#F7F7F7"
					/>
					<path
						opacity="0.3"
						d="M25.9106 183.378L25.6895 183.189C47.6783 157.435 77.1626 136.484 105.678 116.22C123.966 103.228 142.876 89.79 159.619 75.2188L159.811 75.4401C143.056 90.0055 124.14 103.461 105.847 116.459C77.346 136.705 47.8762 157.647 25.9106 183.378Z"
						fill="#F7F7F7"
					/>
					<path
						opacity="0.3"
						d="M49.9654 183.306L49.7559 183.102C70.2229 162.128 95.5522 144.14 120.046 126.734C142.664 110.663 166.049 94.0465 185.541 75.1406L185.742 75.3504C166.247 94.2708 142.838 110.89 120.215 126.973C95.7296 144.37 70.412 162.361 49.9654 183.306Z"
						fill="#F7F7F7"
					/>
					<path
						opacity="0.3"
						d="M75.318 183.233L75.1201 183.017C93.2099 166.532 114.157 151.649 134.415 137.253C161.143 118.26 188.786 98.6196 210.178 75.0703L210.394 75.2684C188.978 98.8381 161.324 118.487 134.583 137.492C114.332 151.879 93.3816 166.774 75.318 183.233Z"
						fill="#F7F7F7"
					/>
					<path
						opacity="0.3"
						d="M101.919 183.153L101.735 182.929C116.619 170.627 132.968 159.006 148.78 147.774C173.513 130.19 199.118 112.01 219.678 90.7734L219.888 90.9774C199.296 112.231 173.69 130.42 148.957 148.01C133.143 159.242 116.796 170.851 101.919 183.153Z"
						fill="#F7F7F7"
					/>
					<path
						opacity="0.3"
						d="M129.635 183.088L129.457 182.858C140.406 174.468 151.967 166.253 163.149 158.309C182.414 144.618 202.334 130.463 219.761 114.957L219.953 115.176C202.518 130.691 182.589 144.851 163.318 158.545C152.138 166.484 140.581 174.687 129.635 183.088Z"
						fill="#F7F7F7"
					/>
					<path
						opacity="0.3"
						d="M158.231 182.988L158.06 182.752C164.487 178.068 171.107 173.366 177.505 168.819C191.661 158.76 206.312 148.357 219.836 137.5L220.017 137.727C206.475 148.59 191.833 158.993 177.674 169.054C171.276 173.602 164.67 178.304 158.231 182.988Z"
						fill="#F7F7F7"
					/>
					<path
						opacity="0.3"
						d="M187.366 182.896L187.197 182.66L191.853 179.345C201.123 172.759 210.711 165.945 219.9 159.07L220.074 159.303C210.883 166.181 201.295 172.995 192.022 179.584L187.366 182.896Z"
						fill="#F7F7F7"
					/>
				</g>
				<path
					d="M201.171 168.941L198.456 169.638C198.054 169.74 197.641 169.794 197.226 169.798L199.94 169.102C200.355 169.096 200.769 169.042 201.171 168.941Z"
					fill="#4D6A7B"
				/>
				<path
					d="M29.0368 90.5126L31.7516 89.8164C30.5989 90.1216 29.5832 90.808 28.8695 91.7642C28.1558 92.7204 27.7861 93.8901 27.8205 95.0833L25.1057 95.7795C25.0713 94.5863 25.441 93.4166 26.1547 92.4604C26.8684 91.5042 27.8841 90.8178 29.0368 90.5126Z"
					fill="#1A2E7D"
				/>
				<path
					d="M27.8204 95.082L25.1056 95.7782C25.0742 94.659 25.3977 93.5587 26.0297 92.6349C26.6617 91.7112 27.5697 91.0117 28.6235 90.6366L31.3383 89.9375C30.2848 90.3138 29.3772 91.0141 28.7453 91.9381C28.1135 92.8622 27.7897 93.9626 27.8204 95.082Z"
					fill="#1A2E7D"
				/>
				<path
					d="M31.3378 89.9388L28.623 90.6379C28.7585 90.5891 28.8964 90.5473 29.0362 90.5126L31.751 89.8164C31.6113 89.8514 31.46 89.8922 31.3378 89.9388Z"
					fill="#1A2E7D"
				/>
				<path
					d="M199.939 169.102L197.225 169.798L31.9082 171.723L34.623 171.024L199.939 169.102Z"
					fill="#1A2E7D"
				/>
				<path
					d="M29.2431 165.72L26.5283 166.419L25.1055 95.7773L27.8203 95.0781L29.2431 165.72Z"
					fill="#1A2E7D"
				/>
				<path
					d="M198.299 87.7266C199.713 87.7249 201.07 88.2812 202.077 89.2747C203.083 90.2683 203.658 91.6191 203.676 93.0342L205.102 163.676C205.124 164.373 205.007 165.067 204.759 165.719C204.51 166.37 204.135 166.965 203.655 167.47C203.174 167.975 202.599 168.38 201.961 168.66C201.324 168.94 200.637 169.091 199.94 169.103L34.6239 171.029C33.2105 171.031 31.8534 170.475 30.8464 169.482C29.8395 168.489 29.2637 167.139 29.2438 165.724L27.8209 95.0821C27.7992 94.3851 27.916 93.6907 28.1645 93.0393C28.4129 92.3878 28.7882 91.7922 29.2684 91.2871C29.7485 90.782 30.3242 90.3773 30.9618 90.0966C31.5994 89.816 32.2864 89.6649 32.9828 89.6521L198.299 87.7266Z"
					fill="#88BAD9"
				/>
				<path
					d="M34.6232 171.027L31.9084 171.724C30.4948 171.726 29.1373 171.17 28.1301 170.177C27.123 169.184 26.5475 167.834 26.5283 166.419L29.2431 165.723C29.263 167.137 29.8388 168.488 30.8458 169.48C31.8527 170.473 33.2099 171.029 34.6232 171.027Z"
					fill="#1A2E7D"
				/>
				<path
					d="M84.6502 102.777L81.9354 103.476L73.7939 103.57L76.5087 102.873L84.6502 102.777Z"
					fill="#F55273"
				/>
				<path
					d="M83.8841 111.559L81.1693 112.255L73.9678 112.339L76.6825 111.64L83.8841 111.559Z"
					fill="#F55273"
				/>
				<path
					d="M84.5626 98.3945L84.6499 102.776L76.5084 102.872L76.5986 107.416L83.7973 107.332L83.8846 111.559L76.683 111.64L76.7849 116.569L84.9263 116.476L85.0165 120.857L71.495 121.015L71.0439 98.5518L84.5626 98.3945Z"
					fill="white"
				/>
				<path
					d="M85.0166 120.855L82.3018 121.555L68.7803 121.712L71.4951 121.013L85.0166 120.855Z"
					fill="#F55273"
				/>
				<path
					d="M71.4949 121.014L68.7801 121.713L68.3291 99.247L71.0439 98.5508L71.4949 121.014Z"
					fill="#F55273"
				/>
				<path
					d="M59.6757 115.037L56.9609 115.734L59.8387 99.3447L62.5564 98.6484L59.6757 115.037Z"
					fill="#F55273"
				/>
				<path
					d="M63.0403 121.113L60.3255 121.81L53.8164 121.885L56.5312 121.189L63.0403 121.113Z"
					fill="#F55273"
				/>
				<path
					d="M56.5304 121.188L53.8156 121.885L49.998 107.142L52.7128 106.445L56.5304 121.188Z"
					fill="#F55273"
				/>
				<path
					d="M46.0644 115.195L43.3496 115.894L46.9519 99.4931L49.6666 98.7969L46.0644 115.195Z"
					fill="#F55273"
				/>
				<path
					d="M49.3645 121.273L46.6497 121.97L40.1436 122.045L42.8583 121.349L49.3645 121.273Z"
					fill="#F55273"
				/>
				<path
					d="M68.3414 98.582L63.0398 121.112L56.5308 121.188L52.7132 106.444L49.3641 121.272L42.8579 121.348L36.8086 98.9491L42.5611 98.8792L46.0645 115.195L49.6667 98.7976L55.5764 98.7306L59.6762 115.038L62.5568 98.649L68.3414 98.582Z"
					fill="white"
				/>
				<path
					d="M42.8579 121.348L40.1431 122.044L34.0938 99.6454L36.8085 98.9492L42.8579 121.348Z"
					fill="#F55273"
				/>
				<path
					d="M177.159 119.785L174.442 120.484L169.07 120.545L171.785 119.849L177.159 119.785Z"
					fill="#F55273"
				/>
				<path
					d="M171.785 119.852L169.07 120.548L168.8 107.075L171.515 106.379L171.785 119.852Z"
					fill="#F55273"
				/>
				<path
					d="M164.572 113.02L161.857 113.716L167.674 98.0908L170.389 97.3945L164.572 113.02Z"
					fill="#F55273"
				/>
				<path
					d="M166.847 119.906L164.132 120.605L159.794 120.655L162.509 119.959L166.847 119.906Z"
					fill="#F55273"
				/>
				<path
					d="M162.509 119.958L159.794 120.655L154.554 107.208L157.271 106.512L162.509 119.958Z"
					fill="#F55273"
				/>
				<path
					d="M157.542 120.016L154.827 120.712L149.453 120.773L152.168 120.077L157.542 120.016Z"
					fill="#F55273"
				/>
				<path
					d="M152.168 120.079L149.453 120.775L148.999 98.3124L151.714 97.6133L152.168 120.079Z"
					fill="#F55273"
				/>
				<path
					d="M176.706 97.3203L177.159 119.786L171.785 119.85L171.515 106.377L166.847 119.905L162.509 119.958L157.271 106.511L157.542 120.016L152.168 120.077L151.714 97.6116L158.066 97.5388L164.572 113.022L170.389 97.3961L176.706 97.3203Z"
					fill="white"
				/>
				<path
					d="M139.355 115.282L136.641 115.979C136.789 115.941 136.932 115.894 137.08 115.845L139.795 115.148C139.652 115.198 139.504 115.242 139.355 115.282Z"
					fill="#8A8A8A"
				/>
				<path
					d="M139.789 115.149L137.074 115.845C137.929 115.547 138.689 115.025 139.274 114.333L141.989 113.637C141.404 114.329 140.644 114.851 139.789 115.149Z"
					fill="#858585"
				/>
				<path
					d="M133.213 103.316L135.928 102.62C136.453 102.488 136.992 102.42 137.534 102.419C139.291 102.395 140.712 102.97 141.797 104.143C142.881 105.316 143.443 106.894 143.481 108.877C143.518 110.84 143.021 112.426 141.989 113.634L139.274 114.33C140.306 113.124 140.803 111.539 140.767 109.573C140.73 107.608 140.168 106.03 139.082 104.839C137.999 103.674 136.578 103.099 134.819 103.115C134.278 103.117 133.738 103.184 133.213 103.316Z"
					fill="#F55273"
				/>
				<path
					d="M141.989 113.634L139.274 114.33C140.306 113.124 140.803 111.539 140.767 109.573C140.73 107.608 140.168 106.03 139.082 104.839C137.999 103.674 136.578 103.099 134.819 103.115C134.278 103.117 133.738 103.184 133.213 103.316L135.928 102.62C136.453 102.488 136.992 102.42 137.534 102.419C139.291 102.395 140.712 102.97 141.797 104.143C142.881 105.316 143.443 106.894 143.481 108.877C143.518 110.84 143.021 112.426 141.989 113.634Z"
					fill="#F55273"
				/>
				<path
					d="M147.337 102.911C148.385 104.697 148.944 106.729 148.958 108.801C149.032 110.874 148.552 112.93 147.567 114.755C146.639 116.484 145.254 117.924 143.563 118.918C141.846 119.924 139.893 120.455 137.904 120.456C135.902 120.499 133.924 120.014 132.169 119.049C130.427 118.093 128.975 116.684 127.967 114.971C126.902 113.18 126.335 111.136 126.323 109.051C126.248 106.981 126.733 104.928 127.728 103.112C128.671 101.388 130.067 99.956 131.764 98.9693C133.486 97.9623 135.444 97.4305 137.438 97.4282C139.44 97.3866 141.418 97.8718 143.173 98.8353C144.907 99.7869 146.348 101.196 147.337 102.911ZM141.989 113.634C143.021 112.428 143.519 110.842 143.482 108.877C143.445 106.911 142.883 105.333 141.797 104.143C140.715 102.978 139.294 102.403 137.534 102.418C135.775 102.434 134.366 103.037 133.309 104.227C132.273 105.412 131.777 107.007 131.82 109.014C131.862 111.02 132.423 112.598 133.501 113.747C134.584 114.913 136.015 115.487 137.796 115.472C139.577 115.456 140.974 114.844 141.989 113.634Z"
					fill="white"
				/>
				<path
					d="M140.72 120.105L138.006 120.802C137.083 121.037 136.135 121.159 135.183 121.166C133.182 121.208 131.204 120.724 129.448 119.762C127.706 118.805 126.255 117.396 125.246 115.683C124.182 113.892 123.614 111.848 123.602 109.764C123.527 107.694 124.012 105.643 125.008 103.827C125.95 102.103 127.346 100.669 129.044 99.6817C129.934 99.1617 130.893 98.7695 131.892 98.5165L134.607 97.8203C133.608 98.0733 132.649 98.4655 131.758 98.9855C130.057 99.9746 128.66 101.411 127.717 103.14C126.722 104.956 126.236 107.009 126.311 109.079C126.323 111.164 126.891 113.207 127.955 114.999C128.963 116.712 130.415 118.121 132.157 119.077C133.912 120.042 135.89 120.527 137.892 120.484C138.847 120.472 139.796 120.344 140.72 120.105Z"
					fill="#F55273"
				/>
				<path
					d="M140.72 120.107L138.006 120.803C137.083 121.038 136.135 121.16 135.183 121.167C133.182 121.21 131.204 120.726 129.448 119.763C127.706 118.807 126.255 117.398 125.246 115.685C124.182 113.893 123.614 111.85 123.602 109.765C123.527 107.696 124.012 105.644 125.008 103.829C125.95 102.104 127.346 100.671 129.044 99.6832C129.667 99.3187 130.324 99.0144 131.005 98.7743L133.72 98.0781C133.038 98.3157 132.381 98.6202 131.758 98.987C130.057 99.976 128.66 101.413 127.717 103.141C126.722 104.958 126.236 107.01 126.311 109.081C126.323 111.165 126.891 113.209 127.955 115C128.963 116.714 130.415 118.123 132.157 119.079C133.912 120.043 135.89 120.528 137.892 120.486C138.844 120.477 139.792 120.355 140.715 120.121"
					fill="#F55273"
				/>
				<path
					d="M133.72 98.0883L131.005 98.7845C131.296 98.6855 131.587 98.5952 131.892 98.5165L134.607 97.8203C134.316 97.899 134.011 97.9863 133.72 98.0883Z"
					fill="#F55273"
				/>
				<path
					d="M123.856 105.551L121.141 106.25L115.229 106.317L117.943 105.621L123.856 105.551Z"
					fill="#F55273"
				/>
				<path
					d="M109.133 103.672L111.848 102.972C112.312 102.857 112.789 102.797 113.268 102.795C114.236 102.769 115.193 103.012 116.032 103.497C116.866 103.998 117.532 104.738 117.943 105.62L115.229 106.317C114.818 105.434 114.152 104.696 113.317 104.196C112.478 103.709 111.522 103.465 110.553 103.491C110.074 103.494 109.597 103.555 109.133 103.672Z"
					fill="#F55273"
				/>
				<path
					d="M117.943 105.62L115.229 106.317C114.818 105.434 114.152 104.696 113.317 104.196C112.478 103.709 111.522 103.465 110.553 103.491C110.074 103.494 109.597 103.555 109.133 103.672L111.848 102.972C112.312 102.857 112.789 102.797 113.268 102.795C114.236 102.769 115.193 103.012 116.032 103.497C116.866 103.998 117.532 104.738 117.943 105.62Z"
					fill="#F55273"
				/>
				<path
					d="M114.873 115.555L112.158 116.251C112.648 116.129 113.116 115.933 113.546 115.668C114.361 115.15 114.999 114.395 115.373 113.504L118.088 112.805C117.714 113.697 117.077 114.453 116.261 114.972C115.831 115.237 115.363 115.433 114.873 115.555Z"
					fill="#F55273"
				/>
				<path
					d="M114.873 115.552L112.158 116.248C112.296 116.213 112.43 116.173 112.56 116.126L115.275 115.43C115.145 115.476 115.011 115.517 114.873 115.552Z"
					fill="#F55273"
				/>
				<path
					d="M115.274 115.431L112.56 116.127C112.906 116.009 113.237 115.849 113.546 115.653C114.361 115.134 114.999 114.379 115.373 113.488L118.088 112.789C117.714 113.681 117.077 114.438 116.261 114.956C115.951 115.152 115.62 115.311 115.274 115.431Z"
					fill="#F55273"
				/>
				<path
					d="M113.226 97.8017C115.845 97.7725 118.103 98.4523 120 99.8408C121.904 101.246 123.265 103.266 123.855 105.559L117.943 105.626C117.531 104.744 116.865 104.004 116.031 103.503C115.192 103.018 114.235 102.775 113.267 102.801C112.509 102.783 111.757 102.936 111.066 103.249C110.375 103.562 109.764 104.027 109.278 104.61C108.275 105.796 107.79 107.369 107.823 109.329C107.856 111.288 108.401 112.852 109.458 114.019C109.968 114.589 110.598 115.038 111.302 115.335C112.007 115.631 112.768 115.766 113.532 115.732C114.497 115.737 115.445 115.471 116.267 114.963C117.083 114.444 117.72 113.688 118.094 112.795L124.007 112.728C123.515 115.024 122.231 117.073 120.381 118.517C118.542 119.936 116.311 120.662 113.689 120.693C111.679 120.742 109.692 120.258 107.93 119.289C106.241 118.357 104.846 116.97 103.903 115.286C102.901 113.479 102.373 111.448 102.367 109.381C102.29 107.319 102.736 105.272 103.665 103.43C104.535 101.719 105.874 100.293 107.526 99.3194C109.258 98.3153 111.225 97.7914 113.226 97.8017Z"
					fill="white"
				/>
				<path
					d="M116.66 120.311L113.945 121.008C112.971 121.252 111.97 121.378 110.966 121.383C108.954 121.439 106.964 120.959 105.199 119.991C103.509 119.059 102.114 117.673 101.172 115.988C100.17 114.182 99.6416 112.152 99.6353 110.086C99.5635 108.025 100.015 105.98 100.948 104.141C101.817 102.431 103.157 101.004 104.809 100.03C105.676 99.517 106.611 99.1305 107.588 98.8827L110.302 98.1836C109.325 98.4369 108.389 98.8293 107.524 99.3488C105.872 100.323 104.532 101.749 103.662 103.459C102.734 105.301 102.287 107.349 102.365 109.411C102.37 111.477 102.899 113.509 103.901 115.315C104.849 116.99 106.244 118.366 107.931 119.289C109.696 120.256 111.687 120.736 113.698 120.681C114.697 120.677 115.691 120.553 116.66 120.311Z"
					fill="#F55273"
				/>
				<path
					d="M116.66 120.312L113.945 121.008C112.971 121.253 111.971 121.379 110.966 121.384C108.954 121.44 106.964 120.959 105.199 119.991C103.509 119.06 102.114 117.673 101.172 115.989C100.17 114.183 99.6416 112.153 99.6353 110.087C99.5635 108.026 100.015 105.98 100.948 104.141C101.817 102.431 103.157 101.005 104.809 100.031C105.413 99.678 106.05 99.3853 106.712 99.1572L109.427 98.4609C108.765 98.6891 108.128 98.9818 107.524 99.3349C105.872 100.309 104.532 101.735 103.662 103.445C102.734 105.287 102.287 107.335 102.365 109.397C102.37 111.463 102.899 113.495 103.901 115.301C104.847 116.981 106.243 118.362 107.931 119.289C109.696 120.256 111.687 120.737 113.698 120.682C114.703 120.677 115.703 120.551 116.678 120.306"
					fill="#F55273"
				/>
				<path
					d="M109.421 98.4409L106.706 99.1371C106.997 99.038 107.288 98.9507 107.579 98.8749L110.294 98.1758C110.003 98.2544 109.712 98.3418 109.421 98.4409Z"
					fill="#F55273"
				/>
				<path
					d="M93.426 98.2891L93.7956 116.531L100.837 116.449L100.922 120.673L88.5028 120.819L88.0518 98.3532L93.426 98.2891Z"
					fill="white"
				/>
				<path
					d="M100.922 120.672L98.2068 121.368L85.7881 121.514L88.5029 120.818L100.922 120.672Z"
					fill="#F55273"
				/>
				<path
					d="M88.5027 120.817L85.7879 121.513L85.3369 99.0478L88.0517 98.3516L88.5027 120.817Z"
					fill="#F55273"
				/>
				<path
					d="M194.178 101.5L191.464 102.196L183.322 102.289L186.037 101.593L194.178 101.5Z"
					fill="#F55273"
				/>
				<path
					d="M193.415 110.281L190.698 110.977L183.499 111.062L186.214 110.366L193.415 110.281Z"
					fill="#F55273"
				/>
				<path
					d="M194.091 97.1172L194.178 101.501L186.037 101.595L186.13 106.139L193.329 106.055L193.416 110.281L186.214 110.366L186.313 115.295L194.455 115.199L194.545 119.583L181.026 119.74L180.572 97.2745L194.091 97.1172Z"
					fill="white"
				/>
				<path
					d="M194.545 119.582L191.827 120.278L178.312 120.436L181.026 119.739L194.545 119.582Z"
					fill="#F55273"
				/>
				<path
					d="M181.026 119.739L178.311 120.435L177.857 97.9697L180.572 97.2734L181.026 119.739Z"
					fill="#F55273"
				/>
				<path
					d="M138.37 143.801L135.652 144.497L129.742 144.564L132.457 143.868L138.37 143.801Z"
					fill="#F55273"
				/>
				<path
					d="M123.644 141.918L126.358 141.221C126.824 141.104 127.301 141.044 127.781 141.041C128.75 141.015 129.707 141.259 130.545 141.746C131.379 142.248 132.044 142.987 132.457 143.869L129.742 144.566C129.33 143.684 128.664 142.944 127.831 142.442C126.991 141.955 126.033 141.712 125.064 141.74C124.585 141.742 124.108 141.802 123.644 141.918Z"
					fill="#F55273"
				/>
				<path
					d="M132.457 143.869L129.742 144.566C129.33 143.684 128.664 142.944 127.831 142.442C126.991 141.955 126.033 141.712 125.064 141.74C124.585 141.742 124.108 141.802 123.644 141.918L126.358 141.221C126.824 141.104 127.301 141.044 127.781 141.041C128.75 141.015 129.707 141.259 130.545 141.746C131.379 142.248 132.044 142.987 132.457 143.869Z"
					fill="#F55273"
				/>
				<path
					d="M129.381 153.802L126.666 154.501C127.157 154.379 127.627 154.182 128.06 153.918C128.874 153.398 129.511 152.642 129.887 151.751L132.602 151.055C132.225 151.945 131.588 152.7 130.775 153.222C130.342 153.485 129.872 153.681 129.381 153.802Z"
					fill="#F55273"
				/>
				<path
					d="M129.381 153.802L126.666 154.501C126.803 154.463 126.94 154.423 127.07 154.379L129.785 153.68C129.654 153.726 129.518 153.767 129.381 153.802Z"
					fill="#F55273"
				/>
				<path
					d="M129.786 153.678L127.071 154.377C127.418 154.257 127.75 154.097 128.061 153.903C128.875 153.382 129.512 152.626 129.888 151.735L132.603 151.039C132.226 151.929 131.589 152.685 130.775 153.206C130.465 153.401 130.133 153.56 129.786 153.678Z"
					fill="#F55273"
				/>
				<path
					d="M127.74 136.048C130.361 136.017 132.618 136.697 134.511 138.087C136.417 139.491 137.78 141.511 138.369 143.805L132.457 143.875C132.044 142.993 131.378 142.254 130.545 141.752C129.706 141.265 128.75 141.021 127.781 141.047C127.023 141.029 126.27 141.183 125.58 141.495C124.889 141.808 124.277 142.272 123.789 142.853C122.788 144.039 122.303 145.614 122.334 147.578C122.365 149.541 122.91 151.104 123.969 152.265C124.478 152.836 125.108 153.286 125.813 153.582C126.517 153.878 127.279 154.014 128.043 153.978C129.008 153.983 129.955 153.718 130.778 153.212C131.591 152.69 132.228 151.935 132.605 151.044L138.515 150.975C138.026 153.271 136.743 155.321 134.892 156.763C133.053 158.182 130.822 158.907 128.2 158.936C126.189 158.993 124.199 158.514 122.433 157.549C120.744 156.614 119.35 155.226 118.406 153.541C117.404 151.734 116.876 149.703 116.872 147.636C116.793 145.574 117.239 143.525 118.167 141.682C119.038 139.971 120.378 138.545 122.031 137.571C123.764 136.561 125.735 136.035 127.74 136.048Z"
					fill="white"
				/>
				<path
					d="M131.185 158.554L128.47 159.25C127.495 159.495 126.493 159.622 125.488 159.629C123.477 159.683 121.487 159.204 119.721 158.239C118.034 157.308 116.64 155.925 115.694 154.246C114.692 152.439 114.164 150.407 114.16 148.341C114.082 146.279 114.527 144.231 115.455 142.389C116.324 140.678 117.665 139.251 119.319 138.279C119.924 137.918 120.563 137.619 121.228 137.388L123.943 136.691C123.279 136.918 122.64 137.211 122.034 137.565C120.381 138.539 119.041 139.965 118.17 141.676C117.242 143.519 116.796 145.568 116.875 147.63C116.879 149.696 117.407 151.728 118.408 153.535C119.354 155.217 120.748 156.603 122.436 157.537C124.201 158.502 126.192 158.981 128.203 158.924C129.208 158.919 130.21 158.793 131.185 158.548"
					fill="#F55273"
				/>
				<path
					d="M123.934 136.684L121.22 137.38C121.505 137.281 121.796 137.194 122.093 137.118L124.807 136.422C124.511 136.498 124.22 136.585 123.934 136.684Z"
					fill="#8A8A8A"
				/>
				<path
					d="M115.592 158.918L112.877 159.614L107.188 159.681L109.903 158.982L115.592 158.918Z"
					fill="#F55273"
				/>
				<path
					d="M109.903 158.981L107.189 159.681L105.786 155.727L108.501 155.031L109.903 158.981Z"
					fill="#F55273"
				/>
				<path
					d="M107.035 150.823L104.32 151.52L101.416 143.325L104.131 142.629L107.035 150.823Z"
					fill="#F55273"
				/>
				<path
					d="M108.501 155.031L105.786 155.728L97.5488 155.824L100.264 155.127L108.501 155.031Z"
					fill="#F55273"
				/>
				<path
					d="M107.154 136.543L115.592 158.918L109.903 158.985L108.513 155.032L100.275 155.128L99.0356 159.111L93.4082 159.178L100.942 136.616L107.154 136.543ZM101.593 150.887L107.034 150.823L104.125 142.628L101.587 150.887"
					fill="white"
				/>
				<path
					d="M99.0238 159.113L96.3091 159.81L90.6816 159.877L93.3964 159.177L99.0238 159.113Z"
					fill="#F55273"
				/>
				<path
					d="M93.3964 159.176L90.6816 159.875L98.2149 137.313L100.93 136.617L93.3964 159.176Z"
					fill="#F55273"
				/>
				<path
					d="M83.7794 145.797L81.0646 146.493C81.4277 146.41 81.7658 146.241 82.051 146.001C82.2887 145.78 82.4728 145.508 82.5893 145.205C82.7058 144.902 82.7516 144.577 82.7231 144.253C82.7368 143.925 82.677 143.597 82.5481 143.295C82.4192 142.993 82.2245 142.723 81.9782 142.505C81.501 142.103 80.8143 141.923 79.9414 141.923L82.6562 141.227C83.5291 141.227 84.2187 141.41 84.693 141.809C84.9395 142.026 85.1343 142.296 85.2632 142.599C85.392 142.901 85.4518 143.229 85.4379 143.557C85.467 143.88 85.4215 144.206 85.305 144.509C85.1885 144.812 85.004 145.084 84.7658 145.305C84.4798 145.544 84.142 145.712 83.7794 145.797Z"
					fill="#F55273"
				/>
				<path
					d="M83.7802 145.799L81.0654 146.496C81.1474 146.477 81.2281 146.452 81.3069 146.423L84.0217 145.727C83.9461 145.753 83.8646 145.779 83.7802 145.799Z"
					fill="#F55273"
				/>
				<path
					d="M84.0248 145.724L81.31 146.421C81.5834 146.331 81.8366 146.189 82.0549 146.001C82.2926 145.78 82.4767 145.508 82.5932 145.205C82.7097 144.902 82.7555 144.577 82.727 144.253C82.7407 143.925 82.6809 143.597 82.552 143.295C82.4231 142.993 82.2284 142.723 81.9821 142.505C81.5049 142.103 80.8182 141.923 79.9453 141.923L82.6601 141.227C83.533 141.227 84.2226 141.41 84.6969 141.809C84.9434 142.026 85.1382 142.296 85.2671 142.599C85.3959 142.901 85.4556 143.229 85.4418 143.557C85.4709 143.88 85.4254 144.206 85.3089 144.509C85.1924 144.812 85.0079 145.085 84.7696 145.305C84.5506 145.491 84.2977 145.634 84.0248 145.724Z"
					fill="#F55273"
				/>
				<path
					d="M85.4533 154.229L82.7385 154.925C82.9853 154.696 83.1766 154.413 83.2981 154.099C83.4196 153.785 83.468 153.447 83.4398 153.111C83.45 152.762 83.3831 152.415 83.2437 152.095C83.1044 151.776 82.8961 151.491 82.6338 151.261C82.0233 150.792 81.2629 150.562 80.4951 150.614L83.2099 149.918C83.9779 149.864 84.7389 150.094 85.3486 150.565C85.6107 150.794 85.8188 151.078 85.9582 151.398C86.0975 151.717 86.1646 152.063 86.1546 152.411C86.1831 152.748 86.1347 153.086 86.0132 153.401C85.8917 153.716 85.7003 153.999 85.4533 154.229Z"
					fill="#F55273"
				/>
				<path
					d="M85.4533 154.229L82.7385 154.925C82.9853 154.696 83.1766 154.413 83.2981 154.099C83.4196 153.785 83.468 153.447 83.4398 153.111C83.45 152.762 83.3831 152.415 83.2437 152.095C83.1044 151.776 82.8961 151.491 82.6338 151.261C82.0233 150.792 81.2629 150.562 80.4951 150.614L83.2099 149.918C83.9779 149.864 84.7389 150.094 85.3486 150.565C85.6107 150.794 85.8188 151.078 85.9582 151.398C86.0975 151.717 86.1646 152.063 86.1546 152.411C86.1831 152.748 86.1347 153.086 86.0132 153.401C85.8917 153.716 85.7003 153.999 85.4533 154.229Z"
					fill="#F55273"
				/>
				<path
					d="M84.4081 154.755L81.6934 155.452C81.7807 155.428 81.8621 155.405 81.9436 155.376L84.6584 154.68C84.5769 154.709 84.4954 154.732 84.4081 154.755Z"
					fill="#8A8A8A"
				/>
				<path
					d="M84.6523 154.679L81.9375 155.375C82.2285 155.278 82.4981 155.126 82.7319 154.927L85.4466 154.23C85.2129 154.429 84.9432 154.582 84.6523 154.679Z"
					fill="#858585"
				/>
				<path
					d="M87.024 158.965L84.3092 159.664C83.452 159.874 82.5729 159.98 81.6904 159.982L84.4052 159.285C85.2878 159.284 86.1669 159.176 87.024 158.965Z"
					fill="#858585"
				/>
				<path
					d="M82.6564 141.215L79.9417 141.911L76.4209 141.952L79.1357 141.256L82.6564 141.215Z"
					fill="#F55273"
				/>
				<path
					d="M83.2095 149.914L80.4948 150.61L76.5986 150.657L79.3134 149.961L83.2095 149.914Z"
					fill="#F55273"
				/>
				<path
					d="M74.1257 159.399L71.411 160.098L70.957 137.63L73.6718 136.934L74.1257 159.399Z"
					fill="#F55273"
				/>
				<path
					d="M84.405 159.281L81.6902 159.977L71.4102 160.1L74.1249 159.401L84.405 159.281Z"
					fill="#F55273"
				/>
				<path
					d="M90.3738 149.625C91.1615 150.605 91.5962 151.822 91.6075 153.08C91.6443 154.958 91.0304 156.454 89.7656 157.569C88.5009 158.684 86.7143 159.254 84.4059 159.279L74.1258 159.399L73.6719 136.93L83.6057 136.814C85.8501 136.786 87.6163 137.289 88.9043 138.322C90.1924 139.356 90.8548 140.768 90.8917 142.558C90.9621 143.737 90.6251 144.905 89.9373 145.864C89.2642 146.754 88.3239 147.405 87.2545 147.72C88.4911 147.954 89.6001 148.632 90.3738 149.625ZM85.4534 154.228C85.7003 153.998 85.8918 153.715 86.0133 153.4C86.1348 153.085 86.1831 152.747 86.1546 152.41C86.1646 152.062 86.0976 151.716 85.9582 151.396C85.8189 151.077 85.6107 150.793 85.3486 150.563C84.7389 150.093 83.9779 149.863 83.21 149.917L79.3138 149.96L79.4128 154.912L83.3729 154.866C84.275 154.866 84.9675 154.636 85.4534 154.216V154.228ZM79.1247 141.256L79.2207 145.961L82.7415 145.92C83.6144 145.92 84.2953 145.704 84.758 145.302C84.9962 145.082 85.1807 144.81 85.2972 144.507C85.4137 144.204 85.4592 143.878 85.4301 143.554C85.444 143.226 85.3843 142.898 85.2554 142.596C85.1265 142.294 84.9317 142.024 84.6852 141.807C84.208 141.405 83.5213 141.224 82.6484 141.224L79.1276 141.265"
					fill="white"
				/>
				<path
					d="M161.333 158.387L158.618 159.083L152.048 159.159L154.763 158.462L161.333 158.387Z"
					fill="#F55273"
				/>
				<path
					d="M147.09 145.941L144.375 146.638L151.533 136.692L154.248 135.996L147.09 145.941Z"
					fill="#F55273"
				/>
				<path
					d="M154.763 158.463L152.048 159.16L144.428 149.325L147.143 148.629L154.763 158.463Z"
					fill="#F55273"
				/>
				<path
					d="M160.568 135.922L152.24 147.032L161.333 158.385L154.763 158.46L147.142 148.626L147.343 158.548L141.969 158.609L141.515 136.143L146.889 136.079L147.09 145.94L154.248 135.995L160.568 135.922Z"
					fill="white"
				/>
				<path
					d="M147.343 158.551L144.628 159.247L139.254 159.311L141.969 158.612L147.343 158.551Z"
					fill="#F55273"
				/>
				<path
					d="M141.969 158.61L139.254 159.309L138.8 136.841L141.515 136.145L141.969 158.61Z"
					fill="#F55273"
				/>
				<path
					d="M119.058 133.526L128.217 128.723L133.921 129.733L124.755 134.54L119.058 133.526Z"
					fill="#FB113F"
				/>
				<path
					d="M55.6104 192.48L73.2288 195.807L71.8059 207.623L54.1875 204.296L55.6104 192.48Z"
					fill="#39B54A"
				/>
				<path d="M31.6318 93.0508H38.1933L38.2457 96.8291L31.69 96.832L31.6318 93.0508Z" fill="#90C4E5" />
				<path
					d="M121.723 99.4605L120.911 102.379L119.809 102.301L120.623 99.3789L121.723 99.4605Z"
					fill="#F55273"
				/>
				<path
					d="M14.3737 172.962L27.4763 172.449L20.7431 181.445L7.6377 181.955L14.3737 172.962Z"
					fill="#F55273"
				/>
				<path
					d="M151.341 75.3094L146.945 80.6723L140.878 79.4343L145.275 74.0742L151.341 75.3094Z"
					fill="#39B54A"
				/>
				<path
					d="M208.913 108.711L195.834 93.4961L200.434 95.2148L213.513 110.43L208.913 108.711Z"
					fill="#FB113F"
				/>
				<path
					d="M202.509 144.363L224 141.715L216.173 147.273L194.679 149.924L202.509 144.363Z"
					fill="#1A2E7D"
				/>
				<path
					d="M52.9219 79.8072L67.2989 74.0859L67.392 92.1121L53.0179 97.8363L52.9219 79.8072Z"
					fill="#FFAD29"
				/>
				<path
					d="M41.6732 67.8817L37.1689 66.5359L38.9963 56.7305L43.5034 58.0734L41.6732 67.8817Z"
					fill="#FB113F"
				/>
				<path
					d="M172.416 58.6862L169.632 51.5608L172.3 44.418L175.085 51.5463L172.416 58.6862Z"
					fill="#FFAD29"
				/>
				<path
					d="M164.808 138.354L178.405 129.527L180.832 133.909L167.234 142.741L164.808 138.354Z"
					fill="#FFAD29"
				/>
				<path
					d="M69.6152 35.7656L87.4141 36.6104L91.6477 39.5439L73.8489 38.6991L69.6152 35.7656Z"
					fill="#FFAD29"
				/>
				<path
					d="M148.464 15.2092L143.401 20.6829L134.604 9.42094L139.667 3.94727L148.464 15.2092Z"
					fill="#FB113F"
				/>
				<path
					d="M152.802 56.6216L152.875 54.0115L159.747 50.5508L159.675 53.1609L152.802 56.6216Z"
					fill="#1A2E7D"
				/>
				<path
					d="M191.653 76.4922L195.688 87.9056L186.901 90.0817L182.865 78.6712L191.653 76.4922Z"
					fill="#90C4E5"
				/>
				<path
					d="M60.9592 198.185L54.8633 175.093L58.8671 173.922L64.963 197.014L60.9592 198.185Z"
					fill="#FFAD29"
				/>
				<path d="M2.64495 174.098L9.06964 175.811L6.42469 176.615L0 174.902L2.64495 174.098Z" fill="#F55273" />
				<path
					d="M186.447 138.823L195.456 134.316L212.772 138.345L203.763 142.849L186.447 138.823Z"
					fill="#FB113F"
				/>
				<path
					d="M144.238 210.841L136.347 229.004L121.699 228.972L129.59 210.809L144.238 210.841Z"
					fill="#39B54A"
				/>
				<path
					d="M113.822 10.5322C116.326 10.5322 118.356 8.50024 118.356 5.99365C118.356 3.48707 116.326 1.45508 113.822 1.45508C111.319 1.45508 109.289 3.48707 109.289 5.99365C109.289 8.50024 111.319 10.5322 113.822 10.5322Z"
					fill="#FB113F"
				/>
				<path
					d="M114.309 9.93848C116.812 9.93848 118.842 7.90649 118.842 5.3999C118.842 2.89332 116.812 0.861328 114.309 0.861328C111.805 0.861328 109.775 2.89332 109.775 5.3999C109.775 7.90649 111.805 9.93848 114.309 9.93848Z"
					fill="#F55273"
				/>
			</svg>
		</div>
	);
};
