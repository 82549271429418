import config from '../config/config';

const api = config.API.url;
let DATA = {};

fetch(api + 'app/assets/images', {
	method: 'GET',
})
	.then((response) => response.json())
	.then((response) => {
		const density = Math.round(window.devicePixelRatio);
		const { scholarship } = response.data.Scholarshipsearch.web;
		if (scholarship === undefined) return;
		for (const [key, _value] of Object.entries(scholarship)) {
			let url = scholarship[key][density + 'x'];
			if (url == undefined) {
				url = scholarship[key]['1x'];
			}
			DATA[key] = url;
		}
	})
	.catch((err) => console.log(err));

export default DATA;
