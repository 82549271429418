import React, { useState, useContext, useEffect } from 'react';
import { Button } from '@scholly/elements';
import { SubHeader } from '../../components/layouts/SubHeader';

import styles from './refund.scss';
import { UserContext } from '../../contexts';
import { useHistory } from 'react-router-dom';
import { Routes, ScreenStrings } from '../../constants';
import RefundPageLoader from './refundPageLoader';

import { refundStatusPageHit } from '../../actions';

const Refund = () => {
	const user = useContext(UserContext);
	const history = useHistory();

	const userTransaction = user?.merchant?.transactions?.[0];

	const [isLoading, setIsLoading] = useState<boolean | null>(null);
	const [contentHeader, setContentHeader] = useState<string>(
		ScreenStrings.refund.contentHeader.eligible(userTransaction?.amount)
	);
	const [contentBody, setContentBody] = useState<string>('');

	const onViewScholarship = () => {
		history.push(Routes.scholarships.feed);
	};

	const onUpdateProfile = () => {
		history.push(Routes.user.myProfile);
	};

	const onFrequentlyAskedQuesitons = () => {
		window.open('https://myscholly.com/faqs/');
	};

	useEffect(() => {
		setIsLoading(user.user_id === null);
	}, [user]);

	const transactionStatus = userTransaction?.status;

	const seenRefundScreen = transactionStatus === 'succeeded';
	useEffect(() => {
		const refundAmount = userTransaction?.amount;
		const userIsEligibleForRefund = Number(refundAmount) > 0;
		const refundIsComplete = userTransaction?.refunded === 1;
		const userEmail = user.user_email;

		const refundPageHit = async () => {
			try {
				await refundStatusPageHit();
			} catch (error) {
				console.log(error);
			}
		};

		if (userIsEligibleForRefund && transactionStatus === 'pending') {
			refundPageHit();
		}

		if (userIsEligibleForRefund) {
			if (!seenRefundScreen) {
				setContentHeader(ScreenStrings.refund.contentHeader.eligible(refundAmount));
				setContentBody(ScreenStrings.refund.content.allow60Days);
			} else {
				setContentHeader(
					refundIsComplete
						? ScreenStrings.refund.contentHeader.completed
						: ScreenStrings.refund.contentHeader.inProgress
				);
				setContentBody(
					refundIsComplete
						? ScreenStrings.refund.content.completed(userEmail)
						: ScreenStrings.refund.content.allow60Days
				);
			}
		} else {
			setContentHeader(ScreenStrings.refund.contentHeader.notEligible);
			setContentBody(ScreenStrings.refund.content.notEligible);
		}
	}, [user]);

	return (
		<React.Fragment>
			<div>
				<SubHeader title={ScreenStrings.refund.subHeader} hideBackButton />
				{isLoading ? (
					<RefundPageLoader />
				) : (
					<div className={styles.refundContentContainer}>
						<h2 className={styles.refundContentHeader}>{contentHeader}</h2>
						<div>
							<p>{contentBody}</p>

							<br />
							<p>
								If you have any questions, don't hesitate to contact us at{' '}
								<a href="mailto:info@myscholly.com">info@myscholly.com</a>.
							</p>
						</div>
						<div className={styles.refundCtaContainer}>
							<Button variant="primary" onClick={onViewScholarship}>
								View Scholarships
							</Button>
							<Button variant="tetiary" onClick={onUpdateProfile}>
								Update Profile
							</Button>
							<Button variant="tetiary" onClick={onFrequentlyAskedQuesitons}>
								Frequently Asked Questions
							</Button>
						</div>
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default Refund;
