import * as React from 'react';

import { ParameterValueType } from '@scholly/scholly-types';

import Select from 'react-select';
import { ScreenStrings } from '../../constants';

import customStyles from './lib/CustomStyles';
import styles from './profile.scss';
import { getParameterValueToSelect } from '../../helpers/Functions';
import { UserFormParamsType } from '../../types';

type OUserBasicInfoType = Pick<UserFormParamsType, 'gpa'>;

type IProps = OUserBasicInfoType & {
	onChangeText?: (property: keyof OUserBasicInfoType) => (value: string) => void;
	parameter_values: ParameterValueType[];
	styles?: object;
	hasError?: boolean;
	onChangeTextOnboarding?: (value: string) => void;
	noLabel?: boolean;
	notBottom?: boolean;
};

const ProfileGPA: React.FC<IProps> = (props) => {
	const gpa = props.parameter_values;

	const _onChangeVal =
		(property: 'gpa') =>
		({ value }) => {
			props.onChangeText(property)(value);
		};
	return (
		<div className={styles.profileWrapper}>
			<h3>{props.noLabel ? '' : ScreenStrings.profile.withGPA}</h3>
			<div className={styles.profileContainer}>
				<Select
					//@ts-ignore
					testID={`form_input_goal`}
					hasError={props.hasError}
					styles={customStyles(props)}
					placeholder={ScreenStrings.profile.gpa}
					value={getParameterValueToSelect(props.gpa, gpa)}
					options={gpa}
					onChange={
						props.onChangeTextOnboarding ? (e) => props.onChangeTextOnboarding(e) : _onChangeVal('gpa')
					}
					bottom={!!!props.notBottom}
					getOptionLabel={(options) => options.value}
					getOptionValue={(options) => options.id}
				/>
			</div>
		</div>
	);
};
export default ProfileGPA;
