import * as React from 'react';

import { ParameterValueType } from '@scholly/scholly-types';

import Select from 'react-select';
import { ScreenStrings } from '../../constants';
import customStyles from './lib/CustomStyles';
import styles from './profile.scss';
import { getParameterValueToSelect } from '../../helpers/Functions';
import { UserFormParamsType } from '../../types';

type OUserBasicInfoType = Pick<UserFormParamsType, 'grade'>;

type IProps = OUserBasicInfoType & {
	onChangeText?: (property: keyof OUserBasicInfoType) => (value: string) => void;
	parameter_values: ParameterValueType[];
	styles?: object;
	hasError?: boolean;
	onChangeTextOnboarding?: (value: string) => void;
	noLabel?: boolean;
};

export default class ProfileGradeLevel extends React.Component<IProps> {
	static defaultProps = {
		styles: {},
	};

	render() {
		const grade = this.props.parameter_values;
		return (
			<div className={styles.profileWrapper}>
				<h3>{this.props.noLabel ? '' : ScreenStrings.profile.currently}</h3>
				<div className={styles.profileContainer}>
					<Select
						//@ts-ignore
						testID={`form_input_grade_level`}
						hasError={this.props.hasError}
						styles={customStyles(this.props)}
						value={getParameterValueToSelect(this.props.grade, grade)}
						placeholder={ScreenStrings.profile.schoolYear}
						options={grade}
						onChange={this.props.onChangeText('grade')}
						getOptionLabel={(options) => options.value}
						getOptionValue={(options) => options.id}
					/>
				</div>
			</div>
		);
	}
}
