// @ts-nocheck
import React from 'react';
import { PortalWithState } from 'react-portal';
import { useHistory } from 'react-router-dom';

import { SessionContext } from '../../contexts';
import { Hamburger, ProfileMenu, ProfileMenuItem } from '@scholly/elements';

import config from '../../config/config';
import styles from './hamburger-menu.scss';
import { useDisableBodyScroll } from '../../helpers/hooks/useScrollBlock';
import { Status } from '../../constants';

interface HamburgerProps {}
const HamburgerMenu = ({}: HamburgerProps) => {
	const history = useHistory();
	const [menu, setMenu] = React.useState(false);
	const { endSession, isAuthenticated } = React.useContext(SessionContext);
	const isUserExist = isAuthenticated === Status.RESOLVED;

	useDisableBodyScroll(menu);

	const actionClick = (value: string) => () => {
		const regex = '^https?://';
		if (!value.match(regex)) history.push(value);
		else window.open(value, '_blank');
		setMenu(!menu);
	};
	const handleClick = () => {
		setMenu(!menu);
	};

	return (
		<PortalWithState onOpen={handleClick} onClose={handleClick}>
			{({ openPortal, isOpen, portal, closePortal }) => (
				<div className={`${styles.hamburgerWrapper} mt-1`}>
					<div className={styles.hamburgerContainer}>
						<Hamburger
							clickHandler={() => {
								handleClick();
								openPortal();
							}}
						/>
					</div>

					{menu &&
						isOpen &&
						portal(
							<div className={styles.authenticationMenuWrapper}>
								<ProfileMenu>
									<ProfileMenuItem clickHandler={actionClick(config.offers_url)} text={'Offers'} />
									<ProfileMenuItem
										clickHandler={actionClick(config.exclusives_url)}
										text={'Exclusives'}
									/>
									<ProfileMenuItem
										clickHandler={actionClick(config.partner_with_us_url)}
										text={'Partner With Us'}
									/>
									<ProfileMenuItem clickHandler={actionClick(config.faq_url)} text={'FAQs'} />
									<ProfileMenuItem
										clickHandler={actionClick(config.testimonials_url)}
										text={'Testimonials'}
									/>
									<ProfileMenuItem clickHandler={actionClick(config.blog)} text={'Blog'} />
									{isUserExist && (
										<ProfileMenuItem clickHandler={endSession} text={'Logout'} icon={'logout'} />
									)}
								</ProfileMenu>
							</div>
						)}
				</div>
			)}
		</PortalWithState>
	);
};

export default HamburgerMenu;
