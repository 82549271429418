export enum STORAGE_KEYS {
	TOKEN = 'token',
	USER = 'user',
	COMPLETED = 'completed_flow',
	FEATURED_CAMPAIGN_ID = 'featuredCampaignId',
	FEATURED_CAMPAIGN = 'featuredCampaign',
	SCHOLARSHIP_TOTAL = 'scholarshipTotal',
	OFFER_PROMO = 'OFFER_PROMO',
	OFFER_MOBILE_BANNER = 'OFFER_MOBILE_BANNER',
	FEATURE_FLAGS = 'feature_flags',
	CATEGORIES = 'categories',
	FREE_ACCOUNT_ACTIVATED = 'free_account_activated',
	REFUND_SCREEN = 'refund_screen_seen',
	UPDATE_PROFILE = 'update_profile',
}
