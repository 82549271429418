import _ from 'lodash';
import { commafy, missingReq, parseUserParamsData } from '../helpers/Functions';
import { Status } from '../constants';
import { ActionMap } from '../types';
import { setUser } from '../actions';

export enum UserDispatchTypes {
	ON_CHANGE_TEXT = 'ON_CHANGE_TEXT',
	OnChangeDate = 'ON_CHANGE_DATE',
	SET_STATUS = 'SET_STATUS',
	SET_USER = 'SET_USER',
	SET_MISSING = 'SET_MISSING',
	CLEARSTATE = 'CLEAR_STATE',
}

export interface ProfileFieldsType {
	full_name: string;
	first_name: string;
	last_name: string;
	birthday: string;
	phone_number: string;
	[key: string]: any;
}

interface ProfileFieldTouched {
	[key: string]: boolean;
}

export interface ProfileState extends ProfileFieldsType {
	user_id: string | null;
	user_email: string;
	hasError: string[];
	profileStatus: Status;
	missingReq: boolean;
	userRefer: boolean;
	touched?: ProfileFieldTouched;
}

type ProfilePayload = {
	[UserDispatchTypes.ON_CHANGE_TEXT]: {
		[key: string]: any;
	};
	[UserDispatchTypes.SET_USER]: {
		user: any;
	};
	[UserDispatchTypes.SET_STATUS]: Status;
	[UserDispatchTypes.SET_MISSING]: string[];
	[UserDispatchTypes.CLEARSTATE]: ProfileState;
};
// type StatusActionPayload = {
// 	[UserDispatchTypes.Error]: { status: Status };
// 	[UserDispatchTypes.Reset]: { status: Status };
// 	[UserDispatchTypes.Started]: { status: Status };
// 	[UserDispatchTypes.Success]: { status: Status };
// };

export type ProfileActions = ActionMap<ProfilePayload>[keyof ActionMap<ProfilePayload>];

// type StatusActions = ActionMap<StatusActionPayload>[keyof ActionMap<StatusActionPayload>];

export const profileReducer = (state: ProfileState, actions: ProfileActions) => {
	switch (actions.type) {
		case UserDispatchTypes.ON_CHANGE_TEXT: {
			const changed = actions.payload;
			let [stateKey] = Object.keys(state).filter((key) => Object.hasOwnProperty.call(changed, key));

			if (!stateKey) {
				[stateKey] = Object.keys(changed);
			}

			let load = changed[stateKey];

			if (['first_name', 'last_name'].includes(stateKey)) {
				// @ts-ignore
				load = changed[stateKey].replace(/[^a-zA-Z]+/g, '');
			}

			if (stateKey === 'goal') {
				// @ts-ignore
				load = changed[stateKey].replace(/\D+|[.]+/g, '');
			}

			let touchedVal = state[stateKey] === load ? false : true;
			return {
				...state,
				touched: { ...state.touched, [stateKey]: touchedVal },
				// @ts-ignore
				[stateKey]: load,
			};
		}
		case UserDispatchTypes.SET_USER: {
			const { user } = actions.payload;
			setUser(user);
			return {
				...state,
				profile_img: '',
				user_id: user.id,
				user_email: user.email,
				first_name: user.first_name ? user.first_name : '',
				last_name: user.last_name ? user.last_name : '',
				birthday: user.birthday ? user.birthday : '',
				phone_number: user.phone_number ? user.phone_number : '',
				goal: user.goals ? commafy(user.goals.goal) : '0',
				profileStatus: Status.RESOLVED,
				missingReq: missingReq(user),
				...parseUserParamsData(user),
			};
		}
		case UserDispatchTypes.SET_STATUS: {
			return {
				...state,
				profileStatus: actions.payload,
			};
		}
		case UserDispatchTypes.SET_MISSING:
			return {
				...state,
				hasError: actions.payload,
			};
		case UserDispatchTypes.CLEARSTATE:
			return {
				...actions.payload,
			};
		default:
			return state;
	}
};
