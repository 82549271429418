import { fetcher, get, post } from './apiActions';

import { UserInfoType } from '@scholly/scholly-types';
import config from '../config/config';
import { STORAGE_KEYS } from '../constants/StorageKeys';

let app_id = 3;

export const getUser = () => get('user');
export const setUser = async (user: object) => {
	await localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(user));
};
export const getLocalUser = async () => {
	const value = await localStorage.getItem(STORAGE_KEYS.USER);
	if (value) {
		return JSON.parse(value);
	}

	return {};
};

export const setToken = async (token: string) => await localStorage.setItem(STORAGE_KEYS.TOKEN, token);
export const getToken = () => {
	let token = null;

	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');
	let c = ca.find((a) => a.includes(config.same_site.name));
	if (c) token = c.split('=')[1];

	return token;
};

export const getDashboard = () => get(`dashboard?app_id=${app_id}`);

export const getAllQualifiedScholarships = () => get(`hosted-scholarships/user`);
export const getHostedAppliedScholarships = () => get(`hosted-scholarships/user/applied`);
export const getCampaignsAppliedScholarships = () => get(`user/campaigns/applied`);
export const doApplyScholarships = async (params: object) => await post('hosted-scholarships/user/apply', params);
export const getReferalCode = (test_value?: string) => get(`user/refer-link`);

export const getSubscriptions = () => get(`subscription-plans/${app_id}`);
export const applySchollyCode = (join_code: string) => post('registration-code/apply', { join_code });
export const submitBugReport = (params: { cancel_reason_id: string; comment?: string; issue: string }) =>
	post(`bug-report/submit`, { app_id, ...params });
export const getRefer = (test_value?: string) => get(`user/refer-link`);
export const pauseSubscription = async (params: object, subscription_id: string) =>
	await post(`subscription/${subscription_id}/pause`, params);
export const createUserSubscription = async (params: object) => await post('subscription', params);
export const getUserSubscription = () => get('user/subscription');
export const unpauseSubscription = async (id) => await post(`subscription/${id}/activate`, {});
export const updateUserSubscription = (params: any) => post('user/subscription', params);
export const cancelSubscription = (params: any) => post(`subscription/${params.id}/cancel`, params);
export const updateUser = (userInfo: UserInfoType & { phone_number?: string }) => post('user/update', userInfo);
export const completedGatherFlow = () => post('user/update', { completed_gather: true });
export const changePassword = (passObj: object) => post('user/change-password', passObj);

export const userDevice = async (params: object) => {
	const authToken = getToken();
	if (authToken === null) return;
	post('user/device', { app_id, ...params });
};
export const hasReqParameter = () => {
	// @ts-ignore
	const user = this.getLocalUser();
	return user && user.first_name && user.last_name && user.birthday;
};
//TODO: Add unpause subscription

export const createUserParameterValues = async (params: object) => post('user/parameter-value', params);
export const updateParameterValues = async (params: object) => post('user/parameter-values', params);
export const updateSchool = async (params: object) => post('user/school', params);
export const updateLocation = async (params: object) => post('user/location', params);
export const getUserNotifications = async () => get('user/notification');
export const updateUserNotification = async (params: object) => post('user/notification', params);
export const deleteAccount = async () => post('user/delete', {});
export const deactivateAccount = async () => post('user/deactivate', {});
export const getSubscriptionCancelReasons = () => fetcher('subscription-cancel-reasons');
export const createUserReview = async (params: object) => post('app/review', { app_id: app_id, ...params });
export const getUserReviews = async (user_id: string) => get(`user/${user_id}/app/reviews?app_id=${app_id}`);
export const getUserGoals = async () => get('user/goals');
export const getABRef = (ref: string) => get(`user/ab-test?ref=${ref}`);
export const getFeaturedCampaign = async () => get(`campaigns/active`);
export const getUserFeaturedCampaign = async () => get(`user/campaigns/active?app_id=${app_id}`);
export const refundStatusPageHit = async () => post(`integrations/refund`, {});

// Impact requirement
export const getEmailHash = () => get('user/email-hash');

// On-boarding & Profile Questions
export const getQuestions = async () => get('user/profile/question');
export const getQuestionByStep = async (step: string) => get(`user/profile/question?step=${step}`);
