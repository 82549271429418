import React, { CSSProperties, FC } from 'react';

interface RightArrowProps {
	onClick?: (val: any) => void;
	styles?: CSSProperties;
	fill?: string;
}

const RightArrow: FC<RightArrowProps> = (props) => {
	const { onClick, styles, fill } = props;

	return (
		<div>
			<div className="content" onClick={onClick} style={{ cursor: 'pointer', ...styles }}>
				<svg xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 7 14" fill="none">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M0.650997 0.677861C0.787741 0.523958 0.97318 0.4375 1.16654 0.4375C1.35989 0.4375 1.54533 0.523958 1.68207 0.677861L6.78641 6.42446C6.92311 6.57841 6.99991 6.78718 6.99991 7.00487C6.99991 7.22255 6.92311 7.43132 6.78641 7.58527L1.68207 13.3319C1.54455 13.4814 1.36035 13.5642 1.16916 13.5623C0.977968 13.5604 0.795078 13.4741 0.65988 13.3219C0.524682 13.1697 0.447994 12.9638 0.446332 12.7485C0.444671 12.5333 0.518169 12.3259 0.650997 12.1711L5.2398 7.00487L0.650997 1.83867C0.514295 1.68472 0.4375 1.47595 0.4375 1.25827C0.4375 1.04058 0.514295 0.83181 0.650997 0.677861Z"
						fill={fill || '#4E4B66'}
					/>
				</svg>
			</div>
		</div>
	);
};

export default RightArrow;
