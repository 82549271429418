import * as React from 'react';

import { Colors, Loader } from '@scholly/scholly-ui';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { Dimensions, FlatList, StyleSheet, View } from 'react-native';

import { ScreenStrings } from '../../constants';
import SubCategory from './SubCategory';
import { SubHeader } from '../../components/layouts/SubHeader';
import { alphaSort } from '../../helpers/Functions';
import { getParameterValuesCategory } from '../../actions';

import subStyles from './categories.scss';

interface Props {
	next?: () => void;
	onBack?: () => void;
	selectedSections?: any;
	index?: number;
	setIndex?: any;
	setLoadingBtn?: (isLoad: boolean) => void;
}

const { width } = Dimensions.get('window');
const SubCategoryList: React.FC<Props> = ({ next, index, selectedSections, setLoadingBtn, onBack }) => {
	const [expanded, setExpanded] = React.useState(new Map());
	const [isLoading, setLoading] = React.useState(true);
	const [selectedX, setSelectedX] = React.useState([]);

	React.useEffect(() => {
		updateSubCategories();
	}, []);

	const updateSubCategories = () => {
		setLoading(true);
		try {
			selectedSections.map(async (category) => {
				await getSubCategories(category);
				if (category) {
					if (
						category.sort_order === '1' ||
						category.sort_order === '2' ||
						category.sort_order === '3' ||
						category.sort_order === '4'
					) {
						const val = { title: category.display, id: category.id, data: category.sub_categories };
						setSelectedX((prevState) => [...prevState, val]);
					}
				}

				setLoading(false);
				setLoadingBtn(false);

				return category;
			});
		} catch (error) {
			setLoading(false);
			setLoadingBtn(false);
		}
	};

	// TODO: keep for now
	const getSubCategories = async (arr) => {
		const promises = arr.sub_categories.map(async (s, i) => {
			const response = await getParameterValuesCategory(`${s.id}`);
			const data = response.data.categories;
			s.params = alphaSort(data, 'value');
			return s;
		});
		const subcategories = await Promise.all(promises);

		return subcategories;
	};

	const onItemPress = React.useCallback(
		(item) => {
			const newExpanded = new Map(expanded);
			newExpanded.set(item.id, !expanded.get(item.id));
			setExpanded(newExpanded);
		},
		[expanded]
	);

	const _renderItem = ({ item }) => {
		return (
			<SubCategory
				expanded={!!expanded.get(item.id)}
				key={item.id.toString()}
				item={item}
				onItemPress={onItemPress}
			/>
		);
	};

	const _keyExtractor = (item, i) => item.id.toString();

	if (isLoading) {
		return (
			<View style={width < 416 ? [styles.root, styles.loaderContainer] : { height: 350 }}>
				{width < 416 ? <SubCategoryListLoader /> : <Loader />}
			</View>
		);
	}

	return (
		<React.Fragment>
			{selectedX.map((section, i) => {
				const data = section.data;

				if (index === i) {
					return (
						<div key={section.title + index.toString()}>
							<SubHeader title={section.title} onBack={onBack} />
							<View style={styles.root}>
								<div className={subStyles.subHeaderContainer}>
									<p className={subStyles.subHeaderText}>{ScreenStrings.subCategories.title}</p>
								</div>

								<div className={subStyles.contentWrapper}>
									<FlatList
										data={data}
										renderItem={_renderItem}
										keyExtractor={_keyExtractor}
										contentContainerStyle={styles.flatlistContainer}
										extraData={[expanded, selectedSections]}
										//@ts-ignore
										ListFooterComponentStyles={styles.listFooter}
									/>
								</div>
							</View>
						</div>
					);
				}
			})}
		</React.Fragment>
	);
};
const SubCategoryListLoader: React.FC = () => {
	return (
		<ContentLoader height={700} width={312}>
			<Rect x="0" y="12" rx="4" ry="4" width="40%" height="30" />
			<Rect x="0" y="60" rx="3" ry="3" width="75%" height="30" />
			<Rect x="0" y="132" rx="3" ry="3" width="312" height="42" />
			<Rect x="0" y="192" rx="3" ry="3" width="312" height="42" />
			<Rect x="0" y="262" rx="3" ry="3" width="312" height="42" />
			<Rect x="0" y="332" rx="3" ry="3" width="312" height="42" />
			<Rect x="0" y="402" rx="3" ry="3" width="312" height="42" />
			<Rect x="0" y="472" rx="3" ry="3" width="312" height="42" />
			<Rect x="0" y="542" rx="3" ry="3" width="312" height="42" />
		</ContentLoader>
	);
};

export default SubCategoryList;

const styles = StyleSheet.create({
	root: { flex: 1, justifyContent: 'center' },
	headerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	headerIcon: {
		width: 40,
		height: 40,
		marginRight: 8,
	},
	textBlack: { color: Colors.black },
	marginHorizontal: {
		marginHorizontal: width > 416 ? 10 : 0,
	},
	flatlistContainer: {
		display: 'flex',
		flexDirection: 'row',
		// Virtualized list does not support flexWrap but works on WEB.
		flexWrap: 'wrap',
		justifyContent: 'center',
		marginHorizontal: width > 416 ? 10 : 0,
		scrollbarWidth: 'none',
	},
	listFooter: {
		paddingTop: 0,
	},
	loaderContainer: { alignItems: 'center', paddingHorizontal: 32 },
});
