import React, { CSSProperties, FC } from 'react';

interface PauseIconProps {
	svgStyle?: CSSProperties;
}
export const PauseIcon: FC<PauseIconProps> = (props) => {
	return (
		<div>
			<div className="content">
				<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150" fill="none">
					<path
						d="M129.589 15.5742H19.7073C13.4624 15.5742 8.3999 20.6365 8.3999 26.8812V131.493C8.3999 137.737 13.4624 142.8 19.7073 142.8H129.589C135.833 142.8 140.896 137.737 140.896 131.493V26.8812C140.896 20.6365 135.833 15.5742 129.589 15.5742Z"
						fill="#2D50C1"
					/>
					<path
						d="M129.589 7.19922H19.7073C13.4624 7.19922 8.3999 12.2615 8.3999 18.5062V123.118C8.3999 129.362 13.4624 134.425 19.7073 134.425H129.589C135.833 134.425 140.896 129.362 140.896 123.118V18.5062C140.896 12.2615 135.833 7.19922 129.589 7.19922Z"
						fill="#3259F1"
					/>
					<path
						opacity="0.3"
						d="M20.69 7.19947C16.8919 19.7405 12.7464 32.2396 8.50195 44.6398V46.4879C12.965 33.4706 17.3322 20.3515 21.3161 7.1875L20.69 7.19947Z"
						fill="#F7F7F7"
					/>
					<path
						opacity="0.3"
						d="M46.1175 7.19922H45.4975C39.5787 26.7431 32.8182 46.1851 26.1535 65.3576C20.1119 82.7119 13.9984 100.297 8.50195 117.969V119.994C14.1422 101.705 20.4834 83.5117 26.7137 65.5553C33.3993 46.3169 40.1837 26.812 46.1175 7.19922Z"
						fill="#F7F7F7"
					/>
					<path
						opacity="0.3"
						d="M70.919 7.19922H70.2929C64.3741 26.7431 57.6136 46.1851 50.949 65.3576C43.0443 88.0914 35.0078 111.206 28.394 134.425H29.0231C35.6128 111.274 43.6373 88.2261 51.5211 65.5523C58.2037 46.3199 64.9852 26.812 70.919 7.19922Z"
						fill="#F7F7F7"
					/>
					<path
						opacity="0.3"
						d="M95.7233 7.19922H95.0972C89.1754 26.7431 82.4179 46.1851 75.7503 65.3576C67.8456 88.0914 59.809 111.206 53.1953 134.425H53.8184C60.4081 111.277 68.4327 88.2261 76.3164 65.5553C83.005 46.3199 89.7865 26.812 95.7233 7.19922Z"
						fill="#F7F7F7"
					/>
					<path
						opacity="0.3"
						d="M120.525 7.19922H119.899C113.98 26.7431 107.22 46.1851 100.552 65.3576C92.6472 88.0914 84.6107 111.206 78 134.425H78.623C85.2128 111.277 93.2373 88.2261 101.118 65.5553C107.801 46.3199 114.591 26.812 120.525 7.19922Z"
						fill="#F7F7F7"
					/>
					<path
						opacity="0.3"
						d="M141.001 21.0517V19.0898C136.041 34.6261 130.667 50.0785 125.356 65.3571C117.451 88.0909 109.415 111.208 102.801 134.424H103.424C110.014 111.277 118.039 88.2257 125.922 65.5548C131.032 50.8542 136.199 35.9949 141.001 21.0517Z"
						fill="#F7F7F7"
					/>
					<path
						opacity="0.3"
						d="M141.001 93.8134V91.9414C136.238 106.019 131.655 120.204 127.603 134.426H128.226C132.102 120.836 136.463 107.28 141.001 93.8134Z"
						fill="#F7F7F7"
					/>
					<path
						d="M74.6478 123.255C100.69 123.255 121.801 102.145 121.801 76.104C121.801 50.0633 100.69 28.9531 74.6478 28.9531C48.6061 28.9531 27.4951 50.0633 27.4951 76.104C27.4951 102.145 48.6061 123.255 74.6478 123.255Z"
						fill="#2D50C1"
					/>
					<path
						d="M74.6479 118.211C100.827 118.211 122.049 96.9896 122.049 70.8116C122.049 44.6336 100.827 23.4121 74.6479 23.4121C48.4689 23.4121 27.2466 44.6336 27.2466 70.8116C27.2466 96.9896 48.4689 118.211 74.6479 118.211Z"
						fill="#95CBED"
					/>
					<path
						d="M67.6298 52.4922H58.7366C57.7077 52.4922 56.8735 53.3263 56.8735 54.3552V96.0548C56.8735 97.0837 57.7077 97.9178 58.7366 97.9178H67.6298C68.6588 97.9178 69.4929 97.0837 69.4929 96.0548V54.3552C69.4929 53.3263 68.6588 52.4922 67.6298 52.4922Z"
						fill="#F55273"
					/>
					<path
						d="M90.559 52.4922H81.6658C80.6369 52.4922 79.8027 53.3263 79.8027 54.3552V96.0548C79.8027 97.0837 80.6369 97.9178 81.6658 97.9178H90.559C91.588 97.9178 92.4221 97.0837 92.4221 96.0548V54.3552C92.4221 53.3263 91.588 52.4922 90.559 52.4922Z"
						fill="#F55273"
					/>
					<path
						d="M67.6298 48.0977H58.7366C57.7077 48.0977 56.8735 48.9318 56.8735 49.9607V91.6603C56.8735 92.6892 57.7077 93.5233 58.7366 93.5233H67.6298C68.6588 93.5233 69.4929 92.6892 69.4929 91.6603V49.9607C69.4929 48.9318 68.6588 48.0977 67.6298 48.0977Z"
						fill="white"
					/>
					<path
						d="M90.559 48.0977H81.6658C80.6369 48.0977 79.8027 48.9318 79.8027 49.9607V91.6603C79.8027 92.6892 80.6369 93.5233 81.6658 93.5233H90.559C91.588 93.5233 92.4221 92.6892 92.4221 91.6603V49.9607C92.4221 48.9318 91.588 48.0977 90.559 48.0977Z"
						fill="white"
					/>
				</svg>
			</div>
		</div>
	);
};
