import React from 'react';
import { Button, ErrorMessage } from '@scholly/elements';

import { EmailInput } from '../../components/inputs/Email';

import { FlashContext, UserContext } from '../../contexts';

import { validateEmail } from '../../helpers/Validation';

import styles from './settings.scss';

const ChangeEmail: React.FC = () => {
	const userContext = React.useContext(UserContext);
	const flashContext = React.useContext(FlashContext);
	const [newEmail, setEmail] = React.useState('');
	const [isError, setIsError] = React.useState<boolean>(false);
	const [isProcessing, setProcessing] = React.useState(false);

	const handleEmail = (value) => {
		setEmail(value);
		setIsError(false);
	};
	const updateEmail = async () => {
		if (userContext.user_email == newEmail) {
			flashContext.display({
				text: 'Email is the same to your old Email.',
				type: 'error',
				disableScrollTop: true,
			});
		} else {
			setProcessing(true);
			try {
				await userContext.updateUserEmail({ email: newEmail });
				flashContext.display({ text: 'Email changed successful.', type: 'success', disableScrollTop: true });
			} catch (error) {
				flashContext.display({ text: error[0].message, type: 'error', disableScrollTop: true });

				if (error[0].message === 'Domain not allowed' || error[0].message === 'E-mail is already in use') {
					setIsError(true);
				}
			}
			setEmail('');
			setProcessing(false);
		}
	};

	const isDisabled = () => !validateEmail(newEmail) || isProcessing;

	const _renderList = () => {
		return (
			<div className="px-2">
				<div className={styles.title}>Email</div>

				<div>
					{isError && (
						<ErrorMessage className={styles.emailError}>
							We were unable to update your email address. Please contact us at{' '}
							<div>
								<a href={'mailto:info@myscholly.com'} target="_blank" rel="noopener noreferrer">
									info@myscholly.com
								</a>{' '}
								for assistance.
							</div>{' '}
						</ErrorMessage>
					)}
					<EmailInput
						testID={'new-email'}
						placeholder={userContext.user_email}
						onBlur={this.handleEmailBlur}
						onChangeText={handleEmail}
						value={newEmail}
						isError={isError}
					/>
				</div>
				<div className="mt-1">
					<Button
						className="px-0 mb-0 mt-4"
						variant="primary"
						onClick={updateEmail}
						disabled={isDisabled()}
						style={{ width: '100%' }}
					>
						Update Email
					</Button>
				</div>
			</div>
		);
	};
	return <div className={styles.accordionContainer}>{_renderList()}</div>;
};
export default ChangeEmail;
