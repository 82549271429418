import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Button } from '@scholly/elements';

import { SessionContext } from '../../../../contexts';
import { deleteAccount } from '../../../../actions';

import { Status } from '../../../../constants';

import styles from '../offboarding.scss';

const CloseAccount = () => {
	const history = useHistory();
	const sessionContext = React.useContext(SessionContext);
	const [status, setStatus] = React.useState(Status.IDLE);

	const onClose = async () => {
		try {
			setStatus(Status.PENDING);
			await deleteAccount();

			setTimeout(() => {
				sessionContext.endSession();
				setStatus(Status.RESOLVED);
			}, 2000);
		} catch (err) {
			setStatus(Status.REJECTED);
			console.log(err, '<---err');
		}
	};
	return (
		<div className={styles.pauseCardContainer}>
			<div className={classNames(styles.pauseText, styles.pauseTitle)}>
				Are you sure you want to close your account?
			</div>

			<div className={classNames(styles.pauseText, styles.pauseDescription)}>
				If you close your account, all data and personal information will be erased permanently. You will also
				lose access to the flowing services: <br />
				<br />
				<ul>
					<li>Scholly Search </li>
					<li>Offers </li>
				</ul>
				<br />
				Are you sure you want to close your account?
			</div>

			<div className={styles.closeAccountCta}>
				<Button
					variant="primary"
					onClick={onClose}
					disabled={status === Status.PENDING}
					className={styles.closeAccountCtaButton}
				>
					Close Account
				</Button>
				<Button
					variant="secondary"
					onClick={() => {
						history.goBack();
					}}
					className={styles.closeAccountCtaButton}
				>
					Not Now
				</Button>
			</div>
		</div>
	);
};

export default CloseAccount;
