import React from 'react';
import ContentLoader, { Rect, Circle } from 'react-content-loader/native';
import { isMobileDevice } from '../../helpers/checkIfDesktop';

export const AnimatedListLoader = () => {
	return (
		<ContentLoader height={600} width={332}>
			<Rect x="80" y="56" rx="3" ry="3" width="60%" height="6" />
			<Rect x="80" y="74" rx="3" ry="3" width="40%" height="6" />
			<Circle x="24" cx="20" cy="68" r="24" />

			<Rect x="80" y="160" rx="3" ry="3" width="60%" height="6" />
			<Rect x="80" y="178" rx="3" ry="3" width="40%" height="6" />
			<Circle x="24" cx="20" cy="172" r="24" />
			<Rect x="80" y="264" rx="3" ry="3" width="60%" height="6" />
			<Rect x="80" y="282" rx="3" ry="3" width="40%" height="6" />
			<Circle x="24" cx="20" cy="276" r="24" />

			<Rect x="80" y="368" rx="3" ry="3" width="60%" height="6" />
			<Rect x="80" y="386" rx="3" ry="3" width="40%" height="6" />
			<Circle x="24" cx="20" cy="380" r="24" />

			<Rect x="80" y="472" rx="3" ry="3" width="60%" height="6" />
			<Rect x="80" y="490" rx="3" ry="3" width="40%" height="6" />
			<Circle x="24" cx="20" cy="484" r="24" />

			<Rect x="80" y="576" rx="3" ry="3" width="60%" height="6" />
			<Rect x="80" y="594" rx="3" ry="3" width="40%" height="6" />
			<Circle x="24" cx="20" cy="588" r="24" />
		</ContentLoader>
	);
};

export const WebLoader: ({ showExclusive }: { showExclusive: boolean }) => JSX.Element = ({ showExclusive }) => {
	if (isMobileDevice) {
		return <MobileWebLoader showExclusive={showExclusive} />;
	}
	return (
		<ContentLoader height={showExclusive ? 800 : 750} width={672} backgroundColor={'#d6d6d6'}>
			<Rect x="0" y="48" width="100%" height={showExclusive ? '392' : '145'} rx="5" ry="5" />

			<Rect x="0" y="218" width="100%" height="145" rx="5" ry="5" />

			<Rect x="0" y={showExclusive ? '468' : '388'} width="100%" height="145" rx="5" ry="5" />

			<Rect x="0" y={showExclusive ? '638' : '558'} width="100%" height="145" rx="5" ry="5" />
		</ContentLoader>
	);
};

const MobileWebLoader: ({ showExclusive }: { showExclusive: boolean }) => JSX.Element = ({ showExclusive }) => {
	return (
		<ContentLoader height={700} width={327} backgroundColor={'#d6d6d6'}>
			<Rect x="0" y="48" width="100%" height={showExclusive ? '278' : '136'} rx="5" ry="5" />

			<Rect x="0" y="208" width="100%" height="136" rx="5" ry="5" />

			<Rect x="0" y={showExclusive ? '368' : '368'} width="100%" height="136" rx="5" ry="5" />

			<Rect x="0" y={showExclusive ? '528' : '528'} width="100%" height="136" rx="5" ry="5" />
		</ContentLoader>
	);
};
