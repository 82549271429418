import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './scholarship.scss';
import { isMobileOrTabletDevice } from '../../helpers/checkIfDesktop';
import classnames from 'classnames';

export const ScholarshipLoader = ({ isExclusive }) => {
	const HeadingLoader = () => {
		return (
			<ContentLoader height={380} width={'100%'}>
				{!isExclusive && <rect x="5%" y="20" width="672" height="275" />}
				<rect x="5%" y="320" width="200" height="40" />
			</ContentLoader>
		);
	};
	const SideLoader = () => {
		return (
			<ContentLoader height={511} width={325} style={{ marginBottom: '50px' }}>
				<rect x="0" y="20" rx="5" ry="5" width="90%" height="100%" />
			</ContentLoader>
		);
	};
	const Loader = () => {
		return (
			<ContentLoader height={300} width={'100%'}>
				<rect x="5%" y="0" width="672" height="100%" />
			</ContentLoader>
		);
	};

	return (
		<div className={classnames(styles.isFlex, styles.justifyContentCenter)}>
			<div className={styles.loaderContent}>
				<HeadingLoader />
				<Loader />
			</div>
			{!isMobileOrTabletDevice && <SideLoader />}
		</div>
	);
};
