import { Heading } from '@scholly/elements';
import { colors } from '@scholly/core';
import React from 'react';
import styles from '../common.scss';
import Icon from 'react-web-vector-icons';
import classNames from 'classnames';

interface IProps {
	visible: boolean;
	text: any;
	type: any;
	isHeader: boolean;
	isScroll: boolean;
	onClick: () => void;
}
export const FlashMessage = ({ isHeader, isScroll, text, type, onClick, visible }: IProps) => {
	const isString = typeof text === 'string';
	if (!visible) return null;
	return (
		<div
			className={classNames([
				styles.flashMessage,
				styles[`flash-${type}`],
				isHeader ? { top: 0, zIndex: 1025 } : { top: 75 },
				{ position: isScroll ? 'sticky' : 'absolute' },
			])}
		>
			<a className={styles.flashClose} onClick={onClick}>
				<Icon font="MaterialIcons" name="close" size={20} color={colors.gray.light} />
			</a>
			<div className={styles.flashContent}>
				{isString ? (
					<Heading variant="h4" className={styles.flashText}>
						{text}
					</Heading>
				) : (
					text
				)}
			</div>
		</div>
	);
};
