import React, { Fragment } from 'react';
import ImageHolder from '../ImageHolder';
import { Typography } from '@scholly/scholly-ui';
import { useHistory } from 'react-router-dom';
import styles from '../offboarding.scss';
import Icon from 'react-web-vector-icons';
import { FlashContext } from '../../../../contexts';
import { Routes } from '../../../../constants';
import { getReferalCode } from '../../../../actions';
import config from '../../../../config/config';
import Button from '../../../../components/button/Button';

const Referral = ({}) => {
	const [code, setCode] = React.useState('');
	const { offboarding } = Routes;
	const { display } = React.useContext(FlashContext);
	const history = useHistory();
	const getReferal = async () => {
		const resCode = await getReferalCode();
		setCode(resCode.data.code);
	};
	const saveCode = () => {
		navigator.clipboard.writeText(code);
		display({ type: 'success', text: 'Code copied!' });
	};
	React.useEffect(() => {
		getReferal();
	}, []);
	return (
		<Fragment>
			<ImageHolder src={`${config.cdn}images/girl@2x.png`} />
			<div style={{ textAlign: 'left', marginTop: '40px' }}>
				<Typography variant="h1">Invite your friends</Typography>
			</div>
			<div style={{ textAlign: 'left', marginTop: '24px' }}>
				<Typography variant="body">
					Know someone who can benefit from Scholly? Share the news, invite friends in High School or College
					and recieve
				</Typography>
			</div>
			<div style={{ textAlign: 'left', marginTop: '24px' }}>
				<Typography variant="h2">$10 Off</Typography>
			</div>
			<div className={styles.codeHolder} style={{ marginTop: '24px' }}>
				<div className={styles.code}>{code}</div>
				<div className={styles.symbol} onClick={saveCode}>
					<Icon name="share" font="Entypo" color="black" size={20} />
				</div>
			</div>
			<div style={{ display: 'grid', placeItems: 'center', marginTop: '48px' }}>
				<Button
					variant="solid"
					label={'StatusDropdown Code'}
					onClick={() => {
						saveCode();
					}}
					compact
					style={{ width: '50%', margin: '0 auto', minWidth: '200px' }}
					testID={`testID_referral_button_share_code`}
				/>
				<div style={{ cursor: 'pointer', marginTop: '24px' }}>
					<Typography
						variant="body"
						onPress={() => {
							history.push(`${offboarding.home}/${offboarding.accountType}`);
						}}
						testID={`testID_referral_button_continue`}
					>
						Continue closing account
					</Typography>
				</div>
			</div>
		</Fragment>
	);
};
export default Referral;
