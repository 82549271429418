import React from 'react';
import classNames from 'classnames';
import styles from './profile-update-success.scss';
import { useUser } from '../../../contexts';
import { STORAGE_KEYS, ScreenStrings } from '../../../constants';
import { SubHeader } from '../../../components/layouts/SubHeader';
import TotalMatch from '../../../components/total-match/total-match';
import moment from 'moment';

const ProfileUpdateSuccess: React.FC<{}> = () => {
	const { user_id } = useUser();

	React.useEffect(() => {
		localStorage.setItem(STORAGE_KEYS.UPDATE_PROFILE, moment().toString());
	}, [user_id]);

	return (
		<div className="profileUpdateSuccess">
			<SubHeader
				title={ScreenStrings.updateProfile.success}
				hideBackButton
				className={classNames(styles.subHeader, styles.subHeaderContainer)}
				isFreeAccountScreen
			/>
			<TotalMatch showConfetti />
		</div>
	);
};

export default ProfileUpdateSuccess;
