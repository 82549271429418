import * as React from 'react';
import './styles/global.scss';

import { CookiesProvider, withCookies } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';
import config from './config/config';

import {
	BrandingContextProvider,
	CategoriesProvider,
	FlashContextProvider,
	ParameterContextProvider,
	PromoContextProvider,
	ScholarshipContextProvider,
	SessionProvider,
	UserContextProvider,
	FeatureFlagsProvider,
	OfferContextProvider,
} from './contexts';
import { localStorageProvider } from './helpers/Cache';
import AppNavigator from './navigation/AppNavigator';

const UserContextCookieProvider = withCookies(UserContextProvider);
const basename = config?.basename || '';

const App: React.FC = () => {
	return (
		<CookiesProvider>
			<BrowserRouter basename={basename}>
				{/* @ts-ignore */}
				<SWRConfig value={{ provider: localStorageProvider }}>
					<FeatureFlagsProvider>
						<BrandingContextProvider>
							<SessionProvider>
								<UserContextCookieProvider>
									{() => (
                                        <OfferContextProvider>
                                            <PromoContextProvider>
                                                <ParameterContextProvider>
                                                    <CategoriesProvider>
                                                        <ScholarshipContextProvider>
                                                            <FlashContextProvider>
                                                                <AppNavigator />
                                                            </FlashContextProvider>
                                                        </ScholarshipContextProvider>
                                                    </CategoriesProvider>
                                                </ParameterContextProvider>
                                            </PromoContextProvider>
                                        </OfferContextProvider>
									)}
								</UserContextCookieProvider>
							</SessionProvider>
						</BrandingContextProvider>
					</FeatureFlagsProvider>
				</SWRConfig>
			</BrowserRouter>
		</CookiesProvider>
	);
};

export default App;
