import React, { FC } from 'react';
import styles from './icons.scss';
import classNames from 'classnames';

interface MenuIconProps {
	onClick: (e?: React.MouseEvent<HTMLElement>) => void;
}
const MenuIcon: FC<MenuIconProps> = (props) => {
	const { onClick } = props;

	return (
		<div className={classNames(styles.menuWrapper, 'content')} onClick={onClick}>
			<svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect y="0.960938" width="34" height="34" rx="6" fill="white" />
				<ellipse
					cx="16.4975"
					cy="8.45418"
					rx="2.49324"
					ry="2.5025"
					transform="rotate(90 16.4975 8.45418)"
					fill="#A0A3BD"
				/>
				<ellipse
					cx="16.4975"
					cy="18.4268"
					rx="2.49324"
					ry="2.5025"
					transform="rotate(90 16.4975 18.4268)"
					fill="#A0A3BD"
				/>
				<ellipse
					cx="16.4975"
					cy="28.3995"
					rx="2.49324"
					ry="2.5025"
					transform="rotate(90 16.4975 28.3995)"
					fill="#A0A3BD"
				/>
			</svg>
		</div>
	);
};

export default MenuIcon;
