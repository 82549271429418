import * as React from 'react';

import { SVGProps } from 'react';

const Cap = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="40px" height="40px" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<ellipse cx={20.763} cy={20} rx={19.878} ry={20} fill="#F5F6FA" />
			<path
				d="M34.75 18.598c1.788-.624 1.788-3.153 0-3.777l-13.04-4.549a2 2 0 0 0-1.317 0L7.311 14.82c-1.79.622-1.79 3.155 0 3.778l13.082 4.549a2 2 0 0 0 1.316-.001l13.04-4.549Z"
				stroke="#3259F1"
				strokeMiterlimit={10}
			/>
			<path
				d="M29.856 20.414v4.889a4 4 0 0 1-4 4h-9.67a4 4 0 0 1-4-4v-4.889M31.328 24.965s2.945-.167 2.945 2.857h-2.945v-2.857Zm0 0v-4.55"
				stroke="#3259F1"
				strokeMiterlimit={10}
			/>
		</svg>
	);
};

export default Cap;
