import React from 'react';
import { fetcher } from '../actions/apiActions';
import Cache from '../helpers/Cache';
import { FLAGS, HTTPStatus, STORAGE_KEYS } from '../constants';

type FeatureType = {
	id?: string;
	feature_name: string;
	description?: string;
	active?: boolean;
};

interface IFeatureProps {
	features: Array<any>;
	toggleFeature?: (s: string) => void;
}

interface IProviderProps {
	features?: Array<any>;
	children: React.ReactNode;
}

interface IFlagProps {
	name: string;
	children?: React.ReactNode | ((hasFeature: boolean | Array<string>) => JSX.Element);
	render?: React.ReactNode | ((hasFeature: boolean | Array<string>) => JSX.Element);
}

export type FreeProps = {
	isFree: boolean;
};

export function checkFeature(name: string): boolean {
	const { features } = useFeatures();
	if (Array.isArray(features)) {
		return features.some((feat: any) => {
			if (typeof feat === 'string') {
				return feat === name ? true : undefined; // stop loop on first match
			}

			if (feat.feature_name === name && feat.active) {
				return true;
			}
		});
	}
	return false;
}

export const checkFreeHOC = (Component: any) => {
	return (props: any) => {
		const hasFeature = checkFeature(FLAGS.SCHOLLY_FREE);

		return <Component isFree={hasFeature} {...props} />;
	};
};

export const FeatureFlagsContext = React.createContext<IFeatureProps>({ features: [] });

export function Feature({ name, children, render = children }: IFlagProps) {
	const hasFeature = checkFeature(name);
	if (typeof render === 'function') return render(hasFeature);
	if (!hasFeature) return null;
	return <React.Fragment>{render}</React.Fragment>;
}

export class FeatureFlagsProvider extends React.Component<IProviderProps> {
	state = {
		flags: [],
	};
	static defaultProps = {
		features: [],
	};
	constructor(props: IProviderProps) {
		super(props);
		this.toggle = this.toggle.bind(this);
	}
	componentDidMount() {
		fetcher('app/features?expand=true&app_id=3')
			.then((res) => {
				if (res.status === HTTPStatus.OK) {
					let flags: FeatureType[];
					if (res.data.some((feat: any) => typeof feat === 'string')) {
						flags = res.data.map((feat: FeatureType) => ({
							feature_name: feat,
							active: true,
						}));
						this.setState({ flags });
					} else {
						flags = res.data.map((feat: FeatureType) => ({
							...feat,
							active: true,
						}));
					}
					this.setState({ flags });
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	toggle(selected: string) {
		const list = this.state.flags;
		const found = list.findIndex((feat) => feat.feature_name === selected);

		if (list[found]) {
			list[found].active = !list[found].active;
		} else {
			list.push({ feature_name: selected, active: true });
		}

		this.setState({ flags: [...list] });
		Cache.set(STORAGE_KEYS.FEATURE_FLAGS, JSON.stringify(list));
	}

	render() {
		const { children } = this.props;
		const { flags } = this.state;
		return (
			<FeatureFlagsContext.Provider value={{ features: flags, toggleFeature: this.toggle }}>
				{children}
			</FeatureFlagsContext.Provider>
		);
	}
}

export function useFeatures() {
	const context = React.useContext(FeatureFlagsContext);
	if (context === undefined) {
		console.error('useFeatures must be used within a ProfileProvider');
		return { features: [] };
	}
	return context;
}
