import React from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { Card } from '../../../../components/card/Card';
import { Button } from '@scholly/elements';
import { Routes } from '../../../../constants';
import styles from '../offboarding.scss';
import classNames from 'classnames';
import { OffersIcon, PauseIcon, PayoffIcon } from '../../../../components/common/icons';
import config from '../../../../config/config';

export const SuccessfullyPaused = () => {
	const { pauseLength } = useParams<{ pauseLength: string }>();
	const history = useHistory();
	const date = new Date();
	const newDate = new Date(date.setMonth(date.getMonth() + Number(pauseLength)));
	const formattedDate = newDate.toLocaleDateString('en-US');

	const openMainScreen = () => {
		history.push(Routes.dashboard);
	};

	const openPage = (value: string) => () => {
		const regex = '^https?://';
		if (!value.match(regex)) history.push(value);
		else window.open(value, '_blank');
	};

	return (
		<div className={styles.pauseCardContainer}>
			<div className={classNames(styles.isFlex, styles.justifyContentCenter)}>
				<PauseIcon />
			</div>
			<div style={{ textAlign: 'center' }}>
				<div style={{ marginTop: '36px', padding: '0 10px' }}>
					<div className={classNames(styles.pauseText, styles.pauseTitle)}>
						Your membership has been successfully paused for {pauseLength} Months
					</div>
				</div>
			</div>
			<div style={{ marginTop: '24px' }} className={classNames(styles.isFlex, styles.justifyContentCenter)}>
				<div
					className={classNames(styles.pauseText, styles.pauseDescription, 'has-text-centered')}
					style={{ textAlign: 'left', textTransform: 'initial' }}
				>
					Your account will resume on {formattedDate}. We’ll send you an email confirmation and notification
					before your membership resume.
				</div>
			</div>

			<div style={{ marginTop: '24px' }} className={classNames(styles.isFlex, styles.justifyContentCenter)}>
				<div
					className={classNames(styles.pauseText, styles.pauseDescription, 'has-text-centered')}
					style={{ textAlign: 'left', textTransform: 'initial' }}
				>
					Explore Scholly Offers and Payoff to help with or student loans and financial goals.
				</div>
			</div>
			<div className={classNames(styles.isFlex, styles.isFlexRow, styles.justifyContentBetween, 'mt-6')}>
				<Card onClick={openPage(config.payoff_url)}>
					<PayoffIcon />
				</Card>
				<Card onClick={openPage(config.offers_url)}>
					<OffersIcon />
				</Card>
			</div>
			<div className={classNames('mt-6')}>
				<Button
					variant="secondary"
					onClick={() => {
						openMainScreen();
					}}
					className={styles.pauseButton}
					style={{ marginBottom: 20 }}
				>
					Go to Main Screen
				</Button>
			</div>
		</div>
	);
};
