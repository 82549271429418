import { Colors } from '@scholly/scholly-ui';

const customStyles = (props, widthWindow?) => {
	return {
		singleValue: (styles) => ({ ...styles, color: '#000000', fontSize: 14 }),
		control: (styles, { isFocused }) => ({
			...styles,
			backgroundColor: Colors.white,
			display: 'flex',
			color: Colors.black,
			borderRadius: 6,
			fontSize: 14,
			border: 0,
			padding: '5px 0',
			boxShadow: isFocused ? '0px 4px 11px rgb(0 0 0 / 8%)' : null,
			borderColor: Colors.white,
			cursor: 'pointer',
		}),

		container: (styles, { isDisabled, isFocused, isSelected, hasValue }) => {
			return {
				...styles,
				border: isFocused
					? `2px solid #3259F1
				`
					: hasValue
					? '1px solid #a0a3bd'
					: 0,
				borderRadius: 6,
				boxShadow: ' 0px 4px 11px rgba(0, 0, 0, 0.08)',
			};
		},
		indicatorSeparator: (styles) => {
			return {
				...styles,
				display: 'none',
			};
		},
		dropdownIndicator: (styles, { isFocused }) => {
			return {
				...styles,
				transform: isFocused ? 'rotate(180deg)' : null,
				transition: 'all .2s ease',
				color: '#000',
			};
		},
		menu: (styles) => {
			return {
				...styles,
				background: '#FFFFFF',
				boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.08)',
				borderRadius: 6,
			};
		},
		option: (styles, { isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: isDisabled ? null : isSelected ? Colors.white : isFocused ? Colors.whiteTwo : null,
				color: isDisabled ? '#ccc' : isSelected ? '#000' : Colors.greyishBrown,
				cursor: isDisabled ? 'not-allowed' : 'default',
				fontSize: 14,
				':active': {
					...styles[':active'],
					backgroundColor: !isDisabled && isSelected && Colors.greyWithOpacity,
				},
				':hover': {
					backgroundColor: '#3259F1',
					color: Colors.white,
					borderRadius: 8,
					transition: '0.1s',
				},
			};
		},
		multiValue: (styles) => {
			return {
				...styles,

				backgroundColor: '#F5F6FA',
				borderRadius: 6,
				color: '#4E4B66',
			};
		},
		multiValueLabel: (styles) => ({
			...styles,
			color: '#4E4B66',
			fontSize: 14,
			fontWeight: 500,
		}),
		multiValueRemove: (styles) => ({
			...styles,
			color: '#4E4B66',
			fontSize: 14,
			fontWeight: 500,
			transition: '0.1s',
			':hover': {
				backgroundColor: '#3259F1',
				color: '#fff',
				fontWeight: 400,
				transition: '0.1s',
			},
		}),
	};
};
export default customStyles;
