import React, { FC, useMemo, useState } from 'react';

import { Button } from '@scholly/elements';
import { CustomModal } from '../modal/Modal';
import { Menu } from '../ScholarshipCard/components/Modals';
import RadioCheckbox from '../common/RadioCheckbox/radio-checkbox';
import { ScreenStrings } from '../../constants';
import classNames from 'classnames';
import moment from 'moment';
import style from './exclusive-card.scss';
import { useScholarship } from '../../contexts';

interface ExclusiveCardProps {
	classname?: string;
	displayQuickApplyButton?: boolean;
	image?: string;
	checked?: boolean;
	handleShare: () => void;
	handleQuickApply?: () => void;
	handleCheck?: () => void;
	hideCheckbox?: boolean;
	onScholarshipDetails?: boolean;
	scholarshipItem?: { name: string; image: string; id: string; end_time: string };
	title: string;
}

const ExclusiveCard: FC<ExclusiveCardProps> = (props) => {
	const {
		classname,
		displayQuickApplyButton,
		image,
		handleQuickApply,
		hideCheckbox,
		checked = true,
		handleCheck,
		onScholarshipDetails,
		title,
		scholarshipItem,
	} = props;
	const [isShowMenu, setIsShowMenu] = useState(false);
	const { removeReasons } = useScholarship();

	const daysRemaining = useMemo(() => {
		const deadlineDate = moment(scholarshipItem.end_time, 'YYYY-MM-DD');
		const today = moment().startOf('day');
		let diff = deadlineDate.diff(today, 'days');

		return diff;
	}, [scholarshipItem]);

	const handleMenuVisibleChange = () => {
		setIsShowMenu(!isShowMenu);
	};

	return (
		<div
			className={classNames(style.exclusiveCardContainer, onScholarshipDetails && style.cardOnDetails, classname)}
		>
			<div
				style={{
					backgroundImage: `url(${image})`,
				}}
				className={style.topSection}
			>
				<div className={classNames(style.iconContainer)}>
					<div>
						{/* //! ONCE SCHOLLY UI IS UPDATED, THIS SHOULD BE REPLACED WITH AN IMPORT FROM SCHOLLY ELEMENTS */}
						<Ending2L days={daysRemaining} />
					</div>

					<div className={classNames(style.rightIconsContainer)}></div>
				</div>
			</div>
			<div className={style.bottomSection}>
				<div className={style.bottomSectionWrapper}>
					<div className={style.cardTitleContainer}>
						<h2 className={style.cardTitle}>{title}</h2>
					</div>
					{!displayQuickApplyButton && !hideCheckbox && !onScholarshipDetails && (
						<div>
							<RadioCheckbox onChange={handleCheck} value={'true'} checked={checked} />
						</div>
					)}
				</div>
				{displayQuickApplyButton && (
					<div className={style.ctaContainer}>
						<Button onClick={handleQuickApply} variant="primary">
							Apply
						</Button>
					</div>
				)}
			</div>
			<CustomModal visible={isShowMenu}>
				<Menu onClose={handleMenuVisibleChange} hiddenItems={['share']} reasons={removeReasons} />
			</CustomModal>
		</div>
	);
};
export default ExclusiveCard;

//! ONCE SCHOLLY UI IS UPDATED, THIS SHOULD BE REPLACED WITH AN IMPORT FROM SCHOLLY ELEMENTS
interface ExclusiveBadgeProps {
	days: number;
	text?: string;
}

const Ending2L = (props: ExclusiveBadgeProps): JSX.Element => {
	const { days } = props;

	return (
		<div className={classNames({ 'is-hidden': days > 7 })}>
			<div className={style.exclusiveBadge}>
				<div className="is-flex is-align-items-center">
					<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="4" cy="4.95764" r="3.5" stroke="#192c78" />
						<path d="M4 2.95764V5.68359" stroke="#192c78" strokeWidth="0.5" strokeLinecap="round" />
						<path
							d="M5.76758 3.95764L3.99981 5.72541"
							stroke="#192c78"
							strokeWidth="0.5"
							strokeLinecap="round"
						/>
					</svg>
				</div>
				<span>
					{days > 1
						? `${days} Days Left`
						: days === 1
						? ScreenStrings.scholarship.scholarshipBadgeExpiresToday
						: ScreenStrings.scholarship.scholarshipBadgeExpired}
				</span>
			</div>
		</div>
	);
};
