export const FLAGS: {
	PROFILE: string;
	OFFER_TAB: string;
	OFFER_BANNER: string;
	OFFER_MODAL: string;
	OFFER_DASHBOARD: string;
	OFFER_IN_FEED: string;
	SCHOLLY_FREE: string;
	SALLIE_CARD: string;
	UPDATE_PROFILE: string;
	SCHOLARSHIP_DETAILS: string;
} = {
	PROFILE: 'profile',
	OFFER_BANNER: 'offers_banner',
	OFFER_MODAL: 'offers_modal',
	OFFER_TAB: 'offers_tab',
	OFFER_DASHBOARD: 'offers_dashboard',
	OFFER_IN_FEED: 'offers_in_feed',
	SCHOLLY_FREE: 'free_scholly',
	SALLIE_CARD: 'sallie_card',
	UPDATE_PROFILE: 'update_profile',
	SCHOLARSHIP_DETAILS: 'scholarship_details',
};
