import { STORAGE_KEYS } from '../constants/StorageKeys';
import { fetcher, post } from './apiActions';

let app_id = 3;

export const facebook = (data: any) => fetcher('auth/facebook', 'POST', { ...data, app_id });
export const login = (data: any) => fetcher('auth/login', 'POST', { ...data, app_id });
export const register = (data: any) => fetcher('auth/register', 'POST', { ...data, app_id });
export const emailExist = (data: any) => fetcher('auth/email-exist', 'POST', { ...data, app_id });
export const logout = async () => {
	let qKeys = Object.keys(localStorage).filter((k) => k.includes('offline.')) ?? [];
	const keys = [
		STORAGE_KEYS.TOKEN,
		STORAGE_KEYS.USER,
		STORAGE_KEYS.COMPLETED,
		STORAGE_KEYS.FEATURED_CAMPAIGN_ID,
		STORAGE_KEYS.SCHOLARSHIP_TOTAL,
		STORAGE_KEYS.OFFER_PROMO,
		`cache.${STORAGE_KEYS.FEATURE_FLAGS}`,
		`cache.${STORAGE_KEYS.CATEGORIES}`,
		...qKeys,
	];
	for (let key of keys) {
		localStorage.removeItem(key);
	}
	return post('auth/logout', {});
};
export const resetPassword = (data: any) => fetcher('auth/reset-password', 'POST', { ...data });
// TODO: temporary name change as it was causing errors in the search app, similar function name in userActions.
export const resetChangePassword = (token: string, password: string) =>
	post('auth/change-password', { token, password });
export const verifyPasswordReset = (token: string) => fetcher('auth/password-token', 'POST', { token });
export const verifySchollyCode = (code: string) => fetcher(`registration-code/${code}/verify`);
