import React from 'react';
import styles from './onboarding.scss';

type Props = {
	value: number;
	onSlide: (value: string) => void;
	maximum: number;
	minimum: number;
	title: string;
	step: number;
};

const Slider: React.FC<Props> = (props) => {
	const { value, maximum, minimum, title, step } = props;

	React.useEffect(() => {
		// Setting the average value in the first slider rendering, when the default value has not been defined
		if (!value) {
			props.onSlide(((maximum + minimum) / 2).toFixed());
		}
	}, []);
	return (
		<div className={styles.onboardingFormWrapper}>
			<h2>{title}</h2>
			<div className={styles.sliderContainer}>
				<div className={styles.sliderLabelContainer}>
					<p>GPA</p>
					<p className={styles.sliderValue}>{value || 0}</p>
				</div>
				<input
					className={styles.slider}
					type="range"
					step={step}
					max={maximum}
					min={minimum}
					value={value}
					onChange={(e) => props.onSlide(e.target.value)}
				/>
			</div>
		</div>
	);
};

export default Slider;
