import React from 'react';
import classNames from 'classnames';

import { WalletIcon } from '../../../../components/common/icons';
import { Button } from '@scholly/elements';
import { useHistory } from 'react-router-dom';

import styles from './../offboarding.scss';

const PauseAccountSubscription = () => {
	const history = useHistory();

	return (
		<div className={styles.affiliateOffer}>
			<div className={classNames(styles.isFlex, styles.justifyContentCenter)}>
				<WalletIcon />
			</div>
			<div className={classNames(styles.pauseText, styles.affiliateOfferTitle)}>
				Your free subsrciption is almost there!
			</div>
			<div className={classNames(styles.pauseText)}>
				Your free subscription will take effect once all affiliate actions have been complete
			</div>
			<div className={styles.affiliateOfferButtonContainer}>
				<Button variant="primary" onClick={() => history.push('/scholarships')} className={styles.pauseButton}>
					Match More Scholarships
				</Button>
			</div>
		</div>
	);
};

export default PauseAccountSubscription;
