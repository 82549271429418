import React, { useContext } from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import { markdown } from 'markdown';
import styles from './apply-to-affiliate.scss';
import Button from '../affiliate/button';
import { affiliateUrlParser } from '../../helpers/affiliateUrlParser';
import closeButton from '../../assets/close-button.png';
import { UserContext } from '../../contexts';

interface Props {
	offer: any;
	isOpen: boolean;
	close: React.MouseEventHandler<HTMLDivElement>;
	onClickGoToAffiliate?: () => void;
}

const ApplyToAffiliate: React.FC<Props> = (props) => {
	const { offer, isOpen = false, close, onClickGoToAffiliate } = props;

	const session = useContext(UserContext);
	if (!offer) return <div />;

	let url = affiliateUrlParser(offer, session.user_id);

	return (
		<Modal
			className={classNames(styles.modal, styles.smallOnMobile)}
			isOpen={isOpen}
			onRequestClose={close}
			shouldCloseOnOverlayClick={true}
			overlayClassName={classNames(styles.modalOverlay)}
			onAfterOpen={() => (document.body.style.overflow = 'hidden')}
			onAfterClose={() => (document.body.style.overflow = 'unset')}
		>
			<div onClick={close} className={styles.modalClose}>
				<img onClick={close} src={closeButton} alt="" />
			</div>

			<div className={styles.applyContainer}>
				<div
					className={styles.applyText}
					dangerouslySetInnerHTML={{
						__html: markdown.toHTML(offer.modal_copy),
					}}
				/>
				<Button
					isPrimary
					isDisabled={!url}
					label={offer.cta ? offer.cta : `Go to ${offer.affiliate.name}`}
					onClick={() => {
						if (url?.href) {
							onClickGoToAffiliate();

							window.open(url, '_blank');
						}
					}}
				/>
			</div>
		</Modal>
	);
};
export default ApplyToAffiliate;
