import classNames from 'classnames';
import React from 'react';
import styles from './recent-winner-thumbnail.scss';

interface RecentWinnerThumbnailProps {
	variant: number;
	imageUrl: string;
	name: string;
	campaign: string;
}

const RecentWinnerThumbnail = (props: RecentWinnerThumbnailProps) => {
	let { variant, imageUrl = '', name, campaign } = props;

	let variantStyle;

	switch (variant) {
		case 0:
			variantStyle = styles.variant0;
			break;
		case 1:
			variantStyle = styles.variant1;
			break;
		case 2:
			variantStyle = styles.variant2;
			break;
		default:
			null;
	}

	return (
		<div className={styles.container}>
			<div className={classNames(styles.circleWrapper, variantStyle)}>
				<div className={styles.smallCircle}></div>
				<div className={styles.colorCircle}></div>
				<img className={styles.roundedImage} src={imageUrl} alt="" />
			</div>
			<div className={styles.infoBox}>
				<div className={styles.name}>{name}</div>
				<div className={styles.campaign}>{campaign}</div>
			</div>
		</div>
	);
};

export default RecentWinnerThumbnail;
