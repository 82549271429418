import React, { useLayoutEffect, useState } from 'react';

import { Badge } from './Badge';
import { ScholarshipObject } from '../../../types';
import { ScreenStrings } from '../../../constants';
import moment from 'moment';

interface CardBadgeProps extends ScholarshipObject {
	size: any;
}
// @ts-ignore
export const CardBadge: React.FC<{ item: CardBadgeProps }> = ({ item, size }) => {
	const { deadline, progress } = item;

	const [variant, setVariant] = useState<
		'new' | 'expired' | 'left' | 'left_rounded' | 'applied' | 'won' | 'not_awarded' | 'temporary' | 'viewed' | ''
	>('');
	const [text, setText] = useState('');

	// Sets the deadlineDate to the beginning of the next day
	const deadlineDate = moment(deadline, 'YYYY-MM-DD').add(1, 'days');
	const today = moment().startOf('day');
	let diff = deadlineDate.diff(today, 'days');
	const isBefore = deadlineDate.isBefore(moment());

	const capitalizeFirstLetter = (string) => {
		const words = string.split(' ');
		words.forEach((word, idx) => {
			words[idx] = word[0].toUpperCase() + word.slice(1);
		});

		return words.join(' ');
	};

	useLayoutEffect(() => {
		if (item.isNew && item.read_status !== 'read') {
			setVariant('new');
		}
		if (diff < 0 || isBefore) {
			// Expired
			setText('Expired');
			setVariant('expired');
		}
		if (diff === 1 && !progress) {
			// Today
			setVariant('left');
			setText(ScreenStrings.scholarship.scholarshipBadgeExpiresToday);
		}
		if (diff > 1 && diff <= 7 && !progress) {
			// Future
			setVariant('left');
			const leftDate = moment(deadlineDate).fromNow().replace('in ', '') + ' Left';
			setText(capitalizeFirstLetter(leftDate));
		}
		if (progress === 'applied') {
			setVariant('applied');
		}

		if (progress === 'won') {
			setVariant('won');
		}

		if (progress === 'not_awarded') {
			setVariant('not_awarded');
		}
	}, [item, deadline, diff, isBefore, progress]);

	// Rethink the Badge UI component.
	// Can be rewritten cleaner with fewer lines of code.

	return <>{variant && <Badge variant={variant} text={text} size={size} />}</>;
};
