import React from 'react';
import { Button } from '@scholly/elements';
import Carousel from '../carousel/carousel';
import CarouselItem from '../carousel-item/carousel-item';
import ExclusiveCard from '../exclusive-card/exclusive-card';
import { HTTPStatus, ButtonStrings } from '../../constants';
import styles from './exclusives.scss';
import { useScholarship } from '../../contexts';
import { scrollTo } from '../../helpers/Functions';

interface Props {
	data: any[];
	speed?: number;
	removeSection?: (type: string) => void;
	updateScholarships?: (items: any[]) => void;
}

const Exclusives = ({ data, speed, removeSection, updateScholarships }: Props) => {
	const [numberOfQuickApply, setNumberOfQuickApply] = React.useState(data.length);
	const [listData, setList] = React.useState(data);
	const [loading, setLoading] = React.useState(false);
	const [selected, setSelected] = React.useState(new Map());
	const [cardCheck, setCardCheck] = React.useState(new Array(data.length).fill(true));
	const { handleEasyApply } = useScholarship();

	React.useEffect(() => {
		let mapped = new Map();
		listData.forEach((value, index) => {
			if (value) {
				mapped.set(index, value.id);
			}
		});

		setSelected(mapped);
		if ((!listData || listData.length === 0) && removeSection) {
			removeSection('quick_apply');
		}
		return () => {
			setSelected(new Map());
		};
	}, [removeSection, data, listData]);

	const onUnselectAll = () => {
		const unselectedCheckedCard = cardCheck.map((item, index) => index === item);
		setCardCheck(unselectedCheckedCard);
		setSelected(new Map());
		setNumberOfQuickApply(0);
	};

	const onShare = () => {
		// Sharing Scholarshop
	};

	const handleOnChange = (position: number) => {
		const updatedCheckedCard = cardCheck.map((item, index) => {
			return index === position ? !item : item;
		});
		setCardCheck(updatedCheckedCard);
		setNumberOfQuickApply(updatedCheckedCard.filter((i) => i).length);
		updatedCheckedCard.forEach((value, index) => {
			if (!value) {
				selected.delete(index);
			} else {
				selected.set(value, listData[index].id);
			}
		});
	};

	const onSubmit = async () => {
		let count = 0;

		selected.forEach((v, k) => {
			const exclusive = listData.find((d) => d.id === v);
			setLoading(true);

			handleEasyApply(exclusive)
				.then((res) => {
					if (res.status === HTTPStatus.OK) count++;
				})
				.finally(() => {
					setLoading(false);
					if (count === selected.size) {
						let vals = [...selected.values()];
						let key = [...selected.entries()].filter(({ 1: v }) => v === exclusive.id).map(([k]) => k)[0];
						const updated = listData.filter((d: { id: string }) => !vals.includes(d.id));
						selected.delete(key);

						setList(updated);
						updateScholarships(updated);
						if (updated.length === 0) {
							scrollTo({ top: 0 });
						}
					}
				});
		});
	};

	return (
		<div className={styles.container}>
			<div className={styles.sliderContainer} style={{ width: listData?.length > 1 ? '100%' : 'fit-content' }}>
				<Carousel showArrows={window.innerWidth >= 768 && listData?.length > 1} speed={speed}>
					{listData?.map((scholarship, index) => {
						const scholarshipImage =
							Array.isArray(scholarship?.assets) &&
							scholarship?.assets.find((asset) => asset.type === 'web').url;
						return (
							<CarouselItem key={index}>
								<div className={styles.exclusiveCardContainer}>
									<ExclusiveCard
										image={scholarshipImage}
										handleShare={onShare}
										title={scholarship?.name}
										scholarshipItem={scholarship}
										checked={cardCheck[index]}
										handleCheck={() => handleOnChange(index)}
									/>
								</div>
							</CarouselItem>
						);
					})}
				</Carousel>
			</div>

			<div className={styles.ctaContainer}>
				<Button
					className={styles.sliderContainer}
					variant={numberOfQuickApply > 0 ? 'primary' : undefined}
					disabled={Boolean(numberOfQuickApply === 0)}
					onClick={onSubmit}
					isLoading={loading}
				>
					{numberOfQuickApply === listData?.length && listData?.length > 1
						? 'Apply to All'
						: 'Apply to Selected'}
				</Button>
				<Button className={styles.unselectAllButton} onClick={onUnselectAll}>
					{ButtonStrings.unselectAll}
				</Button>
			</div>
		</div>
	);
};

export default Exclusives;
