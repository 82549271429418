import React, { createContext, useEffect, useState } from 'react';

import { FlashMessage } from '../components/common/FlashMessage/FlashMessage';

export type FlashContextType = {
	display: (obj: DisplayFunction) => void;
	close?: () => void;
};

export type FlashMessageType = 'error' | 'success';

type DisplayFunction = {
	text: string | JSX.Element;
	type: FlashMessageType;
	autoDismiss?: boolean;
	shouldShowAsHeader?: boolean;
	disableScrollTop?: boolean;
};

type FlashContextProps = {
	children: JSX.Element;
};

const HIDE_FLASH_AFTER = 5000; //ms

export const FlashContext = createContext<FlashContextType>(null);

export const FlashContextProvider: React.FC<FlashContextProps> = (props) => {
	let [visibility, setVisibility] = useState(false);
	let [text, setText] = useState(null);
	let [type, setType] = useState('');
	let [showAsHeader, setShowAsHeader] = useState(false);
	const [isScrollPosition, setScrollPosition] = useState(false);

	useEffect(() => {
		if (window.scrollY > 0) {
			setScrollPosition(true);
		} else {
			setScrollPosition(false);
		}
	}, [window.scrollY]);

	const display = ({
		text,
		type,
		autoDismiss = true,
		shouldShowAsHeader = false,
		disableScrollTop = false,
	}: DisplayFunction): any => {
		setText(text);
		setType(type);
		setVisibility(true);

		if (shouldShowAsHeader) {
			setShowAsHeader(true);
		}
		!disableScrollTop && window.scrollTo({ top: 0 });

		if (autoDismiss) {
			setTimeout(() => {
				setVisibility(false);
				setShowAsHeader(false);
			}, HIDE_FLASH_AFTER);
		}
	};

	const close = () => setVisibility(false);
	return (
		<FlashContext.Provider value={{ display, close }}>
			<FlashMessage
				visible={visibility}
				text={text}
				type={type}
				isHeader={showAsHeader}
				isScroll={isScrollPosition}
				onClick={() => setVisibility(false)}
			/>
			{props.children}
		</FlashContext.Provider>
	);
};
