import * as React from 'react';

import classNames from 'classnames';
import styles from './close-button.scss';

interface CloseButtonProps {
	onClick: React.MouseEventHandler<HTMLDivElement>;
	className?: string;
}

const CloseButton = (props: CloseButtonProps) => {
	const { onClick, className } = props;
	return (
		<div className={styles.wrapper} onClick={onClick}>
			<svg fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M23.982 23.963 17.02 17l6.963-6.963M10.018 10.037 16.98 17l-6.963 6.963"
					className={classNames('path', className)}
					strokeWidth={2}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
};

export default CloseButton;
