import React from 'react';
import style from './style.scss';
import SavedIcon from '../../../components/common/icons/saved-icon';
import classNames from 'classnames';

interface Props {
	title: string;
	progress: string;
	handleSave: () => void;
}
export const CardHeader = (props: Props) => {
	const { title, handleSave, progress } = props;

	const hideSaveIcon = progress === 'applied' || progress === 'won' || progress === 'not_awarded';

	return (
		<div className={classNames(style.cardTitleWrapper)}>
			<div className={style.cardTitleContainer}>
				<h2 className={style.cardTitle}>{title}</h2>
			</div>
			<div className={style.rightIconsContainer}>
				{!hideSaveIcon && <SavedIcon backgroundColor={'#fff'} onClick={handleSave} progress={progress} />}
			</div>
		</div>
	);
};
