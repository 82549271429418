import * as React from 'react';
import Select from 'react-select';
import { ParameterValueType } from '@scholly/scholly-types';

import { ScreenStrings } from '../../constants';

import customStyles from './lib/CustomStyles';
import { getParameterValueToSelect } from '../../helpers/Functions';

import styles from './profile.scss';
import { UserFormParamsType } from '../../types';

type OUserBasicType = Pick<UserFormParamsType, 'degree'>;

type IProps = OUserBasicType & {
	onChangeText?: (property: keyof OUserBasicType) => (value: string) => void;
	parameter_values: ParameterValueType[];
	styles?: object;
	hasError?: boolean;
	noLabel?: boolean;
	onChangeTextOnboarding?: (value: string) => void;
};
export default class ProfileDegree extends React.Component<IProps> {
	static defaultProps = {
		styles: {},
	};
	_onChangeVal =
		(property: 'degree') =>
		({ value }) => {
			this.props.onChangeText(property)(value);
		};
	_renderDropdown() {
		const degree = this.props.parameter_values;

		return (
			<Select
				//@ts-ignore
				testID={`form_input_degree`}
				hasError={this.props.hasError}
				styles={customStyles(this.props)}
				placeholder={ScreenStrings.profile.seeking}
				value={getParameterValueToSelect(this.props.degree, degree)}
				onChange={
					this.props.onChangeTextOnboarding ? this.props.onChangeTextOnboarding : this._onChangeVal('degree')
				}
				options={degree}
				getOptionLabel={(options) => options.value}
				getOptionValue={(options) => options.id}
			/>
		);
	}
	render() {
		return (
			<div className={styles.profileWrapper}>
				<h3>{this.props.noLabel ? '' : ScreenStrings.profile.seeking}</h3>
				<div className={styles.profileContainer}>{this._renderDropdown()}</div>
			</div>
		);
	}
}
