import { Colors } from '@scholly/scholly-ui';
import { Dimensions, StyleSheet, View } from 'react-native';
import React from 'react';
import { useCategoriesDispatch, useCategoriesState } from '../../contexts';

import AccordionSimple from '../../components/common/Accordion/AccordionSimple';
import { CategoriesTypes } from '../../reducers';
import Icon from 'react-native-vector-icons/Entypo';
import ToggleIcon from '../../components/scholarship/ToggleIcon';
import subStyles from './categories.scss';

const { width } = Dimensions.get('window');

interface ItemObj {
	active: string;
	display: string;
	id: string;
	name: string;
	params: Array<Params>;
	sort_order: string | null;
}
interface Params {
	active: string;
	categories: Array<any>;
	id: string;
	parameter_id: string;
	replaced_by: string | null;
	sort_order: string;
	value: string;
}

type Props = {
	expanded: boolean;
	item: ItemObj;
	onItemPress: (item: ItemObj) => void;
	testID?: string;
	onPressTestID?: boolean;
	selectedSections?: any;
	setSelectedSections?: any;
	index?: number;
	setIndex?: any;
};

const SubCategory = ({ expanded, item, onItemPress, onPressTestID, testID }: Props) => {
	const categoriesDispatch = useCategoriesDispatch();
	const { selectedSubCategories } = useCategoriesState();
	const _renderList = (params) => {
		if (params) {
			return params.map((param: Params, i: number) => {
				const id = param.id;
				const checked = selectedSubCategories.includes(id);
				return (
					<div
						style={{
							marginTop: 5,
						}}
						key={param.id + i}
						data-testID={onPressTestID && (param.id + i).toString()}
					>
						<ToggleIcon
							size={24}
							onPress={() => {
								categoriesDispatch({
									type: CategoriesTypes.SelectSubCategories,
									payload: { selectedSubCategories: param.id },
								});
							}}
							checked={checked}
							title={param.value}
						/>
					</div>
				);
			});
		}
	};

	const _renderHeader = (item: ItemObj) => {
		return (
			<View style={styles.accordionHeader}>
				<p className={subStyles.subCategoryAccordionHeader}>{item.display}</p>

				<View style={styles.accordionIcon}>
					<p className={subStyles.dropDownNumber}>{item.params.length > 0 && item.params.length}</p>
					<Icon name="chevron-down" size={18} color={'#3259f1'} />
				</View>
			</View>
		);
	};

	const params = item.params;

	if (params.length === 0) {
		return null;
	}
	return (
		<View style={styles.root} testID={testID}>
			<AccordionSimple
				onPress={() => onItemPress(item)}
				expanded={expanded}
				key={item.id + item.display}
				header={_renderHeader(item)}
				content={_renderList(params)}
				containerStyles={{ maxHeight: '300px', background: '#fff' }}
				contentStyles={{ maxHeight: '280px' }}
				isScroll={true}
			/>
		</View>
	);
};

export default SubCategory;

const styles = StyleSheet.create({
	root: {
		// TODO: Calculate width for tablet, currently tablet will only show one colum, category must be smalle
		width: width > 416 ? 422 : 312,
		flexGrow: 1,
		marginVertical: 16,
		marginHorizontal: 8,
	},
	accordionHeader: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingTop: 1,
		paddingBottom: 2,
	},
	accordionIcon: { flex: 1, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' },
	textBlack: { color: Colors.black },
	flatlistContainer: {
		flex: 1,
		marginBottom: 16,
	},
});
