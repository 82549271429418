import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

interface Props {
	backAction?: () => void;
	strokeColor?: string;
	classes?: string;
	styles?: React.CSSProperties;
}
export const BackArrowButton = ({ backAction, classes, styles, strokeColor = '#A0A3BD' }: Props) => {
	const history = useHistory();
	const handleClick = () => {
		if (backAction) {
			backAction();
		} else {
			history.goBack();
		}
	};
	return (
		<div style={{ ...styles }} className={classNames({ [`${classes}`]: classes })}>
			<svg
				onClick={handleClick}
				width="34"
				height="34"
				viewBox="0 0 34 34"
				style={{ cursor: 'pointer', display: 'block', margin: '0 auto', textAlign: 'center' }}
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="17" cy="17" r="16.5" stroke={strokeColor} />
				<path
					d="M20 23.9258L13.0368 16.9626L20 9.99944"
					stroke={strokeColor}
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
};
