import * as React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch, withRouter } from 'react-router-dom';

import { FlashContext, ScholarshipContext, UserContext, SessionContext } from '../contexts';
import { Layout } from '../components/layouts/Layout';
import { Status, Routes } from '../constants';
import { isMobileOrTabletDevice } from '../helpers/checkIfDesktop';
import { trackpageview } from '../helpers/AnalyticsHelper';
import { Loader } from '@scholly/scholly-ui';
import config from '../config/config';

const Authentication = React.lazy(() => import('../pages/authentication'));
const Scholarship = React.lazy(() => import('../pages/scholarship'));
const Scholarships = React.lazy(() => import('../pages/scholarships'));
const AppliedScholarships = React.lazy(() => import('../pages/scholarships/applied'));
const SavedScholarships = React.lazy(() => import('../pages/scholarships/saved'));
const Dashboard = React.lazy(() => import('../pages/dashboard'));
const Categories = React.lazy(() => import('../pages/categories'));
const Onboarding = React.lazy(() => import('../pages/onboarding'));
const OnboardingSingle = React.lazy(() => import('../pages/onboarding/onboarding-single'));
const UpdateProfile = React.lazy(() => import('../pages/update-profile'));
const UpdateProfileSuccess = React.lazy(
	() => import('../pages/update-profile/profileUpdateSuccess/profile-update-success')
);
const RedirectScholarship = React.lazy(() => import('../pages/scholarship/RedirectScholarship'));
const Register = React.lazy(() => import('../pages/branding'));
const Reset = React.lazy(() => import('../pages/onboarding'));
const Offboarding = React.lazy(() => import('../pages/settings/offboarding'));
const Profile = React.lazy(() => import('../pages/profile'));
const Settings = React.lazy(() => import('../pages/settings'));
const ReportIssue = React.lazy(() => import('../pages/scholarship/ReportIssue'));
const SchollyFree = React.lazy(() => import('../pages/promos/scholly-free'));
const Refund = React.lazy(() => import('../pages/refund'));
const UnauthenticatedLanding = React.lazy(() => import('../pages/unauthenticated'));

interface IPrivateRouteProps extends RouteProps {
	component: any;
	isOnboarding?: boolean;
	isHideNav?: boolean;
}

interface IPublicRouteProps extends RouteProps {
	name?: string;
	route?: string;
}

const RestrictedRoute = (props: IPrivateRouteProps) => {
	const { component: Component, isOnboarding, isHideNav, ...rest } = props;
	const { isAuthenticated } = React.useContext(SessionContext);
	const flashContext = React.useContext(FlashContext);
	if (Status.REJECTED === isAuthenticated) {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const campaign = urlParams.get('featuredCampaignId');
		campaign && localStorage.setItem('featuredCampaign', campaign);
		campaign && flashContext.display({ text: 'Log in to view your scholarship', type: 'success' });
	}
	return (
		<Route
			{...rest}
			render={(routeProps: RouteProps) =>
				Status.REJECTED === isAuthenticated ? (
					<Redirect to={{ pathname: Routes.login, state: { requestedPath: rest.path } }} />
				) : (
					<Layout isOnboarding={isOnboarding}>
						<Component {...routeProps} />
					</Layout>
				)
			}
		/>
	);
};

const PublicRoute = (props: IPublicRouteProps) => {
	const { isAuthenticated } = React.useContext(SessionContext);

	const searchParams = new URLSearchParams(location.search);

	const redirectPath = searchParams.get('page') === 'refund' ? Routes.refund : Routes.dashboard;

	if (Status.RESOLVED == isAuthenticated) {
		return <Redirect to={redirectPath} />;
	}

	return <Route {...props} />;
};

const AppNavigator: React.FC<RouteComponentProps> = ({ location }) => {
	const scholarshipContext = React.useContext(ScholarshipContext);
	const userContext = React.useContext(UserContext);

	React.useEffect(() => trackpageview(location.pathname), [location]);

	// NOTE: load user scholarships after sign in
	React.useEffect(() => {
		if (userContext.merchant_type !== '' && scholarshipContext.status === Status.IDLE) {
			scholarshipContext.getAndSetScholarships();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userContext.merchant_type]);

	return (
		<React.Suspense fallback={<Loader />}>
			<Switch location={location}>
				<Route
					exact
					path={config.basename == '/' ? '/search' : Routes.search}
					component={UnauthenticatedLanding}
				/>
				<PublicRoute exact path={Routes.register} name="register" component={Authentication} />
				<PublicRoute exact path={Routes.login} name="login" component={Authentication} />
				<PublicRoute exact path={Routes.resetpassword} name="resetpassword" component={Authentication} />
				<RestrictedRoute exact path={Routes.redirect} component={RedirectScholarship} />
				<RestrictedRoute
					exact
					path={Routes.user.create}
					component={Onboarding}
					isHideNav={!isMobileOrTabletDevice}
					isOnboarding
				/>
				<RestrictedRoute
					exact
					path={Routes.user.updateProfile}
					component={UpdateProfile}
					isHideNav={!isMobileOrTabletDevice}
					isOnboarding
				/>
				<RestrictedRoute
					exact
					path={Routes.user.updateProfileSuccess}
					component={UpdateProfileSuccess}
					isHideNav={!isMobileOrTabletDevice}
					isOnboarding
				/>
				<RestrictedRoute exact path={Routes.user.profile} component={OnboardingSingle} />
				<RestrictedRoute exact path={Routes.user.myProfile} component={Profile} />
				<RestrictedRoute exact path={Routes.dashboard} component={Dashboard} />
				<RestrictedRoute exact path={Routes.scholarships.feed} component={Scholarships} />
				<RestrictedRoute exact path={Routes.scholarships.applied} component={AppliedScholarships} />
				<RestrictedRoute exact path={Routes.scholarships.saved} component={SavedScholarships} />
				<RestrictedRoute exact path={Routes.scholarships.reportIssue} component={ReportIssue} />
				<RestrictedRoute exact path={Routes.scholarships.slug} component={Scholarship} />
				<RestrictedRoute exact path={Routes.categories} component={Categories} />
				<RestrictedRoute exact path={Routes.settings} component={Settings} />
				<RestrictedRoute exact path={Routes.offboarding.main} component={Offboarding} />
				<RestrictedRoute exact path={Routes.schollyFree} component={SchollyFree} />
				<RestrictedRoute exact path={Routes.refund} component={Refund} />
				<Route path={Routes.brand} component={Register} />
				<Route exact path={Routes.reset} component={Reset} />
				<Redirect from="/" to={Routes.login} />
			</Switch>
		</React.Suspense>
	);
};

export default withRouter(AppNavigator);
