import { Button } from '@scholly/elements';
import React from 'react';
import { Status, Routes } from '../../../../constants';
import { UserContext, SessionContext } from '../../../../contexts';
import classNames from 'classnames';
import { deleteAccount } from '../../../../actions';
import styles from '../offboarding.scss';
import { useHistory } from 'react-router-dom';

const CancelSubscription = () => {
	const history = useHistory();
	const { subscription } = React.useContext(UserContext);
	// @ts-ignore
	const [status, setStatus] = React.useState(Status.IDLE);
	const sessionContext = React.useContext(SessionContext);

	// Hiding until we are certain we no longer need!
	// const cancelSub = async () => {
	// 	history.push(`${Routes.offboarding.home}/${Routes.offboarding.aspiration}`);
	// };

	const onClose = async () => {
		try {
			setStatus(Status.PENDING);
			await deleteAccount();
			setTimeout(() => {
				sessionContext.endSession();
				setStatus(Status.RESOLVED);
			}, 2000);
		} catch (err) {
			setStatus(Status.REJECTED);
			console.log('Error >>>>>>>', err);
		}
	};
	return (
		<div className={classNames(styles.pauseCardContainer, styles.cancelContainer)}>
			<div className={classNames(styles.isFlex, styles.justifyContentCenter)}>
				<div className={classNames(styles.pauseText, styles.pauseTitle, styles.cancelTitle)}>
					Are you sure you want to close your account?
				</div>
			</div>
			<div className={classNames(styles.isFlex, styles.justifyContentCenter, 'mt-6')}>
				<div className={classNames(styles.pauseText, styles.pauseDescription, styles.cancelDescription)}>
					If you close your account, all data and personal information will be erased permanently. You will
					also lose access to the flowing services: <br />
					<br />
					<ul>
						<li>Scholly Search </li>
						<li>Offers </li>
					</ul>
					<br />
					Are you sure you want to close your account?
				</div>
			</div>

			<div className={classNames(styles.cancelCtaContainer, 'mt-6')}>
				<Button
					variant="secondary"
					onClick={() => {
						history.push(Routes.settings);
					}}
					className={classNames(styles.pauseButton)}
				>
					Not Now
				</Button>
				<Button
					variant="primary"
					onClick={onClose}
					className={classNames(styles.pauseButton)}
					disabled={!subscription}
				>
					Close Account
				</Button>
			</div>
		</div>
	);
};
export default CancelSubscription;
