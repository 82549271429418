import React from 'react';

import Navigation from '../Navigation';
import Logo from '../../nav/Logo';
import HamburgerMenu from '../HamburgerMenu';

import styles from './header.scss';

const Header: React.FC = () => {
	return (
		<div className={styles.headerWrapper}>
			<div className={styles.headerContainer}>
				<header>
					<div className={styles.logoNavContainer}>
						<div className={styles.logoNavWrapper}>
							<Logo />
							<Navigation />
						</div>
						<div className={styles.hamburgerContainer}>
							<HamburgerMenu />
						</div>
					</div>
				</header>
			</div>
		</div>
	);
};

export default Header;
