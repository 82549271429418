import React, { useContext } from 'react';
import { PromoOffer } from '../../../types/offers';
import styles from './styles.scss';
import config from '../../../config/config';
import classNames from 'classnames';
import { UserContext } from '../../../contexts';

interface Props {
	offer: PromoOffer;
	isFromFeed?: boolean;
}

export const PromoOfferCard = ({ offer, isFromFeed }: Props) => {
	const { affiliate_subtext, display, affiliate, amount } = offer;
	const { name, avatar_img } = affiliate || {};
	const session = useContext(UserContext);

	const handleInfoClick = () => {
		window.open(
			'https://www.salliemae.com/landing/student-loans/?dtd_cell=SMLRSOPANLNLEFOTAGZ1005N010160&utm_source=scholly&utm_medium=aff&utm_campaign=actaff_schly_u_u_u_u_u_b&utm_content=AppLM&TPID=' +
				session.user_id,
			'_blank'
		);
	};

	return (
		<div
			className={classNames(styles.exclusiveOfferCard, isFromFeed && styles.exclusiveOfferCardFeed)}
			onClick={handleInfoClick}
		>
			<div>
				<img
					className={styles.exclusiveOfferCardLogo}
					src={`${config.cdn}images/offers/affiliate-imgs/${avatar_img || 'default-avatar.png'}`}
					alt="Offer Avatar"
				/>
			</div>
			<div className={styles.exclusiveOfferCardMiddle}>
				<h3 className={styles.exclusiveOfferCardMiddleTitle}>{name}</h3>
				<p className={styles.exclusiveOfferCardMiddleSubtext}>{display}</p>
			</div>
			<div className={styles.exclusiveOfferCardWrapper}>
				<p className={styles.exclusiveOfferCardAmount}>{amount}</p>
				<p className={styles.exclusiveOfferCardText}>{affiliate_subtext}</p>
			</div>
		</div>
	);
};
