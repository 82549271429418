import React, { useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getBranding } from '../actions';
import { Status, Routes } from '../constants';

type SchollyCodeType = {
	active: boolean;
	code: string;
};

type BrandingContextDefaultType = {
	active?: boolean;
	status: Status;
	dropdownOptions?: [any];
	getBrand: (str: string) => void;
};

type BrandingContextStateType = {
	slug: string;
	display_name: string;
	dropdown_label: string;
	dropdown_options: string[];
	landing_bg_img: string;
	logo_img: string;
	pretext: string;
	web_branding_img: string;
	mobile_branding_img: string;
	mobile_branding_card_img: string;
	subtext: string;
	url?: string;
	opt_in: string;
	opt_in_display: string;
	require_registration_code: boolean;
	registration_code: SchollyCodeType | null;
	ref_data2?: string;
};

type BrandingContextProps = {
	children: JSX.Element;
};
type BrandingContextType = BrandingContextDefaultType & BrandingContextStateType;

export const BrandingContext = createContext<BrandingContextType>(null);

export const BrandingContextProvider: React.FC<BrandingContextProps> = (props) => {
	const history = useHistory();
	const [branding, setBrandingData] = useState<BrandingContextStateType>(null);
	const [dropdownOptions, setDropdownOptions] = useState(null);
	const [active, setActive] = useState(null);
	const [status, setStatus] = useState(Status.IDLE);

	const getBrand = (branding: any) => {
		setStatus(Status.PENDING);
		getBranding(branding)
			.then((res) => {
				let {
					data: { branding_site },
				} = res;
				setBranding(branding_site);

				setStatus(Status.RESOLVED);
			})
			.catch((error) => {
				setStatus(Status.REJECTED);
				history.replace(Routes.login);
			});
	};

	const setBranding = (branding: any) => {
		if (!branding.active) {
			history.replace(Routes.login);
			return;
		}

		let { active, dropdown_options, ...data } = branding;
		if (dropdown_options) {
			let options = JSON.parse(dropdown_options);
			setDropdownOptions(options);
		}
		setActive(active);
		setBrandingData(data);
	};

	return (
		<BrandingContext.Provider value={{ ...branding, status, active, dropdownOptions, getBrand }}>
			{props.children}
		</BrandingContext.Provider>
	);
};
