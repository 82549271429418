import React from 'react';
import { SessionContext, FlashContext } from '../contexts';
import differenceWith from 'lodash/differenceWith';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { profileReducer, ProfileState, ProfileFieldsType, UserDispatchTypes } from '../reducers';

import { Status, HTTPStatus, Routes, ScreenStrings } from '../constants';
import { STORAGE_KEYS } from '../constants/StorageKeys';

import { getLocalUser, getUser } from '../actions';
import { missingReq, userParamCreate } from '../helpers/Functions';
import { useLocation } from 'react-router-dom';

type ProfileProviderProps = { children: React.ReactNode; type?: 'single' | 'step' };

type ContextType = {
	data: ProfileState;
	errors?: any[];
	status?: Status;
	changeState: (payload: { [key: string]: any }) => void;
	setErrors?: (values?: any[]) => void;
	updateStatus?: (values: Status) => void;
};

const initialState: ProfileState = {
	user_id: null,
	user_email: '',
	full_name: '',
	first_name: '',
	last_name: '',
	birthday: '',
	profile_img: '',
	phone_number: '',
	missingReq: false,
	userRefer: false,
	sms_opt_in: false,
	hasError: [],
	profileStatus: Status.IDLE,
	touched: {},
};

export const ProfileStateContext = React.createContext<ContextType>(null);

// * LAZYLOAD
const init = (initialState: ProfileState) => initialState;

export const ProfileProvider = ({ children, type = 'single' }: ProfileProviderProps) => {
	const location = useLocation();
	const { isAuthenticated, endSession } = React.useContext(SessionContext);

	const flash = React.useContext(FlashContext);
	const [state, dispatch] = React.useReducer(profileReducer, initialState, init);

	const getAndSetUser = async () => {
		dispatch({ type: UserDispatchTypes.SET_STATUS, payload: Status.PENDING });

		const res = await getUser().catch((e: any) => {
			if (e?.status && e?.status === 401) {
				endSession();
			} else {
				dispatch({ type: UserDispatchTypes.SET_STATUS, payload: Status.REJECTED });
			}
		});
		if (res?.status !== HTTPStatus.OK) {
			dispatch({ type: UserDispatchTypes.SET_STATUS, payload: Status.REJECTED });
		} else {
			dispatch({ type: UserDispatchTypes.SET_USER, payload: { user: res.data.user } });
		}
	};

	const setErrors = (payload: any[]) => dispatch({ type: UserDispatchTypes.SET_MISSING, payload });
	const updateStatus = (payload: Status) => dispatch({ type: UserDispatchTypes.SET_STATUS, payload });

	const changeState = (payload: ProfileFieldsType) => dispatch({ type: UserDispatchTypes.ON_CHANGE_TEXT, payload });

	React.useLayoutEffect(() => {
		if (isAuthenticated === Status.RESOLVED) {
			getAndSetUser();
		}
	}, [isAuthenticated]);

	React.useEffect(() => {
		if (Routes.user.myProfile === location.pathname && state.profileStatus === Status.RESOLVED) {
			const localUser = JSON.parse(localStorage.getItem(STORAGE_KEYS.USER));
			if (missingReq(localUser)) flash.display({ text: ScreenStrings.profile.missingFields, type: 'error' });
		}
	}, [location]);

	const data = Object.assign({}, omit(state, ['profileStatus', 'hasError', 'user_email', 'user_id']));

	return (
		<ProfileStateContext.Provider
			//@ts-ignore
			value={{ data, status: state.profileStatus, errors: state.hasError, changeState, setErrors, updateStatus }}
		>
			{children}
		</ProfileStateContext.Provider>
	);
};

export const useProfileState = () => {
	const context = React.useContext(ProfileStateContext);
	if (context === undefined) {
		console.error('useProfileState must be used within a ProfileProvider');
	}
	return context as ContextType;
};

export const updateUserInfo = async (newProperties: ProfileState) => {
	const { first_name, last_name, birthday, user_type, phone_number } = await getLocalUser();
	const currentUser = {
		first_name,
		last_name,
		birthday,
		user_type,
		phone_number,
	};
	// Changes
	const updatedUser = {
		first_name: newProperties.first_name,
		last_name: newProperties.last_name,
		birthday: newProperties.birthday,
		user_type: newProperties.user_type,
		...(newProperties.phone_number && { phone_number: newProperties.phone_number }),
	};

	const [diffUserValues] = differenceWith([updatedUser], [currentUser], isEqual);
	return diffUserValues;
};

export const updateUserParameters = async (newProperties: object) => {
	const currentUser = await getLocalUser();
	let currentParams;
	let updatedParams;

	if (currentUser.parameters.length > 0) {
		currentParams = userParamCreate(currentUser.parameters);
	}

	updatedParams = {
		parameters: {
			...currentParams,
			...newProperties,
		},
	};

	return updatedParams;
};
// @ts-ignore
export const updateUser = async (dispatch, state, parameter_values, userAction, parametersAction) => {
	const { citizen, degree, gender, gpa, grade, major, race, enrollment_status } = parameter_values;

	const name = state.full_name.split(' ');
	const first_name = name[0];
	const last_name = state.full_name.substring(name[0].length).trim();
	try {
		// *NOTE: Location is updated immediately on ProfileLocation

		// dispatch({ type: Types.Started });
		const data = {
			first_name,
			last_name,
			birthday: state.birthday,
			...(state.phone_number && { phone_number: state.phone_number }),
			parameters: {
				...(state.gender && {
					// @ts-ignore
					gender: { value: gender.find((p) => p.value === state.gender).id },
				}),
				...(state.race && {
					// @ts-ignore
					race: { value: [race.find((p) => p.value === state.race).id] },
				}),
				...(state.citizen && {
					// @ts-ignore
					citizen: { value: citizen.find((p) => p.value === state.citizen).id },
				}),
				// @ts-ignore
				...(state.gpa && { gpa: { value: gpa.find((p) => p.value === state.gpa).id } }),
				...(state.degree && {
					// @ts-ignore
					degree: { value: degree.find((p) => p.value === state.degree).id },
				}),
				...(state.grade && {
					// @ts-ignore
					grade: { value: grade.find((p) => p.value === state.grade).id },
				}),
				...(state.major && {
					// @ts-ignore
					major: { value: [major.find((p) => p.value === state.major).id] },
				}),
				...(state.enrollment_status && {
					// @ts-ignore
					enrollment_status: {
						enrollment_status: [
							enrollment_status.find((p: { value: any }) => p.value === state.enrollment_status).id,
						],
					},
				}),
			},
		};

		await userAction(data);
		await parametersAction(data.parameters);
		// dispatch({ type: Types.Success });
	} catch (error) {
		console.log('UpdateUserError=>', error);
		// dispatch({ type: Types.Error });
	}
};
