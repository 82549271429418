const config = {
	title: 'Scholly Search',
	cdn_s3: 'https://scholly-app.s3-us-west-2.amazonaws.com/dev/',
	cdn: 'https://d2vgyars8fwaz0.cloudfront.net/live/',
	public_path: '/search/',
	API: {
		url: 'https://www.myscholly.com/api/',
	},
	stripe: {
		key: 'pk_live_lltjrpX3g0z2qstu6j3ds3Oe',
	},
	google_key: 'AIzaSyCgbtcNbUVomo0qpxCUNm34L5DGdXmdH3s',
	sentry_dsn: 'https://7c507b355bb6499d80fdff59ce031176@o25814.ingest.sentry.io/6378012',
	same_site: {
		name: '_sssu',
		options: {
			sameSite: !0,
			domain: 'myscholly.com',
		},
	},
	basename: 'search/',
	ga: {
		id: 'G-7XTVV898WQ',
		options: {},
	},
	gtm: {
		id: 'GTM-NBQ86FS',
	},
	braze: {
		api_key: 'f8fb71ff-7583-46dd-8048-f23b67cb05b9',
		sdk: 'https://sdk.iad-06.braze.com',
	},
	friend_buy: '_fbuy_ref',
	facebook: {
		app_id: '153540071673397',
	},
	play_store: 'https://play.google.com/store/apps/details?id=com.scholly.scholarshipsearch',
	app_store: 'https://apps.apple.com/us/app/scholly-college-scholarships/id1048625540',
	icon: 'https://scholly-app.s3.amazonaws.com/live/images/icons/scholly_icon_2x.png',
	homepage: 'https://myscholly.com/',
	contact: 'info@myscholly.com',
	terms_url: 'https://myscholly.com/terms-of-service',
	terms_and_conditions_url: 'https://myscholly.com/terms-and-conditions/',
	do_not_sell_url: 'https://www.nitrocollege.com/legal/do-not-sell-my-information',
	privacy_policy_url: 'https://myscholly.com/privacy',
	scholly_nitro_privacy_policy: 'https://myscholly.com/scholly-privacy-policies',
	messaging_terms_url: 'https://myscholly.com/sms',
	payoff_url: 'https://myscholly.com/payoff/',
	blog: 'https://myscholly.com/blog/',
	offers_url: 'https://myscholly.com/offers/',
	contact_url: 'https://myscholly.com/contact',
	editor_promo: 'https://myscholly.com/editor',
	editor_url: 'https://editor.stage.myscholly.com/',
	exclusives_url: 'https://myscholly.com/opportunities/',
	partner_with_us_url: 'https://myscholly.com/partnerships/',
	testimonials_url: 'https://myscholly.com/scholly-reviews-testimonials/',
	faq_url: 'https://myscholly.com/faqs/',
	press_url: 'https://myscholly.com/press/',
	social: {
		twitter: 'https://twitter.com/myscholly',
		instagram: 'https://www.instagram.com/my.scholly',
		facebook: 'https://www.facebook.com/MyScholly/',
	},
};
export default config;
