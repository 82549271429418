import React from 'react';
import { markdown } from 'markdown';
import saveMoneyIcon from '../../assets/save-money.png';
import makeMoneyIcon from '../../assets/make-money.png';
import growMoneyIcon from '../../assets/grow-money.png';
import styles from './affiliate.scss';
import Button from './button';
import { OPPORTUNITY_TYPE, OPPORTUNITY_TYPE_COLORS, UNQUALIFIED_GRAY } from '../../constants/offers';
import classNames from 'classnames';
import config from '../../config/config';
// import TooltipStatus from "../tooltip-status/tooltip-status";

import { OfferStatus } from '../../types/offers';
import { UserContext } from '../../contexts';

interface Props {
	offerAffiliateSubtext: string;
	affiliateName: string;
	avatarImg?: string;
	guaranteedCashAmount: string;
	amountDisplay?: string;
	status: string;
	color: string;
	offerCta?: string;
	infoCta?: string;
	isQualifiedFor: boolean;
	startTime?: Date;
	endTime?: Date;
	entryAmount?: number;
	entryString?: string;
	opportunityType: string;
	onClickApply?: React.MouseEventHandler<HTMLButtonElement>;
	onClickInfo?: React.MouseEventHandler<HTMLButtonElement>;
}

const Affiliate: React.FC<Props> = (props) => {
	const {
		affiliateName,
		avatarImg,
		offerAffiliateSubtext,
		guaranteedCashAmount,
		entryAmount,
		entryString,
		color,
		offerCta,
		infoCta,
		opportunityType,
		status,
		isQualifiedFor = true,
		onClickApply,
		onClickInfo,
	} = props;
	const user = React.useContext(UserContext);

	const [showStatusTooltip, setShowStatusTooltip] = React.useState<string>('');

	const affiliateCardConfig = {
		[OPPORTUNITY_TYPE.GROW_MONEY]: {
			icon: growMoneyIcon,
			color: OPPORTUNITY_TYPE_COLORS.GROW_MONEY,
		},
		[OPPORTUNITY_TYPE.SAVE_MONEY]: {
			icon: saveMoneyIcon,
			color: OPPORTUNITY_TYPE_COLORS.SAVE_MONEY,
		},
		[OPPORTUNITY_TYPE.MAKE_MONEY]: {
			icon: makeMoneyIcon,
			color: OPPORTUNITY_TYPE_COLORS.MAKE_MONEY,
		},
	};

	const entryBoxStyle = {
		background: !color ? UNQUALIFIED_GRAY : '#' + color,
	};

	const goToOffersLogin = () => {
		const windowReference = window.open();
		windowReference.location.href = `${config.offers_url}?m=login&email=${user?.user_email}`;
	};
	const btn = () => {
		switch (status) {
			case OfferStatus.ACTIVE:
				return <Button isPrimary label={offerCta ? offerCta : `Get $${entryAmount}`} onClick={onClickApply} />;
			case OfferStatus.PENDING:
				return <Button isOutlinedActive label="Incomplete" onClick={onClickApply} />;
			case OfferStatus.NOT_MATCHED:
				return (
					<Button isPrimary label={offerCta ? offerCta : `Get $${entryAmount}`} onClick={goToOffersLogin} />
				);
		}
	};

	return (
		<div className={classNames(styles.affiliateWrapper)}>
			<div className={styles.affiliateContainer}>
				<div className={styles.topSection}>
					<div className={styles.entryBox} style={entryBoxStyle}>
						{entryAmount && <span className={styles.entryAmount}>${entryAmount}</span>}
						{entryString && <span className={styles.entryString}>{entryString}</span>}
					</div>
					<div className={styles.timeOptionsContainer}>
						<div className={styles.affiliateIconContainer}>
							{affiliateCardConfig[opportunityType]?.icon && (
								<img src={affiliateCardConfig[opportunityType]?.icon} alt="affiliate card icon" />
							)}
						</div>
					</div>
				</div>
				<div className={classNames(styles.midSection, !isQualifiedFor && styles.unqualifiedGray)}>
					<img
						className={styles.affiliateLogo}
						src={`${config.cdn}images/offers/affiliate-imgs/${avatarImg || 'default-avatar.png'}`}
						alt={`${affiliateName} logo`}
					/>

					<div>
						<div className={classNames(styles.affiliateInfo, !isQualifiedFor && styles.unqualifiedGray)}>
							<span className={styles.affiliateName}>{affiliateName}</span>
							<span className={styles.affiliateExcerpt}>{offerAffiliateSubtext}</span>
						</div>

						<div
							className={classNames(
								styles.offerDescriptionContainer,
								!isQualifiedFor && styles.unqualifiedGray
							)}
						>
							{/* TODO: Find out which value the marketing team will be adding content to */}
							{/* using this one below because it has more content atm */}
							{guaranteedCashAmount && (
								<div
									className={styles.cashAmount}
									dangerouslySetInnerHTML={{
										__html: markdown.toHTML(guaranteedCashAmount),
									}}
								/>
							)}
						</div>
					</div>
				</div>
				<div className={styles.bottomSection}>
					<div className={styles.dualButtons}>
						<div
							onMouseEnter={() => {
								if (status === 'completed' || status === 'pending') {
									setShowStatusTooltip(status);
								}
							}}
							onMouseLeave={() => {
								if (status === 'completed' || status === 'pending') {
									setShowStatusTooltip('');
								}
							}}
						>
							{/*<TooltipStatus*/}
							{/*    userEvents={userEvents}*/}
							{/*    entryAmount={entryAmount}*/}
							{/*    affiliateName={affiliateName}*/}
							{/*    isOpen={showStatusTooltip}*/}
							{/*/>*/}

							{btn()}
						</div>

						<Button
							isOutlined
							label={infoCta || 'Details'}
							onClick={onClickInfo}
							className={styles.detailsButton}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Affiliate;
