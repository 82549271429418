import { Button } from '@scholly/elements';
import CloseButton from '../modal/close-button/close-button';
import CompletionIcon from '../icons/completion-icon';
import Modal from 'react-modal';
import React from 'react';
import classNames from 'classnames';
import modalStyles from './offer-completion-modal.scss';
import styles from '../../components/common/common.scss';

interface offerCompletionModalProps {
	handleClose: () => void;
	isOpen: boolean;
}
const OfferCompletionModal = (props: offerCompletionModalProps) => {
	const { handleClose, isOpen } = props;
	return (
		<Modal
			isOpen={isOpen}
			className={classNames(modalStyles.modal, modalStyles.wrapper)}
			overlayClassName={styles.modalOverlay}
		>
			<div>
				<CloseButton onClick={handleClose} />
			</div>
			<div className={modalStyles.wrapper}>
				<div className={modalStyles.offerCompleteTopSection}>
					<h3 className={modalStyles.header}>Congratulations!</h3>
					<p className={modalStyles.subHeader}>You now have a Lifetime Subscription to Scholly Search.</p>
					<CompletionIcon />
				</div>
				<div className={modalStyles.offerCompleteBottomSection}>
					<p className={modalStyles.message}>The #1 college scholarship app</p>
					<Button variant="primary" style={{ width: '100%', boxSizing: 'border-box' }} onClick={handleClose}>
						Get Started
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default OfferCompletionModal;
