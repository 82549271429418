import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Confetti from 'react-confetti';
import { Button } from '@scholly/elements';

import { Feature, ScholarshipContext, UserContext } from '../../contexts';

import Loader from '../../pages/commonapp/loader';

import config from '../../config/config';
import { FLAGS, Routes, ScreenStrings, ButtonStrings } from '../../constants';

import styles from './total-match.scss';

const happyImage = `${config.cdn}images/happy-free-subscription-people.png`;

const TotalMatch = ({ showConfetti }: { showConfetti?: boolean }) => {
	const history = useHistory();
	const [matches, setMatches] = React.useState(0);
	const [amountOfConfettiPieces, setAmountOfConfettiPieces] = useState(500);
	const { scholarshipTotal, getScholarshipTotal } = React.useContext(ScholarshipContext);
	const { showFreeAccountActivated } = React.useContext(UserContext);
	useEffect(() => {
		if (showConfetti) {
			setTimeout(() => {
				setAmountOfConfettiPieces(0);
			}, 5000);
		}
	}, []);

	useEffect(() => {
		if (scholarshipTotal !== 0) {
			setMatches(scholarshipTotal);
		}
		if (!scholarshipTotal) {
			getScholarshipTotal();
		}
	}, [getScholarshipTotal, scholarshipTotal]);

	const viewScholarships = () => {
		history.push(Routes.scholarships.feed);
	};

	const getMoreMatches = () => {
		history.push(Routes.categories);
	};

	if (!scholarshipTotal) {
		return (
			<div className={styles.matchesWrapper}>
				<Loader />
			</div>
		);
	}

	return (
		<div className={styles.matchesWrapper}>
			{showConfetti && (
				<Confetti
					numberOfPieces={amountOfConfettiPieces}
					width={window.innerWidth}
					height={window.innerHeight}
				/>
			)}
			<span className={styles.matchesAmount}>
				{Number(matches).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
			</span>
			<span className={styles.matchesText}>{ScreenStrings.onboarding.emptyQuickApply}</span>

			{showFreeAccountActivated && (
				<Feature name={FLAGS.SCHOLLY_FREE}>
					<div className={styles.freeAccountContainer}>
						<img
							className={styles.freeAccountImage}
							src={happyImage}
							alt="Scholly is now free! Start Earning Today!"
						/>
					</div>
				</Feature>
			)}
			<Button onClick={viewScholarships} className={'mt-4'} variant={'primary'}>
				{ButtonStrings.viewScholarships}
			</Button>
			<Button variant="secondary" onClick={getMoreMatches} className={'mt-4'}>
				{ButtonStrings.getMoreMatches}
			</Button>
		</div>
	);
};

export default TotalMatch;
