import React, { CSSProperties, FC } from 'react';

interface UserBadgeIconProps {
	svgStyle?: CSSProperties;
}
export const UserBadgeIcon: FC<UserBadgeIconProps> = (props) => {
	const { svgStyle } = props;
	return (
		<div>
			<div className="content">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="140"
					height="137"
					viewBox="0 0 140 137"
					fill="none"
					style={svgStyle}
				>
					<path
						opacity="0.1"
						d="M81.465 19.6572C72.1644 22.0856 63.2194 24.0487 53.8062 24.1374C37.9537 24.2863 22.9998 35.1793 16.6996 49.8669C10.3995 64.5544 12.4576 82.2275 20.8636 95.7256C34.0354 116.86 67.8754 133.782 89.6609 115.005C92.9211 112.197 95.7566 108.693 99.7059 107.02C105.744 104.461 113.676 106.475 118.325 101.819C121.57 98.5715 121.718 93.2898 120.635 88.8246C119.551 84.3594 117.464 80.1395 116.949 75.5713C116.879 74.9316 116.837 74.2947 116.822 73.6606C116.649 65.4094 121.093 57.4481 121.617 49.1093C122.229 39.3872 117.129 29.7007 109.193 24.1796C101.257 18.6585 90.8313 17.2151 81.465 19.6572Z"
						fill="#3259F1"
					/>
					<path
						d="M114.743 51.1016H24.7707C21.3652 51.1016 18.6045 53.8717 18.6045 57.2889V99.5522C18.6045 102.969 21.3652 105.74 24.7707 105.74H114.743C118.148 105.74 120.909 102.969 120.909 99.5522V57.2889C120.909 53.8717 118.148 51.1016 114.743 51.1016Z"
						fill="#2D50C1"
					/>
					<path
						d="M114.743 37.6445H86.8277L87.2597 38.9431C87.8846 40.8226 87.589 42.9419 86.4878 44.4749C85.6345 45.6634 84.3846 46.3438 83.0621 46.3438H56.4498C55.1274 46.3438 53.8775 45.6634 53.0242 44.4749C51.923 42.9419 51.6273 40.8226 52.2523 38.9431L52.6842 37.6445H24.7725C23.9626 37.6443 23.1606 37.8042 22.4122 38.115C21.6639 38.4258 20.9839 38.8816 20.4112 39.4561C19.8384 40.0307 19.384 40.7129 19.0741 41.4637C18.7641 42.2145 18.6045 43.0192 18.6045 43.8319V91.6803C18.6045 93.3218 19.2544 94.896 20.4111 96.0567C21.5678 97.2174 23.1366 97.8694 24.7725 97.8694H114.743C115.553 97.8694 116.355 97.7093 117.103 97.3983C117.851 97.0872 118.531 96.6313 119.104 96.0566C119.676 95.4818 120.131 94.7996 120.44 94.0487C120.75 93.2978 120.909 92.493 120.909 91.6803V43.8319C120.909 43.0193 120.75 42.2146 120.44 41.4638C120.131 40.713 119.676 40.0308 119.104 39.4562C118.531 38.8816 117.851 38.4259 117.103 38.1151C116.355 37.8042 115.553 37.6443 114.743 37.6445Z"
						fill="#3259F1"
					/>
					<path
						d="M111.394 59.8491H68.593C68.4756 59.8491 68.363 59.8024 68.28 59.7191C68.197 59.6358 68.1504 59.5228 68.1504 59.405C68.1504 59.2873 68.197 59.1743 68.28 59.091C68.363 59.0077 68.4756 58.9609 68.593 58.9609H111.387C111.504 58.9609 111.616 59.0077 111.699 59.091C111.782 59.1743 111.829 59.2873 111.829 59.405C111.829 59.5216 111.783 59.6335 111.702 59.7166C111.621 59.7996 111.51 59.8473 111.394 59.8491Z"
						fill="#95CBED"
					/>
					<path
						d="M111.394 69.4859H68.593C68.4756 69.4859 68.363 69.4391 68.28 69.3558C68.197 69.2725 68.1504 69.1596 68.1504 69.0418C68.1504 68.924 68.197 68.811 68.28 68.7277C68.363 68.6444 68.4756 68.5977 68.593 68.5977H111.387C111.504 68.5977 111.616 68.6444 111.699 68.7277C111.782 68.811 111.829 68.924 111.829 69.0418C111.829 69.1583 111.783 69.2702 111.702 69.3533C111.621 69.4364 111.51 69.484 111.394 69.4859Z"
						fill="#95CBED"
					/>
					<path
						d="M111.394 79.1304H68.593C68.4756 79.1304 68.363 79.0836 68.28 79.0003C68.197 78.917 68.1504 78.8041 68.1504 78.6863C68.1504 78.5685 68.197 78.4555 68.28 78.3723C68.363 78.289 68.4756 78.2422 68.593 78.2422H111.387C111.504 78.2422 111.616 78.289 111.699 78.3723C111.782 78.4555 111.829 78.5685 111.829 78.6863C111.829 78.8029 111.783 78.9147 111.702 78.9978C111.621 79.0809 111.51 79.1285 111.394 79.1304Z"
						fill="#95CBED"
					/>
					<path
						d="M82.2517 44.9852H57.2628C54.8905 44.9852 53.2795 41.9776 54.1895 39.2455L55.3898 35.6304C55.8695 34.1897 56.9973 33.25 58.249 33.25H81.2638C82.5137 33.25 83.6432 34.1897 84.1212 35.6304L85.3233 39.2455C86.2351 41.9776 84.624 44.9852 82.2517 44.9852Z"
						fill="#2D50C1"
					/>
					<path
						d="M115.446 40.9732C120.513 40.1649 121.545 39.131 122.35 34.0451C122.36 33.993 122.387 33.9458 122.427 33.9119C122.468 33.8779 122.519 33.8594 122.572 33.8594C122.624 33.8594 122.675 33.8779 122.716 33.9119C122.756 33.9458 122.784 33.993 122.793 34.0451C123.598 39.1293 124.629 40.1631 129.697 40.9732C129.749 40.9826 129.796 41.0099 129.83 41.0505C129.864 41.0911 129.882 41.1423 129.882 41.1953C129.882 41.2482 129.864 41.2994 129.83 41.34C129.796 41.3806 129.749 41.408 129.697 41.4173C124.631 42.2238 123.6 43.2595 122.793 48.3454C122.784 48.3975 122.756 48.4447 122.716 48.4786C122.675 48.5126 122.624 48.5311 122.572 48.5311C122.519 48.5311 122.468 48.5126 122.427 48.4786C122.387 48.4447 122.36 48.3975 122.35 48.3454C121.547 43.2613 120.514 42.2256 115.446 41.4173C115.394 41.408 115.347 41.3806 115.313 41.34C115.279 41.2994 115.261 41.2482 115.261 41.1953C115.261 41.1423 115.279 41.0911 115.313 41.0505C115.347 41.0099 115.394 40.9826 115.446 40.9732Z"
						fill="#FFC064"
					/>
					<path
						d="M118.151 20.5094C120.346 20.0937 120.964 19.4737 121.379 17.2709C121.389 17.2198 121.416 17.1739 121.457 17.1409C121.497 17.1079 121.547 17.0898 121.599 17.0898C121.651 17.0898 121.701 17.1079 121.741 17.1409C121.782 17.1739 121.809 17.2198 121.819 17.2709C122.232 19.4737 122.85 20.0937 125.045 20.5094C125.096 20.5196 125.142 20.5473 125.175 20.5876C125.208 20.6279 125.225 20.6784 125.225 20.7305C125.225 20.7826 125.208 20.8331 125.175 20.8735C125.142 20.9138 125.096 20.9414 125.045 20.9517C122.85 21.3656 122.232 21.9856 121.819 24.1884C121.809 24.2394 121.782 24.2854 121.741 24.3184C121.701 24.3514 121.651 24.3694 121.599 24.3694C121.547 24.3694 121.497 24.3514 121.457 24.3184C121.416 24.2854 121.389 24.2394 121.379 24.1884C120.964 21.9856 120.346 21.3656 118.151 20.9517C118.1 20.9414 118.055 20.9138 118.022 20.8735C117.989 20.8331 117.971 20.7826 117.971 20.7305C117.971 20.6784 117.989 20.6279 118.022 20.5876C118.055 20.5473 118.1 20.5196 118.151 20.5094Z"
						fill="#FFC064"
					/>
					<path
						d="M101.473 19.0512C102.358 18.8274 102.712 18.4721 102.93 17.5874C102.942 17.5389 102.97 17.496 103.01 17.4653C103.049 17.4346 103.098 17.418 103.147 17.418C103.197 17.418 103.246 17.4346 103.285 17.4653C103.325 17.496 103.353 17.5389 103.365 17.5874C103.588 18.4756 103.941 18.8309 104.822 19.0512C104.871 19.063 104.914 19.0908 104.945 19.1303C104.976 19.1697 104.993 19.2185 104.993 19.2688C104.993 19.319 104.976 19.3678 104.945 19.4073C104.914 19.4468 104.871 19.4747 104.822 19.4864C103.937 19.7103 103.583 20.0655 103.365 20.9502C103.353 20.9987 103.325 21.0416 103.285 21.0723C103.246 21.103 103.197 21.1197 103.147 21.1197C103.098 21.1197 103.049 21.103 103.01 21.0723C102.97 21.0416 102.942 20.9987 102.93 20.9502C102.707 20.062 102.354 19.7067 101.473 19.4864C101.424 19.4747 101.381 19.4468 101.35 19.4073C101.319 19.3678 101.302 19.319 101.302 19.2688C101.302 19.2185 101.319 19.1697 101.35 19.1303C101.381 19.0908 101.424 19.063 101.473 19.0512Z"
						fill="#FFC064"
					/>
					<path
						d="M97.1078 28.7907C109.132 27.8509 110.727 26.2504 111.664 14.1848C111.664 14.1254 111.687 14.0685 111.729 14.0265C111.771 13.9845 111.828 13.9609 111.887 13.9609C111.946 13.9609 112.003 13.9845 112.045 14.0265C112.086 14.0685 112.11 14.1254 112.11 14.1848C113.047 26.2504 114.642 27.8509 126.666 28.7907C126.725 28.7907 126.782 28.8143 126.824 28.8562C126.866 28.8982 126.889 28.9551 126.889 29.0145C126.889 29.0739 126.866 29.1308 126.824 29.1728C126.782 29.2148 126.725 29.2383 126.666 29.2383C114.642 30.1781 113.047 31.7786 112.11 43.8442C112.11 43.9036 112.086 43.9605 112.045 44.0025C112.003 44.0445 111.946 44.0681 111.887 44.0681C111.828 44.0681 111.771 44.0445 111.729 44.0025C111.687 43.9605 111.664 43.9036 111.664 43.8442C110.727 31.7786 109.132 30.1781 97.1078 29.2383C97.0486 29.2383 96.992 29.2148 96.9501 29.1728C96.9083 29.1308 96.8848 29.0739 96.8848 29.0145C96.8848 28.9551 96.9083 28.8982 96.9501 28.8562C96.992 28.8143 97.0486 28.7907 97.1078 28.7907Z"
						fill="#FFC064"
					/>
					<path
						d="M56.0685 52.2734H33.1016C31.4447 52.2734 30.1016 53.6166 30.1016 55.2734V83.9312C30.1016 85.5881 31.4447 86.9312 33.1016 86.9312H56.0685C57.7254 86.9312 59.0685 85.5881 59.0685 83.9312V55.2734C59.0685 53.6166 57.7254 52.2734 56.0685 52.2734Z"
						fill="white"
					/>
					<mask id="mask0_7325_25396" maskUnits="userSpaceOnUse" x="30" y="52" width="30" height="35">
						<path
							d="M56.0685 52.2734H33.1016C31.4447 52.2734 30.1016 53.6166 30.1016 55.2734V83.9312C30.1016 85.5881 31.4447 86.9312 33.1016 86.9312H56.0685C57.7254 86.9312 59.0685 85.5881 59.0685 83.9312V55.2734C59.0685 53.6166 57.7254 52.2734 56.0685 52.2734Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask0_7325_25396)">
						<path
							d="M61.351 80.5795V80.1016C58.405 81.9055 55.4292 83.6826 52.4812 85.4287C49.9472 86.9361 47.4066 88.4588 44.8594 89.9965H45.6529C47.9978 88.5774 50.3414 87.174 52.6836 85.7866C55.5582 84.0715 58.4625 82.3421 61.351 80.5795Z"
							fill="#2D50C1"
							fillOpacity="0.24"
						/>
						<path
							d="M61.3514 72.0006V71.5273C56.3829 74.5569 51.3 77.4446 46.2793 80.2964C40.7447 83.4397 35.1358 86.627 29.6875 89.998H30.4463C35.7179 86.7548 41.1321 83.6793 46.476 80.6418C51.4305 77.834 56.4452 74.9862 61.3514 72.0006Z"
							fill="#2D50C1"
							fillOpacity="0.24"
						/>
						<path
							d="M61.3514 62.7763V62.293C54.5849 66.8382 47.4433 71.0139 40.4248 75.1164C36.4165 77.4581 32.3666 79.8261 28.3682 82.2734V82.7426C32.43 80.2547 36.5455 77.848 40.6213 75.4657C47.5703 71.4058 54.6345 67.2729 61.3514 62.7763Z"
							fill="#2D50C1"
							fillOpacity="0.24"
						/>
						<path
							d="M61.3514 53.5397V53.0547C52.8903 59.1253 43.5422 64.4309 34.3985 69.6163C32.3944 70.7546 30.3842 71.8994 28.3682 73.0508V73.5043C30.4451 72.3121 32.5207 71.1292 34.595 69.9554C43.6633 64.8134 52.936 59.5531 61.3514 53.5397Z"
							fill="#2D50C1"
							fillOpacity="0.24"
						/>
						<path
							d="M52.7758 50.418H52.0669C44.4877 55.4698 36.3498 60.0199 28.3682 64.4799V64.9306L28.5684 64.817C36.7263 60.259 45.0584 55.6089 52.7758 50.418Z"
							fill="#2D50C1"
							fillOpacity="0.24"
						/>
						<path
							d="M37.524 50.418C34.5033 52.2939 31.4377 54.1128 28.3682 55.9022V56.355C31.6834 54.4219 34.9986 52.4534 38.2631 50.418H37.524Z"
							fill="#2D50C1"
							fillOpacity="0.24"
						/>
						<path
							d="M31.6301 87.1877C30.9817 87.1875 30.4501 86.6331 30.5236 85.9889C30.6242 85.0974 30.8079 84.2172 31.0725 83.3596C31.6745 81.3823 32.6834 79.5513 34.0351 77.9831C34.3416 77.6258 34.6661 77.2843 35.0075 76.9598C35.856 76.1519 36.8012 75.4512 37.8219 74.8734C38.1349 74.6959 38.5254 74.7387 38.8148 74.9525C40.4786 76.1818 42.5024 76.8461 44.5853 76.8367C46.6672 76.8367 48.5263 76.226 50.1892 75.0612C50.5827 74.7856 51.1027 74.7295 51.5212 74.9653C52.3487 75.4483 53.1247 76.0137 53.8373 76.6529C55.0351 77.7269 56.0483 78.9888 56.8364 80.3887C57.5388 81.6311 58.0582 82.9675 58.3787 84.3569C58.5049 84.8972 58.5992 85.4445 58.661 85.9959C58.7482 86.653 58.2053 87.1959 57.5424 87.1957L31.6301 87.1877Z"
							fill="#F55273"
						/>
					</g>
					<path
						d="M45.2469 75.431C40.0711 75.45 36.2825 71.7888 36.3226 67.1442C36.3328 65.7925 36.6774 64.4642 37.3259 63.2766C37.9744 62.0889 38.9069 61.0786 40.0409 60.3347C41.1749 59.5909 42.4756 59.1365 43.828 59.0117C45.1803 58.8869 46.5428 59.0956 47.7949 59.6192C49.047 60.1429 50.1502 60.9654 51.0071 62.0141C51.8639 63.0628 52.4481 64.3055 52.7078 65.6322C52.9676 66.9589 52.895 68.3289 52.4964 69.6211C52.0978 70.9132 51.3856 72.0878 50.4227 73.041C48.8159 74.6262 46.8571 75.4067 45.2469 75.431Z"
						fill="#F55273"
					/>
				</svg>
			</div>
		</div>
	);
};
