import React, { useCallback, useState } from 'react';
import { Button, SquareClose } from '@scholly/elements';
import styles from '../scholarship-card.scss';
import { ShareIcon } from '../../common/icons';

type MenuList = 'share' | 'dismiss' | 'status';
interface MenuProps {
	onRemove?: (reasonId: string, reasonText: string) => void;
	onChangeProgress?: (progress: string) => void;
	onClose: () => void;
	hiddenItems?: Array<MenuList>;
	reasons?: Array<string>;
	isSavedAndExpired?: boolean;
}
export const Menu = ({ onClose, hiddenItems, onChangeProgress, reasons, onRemove, isSavedAndExpired }: MenuProps) => {
	const [isInitialMenu, setIsInitialMenu] = useState(true);
	const [isStatus, setIsStatus] = useState(false);
	const [isDismiss, setIsDismiss] = useState(false);

	const handleStatusClick = () => {
		setIsStatus(!isStatus);
		setIsInitialMenu(false);
	};

	const handleDismissClick = () => {
		setIsDismiss(!isStatus);
		setIsInitialMenu(false);
	};

	const handleChangeProgress = (progress) => () => {
		onChangeProgress(progress);
		onClose();
	};

	const handleChangeDismissStatus = (reasonId, reasonText) => async () => {
		await onRemove(reasonId, reasonText);
		onClose();
	};

	const isShow = useCallback(
		(name: MenuList) => {
			return !(hiddenItems && hiddenItems.includes(name));
		},
		[hiddenItems]
	);
	return (
		<div className={styles.modalContainer}>
			{isStatus && <Status onChangeProgress={handleChangeProgress} />}
			{isDismiss && (
				<Dismiss
					onDismiss={handleChangeDismissStatus}
					reasons={reasons}
					onClose={onClose}
					isSavedAndExpired={isSavedAndExpired}
				/>
			)}
			{isInitialMenu && (
				<>
					{isShow('dismiss') && (
						<Button variant={'secondary'} onClick={handleDismissClick} className={styles.modalButtonCustom}>
							Dismiss
						</Button>
					)}
					{isShow('status') && (
						<Button variant={'secondary'} onClick={handleStatusClick} className={styles.modalButtonCustom}>
							Status
						</Button>
					)}
					{isShow('share') && (
						<Button variant={'secondary'} className={styles.modalButtonCustom}>
							<ShareIcon styles={{ height: 35 }} /> Share App
						</Button>
					)}
				</>
			)}
			<div className={styles.modalCloseIcon}>
				<SquareClose clickHandler={onClose} clickable />
			</div>
		</div>
	);
};

const Status = ({ onChangeProgress }) => {
	return (
		<div className={styles.modalOptions}>
			<Button variant={'primary'} onClick={onChangeProgress('applied')}>
				Applied
			</Button>
			<Button variant={'tetiary'} onClick={onChangeProgress('won')}>
				Won
			</Button>
			<Button variant={'tetiary'} onClick={onChangeProgress('not_awarded')}>
				Not Awarded
			</Button>
		</div>
	);
};

const Dismiss = ({ onDismiss, reasons, onClose, isSavedAndExpired }) => {
	const [showReasons, setIsShowReasons] = useState(false);
	const [showOther, setIsShowOther] = useState(false);
	const [reasonText, setReasonText] = useState('');

	const handleShowOther = () => {
		setIsShowOther(true);
		setIsShowReasons(false);
	};

	const handleReasonText = (reason) => {
		setReasonText(reason);
	};

	const handleYesClick = () => {
		if (isSavedAndExpired) {
			onDismiss()();
		} else {
			setIsShowReasons(true);
		}
	};

	return (
		<div className={styles.modalOptions}>
			{!showReasons && !showOther && (
				<>
					<span>Hey! Are you sure you want to dismiss this scholarship?</span>
					<Button variant={'primary'} onClick={handleYesClick}>
						Yes
					</Button>
					<Button variant={'tetiary'} onClick={onClose}>
						No
					</Button>
				</>
			)}
			{showReasons && (
				<>
					{reasons.map((reason, index) => {
						return (
							<Button
								key={index}
								variant={index === 0 ? 'primary' : 'secondary'}
								onClick={onDismiss(reason.id)}
							>
								{reason.label}
							</Button>
						);
					})}
					<Button variant={'secondary'} onClick={handleShowOther}>
						Other
					</Button>
				</>
			)}
			{showOther && (
				<>
					<span style={{ alignSelf: 'start', paddingLeft: 38 }}>Other:</span>
					<textarea
						placeholder="Enter reason here"
						value={reasonText}
						onChange={(e) => handleReasonText(e.target.value)}
					/>
					<Button variant={'primary'} style={{ width: 240 }} onClick={onDismiss(null, reasonText)}>
						Send
					</Button>
				</>
			)}
		</div>
	);
};
