import * as React from 'react';

import { Colors } from '@scholly/scholly-ui';
import { Platform, StyleSheet, TouchableOpacity } from 'react-native-web';

import Icon from 'react-web-vector-icons';
import subStyles from './scholarship.scss';

type Props = {
	onPress?: () => void;
	isHeart?: boolean;
	checked?: boolean;
	isDisabled?: boolean;
	checkedIcon?: string;
	uncheckedIcon?: string;
	uncheckedColor?: string;
	checkedColor?: string;
	size?: number;
	title?: string;
	icon?: string;
	testID?: string;
};

const ToggleIcon = (props: Props) => {
	const {
		checkedIcon = 'check-circle',
		checkedColor = '#3259f1',
		checked = false,
		isHeart = false,
		title,
		size = 24,
		uncheckedIcon = 'circle',
		uncheckedColor = '#a7a9ab',
		onPress,
		isDisabled,
		// iconRight: false,
		// right: false,
		// center: false,
	} = props;

	const icon = checked ? checkedIcon : uncheckedIcon;
	const color = checked ? checkedColor : uncheckedColor;
	const showHeart = () => {
		return isHeart ? (
			<Icon font="Ionicons" name={icon} size={size} color={color} />
		) : (
			<Icon font="Feather" name={icon} size={size} color={color} />
		);
	};

	return (
		<TouchableOpacity
			onPress={onPress}
			style={[styles.root, isDisabled ? styles.disabled : null]}
			activeOpacity={isDisabled ? 1 : 0.5}
			// * TO BE USED FOR E2E only not to be used for unit or integration tests
			testID={props.testID}
		>
			{showHeart()}

			{title && <p className={subStyles.toggleIcon}>{title}</p>}
		</TouchableOpacity>
	);
};

export default ToggleIcon;

const styles = StyleSheet.create({
	root: { flexDirection: 'row', alignItems: 'center' },
	textBlack: { color: Colors.black },
	disabled: {
		...Platform.select({
			web: { cursor: 'inherit' },
			default: { alignItems: 'center' },
		}),
	},
});
