export const EventStrings = {
	/**
	 * Firebae
	 */
	// Tracking
	//signup: 'SignUp',
	paywall: 'Paywall',
	createProfile: 'CreateProfile',
	profile: 'Profile',
	scholarship: 'ScholarshipFeed',
	categories: 'ParametersCategories',
	subcategories: 'ParametersSubCategories',
	categories_update: 'ParametersCategoriesUpdate',
	subcategories_update: 'ParametersSubCategoriesUpdate',
	// Events
	saved_press: 'scholarship_saved_press',
	applied_press: 'scholarship_applied_press',
	saved: 'scholarship_saved',
	applied: 'scholarship_applied',
	removed: 'scholarship_removed',
	view_application: 'view_application',
	review_dialog_dispayed: 'scholarship_review_dialog_displayed',
	review_dialog_dismissed: 'scholarship_review_dialog_dismissed',
	review_positive_feedback_sent: 'scholarship_review_positive_feedback_sent',
	review_feedback_sent: 'scholarship_review_feedback_sent',
	review_app_store_opened: 'scholarship_review_app_store_opened',
	review_play_store_opened: 'scholarship_review_play_store_opened',
	/**
	 * Adjust
	 */
	NUM_DIMISS: 'NUM_DIMISS',
	NUM_SAVE: 'NUM_SAVE',
	NUM_VIEWS: 'NUM_VIEWS',
	SUCCESS_PARMS: 'SUCCESS_PARMS',
	SUCCESS_PROFILE: 'SUCCESS_PROFILE',
	SUCCESS_PURCHASE: 'SUCCESS_PURCHASE',
	SUCCESS_REG: 'SUCCESS_REG',
	SUCCESS_LOGIN: 'SUCCESS_LOGIN',
	EDITOR_PROMO: 'EDITOR_PROMO',
	MATH_PROMO: 'MATH_PROMO',
	EDITOR_SCREEN_TRY_NOW: 'EDITOR_SCREEN_TRY_NOW',
	MATH_SCREEN_TRY_NOW: 'MATH_SCREEN_TRY_NOW',
	/**

	 * Braze

	 */

	BRAZE: {
		COMPLETES_ONBOARDING: 'completes onboarding',
		VIEW_CART: 'view cart',
		ABANDON_CART: 'abandon cart',
		SCHOLARSHIP_APPLICATION: 'scholarships - view application',
		SCHOLARSHIP_VIEW: 'scholarships - view',
		SCHOLARSHIP_SAVE: 'scholarships - save',
		SCHOLARSHIP_DIMISS: 'scholarships - dismiss',
		SCHOLARSHIP_CONFIRM: 'scholarships - confirm application',
		SCHOLARSHIP_QUICK: 'scholarships - quick apply submit',
		MULTIAPPLY_APPLY: 'multiapply - apply',
	},
};
