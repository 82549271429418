import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SquareClose } from '@scholly/elements';

import { Routes } from '../../constants';

import { isSafari } from '../../helpers/checkIfSafari';

import styles from './scholarship.scss';

interface Props {
	onClose: () => void;
	onShowFeedbackModal: () => void;
}

const RedirectScholarship: React.FC<Props> = ({ onClose, onShowFeedbackModal }) => {
	const history = useHistory();
	const location: any = useLocation();
	const [link, setLink] = React.useState('');
	const [goBack, setGoBack] = React.useState(false);
	const [timeoutNumber, setTimeoutNumber] = React.useState(null);
	let mounted = React.useRef(null);

	let url = location.state.track_url || location.state.website;

	const openNewTab = () => {
		let windowReference;
		let timerNumber = setTimeout(() => {
			try {
				if (!isSafari()) {
					windowReference = window.open();
					windowReference.location.href = url;
				}
				// NOTE: Safari is blocking any call to window.open() which is made inside an async call with target _blank.
				if (isSafari()) {
					windowReference = window.open(url, '_top', 'noopener,noreferrer');
				}
			} catch (e) {
				console.warn(e);
				return;
			}
			setTimeout(() => {
				onShowFeedbackModal();
			}, 3000);
		}, 4000);
		setTimeoutNumber(timerNumber);
	};

	const stopRedirect = () => {
		const { state } = location;

		// @ts-ignore
		state.showApplyModal = false;

		clearTimeout(timeoutNumber);
		setTimeout(() => {
			history.push(Routes.scholarships.feed);
		}, 1000);
	};

	React.useEffect(() => {
		if (!mounted.current) {
			const { state } = location;
			if (state) {
				state.showApplyModal = true;
				setLink(state.website);
				openNewTab();
			}
		}
	}, []);

	React.useEffect(() => {
		goBack && stopRedirect();
	}, [goBack]);

	const handleLinkClick = () => {
		onShowFeedbackModal();
		clearTimeout(timeoutNumber);
	};

	return (
		<div className={styles.modalContainer}>
			<div className={styles.modalBody}>
				<div className={styles.redirectFirst}>
					<h2>Away we go!</h2>
				</div>
				<div className={styles.redirectText}>
					<span>You are leaving Scholly Search and will be automatically redirected to </span>
					<a onClick={handleLinkClick} href={url} target={'_blank'} rel="noopener noreferrer">
						{'\n' + link}
					</a>
					<span> in a moment.</span>
				</div>
				<div className={styles.redirectText}>
					<span style={{ marginTop: 30 }}>Change your mind? </span>
					<span
						test-id={'redirect_scholarship_back'}
						onClick={() => {
							setGoBack(true);
						}}
						style={{ cursor: 'pointer' }}
					>
						<span style={{ color: '#3259F1' }}>Go back to Scholly Search.</span>
					</span>
				</div>
			</div>
			<div className={styles.modalCloseIcon}>
				<SquareClose clickHandler={onClose} />
			</div>
		</div>
	);
};

export default RedirectScholarship;
