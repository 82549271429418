import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import ContentLoader from 'react-content-loader';
import { withRouter } from 'react-router-dom';
import { Button } from '@scholly/elements';

import { SubHeader } from '../../components/layouts/SubHeader';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import ChangePhone from './ChangePhone';
import CloseAccount from './CloseAccount';
import Debug from './Debug';
import UserInfo from './UserInfo';

import { UserContext } from '../../contexts';

import ScreenStrings from '../../constants/ScreenStrings';
import { isDEV } from '../../helpers/Functions';

import styles from './settings.scss';

const Settings: React.FC = () => {
	const context = useContext(UserContext);
	const [view, setView] = useState(null);

	if (!context.isUserLoaded) {
		return (
			<div className={classNames(styles.settingsDiv, styles.loaderContainer)}>
				<SettingsLoader />
			</div>
		);
	} else
		return (
			<>
				{view !== 'debug' ? (
					<div>
						<SubHeader title={ScreenStrings.account.title} className={styles.subheader} />
						<div className={styles.settingsDiv}>
							<UserInfo />
						</div>
						<div className={styles.settingsDiv}>
							<ChangeEmail />
						</div>
						<div className={styles.settingsDiv}>
							<ChangePhone />
						</div>
						<div className={styles.settingsDiv}>
							<ChangePassword />
						</div>
						<div className={styles.settingsDiv}>
							<CloseAccount />
						</div>
						{isDEV && (
							<div className={classNames(styles.settingsDiv, 'px-2')}>
								<Button
									className="px-0"
									variant="secondary"
									style={{ width: '100%' }}
									onClick={() => setView('debug')}
								>
									Debug Menu
								</Button>
							</div>
						)}
					</div>
				) : (
					<Debug onBack={() => setView(null)} />
				)}
			</>
		);
};

export default withRouter(Settings);

const SettingsLoader: React.FC = () => {
	return (
		<ContentLoader height={480} width={'100%'} backgroundColor={'#d6d6d6'}>
			<rect x="6" y="6" rx="10" ry="10" width="98%" height="65" />
			<rect x="6" y="91" rx="10" ry="10" width="98%" height="65" />
			<rect x="6" y="176" rx="10" ry="10" width="98%" height="65" />
			<rect x="6" y="261" rx="10" ry="10" width="98%" height="65" />
			<rect x="25%" y="346" rx="20" ry="20" width="50%" height="35" />
		</ContentLoader>
	);
};

export const inputStyles = {
	rootContainerStyles: {
		height: 48,
		width: '100%',
		backgroundColor: '#FFFFFF',
		borderRadius: 6,
		shadowRadius: 1,
		shadowOpacity: 0.05,
		marginBottom: 17,
	},
	inputContainerStyles: {
		paddingLeft: 30,
		height: 44,
		backgroundColor: '#FFFFFF',
		borderRadius: 6,
		borderColor: 'white',
	},
};
