import React, { CSSProperties, FC } from 'react';

interface SavedIconProps {
	onClick: (val: any) => void;
	fill?: string;
	backgroundColor?: string;
	stroke?: string;
	styles?: CSSProperties;
	progress?: string;
}
const schollyRed = '#FB113F';

const SavedIcon: FC<SavedIconProps> = (props) => {
	const { onClick, fill, stroke = '#A0A3BD', backgroundColor, styles, progress } = props;

	const savedIconStyle =
		progress === 'saved'
			? {
					fill: schollyRed,
					stroke: schollyRed,
			  }
			: {};
	return (
		<div>
			<div className="content" onClick={onClick} style={{ cursor: 'pointer', ...styles }}>
				<svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect y="0.960938" width="34" height="34" rx="6" fill={backgroundColor || ''} />
					<path
						d="M25 8.96094H9V26.4609L17 18.9609L25 26.4609V8.96094Z"
						fill={fill}
						stroke={stroke}
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
						{...savedIconStyle}
					/>
				</svg>
			</div>
		</div>
	);
};

export default SavedIcon;
