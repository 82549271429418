import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Nav as Navbar } from '@scholly/elements';

import { useScholarship } from '../../contexts';

import HamburgerMenu from '../hamburger-menu/hamburger-menu';
import NavbarIcons from '../navbar-icons/navbar-icons';
import Logo from './Logo';

import { NAV_ITEMS, Routes } from '../../constants';
import { ScholarshipFeedIndex } from '../../types';

import styles from './nav.scss';

interface NavProps extends RouteComponentProps {
	isAuthenticationNav?: boolean;
}

const Nav: React.FC<NavProps> = ({ location, history, isAuthenticationNav }) => {
	const { setselectedIndex } = useScholarship();
	const [navSelected, setNavSelected] = React.useState('');

	const dasboardNavSelected = () => {
		setNavSelected(NAV_ITEMS.DASHBOARD);
		history.push(Routes.dashboard);
	};

	const scholarshipsNavSelected = () => {
		setselectedIndex(ScholarshipFeedIndex.MATCHED);
		setNavSelected(NAV_ITEMS.SCHOLARSHIPS);
		history.push(Routes.scholarships.feed);
	};

	React.useEffect(() => {
		const path = location.pathname.slice(1);

		if (path.length > 1) {
			setNavSelected(path.toUpperCase());
		}
	}, [location.pathname]);
	React.useEffect(() => {
		const path = location.pathname.slice(1);
		const selected = path.toUpperCase();
		if (!navSelected.includes(selected)) {
			setNavSelected(selected);
			if (selected.includes(NAV_ITEMS.PROFILE.toLowerCase())) {
				setNavSelected(NAV_ITEMS.PROFILE);
			}
			if (selected.includes(NAV_ITEMS.APPLIED.toLowerCase())) {
				setNavSelected(NAV_ITEMS.APPLIED);
			}
			if (selected.includes(NAV_ITEMS.SAVED.toLowerCase())) {
				setNavSelected(NAV_ITEMS.SAVED);
			}
		}
	}, [location.pathname, navSelected]);

	return (
		<Navbar.Header className={styles.header}>
			<Navbar.NavLogoContainer>
				<Logo />
			</Navbar.NavLogoContainer>
			<Navbar.NavInnerContainer style={{ justifyContent: 'flex-end', width: '100%' }}>
				<Navbar.NavItemContainer style={{ width: '100%' }}>
					<Navbar.NavItem
						active={navSelected === NAV_ITEMS.DASHBOARD}
						onClick={dasboardNavSelected}
						visible={!isAuthenticationNav}
					>
						Dashboard
					</Navbar.NavItem>
					<Navbar.NavItem
						active={navSelected === NAV_ITEMS.SCHOLARSHIPS}
						onClick={scholarshipsNavSelected}
						visible={!isAuthenticationNav}
					>
						Scholarships
					</Navbar.NavItem>
				</Navbar.NavItemContainer>

				<div
					className={classNames(
						'is-flex is-align-items-center',
						isAuthenticationNav && styles.hideSearchInput
					)}
				>
					{isAuthenticationNav ? <HamburgerMenu /> : <NavbarIcons />}
				</div>
			</Navbar.NavInnerContainer>
		</Navbar.Header>
	);
};

export default withRouter(Nav);
