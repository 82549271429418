export interface ProfileQuestion {
	profile: { completed: number | string };
	required: QuestionRequired;
	question: QuestionModel;
	questions: QuestionModel[];
	question_title: string;
}

export enum QuestionTypes {
	SingleSelect = 'single_select',
	MultiSelect = 'multi_select',
	SingleSelectSearch = 'single_select_search',
	MultiSelectSearch = 'multi_select_search',
	SchoolSelectSearch = 'school_single_select_search',
	LocationSelectSearch = 'location_single_select_search',
	Slider = 'single_select_slider',
	SingleSelectDropdown = 'single_select_dropdown',
}

export interface QuestionModel {
	id: number;
	parameter_id: number;
	question: string;
	placeholder: string | undefined;
	required: string;
	question_type: QuestionTypes;
	answers: AnswerModel[];
	string_id?: string;
}

export interface AnswerModel {
	id: number;
	answer_string: string | null;
	value: string;
	sort_order: number;
}

export interface QuestionRequired {
	completed: number;
	current_section: number;
	total_questions: number;
	total_sections: number;
	total_count?: number;
}

export type Opt = {
	value: string;
	title: string;
	selected: boolean;
};
