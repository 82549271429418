import React, { useState } from 'react';
import { ScholarshipType } from '@scholly/scholly-types';
import { Button, SquareClose } from '@scholly/elements';
import SavedIcon from '../../components/common/icons/saved-icon';
import styles from './scholarship.scss';
import { useScholarship } from '../../contexts';
import { Routes, ScreenStrings } from '../../constants';
import { useHistory } from 'react-router-dom';

type ItemType = ScholarshipType & {
	progress?: number;
};

type IProps = {
	item: ItemType;
	handleSave: () => void;
	handleApplied: (scholarship: ItemType, params: { progress: string }) => void;
	onClose?: () => void;
	handleFeedback: (itemId: string, reasonId: number) => void;
	handleRemove: (itemId: string, reasonId: number) => void;
};
export const Feedback = (props: IProps) => {
	const [step, setStep] = useState<'initial' | 'applied' | 'notApplied'>('initial');
	const { onClose, handleSave, item, handleApplied, handleFeedback } = props;

	const handleChange = (step) => {
		if (step === 'applied') {
			handleApplied(item, { progress: 'applied' });
		}
		setStep(step);
	};

	const sendFeedback = (id) => {
		handleFeedback(item.id, id);
	};
	return (
		<div className={styles.modalContainer}>
			{step === 'initial' && <DidYouApply handleChange={handleChange} />}
			{step === 'applied' && <Applied />}
			{step === 'notApplied' && <NotApplied handleFeedback={sendFeedback} />}

			<div className={styles.modalCloseIcon}>
				<SquareClose clickHandler={onClose} />
			</div>
			<ReportIssue step={step} handleSave={handleSave} progress={item.progress} />
		</div>
	);
};

const DidYouApply = ({ handleChange }) => {
	return (
		<div className={styles.modalBody}>
			<h2>{ScreenStrings.scholarship.didYouApply}</h2>

			<Button
				variant={'tetiary'}
				onClick={() => handleChange('notApplied')}
				style={{ marginBottom: 20, height: 34, width: 155 }}
			>
				No
			</Button>
			<Button variant={'tetiary'} onClick={() => handleChange('applied')} style={{ height: 34, width: 155 }}>
				Yes
			</Button>
		</div>
	);
};

const Applied = () => {
	return (
		<div className={styles.modalBody}>
			<div className={styles.modalFeedbackText} style={{ marginTop: 30, justifyContent: 'center' }}>
				{ScreenStrings.scholarship.awesome}
			</div>
			<div className={styles.modalFeedbackText}>{ScreenStrings.scholarship.applied}</div>
		</div>
	);
};

const NotApplied = ({ handleFeedback }) => {
	const [selectedId, setSelectedId] = useState();
	const { noApplyReasons } = useScholarship();

	const handleSelected = (id) => {
		handleFeedback(id);
		setSelectedId(id);
	};
	return (
		<div className={styles.modalBody}>
			{!selectedId && noApplyReasons && (
				<>
					{noApplyReasons.map((reason) => {
						return (
							<Button
								variant={'tetiary'}
								onClick={() => handleSelected(reason.id)}
								style={{ marginTop: 20, height: 34, width: 155 }}
							>
								{reason.label}
							</Button>
						);
					})}
				</>
			)}

			{(selectedId || !noApplyReasons) && (
				<span className={styles.modalFeedbackText} style={{ marginTop: 30 }}>
					{ScreenStrings.scholarship.notApplied}
				</span>
			)}
		</div>
	);
};

const ReportIssue = ({ handleSave, progress, step }) => {
	const history = useHistory();
	const { scholarships } = Routes;

	const handleReportIssue = () => {
		history.push(scholarships.reportIssue);
	};
	return (
		<div className={styles.modalBody} style={{ marginTop: 20 }}>
			<span className={styles.modalReport} onClick={handleReportIssue}>
				Report an Issue
			</span>
			<div className={styles.modalIconsWrapper}>
				{step !== 'applied' && <SavedIcon onClick={handleSave} progress={progress} />}
			</div>
		</div>
	);
};
