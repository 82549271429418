import React from 'react';
import { Input } from '@scholly/scholly-ui';

import { UserContext } from '../../contexts';
import { inputStyles } from './index';
import { ScreenStrings } from '../../constants';

import styles from './settings.scss';

const UserInfo: React.FC = () => {
	const userContext = React.useContext(UserContext);

	return (
		<div className="px-2">
			<div className={styles.title}>Name</div>

			<div style={{ position: 'relative' }}>
				<div className={styles.label}>{ScreenStrings.profile.firstName}</div>
				<Input
					rootContainerStyles={inputStyles.rootContainerStyles}
					inputContainerStyles={{ ...inputStyles.inputContainerStyles, paddingLeft: 0 }}
					style={{ fontFamily: 'Poppins' }}
					value={userContext.first_name}
					editable={false}
				/>
			</div>

			<div style={{ position: 'relative' }}>
				<div className={styles.label}>{ScreenStrings.profile.lastName}</div>
				<Input
					rootContainerStyles={inputStyles.rootContainerStyles}
					inputContainerStyles={{ ...inputStyles.inputContainerStyles, paddingLeft: 0 }}
					style={{ fontFamily: 'Poppins' }}
					value={userContext.last_name}
					editable={false}
				/>
			</div>
		</div>
	);
};
export default UserInfo;
