import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import ErrorBoundary from '../../components/misc/ErrorBoundary';
import ScholarshipList from '../../components/scholarship-list/scholarship-list';
import EmptyList from '../../components/scholarship/EmptyList';
import ScholarshipCard from '../../components/ScholarshipCard';
import { SubHeader } from '../../components/layouts/SubHeader';

import { useScholarship } from '../../contexts';

import { Routes, ButtonStrings } from '../../constants';
import { ScholarshipFeedIndex } from '../../types';
import { ScholarshipClickSourceIndex } from '../../types';

import styles from './scholarship.scss';

const ScholarshipsApplied: FC = (props) => {
	const history = useHistory();
	const { hostedScholarshipApplied, scholarshipApplied, appliedScholarship, status, scholarshipSaved, ...context } =
		useScholarship();

	const renderItem = (scholarship, index) => {
		// const exclusiveImage = scholarship?.assets?.find((asset: any) => asset?.type === 'web')?.url;

		return (
			<ScholarshipCard
				key={scholarship.id}
				scholarshipItem={scholarship}
				source={ScholarshipClickSourceIndex.APPLIED}
				position={index + 1}
			/>
		);

		// TODO:
		// It seems like the ExclusiveCard is no longer needed.
		//  Need to Confirm with Team and Design to see if We can remove it
		// return scholarship?.in_app ? (
		// 	<ExclusiveCard
		// 		classname={styles.exclusiveContainer}
		// 		image={exclusiveImage}
		// 		title={scholarship?.name}
		// 		scholarshipItem={scholarship}
		// 		handleShare={() => {}}
		// 		handleQuickApply={() => {}}
		// 		hideCheckbox
		// 	/>
		// ) : (
		// 	<ScholarshipCard key={scholarship.id} scholarshipItem={scholarship} />
		// );
	};

	const sendError = (error, errorInfo) => {
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			Sentry.captureException(error);
		});
	};
	const onBack = () => {
		context.setselectedIndex(ScholarshipFeedIndex.MATCHED);
		history.replace(Routes.scholarships.feed);
	};

	const isEmpty = window.location.pathname !== Routes.scholarships.applied || scholarshipApplied.length === 0;
	return (
		<ErrorBoundary logErrorToService={sendError}>
			<SubHeader title={ButtonStrings.scholarships.applied} onBack={onBack} />
			<div className={`${styles.makeDissapear} ${styles.scholarshipContainer} THIS-IS-A-DIV`}>
				<div className={styles.scholarshipLayoutContainer}>
					<ScholarshipList
						showExclusive
						renderItem={renderItem}
						data={[...hostedScholarshipApplied, ...scholarshipApplied]}
						EmptyListComponent={
							<EmptyList
								selectedIndex={context.selectedIndex}
								onUpdatePress={() => history.replace(Routes.user.myProfile)}
								onBackToMatched={onBack}
								show={isEmpty}
							/>
						}
					/>
				</div>
			</div>
		</ErrorBoundary>
	);
};
export default ScholarshipsApplied;
