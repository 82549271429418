import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { useScholarship } from '../../contexts';
import { Routes, ButtonStrings } from '../../constants';

import ErrorBoundary from '../../components/misc/ErrorBoundary';
import ScholarshipList from '../../components/scholarship-list/scholarship-list';
import EmptyList from '../../components/scholarship/EmptyList';
import ExclusiveCard from '../../components/exclusive-card/exclusive-card';
import ScholarshipCard from '../../components/ScholarshipCard';
import { SubHeader } from '../../components/layouts/SubHeader';

import { ScholarshipFeedIndex, ScholarshipClickSourceIndex } from '../../types';

import styles from './scholarship.scss';

const ScholarshipsSaved: React.FC = (props) => {
	const history = useHistory();
	const { hostedScholarshipApplied, scholarshipSaved, ...context } = useScholarship();

	const renderItem = (scholarship, index) => {
		const exclusiveImage = scholarship?.assets?.find((asset: any) => asset?.type === 'web')?.url;

		return scholarship?.in_app ? (
			<ExclusiveCard
				classname={styles.exclusiveContainer}
				image={exclusiveImage}
				title={scholarship?.name}
				scholarshipItem={scholarship}
				handleShare={() => {}}
				handleQuickApply={() => {}}
				hideCheckbox
			/>
		) : (
			<ScholarshipCard
				key={scholarship.id}
				scholarshipItem={scholarship}
				source={ScholarshipClickSourceIndex.SAVED}
				position={index + 1}
			/>
		);
	};

	const sendError = (error, errorInfo) => {
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			Sentry.captureException(error);
		});
	};

	const onBack = () => {
		context.setselectedIndex(ScholarshipFeedIndex.MATCHED);
		history.replace(Routes.scholarships.feed);
	};

	const isEmpty = window.location.pathname !== Routes.scholarships.saved || scholarshipSaved.length === 0;
	return (
		<ErrorBoundary logErrorToService={sendError}>
			<SubHeader title={ButtonStrings.scholarships.saved} onBack={onBack} />
			<div className={`${styles.makeDissapear} ${styles.scholarshipContainer} THIS-IS-A-DIV`}>
				<div className={styles.scholarshipLayoutContainer}>
					<ScholarshipList
						renderItem={renderItem}
						data={scholarshipSaved}
						EmptyListComponent={
							<EmptyList
								selectedIndex={context.selectedIndex}
								onUpdatePress={() => history.replace(Routes.user.myProfile)}
								onBackToMatched={onBack}
								show={isEmpty}
							/>
						}
					/>
				</div>
			</div>
		</ErrorBoundary>
	);
};
export default ScholarshipsSaved;
