import React, { FC, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

// TODO: CREATE A NEW BUTTON COMPONENT NOT USING SCHOLLY UI
import { Button, Cal, PiggyBank } from '@scholly/elements';
import { Menu } from './components/Modals';
import { CustomModal } from '../modal/Modal';
import ExternalLink from '../../assets/Icons/ExternalLink';

import { checkFeature, progressTypeToApplied, useScholarship, useUser } from '../../contexts';

import { FLAGS, EventStrings, Routes } from '../../constants';
import { event } from '../../helpers/AnalyticsHelper';
import { getIsExpired } from '../../helpers/Functions';
import config from '../../config/config';
import { ScholarshipClickSourceIndex, ScholarshipObject, ScholarshipProgress, ScholarshipStatus } from '../../types';

import { Badge } from '../common/Badge/Badge';
import { CardBadge } from '../common/Badge/CardBadge';
import { Effort } from '../common/Effort/Effort';
import LockedBanner from '../locked-banner/locked-banner';
import MenuIcon from '../common/icons/menu-icon';
import SavedIcon from '../common/icons/saved-icon';

import styles from './scholarship-card.scss';

interface ScholarshipCardProps {
	isLarge?: boolean;
	scholarshipItem: ScholarshipObject;
	updateNotFeedItem?: (id: number, item: ScholarshipObject) => void;
	isUngated?: boolean;
	position?: number;
	source?: ScholarshipClickSourceIndex;
}
const saveRibbonColor = '#f85272';

// TODO: Ideally, the Lock Banner along with any fuction directing user to paywall should be removed.
// They are currently being hidden behind a feature flag

const ScholarshipCard: FC<ScholarshipCardProps> = (props: ScholarshipCardProps) => {
	const { isLarge, scholarshipItem, isUngated, source, position } = props;
	const { activePromos, expanded, saved, status, removed, hostedScholarshipApplied, ...context } = useScholarship();
	const { isPaidUser } = useUser();
	const [hasAppliedToCampaign, setHasAppliedToCampaign] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const isScholarshipDetail = checkFeature(FLAGS.SCHOLARSHIP_DETAILS);

	const shortDescription = (isLarge && window.innerWidth) > 1024 ? 60 : 40;
	const isFeaturedScholarship =
		[ScholarshipClickSourceIndex.DASHBOARD, ScholarshipClickSourceIndex.LANDING].includes(source) &&
		scholarshipItem?.featured;
	const isUngatedFeatured = isUngated && isFeaturedScholarship;

	const size = isLarge ? 'l' : 's';

	const formatCurrency = (amount) => {
		return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	const renderAmount = (item: any) => {
		let amount = `$${formatCurrency(item.amount)}`;

		if (item.reward_type == '2' && (item.amount === 0 || item.amount == '0.00')) {
			amount = 'Varies';
		}

		if (item.reward_type === '1') {
			amount = 'Full Ride';
		}

		return amount;
	};

	const daysRemaining = useMemo(() => {
		const deadlineDate = moment(scholarshipItem.deadline, 'YYYY-MM-DD');
		const today = moment().startOf('day');
		let diff = deadlineDate.diff(today, 'days');
		return diff;
	}, [scholarshipItem.deadline]);

	const isSavable = useMemo(() => {
		if (
			scholarshipItem.progress !== 'applied' &&
			scholarshipItem.progress !== 'won' &&
			scholarshipItem.progress !== 'not_awarded' &&
			daysRemaining > 0
		)
			return true;
	}, [scholarshipItem, daysRemaining]);

	const noBadges = useMemo(() => {
		if (scholarshipItem?.read_status === ScholarshipStatus.UNREAD && daysRemaining > 7 && isSavable) return true;
	}, [scholarshipItem, isSavable, daysRemaining]);

	const onlyVisibleBadge = useMemo(() => {
		if (scholarshipItem?.read_status === ScholarshipStatus.READ && daysRemaining > 7 && isSavable) return true;
	}, [scholarshipItem, isSavable, daysRemaining]);

	const handleOpen = () => {
		let url = `${scholarshipItem.track_url}?source=${source}&position=${position}&featured=${Number(
			isFeaturedScholarship
		)}`;
		if (scholarshipItem?.slug?.length > 0) {
			event({ category: 'View', action: 'Clicks View Scholarship' });

			if (
				scholarshipItem?.read_status === ScholarshipStatus.UNREAD ||
				scholarshipItem?.read_status === ScholarshipStatus.NEW
			) {
				context.setScholarshipRead(scholarshipItem?.id);
			}
			scholarshipItem.prevLocation = location?.pathname;
			context.closeFeedback(false);

			if (isScholarshipDetail) {
				history.push(`/scholarships/${scholarshipItem?.slug}`, scholarshipItem);
			} else {
				if (location.pathname !== Routes.scholarships.applied) {
					window.open(url, '_blank');
				}
			}
		}
	};

	const handleSave = () => {
		if (scholarshipItem.progress === 'saved') {
			context.removeSavedScholarship(scholarshipItem);
		} else {
			context.saveScholarship(scholarshipItem);
		}
	};

	const savedIconStyle =
		scholarshipItem.progress === 'saved'
			? {
					fill: saveRibbonColor,
					stroke: saveRibbonColor,
			  }
			: {};

	const handleCloseModal = () => {
		setIsModalVisible(false);
	};

	const handleChangeProgress = (progress: string) => {
		context.updateScholarship(scholarshipItem, { progress: progress });

		if (props.updateNotFeedItem) {
			props.updateNotFeedItem(scholarshipItem.id, { ...scholarshipItem, progress: progress });
		}
	};

	const handleRemove = async (reasonId, reasonText) => {
		await context.removeMatchedScholarship(
			{
				scholarship_id: scholarshipItem.id,
				scholarship_remove_reason_id: reasonId,
				other: reasonText,
			},
			true
		);

		if (props.updateNotFeedItem) {
			props.updateNotFeedItem(scholarshipItem.id, null);
		}
	};

	const handleDotsMenuClick = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		setIsModalVisible(!isModalVisible);
	};

	const handleUngatedCardClick = () => {
		const url = `${scholarshipItem?.track_url}?source=${props.source}&position=${props.position}&featured=${Number(
			scholarshipItem.featured
		)}`;
		window.open(url, '_blank');
	};

	const shouldHideAllLockedActivities = !checkFeature(FLAGS.SCHOLLY_FREE) && !isPaidUser;

	const isSavedAndExpired =
		scholarshipItem.progress === ScholarshipProgress.SAVED && getIsExpired(new Date(scholarshipItem.deadline));

	const isDisabled =
		shouldHideAllLockedActivities ||
		progressTypeToApplied.includes(scholarshipItem.progress as ScholarshipProgress) ||
		hasAppliedToCampaign;

	React.useEffect(() => {
		setHasAppliedToCampaign(hostedScholarshipApplied?.some((item) => item.id == scholarshipItem.id));
	}, [hostedScholarshipApplied]);
	return (
		<div
			className={classNames(styles.scholarshipCardWrapper, {
				[styles.lockedCard]: shouldHideAllLockedActivities,
				[styles.scholarshipCardWrapperUngatedFeatured]: isUngatedFeatured,
			})}
			onClick={
				isUngated
					? handleUngatedCardClick
					: () => {
							shouldHideAllLockedActivities ? history.push(Routes.subscription) : undefined;
					  }
			}
		>
			{shouldHideAllLockedActivities && (
				<div className={styles.lockedBannerContainer}>
					<LockedBanner
						isLarge={isLarge}
						text={'Access all matched scholarships'}
						header={'Upgrade Account Required'}
					/>
				</div>
			)}
			{isFeaturedScholarship && (
				<div
					className={classNames(styles.scholarshipCardContainerFeaturedHeader, {
						[styles.scholarshipCardContainerFeaturedHeaderUngated]: isUngatedFeatured,
					})}
				>
					{scholarshipItem?.amount.toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
						minimumFractionDigits: 0,
					})}{' '}
					Scholarship
				</div>
			)}
			<div
				className={classNames(styles.scholarshipCardContainer, {
					[styles.containerLarge]: isLarge,
					[styles.hasBadges]: !noBadges,
					[styles.scholarshipCardContainerFeatured]: isFeaturedScholarship,
					[styles.scholarshipCardContainerFeaturedUngated]: isUngatedFeatured,
					[styles.scholarshipCardContainerUngated]: isUngated && !isFeaturedScholarship,
				})}
			>
				<div
					className={classNames(styles.topCardWrapper, {
						[styles.topCardWrapperGated]: !isUngated,
						[styles.topCardWrapperUngated]: isUngated && !isFeaturedScholarship,
						[styles.topCardWrapperUngatedFeatured]: isUngatedFeatured,
					})}
					onClick={!isUngated && checkFeature(FLAGS.SCHOLLY_FREE) ? handleOpen : undefined}
				>
					<div className={styles.titleRow}>
						<div
							className={classNames(styles.titleContainer, {
								[styles.titleContainerUngated]: isUngated,
							})}
						>
							{isFeaturedScholarship && scholarshipItem?.provider?.logo_img && (
								<div
									className={classNames(styles.titleContainerFeaturedScholarshipIcon, {
										[styles.titleContainerFeaturedScholarshipIconUngated]: isUngatedFeatured,
									})}
								>
									<img
										src={`${config.cdn}images/scholarship-providers/${scholarshipItem?.provider?.logo_img}`}
										width={100}
										alt="src"
									/>
								</div>
							)}
							<h3
								className={classNames(styles.title, {
									[styles.titleLarge]: isLarge,
									[styles.titleUngatedFeatured]: isUngatedFeatured,
									[styles.titleUngated]: isUngated && !isFeaturedScholarship,
								})}
							>
								{scholarshipItem?.name}
							</h3>
							{scholarshipItem?.description && (
								<p
									className={classNames(styles.description, {
										[styles.descriptionLarge]: isLarge,
										[styles.descriptionUngated]: isUngated,
									})}
								>
									{!isUngated
										? `${scholarshipItem?.description?.slice(0, shortDescription)}...`
										: scholarshipItem?.description}
								</p>
							)}
						</div>
						{!isUngated && checkFeature(FLAGS.SCHOLLY_FREE) && (
							<div className={styles.saveContainer}>
								{isSavable && (
									<SavedIcon
										onClick={(e) => {
											e.stopPropagation();
											handleSave();
										}}
										{...savedIconStyle}
									/>
								)}

								<MenuIcon onClick={handleDotsMenuClick} />
							</div>
						)}
					</div>
					{!isUngated && (
						<div className={styles.awardRow}>
							<div className={classNames(styles.contentBlock, 'is-hidden-mobile')}>
								<div className={styles.iconContainer}>
									<PiggyBank clickHandler={() => {}} />
								</div>
								<div className={classNames(styles.awardContainer)}>
									<p className={classNames(styles.label, { [styles.labelLarge]: isLarge })}>Award</p>
									<p className={classNames(styles.value, { [styles.valueLarge]: isLarge })}>
										{renderAmount(scholarshipItem)}
									</p>
								</div>
							</div>
							<div className={styles.contentBlock}>
								<div className={styles.iconContainer}>
									<Cal clickHandler={() => {}} />
								</div>
								<div className={styles.deadlineContainer}>
									<p
										className={classNames(styles.label, 'has-text-left-mobile', {
											[styles.labelLarge]: isLarge,
										})}
									>
										Deadline
									</p>
									<p className={classNames(styles.value, { [styles.valueLarge]: isLarge })}>
										{moment(scholarshipItem?.deadline).format('MM/DD/YYYY')}
									</p>
								</div>
							</div>
							<div className={classNames(styles.contentBlock, 'is-hidden-mobile')}>
								<div className={styles.effortContainer}>
									<p className={classNames(styles.label, { [styles.labelLarge]: isLarge })}>Effort</p>
									<Effort effortScore={scholarshipItem?.effort} />
								</div>
							</div>
							<Button
								onClick={handleOpen}
								variant={isFeaturedScholarship || isDisabled ? 'primary' : 'secondary'}
								disabled={isDisabled}
								className={classNames(styles.applyButton, 'is-hidden-tablet pl-0 pr-0', {
									[styles.applyButtonLarge]: isLarge,
									[styles.applyButtonUnfeatured]: !isFeaturedScholarship && !isDisabled,
								})}
							>
								{isDisabled ? (
									'Applied'
								) : (
									<>
										Apply
										<span>
											<ExternalLink />
										</span>
									</>
								)}
							</Button>
						</div>
					)}
				</div>
				<div
					className={classNames(styles.viewedRow, {
						'is-hidden-mobile': noBadges,
						[styles.viewedRowUngated]: isUngated,
					})}
				>
					{!isUngated && (
						<div className={styles.badgeRow}>
							<div className={styles.badgeContainer}>
								{/* @ts-ignore */}
								<CardBadge item={scholarshipItem} size={size} />
								<div
									className={classNames(styles.viewedContainer, {
										[styles.viewedMT]: onlyVisibleBadge,
									})}
								>
									{scholarshipItem.read_status === 'read' && <Badge size={size} variant={'viewed'} />}
								</div>
							</div>
							{checkFeature(FLAGS.SCHOLLY_FREE) && (
								<div className={styles.saveContainer}>
									{isSavable && <SavedIcon onClick={handleSave} {...savedIconStyle} />}
									<MenuIcon onClick={handleDotsMenuClick} />
								</div>
							)}
						</div>
					)}

					{isUngated && (
						<div className={classNames(styles.awardRow, styles.awardRowUngated)}>
							<div className={classNames(styles.contentBlock, 'is-hidden-mobile')}>
								<div className={styles.iconContainer}>
									<PiggyBank clickHandler={() => {}} />
								</div>
								<div className={classNames(styles.awardContainer)}>
									<p className={classNames(styles.label, { [styles.labelLarge]: isLarge })}>Award</p>
									<p className={classNames(styles.value, { [styles.valueLarge]: isLarge })}>
										{renderAmount(scholarshipItem)}
									</p>
								</div>
							</div>
							<div className={classNames('', styles.contentBlock)}>
								<div className={styles.iconContainer}>
									<Cal clickHandler={() => {}} />
								</div>
								<div className={styles.deadlineContainer}>
									<p
										className={classNames(styles.label, 'has-text-left-mobile', {
											[styles.labelLarge]: isLarge,
										})}
									>
										Deadline
									</p>
									<p className={classNames(styles.value, { [styles.valueLarge]: isLarge })}>
										{moment(scholarshipItem?.deadline).format('MM/DD/YYYY')}
									</p>
								</div>
							</div>
							<div className={classNames(styles.contentBlock, 'is-hidden-mobile')}>
								<div className={styles.effortContainer}>
									<p className={classNames(styles.label, { [styles.labelLarge]: isLarge })}>Effort</p>
									<Effort effortScore={scholarshipItem?.effort} />
								</div>
							</div>
						</div>
					)}

					<Button
						onClick={!isUngated && handleOpen}
						variant={isFeaturedScholarship || isDisabled ? 'primary' : 'secondary'}
						disabled={isDisabled}
						className={classNames(styles.applyButton, {
							[styles.applyButtonUnfeatured]: !isFeaturedScholarship && !isDisabled,
							[styles.applyButtonLarge]: isLarge && !isUngated,
							'is-hidden-mobile': !isUngated,
							[styles.applyButtonUngated]: isUngated,
						})}
					>
						{isDisabled ? (
							'Applied'
						) : (
							<>
								Apply
								<span>
									<ExternalLink />
								</span>
							</>
						)}
					</Button>
				</div>
				<CustomModal visible={isModalVisible}>
					<Menu
						onClose={handleCloseModal}
						onChangeProgress={handleChangeProgress}
						onRemove={handleRemove}
						reasons={context.removeReasons}
						hiddenItems={['share', isFeaturedScholarship ? 'dismiss' : null]}
						isSavedAndExpired={isSavedAndExpired}
					/>
				</CustomModal>
			</div>
		</div>
	);
};

export default ScholarshipCard;
