export default {
	onboarding: {
		basic: 'Your Basic Info',
		personal: 'Your Personal Info',
		complete: 'Yeah!\nOnboarding Completed!',
		freeAccountActivated: 'Free Account Activated',
		mobileBasic: 'Onboarding',
		emptyQuickApply: 'In Scholarships Were\nMatched For You!',
		sweeps: '$2,000 Scholly Scholarship',
	},
	profile: {
		createTitle: 'Create your profile',
		profileTitle: 'Profile',
		updatedAccount: 'Updated account information.',
		updatingError: 'Updating error.',
		complete: 'Complete your profile to unlock\nmore great scholarships',
		eminem: 'My name is',
		firstName: 'First Name',
		lastName: 'Last Name',
		iAm: 'I am a',
		yearsOld: 'Birthday',
		citizen: 'Citizenship Status',
		ethnicity: 'Race/Ethnicity',
		isParent: 'Select - Parent/Student',
		needBased: 'Select - Need/Merit Based',
		need: 'I am searching for need-based scholarships',
		identify: 'I identify as',
		identify2: 'Gender',
		liveIn: 'I live in',
		schoolIn: 'I (will) attend school at',
		currently: 'I am currently a',
		goals: "I'm hoping to win ____ using Scholly",
		schoolYear: 'School Year',
		studying: 'Major',
		withGPA: 'With a GPA of',
		seeking: 'Degree Type',
		enrolledAs: 'I will be enrolled as a',
		enrollmentStatus: 'Enrollment Status',
		sexuality: 'Gender',
		school: 'College/University',
		goal: '$ Goal Amount',
		major: 'Major',
		state: 'City, State',
		gpa: 'GPA',
		addMore: 'Do you want to add another?',
		missingFields: 'Please fill out the missing fields to get more matches',
	},
	auth: {
		email: 'Email',
		password: 'Password',
		newPassword: 'New Password',
		curPassword: 'Current Password',
		rePassword: 'Re-enter Password',
		lengthValid: 'At least 8 characters',
		specialValid: 'At least 1 special character',
		upperCaseValid: 'At least 1 upper case',
		numberValid: 'At least 1 number',
		isValueIdentical: 'Passwords must be identical',
		uemail: 'Update Email',
		upassword: 'Update Password',
		emailValid: 'Please enter your valid email address',
		errorLoginData: 'Incorrect Email and/or Password',
		newPasswordIdentical: 'Current password must be not matched with new password',
	},
	signUp: {
		oldTitle: 'Sign Up and Start Earning',
		title: 'Sign up today!',
		subTitle: 'Sign up for free!',
		disclaimerTitle: '3rd Party Disclaimer',
		disclaimerText:
			'I am aware that my personal information is being collected for the purpose of personalizing my scholarship matches. Additionally, I acknowledge that my information may be used by pre-approved 3rd party services so that Scholly can personalize my experience and customize communications to me.',
		disclaimerAccept: 'Accept',
		disclaimerReject: 'Not Accept',
		alertCheckboxNotAccepted: 'Must accept disclaimer to continue',
	},
	login: {
		title: 'Welcome Back!',
		refundTitle: `Log In To Check Your Refund Eligibility*`,
		subTitle: 'Scholly is Now Free!',
		subSubTitle: 'Start Earning Today!',
		body: 'Log in to view your scholarship matches.',
		emailInUse: 'Oops! Looks like you already have an account with us',
		emailInUseToReplace: 'E-mail is already in use',
	},

	forgotPassword: {
		title: 'Forgot Password',
		body: 'Enter your email to receive a confirmation email to reset your password.',
		modalTitle: "You're Back on Track",
		modalBody: "Don't forget to check your email for instructions on how to reset your password.",
		errorAccountDoesntExist: "Account doesn't exist",
	},
	account: {
		title: 'Account Details',
		freeBody: 'Access to scholarship-matching and writing help with Editor',
		freeTrial: 'Free Trial',
		temporary: 'Temporary',
		schollyCode: 'Scholly Code',
		grandfathered: 'Grandfathered',
		permanent: 'Permanent',
		freeBasic: 'Basic Membership',
		freeBasicBanner: 'Basic',
		freeBasicCopy: 'Access to scholarships',
		membershipSub: 'Membership Info',
		additionalComments:
			'Type any additional comments that may help our developers fix the bug (location, behaviour, reoccuring, etc…)',
	},
	scholarship: {
		emptyListImg: 'https://scholly-app.s3.amazonaws.com/live/images/search/try_again_new.png',
		emptyListMatched: "Oops! Something's not right!",
		emptyListSaved: 'Start saving scholarships now!',
		emptyListApplied: 'Start applying to scholarships now!',
		errorBoundaryImg: 'https://scholly-app.s3.amazonaws.com/live/images/search/error-boundary.png',
		scholarshipBadgeNew: 'New',
		scholarshipBadgeExpiringSoon: 'Expiring Soon',
		scholarshipBadgeExpiresToday: 'Deadline Today',
		scholarshipBadgeExpired: 'Deadline Passed',
		listFooterCopy: 'Looking for more matches?',
		listFooterImg: 'https://scholly-app.s3.amazonaws.com/live/images/scholly_logo_grey.png',
		dismissScholarship: 'Why did you dismiss the scholarship?',
		didYouApply: 'Did you apply?',
		awesome: 'Awesome!',
		applied: 'Good luck and thanks for your feedback.',
		notApplied: 'Ok! Thank you for \n' + 'your feedback.',
		includedInYourMembership: 'Included in your membership',
		schollyMath: 'Scholly Math',
		schollyEditor: 'Scholly Editor',
		copySuccess: 'Copied Successfully',
	},
	promotion: {
		mathHeader: 'Your easy-to-use, AI-powered math solver included in your Scholly membership.',
		mathFooter: 'Instantly solve any type of math problem and see step-by-step instructions.',
		editorHeader: 'Your AI-powered personal proofreader included in your Scholly membership.',
		editorFooter:
			'Instantly improve your  scholarship essays, college applications, and school papers with guaranteed accuracy',
		tryItNow: 'Try It Now',
		learnMoreNow: 'Learn More Now',
	},
	categories: {
		selectTitle: 'Select Categories',
		updateTitle: 'Update Categories',
		selectBody: 'Complete your profile to unlock more great scholarships',
		updateBody: 'Long Press on one of the items to update your matched scholarships',
	},
	subCategories: {
		title: 'Tap each sub-category for an expanded list of items',
	},
	paywall: {
		paymentType: 'Payment Type',
		sadEmjoi: `😩`,
		congratsEmjoi: `🎉`,
		successTitle: "Success, You're all set!",
		successBody:
			'You’re on your way to finding free money for school. Next, tell us more about yourself so we can find scholarships tailored to you.',
		schollyPlatform: 'Scholly Platform',
		terms: 'Terms of Service & Privacy Policy',
		annualTrialDisclaimerIos: (amount: string, type: string) =>
			`You will not be charged during your free trial period. When the free trial ends, your iTunes account will be charged for the ${type} subscription of $${amount}. To avoid that, you must cancel the subscription at least 24 hours before it expires. Manage your subscriptions in your iTunes Account Settings. Any unused portion of the free trial period will be forfeited when you purchase a subscription to Scholly. Membership automatically renews for $${amount} unless auto-renew is turned off at least 24-hours before the end of the current period. The account will be charged $${amount} for renewal within 24-hours before the end of the current period. All refunds have to be processed via Apple, per their policy.`,
		annualTrialDisclaimerAndroid: (amount: string, type: string) =>
			`You will not be charged during your free trial period. When the free trial ends, your Google Play account will be charged for the ${type} subscription of $${amount}. To avoid that, you must cancel the subscription at least 24 hours before it expires. Manage your subscriptions in your Google Play Account Settings. Any unused portion of the free trial period will be forfeited when you purchase a subscription to Scholly. Membership automatically renews for $${amount} unless auto-renew is turned off at least 24-hours before the end of the current period. The account will be charged $${amount} for renewal within 24-hours before the end of the current period.`,
		annualTrialDisclaimerWeb: (amount: string) =>
			`Scholly will automatically continue your membership at the end of your free trial and charge the membership fee (currently $${amount}) to your payment method on a Yearly basis until you cancel. There are no refunds or credits for partial months.`,
		monthlyDisclaimer: (amount: string, _type: string) =>
			`Manage your subscriptions in your iTunes Account Settings. Membership automatically renews for $${amount} unless auto-renew is turned off at least 24-hours before the end of the current period. The account will be charged $${amount} for renewal within 24-hours before the end of the current period. All refunds have to be processed via Apple, per their policy.`,
		monthlyDisclaimerAndroid: (amount: string, _type: string) =>
			`Manage your subscriptions in your Google Play Account Settings. Membership automatically renews for $${amount} unless auto-renew is turned off at least 24-hours before the end of the current period. The account will be charged $${amount} for renewal within 24-hours before the end of the current period.`,
		paymentCopyAmount: (amount: string, type: string) =>
			`Your credit card will be charged $${amount} USD per ${type === 'monthly' ? 'month' : 'year'} ${
				type === 'monthly' ? '' : 'after your free 3-day trial'
			}.`,
		paymentDisclaimer: (amount: string, type: string) =>
			`I understand that my credit card will be charged $${amount} ${type}${type == 'biannual' ? 'ly' : ''}${
				type !== 'monthly' ? ' after the three day free trial' : ''
			}. It is also my responsibility to cancel before my subscription renews`,
		privacyTitle: 'We respect your privacy',
		privacyDescription:
			'Event-based data is collected to personalize communications and content \n  We will NEVER sell your personal data',
		okay: 'Okay',
		webTitle: 'Select a plan',
		webSchollyFound: 'Scholly Search found',
		webInScholarshipsJustForYou: 'in scholarships just for you',

		webSubTitle:
			"Unlock access to the entire Scholly Platform, including Scholly's award-winning scholarship matching app, Scholly Editor for AI-Powered scholarship essay help, and Scholly Math for instant problem-solving.",
		checkout: 'Checkout',
		checkoutSub: "Just a little paperwork \n We'll have you searching in no time.",
		selectPaymentType: 'Select Payment Type',
		paymentMethod: 'Payment Method',
		paymentSuccessful: 'Payment was succesful!',
		yourPaymentSuccessful: 'Your payment was successful',
		scholarshipsMatch: 'Scholarships matched for you!',
		wantMoreMatches: 'Want more matches?',
		continueAnswering: 'Continue answering a few more quesitons to find scholarships\nunique to you.',
		paymentOverview: 'Overview',
		cardNumberError: 'You have incorrectly entered your credit card number.',
		schollyCodeCopy:
			'Give Scholly is Scholly’s membership sponsorship program that enables you to distribute free access to Scholly.',
	},
	snackBar: {
		updatedAccount: 'Updated account information.',
		errorAccount: "Can't update information. Retry later.",
	},
	undefinedError: 'Something went wrong, please try again',
	promo: {
		offerText: 'Get $80 Now!',
		offerDescription: 'For exploring new\nfinancial tools',
	},
	offers: {
		exclusiveOffers: 'Exclusive Offers',
	},
	refund: {
		subHeader: 'Scholly is now free!',
		contentHeader: {
			eligible: (amount: string) => `You are eligible for a refund of $${amount}`,
			notEligible: 'Thanks for checking in with us! Unfortunately, you are not eligible for a refund.',
			inProgress: 'Your refund is in progress',
			completed: 'Your refund is now available.',
		},
		content: {
			allow60Days: `Please allow up to 60 days to receive an email detailing how to claim your refund.`,
			notEligible: 'The good news is that the Scholly app is now free to support your scholarship needs!',
			completed: (email: string) =>
				`Please check your email at ${email} with details on how to claim your funds.`,
		},
	},
	updateProfile: {
		welcomeTitle: 'Keep your scholarship opportunities up to date.',
		welcomeDescription: 'Confirm your current information for relevant and exciting scholarships.',
		success: 'Thanks! Your info has been updated',
		header: 'Confirm your information',
	},
	twoK: {
		studentEmailPlaceHolder: 'Student’s Email',
		parentEamilPlaceHolder: 'Parent/Guardian’s Email',
		applicationReceived: 'Application received!',
		winnerDescription: 'Winner will be notified via email.',
		increaseChancesOfWinning: 'Want to increase your chances of winning?',
		parentGardianEmailPrompt: (isParent) =>
			`Enter ${
				isParent ? "your student's " : 'a parent’s or guardian’s'
			} email below and we’ll invite them to apply for the $2,000 Scholly Scholarship on your behalf.`,
		emailErrorMessgae: 'Please enter a valid email including @ symbol and a domain extension such as .com or .net.',
		gotIt: 'Got it!',
		inviteSent: (email) => `A $2,000 Scholly Scholarship invite's been sent to ${email}.`,
		hasReferralslMessage: (
			referralEmail
		) => `An additional $2,000 Scholly Scholarship invite's been sent to ${referralEmail} 
		to increase your chances of winning.`,
	},
};
