import moment from 'moment';

const Cache = {
	set(key, value) {
		const date = new Date();
		const expires = date.setDate(date.getDate() + 7);
		const cache = {
			expires,
			data: value,
		};
		return localStorage.setItem(`cache.${key}`, JSON.stringify(cache));
	},
	async get(key) {
		let cache = {};
		const fetched = await localStorage.getItem(`cache.${key}`);
		if (fetched) {
			const parse = JSON.parse(fetched);
			if (moment().diff(parse.expires, 'days') < 0) {
				cache = parse.data;
			}
		}
		return cache;
	},
	delete(key) {
		return localStorage.removeItem(`cache.${key}`);
	},
};
export default Cache;

export const localStorageProvider = () => {
	const map = new Map(JSON.parse(localStorage.getItem('scholly-cache') || '[]'));

	window.addEventListener('beforeunload', () => {
		const appCache = JSON.stringify(Array.from(map.entries()));
		localStorage.setItem('scholly-cache', appCache);
	});

	return map;
};
