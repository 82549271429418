import React from 'react';

import { useHistory } from 'react-router-dom';
import { Typography } from '@scholly/scholly-ui';

import { cancelSubscription } from '../../../../actions';
import Button from '../../../../components/button/Button';
import config from '../../../../config/config';
import { Routes } from '../../../../constants';
import { UserContext, FlashContext } from '../../../../contexts';
import ImageHolder from '../ImageHolder';

const AccountType = ({ issue }) => {
	const history = useHistory();
	const { offboarding } = Routes;
	const { subscription, getAndSetSubscription, merchant_type } = React.useContext(UserContext);
	const [processor, setProcessor] = React.useState('stripe');
	const [image, setImage] = React.useState(`${config.cdn}images/applecards@2x.png`);
	const [buttonLabel, setButtonLabel] = React.useState('Cancel subscription');
	const [loading, setLoading] = React.useState<boolean>(false);
	const flashContext = React.useContext(FlashContext);
	React.useEffect(() => {
		subscription?.processor && getScreen(subscription.processor);
	}, [subscription]);
	const cancel = async () => {
		try {
			setLoading(true);
			// @ts-ignore
			const result = await cancelSubscription(subscription);
			await getAndSetSubscription();
			flashContext.display({ type: 'success', text: 'Subscription canceled successfully' });
			history.push(`${offboarding.home}/${offboarding.thankYou}`);
			setLoading(false);
		} catch (error) {
			console.log(error, '<---error');
		}
	};
	const getScreen = (value) => {
		setProcessor(value);
		switch (value) {
			case 'ios':
				setButtonLabel('Cancel via App Store');
				setImage(`${config.cdn}images/applecards@2x.png`);
				break;
			case 'android':
				setButtonLabel('Cancel Subscription');
				setImage(`${config.cdn}images/androidcards@2x.png`);
				break;
			case 'stripe':
				setButtonLabel('Cancel Subscription');
				setImage(`${config.cdn}images/webcards@2x.png`);
				break;
			default:
				return null;
		}
	};

	const buttonFunction = (value) => {
		switch (value) {
			case 'ios':
				flashContext.display({ type: 'error', text: 'Cancel through the App Store' });
				window.open(config.app_store, '_blank');
				break;
			case 'android':
				cancel();
				break;
			case 'stripe':
				cancel();
				break;
			default:
				return null;
		}
	};
	const renderTrial = () => {
		const endDate = new Date(subscription?.trial_end);
		const startDate = new Date(subscription?.start_time);

		return (
			<React.Fragment>
				<div style={{ textAlign: 'left', marginTop: '24px' }}>
					<Typography variant="h4">Trial Started: </Typography>
					<Typography variant="body">{startDate.toLocaleDateString('en-US')}</Typography>
				</div>
				<div style={{ textAlign: 'left', marginTop: '24px' }}>
					<Typography variant="h4">Trial Ending: </Typography>
					<Typography variant="body">{endDate.toLocaleDateString('en-US')}</Typography>
				</div>
			</React.Fragment>
		);
	};
	const renderSub = () => {
		const endDate = new Date(subscription?.next_billing_date);
		const startDate = new Date(subscription?.start_time);

		return (
			<React.Fragment>
				<div style={{ textAlign: 'left', marginTop: '24px' }}>
					<Typography variant="h4">Started: </Typography>
					<Typography variant="body">{startDate.toLocaleDateString('en-US')}</Typography>
				</div>
				<div style={{ textAlign: 'left', marginTop: '24px' }}>
					<Typography variant="h4">Next Billing Date: </Typography>
					<Typography variant="body">{endDate.toLocaleDateString('en-US')}</Typography>
				</div>
				<div style={{ textAlign: 'left', marginTop: '24px' }}>
					<Typography variant="h4">Payment: </Typography>
					<Typography variant="body">
						{subscription?.credit_card_type} ending in {subscription?.credit_card_last_four}
					</Typography>
				</div>
			</React.Fragment>
		);
	};

	const renderIOS = () => {
		return (
			<div style={{ textAlign: 'left', marginTop: '24px' }}>
				<Typography variant="body">
					You subscribed via the Apple App Store. You can manage and cancel your subscription through your
					Apple App Store settings in “Subscriptions”.
				</Typography>
			</div>
		);
	};

	return (
		<React.Fragment>
			<ImageHolder src={image} />
			<div style={{ margin: '0 auto', width: '60%', marginTop: '48px' }}>
				<div style={{ textAlign: 'left' }}>
					<Typography variant="h1">{subscription?.plan?.display_banner || ''}</Typography>
				</div>
				<React.Fragment>{subscription?.trial_end && processor !== 'ios' && renderTrial()}</React.Fragment>
				<React.Fragment>
					{subscription && merchant_type !== 'free' && processor !== 'ios' && renderSub()}
				</React.Fragment>
				<React.Fragment>{processor == 'ios' && renderIOS()}</React.Fragment>
				<div style={{ display: 'grid', placeItems: 'center', marginTop: '48px' }}>
					<Button
						variant="solid"
						label={buttonLabel}
						onClick={() => buttonFunction(processor)}
						compact
						style={{ width: '80%', margin: '0 auto', minWidth: '200px' }}
						disabled={loading}
						loading={loading}
						testID={`testID_accountType_button_cancel`}
					/>
				</div>
			</div>
		</React.Fragment>
	);
};
export default AccountType;
