export const Routes = {
	reset: '/reset',
	redirect: '/redirect',
	resetpassword: '/resetpassword',
	search: '/',
	brand: '/:brand',
	signup: '/sign-up/:index?',
	login: '/login',
	register: '/register',
	categories: '/categories',
	subscription: '/subscription',
	settings: '/settings',
	settingNotification: '/settings/notifications',
	dashboard: '/dashboard',
	schollyFree: '/freeaccountactivated',
	refund: '/refund',
	offboarding: {
		main: '/offboarding/:screen?/:pauseLength?',
		technicalIssues: 'technical-issues',
		aspiration: 'aspiration',
		additionalComments: 'additional-comments',
		closeAccount: 'close-account',
		pauseAccount: 'pause-account',
		affiliateOffer: 'affiliate-offer',
		pauseMembership: 'pause-membership',
		unpauseMembership: 'unpause-membership',
		subscriptionComplete: 'subscription-complete',
		successUnpauseMembership: 'success-unpause-membership',
		thankYou: 'thank-you',
		successfullyPaused: 'successfully-paused',
		successfullyCanceled: 'successfully-canceled',
		accountType: 'account-type',
		cancelSubscription: 'cancel-subscription',
		home: '/offboarding',
		inviteFriends: 'invite-friends',
	},

	user: {
		create: '/create-profile',
		updateProfile: '/update-profile',
		updateProfileSuccess: '/update-profile-success',
		myProfile: '/my-profile',
		profile: '/profile',
	},
	scholarships: {
		feed: '/scholarships',
		applied: '/scholarships/applied',
		saved: '/scholarships/saved',
		slug: '/scholarships/:slug',
		reportIssue: '/scholarships/report-issue',
	},
};

export const Routess = {
	scholarships: {
		feed: '/scholarships',
		applied: '/scholarships/applied',
		saved: '/scholarships/saved',
		slug: '/scholarships/:slug',
		reportIssue: '/scholarships/report-issue',
	},
};
