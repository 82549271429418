import React, { CSSProperties, FC } from 'react';

interface CalendarIconProps {
	onClick?: (val: any) => void;
	svgStyle?: CSSProperties;
}
export const CalendarIcon: FC<CalendarIconProps> = (props) => {
	const { onClick, svgStyle } = props;
	return (
		<div className="content" onClick={onClick}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="84"
				height="68"
				viewBox="0 0 84 68"
				fill="none"
				style={svgStyle}
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M15.8358 6.79102H67.9616C70.8595 6.79102 72.8068 9.13403 72.2823 12.0479L63.3429 62.3678C62.8214 65.2816 60.0308 67.8419 57.133 67.8419H5.00863C2.11077 67.8419 0.16346 65.2591 0.687907 62.3453L9.62734 12.1453C10.1339 9.23143 12.9409 6.79102 15.8388 6.79102H15.8358Z"
					fill="url(#paint0_linear_6696_1502)"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M15.8356 6.79102H67.9614C70.8593 6.79102 73.6037 9.13403 74.07 12.0479L82.0291 62.3678C82.4895 65.2816 80.496 67.8419 77.5966 67.8419H25.4663C22.5685 67.8419 19.8226 65.2591 19.3577 62.3453L11.4031 12.1453C10.9442 9.23143 12.9377 6.79102 15.8356 6.79102Z"
					fill="#2D50C1"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M15.8044 6.79102H67.9302C70.8281 6.79102 73.6351 9.08909 74.1566 11.94L83.312 61.4929C83.8484 64.3393 81.9011 67.0359 79.0017 67.0359H26.8923C23.9944 67.0359 21.1726 64.3258 20.6511 61.4734L11.4956 12.1243C10.9742 9.27785 12.9066 6.79102 15.8044 6.79102Z"
					fill="#3259F1"
				/>
				<path
					d="M32.919 40.3451H26.6614C26.3378 40.3451 26.0241 40.2327 25.7735 40.0268C25.5229 39.821 25.3508 39.5344 25.2862 39.2156L23.9989 32.8741C23.9574 32.669 23.9618 32.4572 24.0116 32.254C24.0615 32.0509 24.1556 31.8613 24.2872 31.6992C24.4188 31.5371 24.5845 31.4064 24.7725 31.3165C24.9605 31.2267 25.166 31.1799 25.3741 31.1797H31.6317C31.9552 31.18 32.2687 31.2926 32.5193 31.4984C32.7698 31.7042 32.942 31.9906 33.0069 32.3093L34.2942 38.6508C34.3356 38.8558 34.3313 39.0676 34.2815 39.2708C34.2316 39.474 34.1375 39.6635 34.0059 39.8256C33.8743 39.9878 33.7085 40.1185 33.5205 40.2083C33.3326 40.2981 33.1271 40.3449 32.919 40.3451Z"
					fill="white"
				/>
				<path
					d="M45.5816 40.3451H39.324C39.0004 40.3451 38.6867 40.2327 38.4361 40.0268C38.1855 39.821 38.0134 39.5344 37.9488 39.2156L36.6615 32.8741C36.62 32.669 36.6243 32.4572 36.6742 32.254C36.7241 32.0509 36.8182 31.8613 36.9498 31.6992C37.0814 31.5371 37.2471 31.4064 37.4351 31.3165C37.6231 31.2267 37.8286 31.1799 38.0367 31.1797H44.2943C44.618 31.1794 44.9317 31.2918 45.1824 31.4977C45.4331 31.7036 45.6052 31.9903 45.6695 32.3093L46.9567 38.6508C46.9982 38.8558 46.9939 39.0676 46.9441 39.2708C46.8942 39.474 46.8001 39.6635 46.6685 39.8256C46.5369 39.9878 46.3711 40.1185 46.1831 40.2083C45.9952 40.2982 45.7897 40.3449 45.5816 40.3451Z"
					fill="white"
				/>
				<path
					d="M58.2432 40.3451H51.9856C51.6621 40.3446 51.3488 40.2319 51.0983 40.0261C50.8478 39.8204 50.6755 39.5341 50.6104 39.2156L49.3231 32.8741C49.2816 32.6689 49.286 32.457 49.3359 32.2537C49.3858 32.0504 49.4801 31.8608 49.6119 31.6986C49.7436 31.5364 49.9096 31.4058 50.0977 31.316C50.2859 31.2263 50.4915 31.1797 50.6998 31.1797H56.9574C57.2811 31.1794 57.5948 31.2918 57.8455 31.4977C58.0962 31.7036 58.2682 31.9903 58.3326 32.3093L59.6198 38.6508C59.6614 38.856 59.657 39.0679 59.6071 39.2711C59.5571 39.4744 59.4629 39.664 59.3311 39.8262C59.1993 39.9884 59.0334 40.119 58.8452 40.2088C58.6571 40.2985 58.4514 40.3451 58.2432 40.3451Z"
					fill="white"
				/>
				<path
					d="M70.9042 40.3451H64.6466C64.3229 40.3449 64.0092 40.2324 63.7584 40.0266C63.5076 39.8208 63.335 39.5344 63.2699 39.2156L61.9841 32.8741C61.9423 32.669 61.9465 32.4571 61.9962 32.2538C62.046 32.0505 62.1401 31.8609 62.2717 31.6986C62.4033 31.5364 62.5692 31.4057 62.7573 31.316C62.9455 31.2262 63.1511 31.1797 63.3593 31.1797H69.6169C69.9407 31.1796 70.2545 31.2921 70.5054 31.4979C70.7562 31.7037 70.9287 31.9903 70.9936 32.3093L72.2793 38.6508C72.3211 38.8559 72.317 39.0677 72.2672 39.271C72.2175 39.4743 72.1234 39.664 71.9917 39.8262C71.8601 39.9884 71.6942 40.1191 71.5061 40.2089C71.318 40.2986 71.1124 40.3452 70.9042 40.3451Z"
					fill="#95CBED"
				/>
				<path
					d="M35.3433 52.2889H29.0857C28.7621 52.2892 28.4483 52.1769 28.1976 51.971C27.9469 51.7651 27.7749 51.4783 27.7105 51.1594L26.4233 44.8179C26.3817 44.6129 26.3861 44.4012 26.4359 44.1981C26.4858 43.995 26.5799 43.8055 26.7115 43.6435C26.8432 43.4815 27.009 43.3509 27.1969 43.2612C27.3849 43.1716 27.5904 43.125 27.7984 43.125H34.056C34.3798 43.125 34.6937 43.2374 34.9445 43.4432C35.1954 43.649 35.3678 43.9356 35.4327 44.2546L36.72 50.5946C36.7615 50.7998 36.7571 51.0117 36.7072 51.215C36.6573 51.4183 36.563 51.6079 36.4313 51.77C36.2995 51.9322 36.1335 52.0628 35.9454 52.1526C35.7572 52.2423 35.5516 52.2889 35.3433 52.2889Z"
					fill="#95CBED"
				/>
				<path
					d="M48.0059 52.2889H41.7483C41.4246 52.2892 41.1109 52.1769 40.8602 51.971C40.6095 51.7651 40.4375 51.4783 40.3731 51.1594L39.0859 44.8179C39.0443 44.6129 39.0487 44.4012 39.0985 44.1981C39.1484 43.995 39.2425 43.8055 39.3741 43.6435C39.5058 43.4815 39.6716 43.3509 39.8595 43.2612C40.0475 43.1716 40.253 43.125 40.461 43.125H46.7186C47.0422 43.125 47.3559 43.2375 47.6065 43.4433C47.8571 43.6492 48.0292 43.9358 48.0938 44.2546L49.3811 50.5961C49.4223 50.801 49.4178 51.0127 49.3678 51.2157C49.3179 51.4186 49.2237 51.608 49.0921 51.7699C48.9606 51.9319 48.7949 52.0624 48.6071 52.1522C48.4192 52.2419 48.2139 52.2887 48.0059 52.2889Z"
					fill="#95CBED"
				/>
				<path
					d="M60.667 52.2889H54.4094C54.0859 52.2886 53.7724 52.1761 53.5219 51.9703C53.2713 51.7645 53.0991 51.4781 53.0343 51.1594L51.747 44.8179C51.7054 44.6127 51.7098 44.4009 51.7597 44.1977C51.8097 43.9945 51.904 43.805 52.0357 43.6429C52.1675 43.4808 52.3335 43.3503 52.5217 43.2607C52.7098 43.1712 52.9155 43.1248 53.1237 43.125H59.3813C59.7049 43.1247 60.0187 43.2371 60.2694 43.443C60.52 43.6489 60.6921 43.9356 60.7564 44.2546L62.0437 50.5946C62.0852 50.7998 62.0809 51.0117 62.0309 51.215C61.981 51.4183 61.8867 51.6079 61.755 51.77C61.6232 51.9322 61.4573 52.0628 61.2691 52.1526C61.081 52.2423 60.8753 52.2889 60.667 52.2889Z"
					fill="#95CBED"
				/>
				<path
					d="M73.3282 52.2889H67.0706C66.7468 52.289 66.433 52.1766 66.1821 51.9708C65.9313 51.765 65.7588 51.4783 65.6939 51.1594L64.4067 44.8179C64.3651 44.6127 64.3695 44.4009 64.4194 44.1977C64.4693 43.9945 64.5636 43.805 64.6954 43.6429C64.8272 43.4808 64.9932 43.3503 65.1813 43.2607C65.3695 43.1712 65.5751 43.1248 65.7833 43.125H72.0409C72.3646 43.1247 72.6784 43.2371 72.929 43.443C73.1797 43.6489 73.3518 43.9356 73.4161 44.2546L74.7034 50.5946C74.7451 50.7997 74.741 51.0116 74.6913 51.2149C74.6415 51.4181 74.5474 51.6078 74.4158 51.77C74.2841 51.9322 74.1182 52.0629 73.9301 52.1527C73.742 52.2424 73.5364 52.289 73.3282 52.2889Z"
					fill="#95CBED"
				/>
				<path
					d="M37.767 64.2279H31.5094C31.1858 64.2279 30.8721 64.1154 30.6215 63.9096C30.3709 63.7038 30.1988 63.4172 30.1342 63.0983L28.8469 56.7568C28.8051 56.5517 28.8093 56.3399 28.859 56.1366C28.9087 55.9333 29.0028 55.7436 29.1345 55.5814C29.2661 55.4192 29.432 55.2885 29.6201 55.1988C29.8083 55.109 30.0139 55.0625 30.2221 55.0625H36.4797C36.8035 55.0625 37.1173 55.1748 37.3682 55.3807C37.619 55.5865 37.7915 55.8731 37.8564 56.1921L39.1436 62.5335C39.1852 62.7387 39.1808 62.9507 39.1309 63.154C39.0809 63.3573 38.9867 63.5468 38.8549 63.709C38.7231 63.8712 38.5572 64.0019 38.369 64.0916C38.1809 64.1813 37.9752 64.2279 37.767 64.2279Z"
					fill="#95CBED"
				/>
				<path
					d="M50.4282 64.2279H44.1706C43.8471 64.2279 43.5334 64.1154 43.2828 63.9096C43.0322 63.7038 42.86 63.4172 42.7955 63.0983L41.5082 56.7568C41.4667 56.5518 41.471 56.34 41.5209 56.1368C41.5707 55.9336 41.6649 55.7441 41.7965 55.582C41.928 55.4198 42.0938 55.2891 42.2818 55.1993C42.4698 55.1094 42.6752 55.0627 42.8834 55.0625H49.141C49.4645 55.0628 49.778 55.1753 50.0285 55.3811C50.2791 55.5869 50.4513 55.8734 50.5161 56.1921L51.8034 62.5335C51.8449 62.7386 51.8406 62.9504 51.7907 63.1536C51.7409 63.3568 51.6468 63.5463 51.5152 63.7084C51.3836 63.8705 51.2178 64.0012 51.0298 64.0911C50.8419 64.1809 50.6364 64.2277 50.4282 64.2279Z"
					fill="#95CBED"
				/>
				<path
					d="M63.0913 64.2279H56.8337C56.5102 64.2276 56.1967 64.1151 55.9462 63.9093C55.6956 63.7035 55.5234 63.417 55.4586 63.0983L54.1713 56.7568C54.1298 56.5518 54.1341 56.34 54.184 56.1368C54.2338 55.9336 54.3279 55.7441 54.4595 55.582C54.5911 55.4198 54.7569 55.2891 54.9449 55.1993C55.1329 55.1094 55.3383 55.0627 55.5465 55.0625H61.8041C62.1277 55.0622 62.4415 55.1746 62.6922 55.3804C62.9428 55.5863 63.1149 55.8731 63.1792 56.1921L64.4665 62.5335C64.508 62.7386 64.5037 62.9504 64.4538 63.1536C64.404 63.3568 64.3098 63.5463 64.1783 63.7084C64.0467 63.8705 63.8809 64.0012 63.6929 64.0911C63.5049 64.1809 63.2995 64.2277 63.0913 64.2279Z"
					fill="#95CBED"
				/>
				<path
					d="M75.7525 64.2279H69.4949C69.1712 64.2277 68.8575 64.1152 68.6067 63.9094C68.3559 63.7036 68.1834 63.4172 68.1182 63.0983L66.8309 56.7568C66.7894 56.5516 66.7938 56.3397 66.8437 56.1364C66.8937 55.9332 66.9879 55.7436 67.1197 55.5814C67.2514 55.4193 67.4174 55.2886 67.6056 55.1988C67.7937 55.1091 67.9994 55.0625 68.2076 55.0625H74.4652C74.7889 55.0622 75.1027 55.1746 75.3533 55.3804C75.604 55.5863 75.7761 55.8731 75.8404 56.1921L77.1277 62.5335C77.1692 62.7386 77.1649 62.9504 77.115 63.1536C77.0651 63.3568 76.971 63.5463 76.8394 63.7084C76.7078 63.8705 76.5421 64.0012 76.3541 64.0911C76.1661 64.1809 75.9606 64.2277 75.7525 64.2279Z"
					fill="#95CBED"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M79.6154 66.6352C79.4158 66.6502 79.2176 67.0337 79.0016 67.0337H26.8921C23.9943 67.0337 21.1873 64.3236 20.6509 61.4712L19.2251 53.8398L79.6154 66.6352Z"
					fill="#3259F1"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M19.2252 53.8735L79.5083 66.6853C79.5083 66.6853 32.9502 48.4324 27.3616 48.2316C21.7729 48.0309 19.2192 53.8735 19.2192 53.8735H19.2252Z"
					fill="#C5E1EF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M15.8044 6.79102H67.9303C70.8281 6.79102 73.6351 9.27186 74.1566 12.1243L76.673 25.6147H13.6053L11.4956 12.1243C10.9742 9.27785 12.9066 6.79102 15.8044 6.79102Z"
					fill="#2D50C1"
				/>
				<path
					d="M39.3765 15.1229C40.2741 14.0919 39.9171 12.3015 38.5791 11.1238C37.2412 9.94607 35.429 9.82705 34.5314 10.858C33.6339 11.8889 33.9909 13.6793 35.3288 14.8571C36.6667 16.0348 38.4789 16.1538 39.3765 15.1229Z"
					fill="#3259F1"
				/>
				<path
					d="M38.6436 14.4769C39.2696 13.7579 39.0208 12.5093 38.0877 11.688C37.1547 10.8667 35.8909 10.7837 35.2648 11.5028C34.6388 12.2218 34.8877 13.4705 35.8207 14.2918C36.7538 15.1131 38.0176 15.196 38.6436 14.4769Z"
					fill="#2D50C1"
				/>
				<path
					d="M62.2452 14.9061C63.1427 13.8751 62.7857 12.0847 61.4478 10.907C60.1098 9.72925 58.2976 9.61026 57.4001 10.6412C56.5025 11.6721 56.8595 13.4625 58.1974 14.6402C59.5354 15.818 61.3476 15.937 62.2452 14.9061Z"
					fill="#3259F1"
				/>
				<path
					d="M61.5122 14.2621C62.1383 13.5431 61.8894 12.2944 60.9564 11.4731C60.0233 10.6518 58.7595 10.5689 58.1335 11.2879C57.5075 12.007 57.7563 13.2556 58.6894 14.0769C59.6224 14.8982 60.8862 14.9811 61.5122 14.2621Z"
					fill="#2D50C1"
				/>
				<path
					d="M37.3249 13.729C37.1631 13.7286 37.0045 13.6841 36.866 13.6002C36.6629 13.4774 36.5167 13.2785 36.4595 13.0473C36.4022 12.8161 36.4385 12.5715 36.5605 12.3673C37.1862 11.3149 37.4966 10.1035 37.4545 8.87816C37.3992 7.18449 36.6779 5.58205 35.4488 4.42261C34.2198 3.26317 32.5834 2.64147 30.8989 2.69397C29.5702 2.73633 28.2881 3.19644 27.2329 4.00961C26.1778 4.82278 25.4027 5.9481 25.0168 7.22725C24.9479 7.45526 24.7919 7.64646 24.5829 7.75883C24.3739 7.87119 24.1291 7.89554 23.9023 7.82649C23.7899 7.79223 23.6852 7.73601 23.5944 7.66106C23.5036 7.58611 23.4283 7.49389 23.373 7.38968C23.3176 7.28546 23.2833 7.17128 23.2718 7.0537C23.2604 6.93611 23.2721 6.81743 23.3063 6.70441C23.8006 5.06588 24.7934 3.62439 26.1449 2.58278C27.4965 1.54117 29.1388 0.951858 30.8408 0.897743C31.9101 0.859417 32.9764 1.0347 33.9778 1.41349C34.9793 1.79227 35.8962 2.36702 36.6753 3.10445C37.458 3.83809 38.0883 4.72062 38.5294 5.7009C38.9706 6.68118 39.2139 7.73974 39.2453 8.81525C39.2983 10.387 38.8977 11.9406 38.0922 13.2886C38.0132 13.4225 37.9009 13.5336 37.7664 13.6108C37.6319 13.688 37.4797 13.7287 37.3249 13.729Z"
					fill="#F55273"
				/>
				<path
					d="M59.9831 13.0122C59.8207 13.0121 59.6614 12.967 59.5228 12.8818C59.321 12.7578 59.1763 12.5583 59.1205 12.3273C59.0646 12.0962 59.1021 11.8522 59.2248 11.6489C59.8554 10.5987 60.1705 9.38749 60.1321 8.1613C60.1083 7.32241 59.9194 6.49656 59.5762 5.73156C59.233 4.96656 58.7424 4.27758 58.1327 3.70443C57.5265 3.12659 56.8124 2.67543 56.0315 2.37713C55.2507 2.07882 54.4188 1.93929 53.584 1.96661C50.7532 2.05051 48.0192 4.49394 47.7242 7.19353C47.6983 7.43026 47.5801 7.64699 47.3955 7.79614C47.2108 7.94529 46.9748 8.01467 46.7394 7.98904C46.6226 7.97617 46.5096 7.94029 46.4067 7.88348C46.3037 7.82667 46.213 7.75002 46.1395 7.65792C46.066 7.56583 46.0113 7.4601 45.9785 7.34674C45.9457 7.23339 45.9354 7.11462 45.9482 6.99727C46.3401 3.40181 49.8116 0.281243 53.5304 0.170383C54.6001 0.135114 55.6661 0.313744 56.6666 0.695891C57.6671 1.07804 58.5822 1.65611 59.3589 2.39659C60.1396 3.13252 60.7675 4.01684 61.2061 4.99838C61.6447 5.97992 61.8853 7.03918 61.914 8.11485C61.9621 9.68624 61.5575 11.2381 60.7489 12.5837C60.6686 12.7152 60.556 12.8237 60.4218 12.8988C60.2877 12.9738 60.1366 13.0129 59.9831 13.0122Z"
					fill="#F55273"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M21.3381 6.80146H27.2306V10.1467H20.3413C20.3413 10.1467 21.566 6.57374 21.3381 6.80146Z"
					fill="#2D50C1"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M44.1366 6.79556H50.0277L50.1037 10.1528H43.2144C43.2144 10.1528 44.3631 6.56785 44.1366 6.79556Z"
					fill="#2D50C1"
				/>
				<defs>
					<linearGradient
						id="paint0_linear_6696_1502"
						x1="2.77896"
						y1="65.9301"
						x2="33.2927"
						y2="57.5008"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#3259F1" />
						<stop offset="1" stopColor="#2D50C1" />
					</linearGradient>
				</defs>
			</svg>
		</div>
	);
};
