import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { StyleSheet } from 'react-native-web';

import { Button, Input } from '@scholly/elements';
import { Colors, Typography } from '@scholly/scholly-ui';

import { BackArrowButton } from '../../components/button/BackArrowButton';
import { CustomModal } from '../../components/modal/Modal';
import ForgotPasswordForm from '../../components/auth/ForgotPasswordForm';
import { PasswordIcon } from '../../components/common/icons/password-icon';
import { PasswordValidation } from '../../components/auth/PasswordValidation';
import { ViewPasswordIcon } from '../../components/common/icons/view-password-icon';

import { Routes, ScreenStrings, PASSWORD_CHECKS } from '../../constants';

import { resetChangePassword, resetPassword, verifyPasswordReset } from '../../actions';
import { passwordStrengthValidation } from '../../helpers/Validation';

import styles from './authentication.scss';

interface IProps {
	toggleScreen: () => void;
	token: string;
	styles?: object;
}

const ResetPassword: React.FC<IProps> = (props) => {
	const history = useHistory();
	const [email, setEmail] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [successMsg, setSuccessMsg] = useState('');
	const [isResetValid, setIsResetValid] = useState(true);
	const [isEmailForm, setEmailForm] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [password, setNewPassword] = useState('');
	const [repassword, setNewRePassword] = useState('');
	const [isShowModal, setIsShowModal] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [newPasswordFocus, setNewPasswordFocus] = useState(false);
	const [showNewRePassword, setShowNewRePassword] = useState(false);
	const svgEyeStyle = { position: 'absolute', zIndex: 2, top: 10, right: 12 } as React.CSSProperties;

	React.useEffect(() => {
		props?.token &&
			(async function () {
				try {
					setEmailForm(false);
					await verifyPasswordReset(props?.token);
				} catch (error) {
					setEmailForm(true);
					setIsResetValid(false);
					setErrorMsg(
						'Password token expired. Please try again. If you have received multiple password recovery emails, try the latest one.  Or you can enter your email to be resent a new recovery link.'
					);
					console.log(error);
				}
			})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.token]);

	const resetSubmit = async (email: string) => {
		setEmail(email);
		try {
			await resetPassword({ email });
			setIsResetValid(true);
			toggleModal();

			// new screen
		} catch (error) {
			setIsResetValid(false);
			setErrorMsg('Email does not exist.');
			setSuccessMsg('');
			console.log(error);
		}
	};

	const handleNewPassword = (e) => setNewPassword(e);
	const handleReNewPassword = (e) => setNewRePassword(e);

	const toggleModal = () => {
		setIsShowModal(!isShowModal);
	};
	const PasswordValid = (): boolean => {
		const passwordErrors = passwordStrengthValidation(password, repassword);

		return Object.values(passwordErrors).filter((value) => !value).length === 0;
	};
	const savePassword = async () => {
		// validate password
		if (!PasswordValid()) return;

		try {
			setIsLoading(true);
			await resetChangePassword(props.token, password);

			setSuccessMsg('Password has been updated');

			setTimeout(() => {
				history.replace(Routes.login);
			}, 2000);
		} catch (error) {
			setIsLoading(false);
			console.log(error);
		}
	};

	const isDisabled = () => {
		return Object.values(passwordStrengthValidation(password, repassword)).filter((value) => !value).length !== 0;
	};

	const goBack = () => {
		history.replace(Routes.login);
	};
	const renderForm = () => {
		return (
			<div className={styles.resetPasswordFormWrapper}>
				<div className={styles.isRelative}>
					<div>
						{(password || newPasswordFocus) && (
							<PasswordValidation
								messages={PASSWORD_CHECKS}
								testID="password_validation"
								passwordVal={password}
								repasswordVal={repassword}
							/>
						)}
					</div>
					<Input
						placeholder={ScreenStrings.auth.password}
						name="password"
						// @ts-ignore
						type={showPassword ? 'text' : 'password'}
						value={password}
						className={classNames(styles.w100, styles.resetPasswordInputWrapper)}
						onChange={(val) => handleNewPassword(val)}
						onFocus={() => setNewPasswordFocus(true)}
						onBlur={() => setNewPasswordFocus(false)}
						Icon={<PasswordIcon />}
						staticPlaceholder
					/>
					<ViewPasswordIcon
						onClick={() => {
							setShowPassword(!showPassword);
						}}
						svgStyle={svgEyeStyle}
						isShow={showPassword}
					/>
				</div>

				<div className={classNames(styles.isRelative, 'mt-4', 'mb-2')}>
					<Input
						placeholder={'Re-enter New Password'}
						name="repassword"
						// @ts-ignore
						type={showNewRePassword ? 'text' : 'password'}
						value={repassword}
						className={classNames(styles.w100, styles.resetPasswordInputWrapper)}
						onChange={(val) => handleReNewPassword(val)}
						Icon={<PasswordIcon />}
						staticPlaceholder
					/>
					<ViewPasswordIcon
						onClick={() => {
							setShowNewRePassword(!showNewRePassword);
						}}
						svgStyle={svgEyeStyle}
						isShow={showNewRePassword}
					/>
				</div>

				<div className={styles.resetPasswordMessageWrapper}>
					<Typography style={newStyles.successMessage}>{successMsg}</Typography>
					<Typography style={newStyles.errorMessage}>{errorMsg}</Typography>
				</div>
				<Button
					variant="primary"
					onClick={savePassword}
					disabled={isDisabled()}
					isLoading={isLoading}
					size="lg"
					style={{ width: '100%', padding: 0 }}
				>
					Set Your Password
				</Button>
			</div>
		);
	};
	return (
		<div className={styles.formContainer}>
			<div className="mx-auto is-flex is-align-items-center is-justify-content-center">
				<div className={classNames('is-flex', !isEmailForm && styles.w100)}>
					<div className={styles.formHeader}>
						<BackArrowButton strokeColor={'#192C78'} backAction={goBack} styles={{ paddingTop: '10px' }} />
					</div>
					<div className={classNames(styles.forgetPasswordWrapper, !isEmailForm && styles.w100)}>
						<div className={styles.formHeader}>
							<div className={`mb-6 ${styles.w100} ${styles.flexCenterXCenterY}`}>
								<Typography style={newStyles.title} variant="h1">
									{isEmailForm ? `Forgot Password?` : `Reset Password`}
								</Typography>
							</div>
						</div>

						<div
							className={classNames('mb-4', styles.forgetPasswordText, isEmailForm && styles.isEmailForm)}
						>
							<p className="has-text-centered mb-1">
								{isEmailForm
									? `If your account exists, you will receive an email with reset password instructions`
									: `Enter your new password.`}
							</p>
						</div>
						<div>
							{isEmailForm ? (
								<ForgotPasswordForm
									onReset={resetSubmit}
									errorMsg={errorMsg}
									isResetValid={isResetValid}
									successMsg={successMsg}
								/>
							) : (
								renderForm()
							)}
						</div>
					</div>
				</div>
				<CustomModal visible={isShowModal}>
					<div className={styles.modalContent}>
						<Typography variant="h3" family="medium" align="right" style={newStyles.modalHeader}>
							Reset Password
						</Typography>
						<Typography variant="caption2" family="medium" align="right" style={newStyles.modalText}>
							Please follow the instructions sent to: {email}
						</Typography>
						<Button
							variant="primary"
							onClick={toggleModal}
							isLoading={isLoading}
							style={{
								minHeight: 48,
								marginBottom: 24,
								boxSizing: 'border-box',
								width: 190,
								backgroundColor: '#3259F1',
								cursor: 'pointer',
								justifySelf: 'center',
								color: '#ffffff',
								marginTop: 45,
							}}
						>
							Got It!
						</Button>
					</div>
				</CustomModal>
			</div>
		</div>
	);
};

export default ResetPassword;

const newStyles = StyleSheet.create({
	successMessage: {
		color: Colors.actionGreen,
	},
	errorMessage: {
		color: Colors.red,
	},
	title: {
		fontFamily: "'Poppins'",
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: 48,
		lineHeight: 55,
		display: 'flex',
		alignItems: 'flex-end',
		letterSpacing: 1,
		color: '#192C78',
	},
	modalText: {
		fontFamily: 'Poppins',
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: 14,
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center',
		color: 'rgba(14, 21, 59, 0.7)',
		width: 200,
	},
	modalHeader: {
		marginTop: 15,
		color: 'rgba(14, 21, 59, 0.7)',
		fontFamily: 'Poppins',
		fontStyle: 'normal',
		fontWeight: '700',
		fontSize: 15,
	},
});
