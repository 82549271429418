import * as React from 'react';
import * as Sentry from '@sentry/react';

import { Status, HTTPStatus } from '../constants';

import { ScholarshipObject } from '../types';
import { SchollyResponse } from '@scholly/core';
import { get } from '../actions/apiActions';

type ContextType = {
	status: Status;
	scholarships: Array<ScholarshipObject>;
	featured_scholarships: Array<ScholarshipObject>;
};

type ContextProps = {
	children: JSX.Element | JSX.Element[];
};

const initialState: ContextType = {
	status: Status.IDLE,
	scholarships: [],
	featured_scholarships: [],
};

export const PublicContext = React.createContext<ContextType>(initialState);

export const PublicProvider = (props: ContextProps) => {
	const [state, setState] = React.useState(initialState);

	const fetchPublicData = async () => {
		if (state.status === Status.PENDING) return;
		try {
			const response: SchollyResponse = await get('scholarships/landing');
			if (response.status === HTTPStatus.OK) {
				const { data } = response;
				setState({
					status: Status.RESOLVED,
					scholarships: data.scholarships,
					featured_scholarships: data.featured_scholarships,
				});
			}
		} catch (error) {
			Sentry.captureException(error);
			setState({
				...state,
				status: Status.REJECTED,
			});
		}
	};

	React.useEffect(() => {
		if (state.status === Status.IDLE) {
			fetchPublicData();
		}
	}, [state]); // eslint-disable-line react-hooks/exhaustive-deps

	const value: ContextType = React.useMemo(() => state, [state]);
	return (
		<PublicContext.Provider
			value={{
				...value,
			}}
		>
			{props.children}
		</PublicContext.Provider>
	);
};
