import React, { useState } from 'react';
import { CrossedOutEye26px, Eye26px, Lock26px } from '@scholly/elements';
import classNames from 'classnames';

import { PasswordValidation } from '../auth/PasswordValidation';

import styles from './styles.scss';

type ValidationCriteria = {
	messages: {
		lengthValid: string;
		specialValid: string;
		upperCaseValid: string;
		numberValid: string;
		// New sign up has one password input
		// Also moving to backend password whitelisting TBD
		isValueIdentical?: string;
	};
	validationTestID?: string;
	passwordVal: string;
	repasswordVal: string;
	type?: 'error' | 'hint';
};

interface Props {
	handlePassword: (value: string) => void;
	value: string;
	onFocus?: (e: any) => void;
	onBlur?: (e: any) => void;
	onClearValidation?: () => void;
	placeholder?: string;
	testID?: string;
	isError?: boolean;
	showValidation?: boolean;
	validationCriteria?: ValidationCriteria;
}
export const PasswordInput = (props: Props) => {
	const [showPassword, setShowPassword] = useState(false);
	const {
		value,
		handlePassword,
		placeholder,
		testID,
		onFocus,
		onBlur,
		onClearValidation,
		isError,
		showValidation,
		validationCriteria,
	} = props;

	const svgItemStyle = { position: 'absolute', zIndex: 2, top: 9, left: 10 } as React.CSSProperties;
	const svgEyeStyle = { position: 'absolute', zIndex: 2, top: 12, right: 12 } as React.CSSProperties;
	const handleChange = (e) => {
		handlePassword(e.target.value);
	};

	return (
		<div className={styles.passwordWrapper}>
			{showValidation && (
				<div onClick={onClearValidation}>
					<PasswordValidation
						messages={validationCriteria?.messages}
						testID={validationCriteria?.validationTestID}
						passwordVal={validationCriteria?.passwordVal}
						repasswordVal={validationCriteria?.repasswordVal}
						type={validationCriteria?.type}
					/>
				</div>
			)}
			<div style={svgItemStyle}>
				<Lock26px clickHandler={() => {}} />
			</div>
			<input
				className={classNames(styles.input, { [styles.inputError]: isError })}
				test-id={testID}
				onChange={handleChange}
				type={showPassword ? 'text' : 'password'}
				placeholder={placeholder}
				onFocus={onFocus}
				onBlur={onBlur}
				value={value}
			/>
			<div style={svgEyeStyle}>
				{!showPassword ? (
					<Eye26px
						clickHandler={() => {
							setShowPassword(!showPassword);
						}}
					/>
				) : (
					<CrossedOutEye26px
						clickHandler={() => {
							setShowPassword(!showPassword);
						}}
					/>
				)}
			</div>
		</div>
	);
};
