import { Next, Prev } from '@scholly/elements';
import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import styles from './multi-slider.scss';

export interface MultiItemSliderProps {
	children?: React.ReactNode;
	title?: string;
	data?: any;
}

const MultiSlider = (props: MultiItemSliderProps) => {
	const { children, title, data, ...rest } = props;

	const [currentSlide, setCurrentSlide] = useState(0);
	const [showNavigation, setShowNavigation] = useState(false);
	const [slideContainerWidth, setSlideContainerWidth] = React.useState(0);
	const elementRef = React.useRef();
	const childrenArray = React.Children.toArray(children);

	const prevSlide = () => {
		if (currentSlide !== 0) {
			const newCurrentSlide = currentSlide - 1;
			setCurrentSlide(newCurrentSlide);
			childrenArray.unshift(childrenArray.pop());
		} else return;
	};

	const nextSlide = () => {
		const newCurrentSlide = childrenArray.length - 2 === currentSlide ? 0 : currentSlide + 1;
		setCurrentSlide(newCurrentSlide);
		childrenArray.push(childrenArray.shift());
	};

	const updateIndex = (newIndex: number) => {
		if (newIndex < 0) {
			newIndex = React.Children.count(children) - 1;
		} else if (newIndex >= React.Children.count(children)) {
			newIndex = 0;
		}

		setCurrentSlide(newIndex);
	};

	useEffect(() => {
		const sliderItemElement = elementRef.current as object;
		//@ts-ignore
		setSlideContainerWidth(sliderItemElement?.clientWidth as number);
	}, []);

	useEffect(() => {
		setShowNavigation(Boolean(childrenArray.length > 2));
	}, [childrenArray]);

	return (
		<div className={styles.container}>
			<div className={styles.innerWrapper}>
				{showNavigation && (
					<div className={classNames(styles.navButtons, styles.prevButton)}>
						<Prev clickHandler={prevSlide} />
					</div>
				)}
				<div className={styles.sliderWrapper}>
					<div className={styles.sliderContainer}>
						<div
							className={styles.sliderContent}
							style={{ transform: 'translateX(-' + slideContainerWidth * currentSlide + 'px)' }}
						>
							{childrenArray.map((child, i) => (
								<div ref={elementRef} key={i} className={styles.sliderItem}>
									<div>{child}</div>
								</div>
							))}
						</div>
					</div>
				</div>
				{showNavigation && (
					<div className={classNames(styles.navButtons, styles.nextButton)}>
						<Next clickHandler={nextSlide} />
					</div>
				)}
			</div>
			{showNavigation && (
				<div className={styles.indicators}>
					{React.Children.map(children, (child: React.ReactElement, index: number) => {
						return (
							<button
								key={`${child}-${index}`}
								className={`${index === currentSlide ? styles.active : styles.buttonIndicator}`}
								onClick={() => {
									updateIndex(index);
								}}
							></button>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default MultiSlider;
