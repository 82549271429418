import { ParameterValueType } from '@scholly/scholly-types';

import { Status } from '../constants';
import { ActionMap } from '../types';

export enum ParametersTypes {
	SetMiscellaneous = 'SET_MISCELLANEOUS',
	SetParameters = 'SET_CATEGORIES',
	SetStatus = 'SET_STATUS',
	Error = 'ERROR',
	Started = 'STARTED',
	Success = 'SUCCESS',
	Reset = 'IDLE',
}

export type ParametersContextState = {
	values?: {
		[screen: string]: any;
	};
	parameters: {
		[screen: string]: any;
	};
	miscellaneous: Array<ParameterValueType>;
	isParametersLoaded: boolean;
	parametersLoadedStatus: Status;
};

type ParametersPayload = {
	[ParametersTypes.SetParameters]: {
		values?: {
			[screen: string]: any;
		};
		isParametersLoaded: boolean;
		parameters: any[];
		status: Status;
	};
	[ParametersTypes.SetMiscellaneous]: {
		miscellaneous: Array<ParameterValueType>;
	};
	[ParametersTypes.SetStatus]: {
		status: Status;
	};
};

type StatusActionPayload = {
	[ParametersTypes.Error]: { status: Status };
	[ParametersTypes.Reset]: { status: Status };
	[ParametersTypes.Started]: { status: Status };
	[ParametersTypes.Success]: { status: Status };
};

export type ParametersActions = ActionMap<ParametersPayload>[keyof ActionMap<ParametersPayload>];

type StatusActions = ActionMap<StatusActionPayload>[keyof ActionMap<StatusActionPayload>];

export const parametersReducer = (state: ParametersContextState, actions: ParametersActions | StatusActions) => {
	switch (actions.type) {
		case ParametersTypes.SetMiscellaneous:
			return { ...state, ...actions.payload };
		case ParametersTypes.SetParameters:
			return { ...state, ...actions.payload, parametersLoadedStatus: actions.payload.status };
		case ParametersTypes.SetStatus: {
			return {
				...state,
				categoriesLoadedStatus: actions.payload.status,
			};
		}

		default:
			return state;
	}
};
