import * as React from 'react';
import { MessageType, passwordStrengthValidation } from '@scholly/scholly-ui';
import classNames from 'classnames';

import styles from './styles.scss';

type Props = {
	messages: {
		lengthValid: string;
		specialValid: string;
		upperCaseValid: string;
		numberValid: string;
		// New sign up has one password input
		// Also moving to backend password whitelisting TBD
		isValueIdentical?: string;
	};
	testID?: string;
	passwordVal: string;
	repasswordVal?: string;
	containerStyle?: React.CSSProperties;
	type?: 'error' | 'hint';
};

export const PasswordValidation = (props: Props) => {
	/**
    -  Password hints used by user when creating a password
    **/
	const { messages, passwordVal, repasswordVal, containerStyle, type } = props;
	const passwordErrors = passwordStrengthValidation(passwordVal, repasswordVal);

	const isError = type === 'error';
	const getMessageType = (messageType: any): any => {
		switch (messageType) {
			case MessageType.SUCCESS:
				return type === 'hint' ? styles.validationSuccess : styles.validationHide;

			case MessageType.ERROR:
				return isError ? styles.validationWhite : styles.validationError;

			case MessageType.DEFAULT:
				return isError ? styles.validationWhite : styles.validationDefault;
			default:
				return styles.validationDefault;
		}
	};

	const isAllHide = Object.values(passwordErrors).every((el) => el === 'success');

	return (
		<div className={styles.validationWrapper}>
			<div
				className={classNames(
					styles.validationContainer,
					isError && styles.validationErrorStyle,
					isAllHide && styles.validationHide
				)}
				style={containerStyle}
			>
				{passwordErrors.lengthValid && (
					<div className={getMessageType(passwordErrors.lengthValid)}>{messages.lengthValid}</div>
				)}

				<div className={getMessageType(passwordErrors.specialValid)}>{messages.specialValid}</div>

				<div className={getMessageType(passwordErrors.upperCaseValid)}>{messages.upperCaseValid}</div>

				<div className={getMessageType(passwordErrors.numberValid)}>{messages.numberValid}</div>
				{messages.isValueIdentical && (
					<div className={getMessageType(passwordErrors.isValueIdentical)}>{messages.isValueIdentical}</div>
				)}
			</div>
		</div>
	);
};
