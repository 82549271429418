import * as React from 'react';

import { SVGProps } from 'react';

const Activities = (props: SVGProps<SVGSVGElement>) => (
	<svg width="40px" height="40px" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<circle cx={20} cy={20.958} r={20} fill="#F5F6FA" />
		<path
			d="M20.02 33C26.635 33 32 27.627 32 21S26.636 9 20.02 9C13.402 9 8.038 14.373 8.038 21s5.364 12 11.98 12Z"
			stroke="#3259F1"
			strokeMiterlimit={10}
		/>
		<path
			d="M20.214 9.195s-5.212 4.09-5.212 10.87c0 9.35 3.734 12.857 3.734 12.857"
			stroke="#3259F1"
			strokeMiterlimit={10}
		/>
		<path
			d="M12.551 11.688s1.167 2.377 3.695 2.533c2.529.156 5.135-.624 7.585-1.87 2.451-1.247 3.812-.546 3.812-.546M8 19.792s1.09-2.22 7.196-2.22c6.107 0 12.68 2.181 16.026 7.402M11.228 29.065s-.739-5.299 3.851-5.572c4.59-.272 5.29 3.156 7.04 4.91 2.14 2.181 4.085 2.805 4.085 2.805"
			stroke="#3259F1"
			strokeMiterlimit={10}
		/>
	</svg>
);

export default Activities;
