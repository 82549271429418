import React from 'react';

export const UnpauseImage = () => {
	return (
		<div>
			<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150" fill="none">
				<path
					d="M129.797 15.0488H19.7272C13.4716 15.0488 8.40039 20.1559 8.40039 26.4559V131.993C8.40039 138.293 13.4716 143.4 19.7272 143.4H129.797C136.052 143.4 141.123 138.293 141.123 131.993V26.4559C141.123 20.1559 136.052 15.0488 129.797 15.0488Z"
					fill="#2D50C1"
				/>
				<path
					d="M129.797 6.59961H19.7272C13.4716 6.59961 8.40039 11.7067 8.40039 18.0067V123.544C8.40039 129.844 13.4716 134.951 19.7272 134.951H129.797C136.052 134.951 141.123 129.844 141.123 123.544V18.0067C141.123 11.7067 136.052 6.59961 129.797 6.59961Z"
					fill="#3259F1"
				/>
				<path
					opacity="0.3"
					d="M21.0839 6.73475C17.2793 19.3868 13.1296 31.9965 8.87793 44.5064V46.3708C13.3486 33.2384 17.7203 20.0032 21.711 6.72266L21.0839 6.73475Z"
					fill="#F7F7F7"
				/>
				<path
					opacity="0.3"
					d="M46.557 6.73633H45.9299C40.001 26.4532 33.2289 46.0672 26.5439 65.4094C20.5009 82.9173 14.377 100.652 8.87109 118.483V120.529C14.542 102.075 20.864 83.7211 27.117 65.6058C33.817 46.2032 40.6101 26.5227 46.557 6.73633Z"
					fill="#F7F7F7"
				/>
				<path
					opacity="0.3"
					d="M71.4014 6.73633H70.7743C64.8454 26.4532 58.0733 46.0672 51.3973 65.4094C43.476 88.3443 35.4288 111.666 28.8037 135.088H29.4278C36.0288 111.736 44.0671 88.4803 51.9644 65.6088C58.6494 46.2032 65.4575 26.5227 71.4014 6.73633Z"
					fill="#F7F7F7"
				/>
				<path
					opacity="0.3"
					d="M96.2452 6.73633H95.6181C89.6891 26.4532 82.9171 46.0672 76.241 65.4094C68.3228 88.3443 60.2725 111.666 53.6475 135.088H54.2716C60.8726 111.736 68.9109 88.4803 76.8081 65.6088C83.5082 46.2032 90.3012 26.5227 96.2452 6.73633Z"
					fill="#F7F7F7"
				/>
				<path
					opacity="0.3"
					d="M121.092 6.73633H120.459C114.527 26.4532 107.758 46.0703 101.079 65.4094C93.1607 88.3443 85.1104 111.666 78.4854 135.088H79.1095C85.7105 111.736 93.7488 88.4803 101.646 65.6088C108.352 46.2032 115.145 26.5227 121.092 6.73633Z"
					fill="#F7F7F7"
				/>
				<path
					opacity="0.3"
					d="M141.6 20.7117V18.7324C136.634 34.4062 131.26 49.9953 125.928 65.4122C118.01 88.3472 109.96 111.666 103.338 135.087H103.956C110.557 111.735 118.595 88.4801 126.49 65.6086C131.617 50.7779 136.79 35.7871 141.6 20.7117Z"
					fill="#F7F7F7"
				/>
				<path
					opacity="0.3"
					d="M141.601 94.1182V92.2266C136.833 106.429 132.239 120.74 128.183 135.087H128.807C132.689 121.371 137.058 107.701 141.601 94.1182Z"
					fill="#F7F7F7"
				/>
				<path
					d="M74.7618 123.683C100.848 123.683 121.995 102.386 121.995 76.115C121.995 49.8439 100.848 28.5469 74.7618 28.5469C48.6755 28.5469 27.5283 49.8439 27.5283 76.115C27.5283 102.386 48.6755 123.683 74.7618 123.683Z"
					fill="#2D50C1"
				/>
				<path
					d="M74.7618 118.595C100.986 118.595 122.244 97.1857 122.244 70.776C122.244 44.3663 100.986 22.957 74.7618 22.957C48.5379 22.957 27.2793 44.3663 27.2793 70.776C27.2793 97.1857 48.5379 118.595 74.7618 118.595Z"
					fill="#95CBED"
				/>
				<path d="M55.604 89.5685L55.5529 94.1646L55.5439 53.8304L55.598 49.2344L55.604 89.5685Z" fill="white" />
				<path
					d="M61.0133 44.043C61.9586 44.0431 62.8881 44.2865 63.7138 44.7501L100.127 64.9473C100.944 65.3759 101.628 66.0216 102.105 66.8144C102.583 67.6071 102.835 68.5165 102.835 69.4437C102.835 70.3708 102.583 71.2802 102.105 72.073C101.628 72.8657 100.944 73.5115 100.127 73.94L63.7257 94.0738C62.8992 94.5343 61.9699 94.7755 61.0253 94.7749C58.1989 94.7749 55.6065 92.5992 55.6065 89.5715V49.2373C55.5975 46.2065 58.1869 44.04 61.0133 44.043Z"
					fill="white"
				/>
				<path
					d="M100.131 73.9295L63.726 94.0724C62.8994 94.5328 61.9702 94.774 61.0256 94.7734C58.1991 94.7734 55.6067 92.6008 55.6067 89.57L55.5527 94.1751C55.5527 97.1968 58.1481 99.3755 60.9746 99.3785C61.9192 99.3791 62.8484 99.1379 63.675 98.6775L100.08 78.5437C100.883 78.1159 101.558 77.4784 102.033 76.6981C102.508 75.9177 102.766 75.023 102.78 74.1078L102.831 69.5117C102.815 70.424 102.555 71.3151 102.08 72.0922C101.605 72.8692 100.932 73.5037 100.131 73.9295Z"
					fill="#F55273"
				/>
			</svg>
		</div>
	);
};
