import styles from '../../components/common/common.scss';
import * as React from 'react';
import Modal from 'react-modal';
import className from 'classnames';

interface Props {
	children?: React.ReactNode;
	visible: boolean;
	overlayStyle?: string;
	disableBackgroundScroll?: boolean;
}
const useDisableBodyScroll = (open, disableBackgroundScroll) => {
	React.useEffect(() => {
		if (open && disableBackgroundScroll) {
			document.documentElement.style.overflow = 'hidden';
		} else if (disableBackgroundScroll) {
			document.documentElement.style.overflow = 'scroll';
		}
		return () => {
			document.documentElement.style.overflow = 'scroll';
		};
	}, [disableBackgroundScroll, open]);
};
export const CustomModal = ({ visible, children, overlayStyle, disableBackgroundScroll }: Props) => {
	useDisableBodyScroll(visible, disableBackgroundScroll);

	return (
		<Modal isOpen={visible} className="modal" overlayClassName={className(styles.modalOverlay, overlayStyle)}>
			{children}
		</Modal>
	);
};
