import React from 'react';

import classNames from 'classnames';
import throttle from 'lodash.throttle';
import { useHistory, useLocation } from 'react-router-dom';

import { Status, Routes } from '../../constants';
import {
	ScholarshipContext,
	useQuestions,
	UserContext,
	UserMerchantType,
	SessionContext,
	FlashContext,
} from '../../contexts';
import Nav from '../nav/Nav';

import styles from './layout.scss';
import UngatedFooter from './UngatedFooter';

const options = {
	mobileLimit: 768,
	tabletLimit: 1024,
};

export enum ScreenType {
	MOBILE,
	TABLET,
	DESKTOP,
}

const getSize = (size: number) => {
	if (size < options.mobileLimit) {
		return ScreenType.MOBILE;
	} else if (size > options.tabletLimit) {
		return ScreenType.DESKTOP;
	} else {
		return ScreenType.TABLET;
	}
};

export type LayoutContextType = {
	setToggle: (val: boolean) => void;
	toggle: boolean | null;
	currentSize: number;
};

interface LayoutProps {
	isOnboarding?: boolean;
	isHideNav?: boolean;
	children: JSX.Element;
	smallPadding?: boolean;
}

export const LayoutContext = React.createContext<LayoutContextType>(null);

export const Layout: React.FC<LayoutProps> = ({ isOnboarding, isHideNav, children, smallPadding }) => {
	const email = React.useRef('none');
	const flashContext = React.useContext(FlashContext);
	const [toggle, setToggle] = React.useState(null);
	const history = useHistory();
	const location = useLocation();
	const [currentSize, setcurrentSize] = React.useState(getSize(window.innerWidth));
	const session = React.useContext(SessionContext);
	const user = React.useContext(UserContext);
	const scholarships = React.useContext(ScholarshipContext);
	const { required } = useQuestions();

	const onResize = () => {
		const size = getSize(window.innerWidth);
		if (size !== currentSize) setcurrentSize(size);
	};

	const tokenCheck = React.useCallback(async () => {
		if (session.isAuthenticated === Status.REJECTED) {
			flashContext.display({ text: 'Session was expired. Please try to login again.', type: 'error' });
			setTimeout(() => {
				session.endSession();
				flashContext.close();

				if (location.pathname !== Routes.login) history.replace(Routes.login);
			}, 2000);
		}
	}, [flashContext, history, location.pathname, session]);

	React.useEffect(() => {
		if (
			![Routes.user.create, Routes.user.profile].includes(location.pathname) &&
			typeof required.completed === 'number' &&
			required.completed < 1
		) {
			history.replace(Routes.user.profile);
		}
	}, [required.completed]);

	React.useEffect(() => {
		const resize = throttle(onResize, 100);
		window.addEventListener('resize', resize);
		setcurrentSize(getSize(window.innerWidth));

		return () => {
			resize.cancel();
			window.removeEventListener('resize', resize);
		};
	}, [onResize]);

	React.useEffect(() => {
		setTimeout(() => tokenCheck(), 1500);
	}, [tokenCheck]);

	React.useEffect(() => {
		if (session.isAuthenticated === Status.IDLE && user.userLoadedStatus === Status.RESOLVED) {
			user.clear();
			scholarships.setStatus(Status.IDLE);
		}
	}, [scholarships, session.isAuthenticated, user, user.userLoadedStatus]);

	React.useEffect(() => {
		if (user.isUserLoaded && Status.IDLE === user.userLoadedStatus) user.getAndSetUser();
		if (Status.REJECTED === user.userLoadedStatus) session.endSession();
	}, [session, user, user.userLoadedStatus]);

	React.useEffect(() => {
		if (session.isAuthenticated === Status.REJECTED) tokenCheck();
	}, [session.isAuthenticated, tokenCheck]);

	React.useEffect(() => {
		if (user.user_email !== '' && email.current === 'none') {
			email.current = user.user_email;
		}
	}, [user.user_email]);

	React.useEffect(() => {
		if (
			user.merchant_subscription_status === UserMerchantType.paused &&
			!location.pathname.includes(Routes.offboarding.home)
		) {
			history.replace(`/offboarding/${Routes.offboarding.unpauseMembership}`);
		}
	}, [history, location.pathname, user.merchant_subscription_status]);

	const values = React.useMemo(() => ({ currentSize, toggle, setToggle }), [currentSize, toggle]);

	return (
		<LayoutContext.Provider value={values}>
			<div className={styles.layoutContainer}>
				<div className={styles.layoutContent}>
					{!isHideNav && <Nav isAuthenticationNav={isOnboarding} />}
					<div className={classNames(styles.layoutPage, (isHideNav || isOnboarding) && styles.adjustPage)}>
						{children}
					</div>
				</div>
				<div className={styles.footerWrapper}>
					<UngatedFooter />
				</div>
			</div>
		</LayoutContext.Provider>
	);
};
