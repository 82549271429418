import React from 'react';
import styles from './navigation.scss';
import Button from '../../button/Button';

interface NavigationProps {
	isOnhamburger?: boolean;
}

const Navigation: React.FC<NavigationProps> = (props) => {
	const { isOnhamburger } = props;

	const login = () => {
		window.location.href = 'https://myscholly.com/sign-in';
	};

	return (
		<div className={isOnhamburger ? styles.hamburgerNavWrapper : styles.navigationWrapper}>
			<ul className={!isOnhamburger ? styles.navigationWrapperPrimaryUl : ''}>
				<li className={styles.active}>
					<a href="/search/" className={styles.active}>
						Scholarships
					</a>
				</li>
				<li>
					<a href="https://myscholly.com/exclusive-scholarships/">Exclusives</a>
				</li>
				<li>
					<a href="https://myscholly.com/offers/">Offers</a>
				</li>
			</ul>
			<div className={styles.loginContainer}>
				<div className={styles.accountButtons}>
					<Button label="Login" onClick={login} />
				</div>
			</div>
		</div>
	);
};

export default Navigation;
