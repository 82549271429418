import React, { Children, FC, ReactNode, useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import classnames from 'classnames';
import { Next, Prev } from '@scholly/elements';

import styles from './carousel.scss';

export type CarouselProps = {
	children: ReactNode;
	showArrows?: boolean;
	isDefault?: boolean;
	width?: number;
	speed?: number;
};

const Carousel: FC<CarouselProps> = (props) => {
	const { children, showArrows = true, speed = 3000 } = props;

	const [activeIndex, setActiveIndex] = useState(0);
	const [paused, setPaused] = useState(false);

	const updateIndex = (newIndex: number) => {
		if (newIndex < 0) {
			newIndex = React.Children.count(children) - 1;
		} else if (newIndex >= React.Children.count(children)) {
			newIndex = 0;
		}

		setActiveIndex(newIndex);
	};
	const handlers = useSwipeable({
		onSwipedLeft: () => updateIndex(activeIndex + 1),
		onSwipedRight: () => updateIndex(activeIndex - 1),
	});

	useEffect(() => {
		const interval = setInterval(() => {
			if (!paused) {
				updateIndex(activeIndex + 1);
			}
		}, speed);

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	});
	return (
		<div className={styles.carouselContainer}>
			<div className={styles.carouselWrapper}>
				{showArrows && (
					<div className={classnames(styles.navButtons, styles.prevButton)}>
						<Prev
							color="#ffffff"
							clickHandler={() => {
								updateIndex(activeIndex - 1);
							}}
						/>
					</div>
				)}
				<div
					className={styles.carousel}
					onMouseEnter={() => setPaused(true)}
					onMouseLeave={() => setPaused(false)}
					{...handlers}
				>
					<div className={styles.inner} style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
						{React.Children.map<ReactNode, ReactNode>(children, (child) => {
							if (React.isValidElement(child)) {
								return React.cloneElement(child, { width: '100%' });
							} else {
								return;
							}
						})}
					</div>
				</div>
				{showArrows && (
					<div className={classnames(styles.navButtons, styles.nextButton)}>
						<Next color="#ffffff" clickHandler={() => updateIndex(activeIndex + 1)} />
					</div>
				)}
			</div>

			<div className={styles.indicators}>
				{Children.map(children, (child, index: number) => {
					return (
						<button
							key={`${child}-${index}`}
							className={`${index === activeIndex ? styles.active : styles.buttonIndicator}`}
							onClick={() => {
								updateIndex(index);
							}}
						></button>
					);
				})}
			</div>
		</div>
	);
};

export default Carousel;
