import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { CategoriesModel, MiscCategory, MiscellaneousCategoryModel } from '../../types';
import { UserContext, UserMerchantType, checkFeature, useCategoriesDispatch, useCategoriesState } from '../../contexts';

import BottomNavigation from '../../components/layouts/BottomNavigation';
import { CategoriesTypes } from '../../reducers';
import CategoryList from './CategoryList';
import { FLAGS, Routes, ButtonStrings, STORAGE_KEYS } from '../../constants';

import SubCategoryList from './SubCategoryList';
import styles from './categories.scss';
import Cache from '../../helpers/Cache';

const Categories: React.FC<{}> = () => {
	const { misc, updateUserParameters, userLoadedStatus } = React.useContext(UserContext);
	const user = React.useContext(UserContext);
	const state = useCategoriesState();
	const dispatch = useCategoriesDispatch();
	const [selectedSections, setSelectedSections] = React.useState([]);
	const [index, setIndex] = React.useState<number>(-1);
	const [loading, setLoadingBtn] = React.useState(false);
	const history = useHistory();
	const isFreeFeature = checkFeature(FLAGS.SCHOLLY_FREE);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [index]);

	React.useEffect(() => {
		state.loadCategories();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userLoadedStatus, misc]);

	const getSelectedCategories = () => {
		const miscCategories: Array<MiscCategory> = misc.flatMap(
			(category: MiscellaneousCategoryModel) => category.categories
		);
		let selectedCategories: CategoriesModel[] = [];
		for (let index = 0; index < state.categories.length; index++) {
			const category: CategoriesModel = state.categories[index];
			for (let index = 0; index < miscCategories.length; index++) {
				const miscCategory = miscCategories[index];
				if (miscCategory.category == null) {
					if (category.id === miscCategory.id) {
						selectedCategories = [...selectedCategories.filter((i) => i.id !== category.id), category];
					}
				} else if (miscCategory.category.id === category.id) {
					selectedCategories = [...selectedCategories.filter((i) => i.id !== category.id), category];
				}
			}
		}

		return selectedCategories;
	};
	React.useEffect(() => {
		const selectedCategories = getSelectedCategories();
		dispatch({
			type: CategoriesTypes.SetCategories,
			payload: {
				categories: state.categories,
				selectedSubCategories: state.selectedSubCategories,
				selectedCategories,
			},
		});
	}, [dispatch, misc, state.categories, state.selectedSubCategories]);

	const navigateToScholarships = async () => {
		const data = {
			misc: { value: state.selectedSubCategories },
		};

		//Update cache with new selectedSubCategories
		Cache.set(
			STORAGE_KEYS.CATEGORIES,
			JSON.stringify({
				categories: state.categories,
				selectedSubCategories: state.selectedSubCategories,
				selectedCategories: state.selectedCategories,
			})
		);

		//Makes the counter make this call again
		localStorage.removeItem('scholarshipTotal');
		await updateUserParameters(data);
		if (user.merchant_type === UserMerchantType.free && !isFreeFeature) {
			history.replace(Routes.subscription);
		} else {
			history.push(Routes.scholarships.feed);
		}
	};

	const handleButtonBack = async () => {
		if (index >= 0) {
			return setIndex((prevState: number) => prevState - 1);
		}
		if (user.merchant_type === UserMerchantType.free && !isFreeFeature) {
			history.replace(Routes.subscription);
		} else {
			history.push(Routes.scholarships.feed);
		}
	};

	const handleButtonNext = async () => {
		setLoadingBtn(true);
		if (selectedSections.length - 1 !== index) {
			if (index >= 0) {
				setLoadingBtn(false);
			}
			return setIndex((prevState: number) => prevState + 1);
		}
		await navigateToScholarships();
	};

	const percentage = Math.floor(100 / (selectedSections.length + 1)) * (index + 1);
	return (
		<div className={styles.categoryWrapper}>
			<div className={styles.categoryContainer}>
				{index < 0 ? (
					<CategoryList
						index={index}
						setIndex={setIndex}
						selectedSections={selectedSections}
						setSelectedSections={setSelectedSections}
					/>
				) : (
					<SubCategoryList
						index={index}
						onBack={() => {
							setIndex(index - 1);
						}}
						setIndex={setIndex}
						selectedSections={selectedSections}
						// setSelectedSections={setSelectedSections}
						setLoadingBtn={setLoadingBtn}
					/>
				)}
			</div>
			<div className={styles.contentWrapper}>
				<BottomNavigation
					progress={index >= 0}
					progressValue={percentage}
					pageKey={index}
					pages={selectedSections.length + 1}
					onBack={handleButtonBack}
					onNext={handleButtonNext}
					disabled={!selectedSections.length || loading}
					backDisabled={index < -1}
					rightButtonText={ButtonStrings.next}
					isLoading={loading}
				/>
			</div>
		</div>
	);
};

export default Categories;
