import * as React from 'react';
import classNames from 'classnames';
import { LayoutAnimation } from 'react-native-web';
import { AccountValidation, MessageType, passwordStrengthValidation } from '@scholly/scholly-ui';
import { Button, ErrorMessage } from '@scholly/elements';
import { EmailValidationType } from '@scholly/scholly-types';

import { emailValidation, validateEmail } from '../../helpers/Validation';

import { EmailInput } from '../../components/inputs/Email';
import { PasswordInput } from '../../components/inputs/Password';

import { EMAIL_CHECK, SINGLE_PASSWORD_CHECKS, Routes, ScreenStrings, ButtonStrings } from '../../constants';

import { UserContext } from '../../contexts';
import config from '../../config/config';

import classes from './authentication.scss';

type IProps = {
	onSignUp: (e: string, p: string, b: string) => void;
	isLoading?: boolean;
	history?: any;
	enterEvent?: boolean;
	setEnterEvent?: (callback: any) => void;
	registerError?: string;
};

type IState = {
	isLoading: boolean;
	emailErrors: EmailValidationType;
	isPasswordValid: boolean;
	isEmailValid: boolean;
	isCheckEmailValid: boolean;
	isEmailTouched: boolean;
	isShowPasswordHint: boolean;
	passwordMessageType: 'hint' | 'error';
	isDisclaimerChecked: boolean;
	isDisclaimerVisible: boolean;
	isSubmitted: boolean;
};

type StateTextType = {
	email: string;
	password: string;
	birthday: string;
};

export default class SignUpForm extends React.Component<IProps, IState & StateTextType> {
	static contextType = UserContext;
	state: IState & StateTextType = {
		email: '',
		password: '',
		birthday: '',
		isDisclaimerChecked: false,
		isDisclaimerVisible: true,
		isLoading: false,
		isPasswordValid: false,
		isEmailValid: true,
		isEmailTouched: false,
		isCheckEmailValid: false,
		isShowPasswordHint: false,
		passwordMessageType: 'hint',
		emailErrors: {
			emailValid: false,
			providerValid: true,
		},
		isSubmitted: false,
	};

	isDisabled = () => {
		const { email, password, isPasswordValid, passwordMessageType } = this.state;

		return !validateEmail(email) || !password || (passwordMessageType === 'error' && !isPasswordValid);
	};

	signup = () => {
		if (this.isPasswordValid(true)) {
			const { email, password, birthday } = this.state;
			this.props.onSignUp(email, password, birthday);
			this.setState({ isSubmitted: true });
		}
	};

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState & StateTextType>, snapshot?: any) {
		if (prevProps.enterEvent === this.props.enterEvent) {
			return;
		}

		this.signup();

		this.props.setEnterEvent(false);
	}

	onChangeText = (property: keyof StateTextType) => (value: string) => {
		this.setState({ [property]: value } as Pick<StateTextType, keyof StateTextType>, () => {
			if (['password'].includes(property)) this.isPasswordValid(false);
			if (property === 'email' && this.state.isCheckEmailValid) this.isEmailValid();
			this.setState({ isSubmitted: false });
		});
	};

	isPasswordValid = (showError?: boolean): boolean => {
		const passwordErrors = passwordStrengthValidation(this.state.password, this.state.password);
		const isPasswordValid = Object.values(passwordErrors).every((value) => value === MessageType.SUCCESS);
		LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

		if (showError) {
			this.setState({ isPasswordValid, isShowPasswordHint: true, passwordMessageType: 'error' });
		} else if (isPasswordValid) {
			this.setState({ isPasswordValid: isPasswordValid });
		}
		return isPasswordValid;
	};

	isEmailValid = (): boolean => {
		const emailErrors = emailValidation(this.state.email);
		const isEmailValid = Object.values(emailErrors).filter((value) => !value).length === 0;
		LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
		this.setState({ emailErrors, isEmailValid });
		return isEmailValid;
	};

	submitSignUp = (event) => {
		if (event.key === 'Enter') {
			!this.isDisabled() && this.signup();
		}
	};

	componentDidMount() {
		if (process.env.NODE_ENV !== 'test') {
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const email = urlParams.get('email');
			if (email) {
				this.setState({ email });
				this.props.history.push(Routes.register);
			}
		}
	}
	handlePasswordBlur = () => {
		this.isPasswordValid(true);
	};

	handleEmailBlur = () => {
		this.setState({ isEmailTouched: true });
		if (this.isEmailValid() && this.state.password) {
			this.isPasswordValid(true);
		}
	};

	handleEmailFocus = () => {
		this.setState((prevState) => ({ ...prevState, isShowPasswordHint: false }));
	};

	render() {
		const {
			email,
			isEmailTouched,
			password,
			isShowPasswordHint,
			passwordMessageType,
			isEmailValid,
			emailErrors,
			isPasswordValid,
			isSubmitted,
		} = this.state;

		return (
			<React.Fragment>
				<div className={classes.registerFormContainer}>
					<div className={classNames(classes.wrapper, classes.authFormFieldWrapper)}>
						<div onBlur={() => this.setState({ isCheckEmailValid: true }, () => this.isEmailValid())}>
							{isSubmitted && this.props.registerError && (
								<ErrorMessage className={classes.signUpError}>{this.props.registerError}</ErrorMessage>
							)}
							<EmailInput
								testID={'new-email'}
								placeholder={ScreenStrings.auth.email}
								value={email}
								onFocus={this.handleEmailFocus}
								onBlur={this.handleEmailBlur}
								onChangeText={this.onChangeText('email')}
								isError={
									(isEmailTouched && !isEmailValid) || (isSubmitted && !!this.props.registerError)
								}
							/>
						</div>
						<PasswordInput
							handlePassword={this.onChangeText('password')}
							value={password}
							placeholder={ScreenStrings.auth.password}
							onBlur={this.handlePasswordBlur}
							onClearValidation={this.handleEmailFocus}
							isError={passwordMessageType === 'error' && !isPasswordValid}
							showValidation={isShowPasswordHint}
							validationCriteria={{
								messages: SINGLE_PASSWORD_CHECKS,
								validationTestID: 'testID_password_validation',
								passwordVal: password,
								repasswordVal: password,
								type: passwordMessageType,
							}}
						/>
					</div>

					<div style={{ marginLeft: 8 }}>
						{isEmailValid && (
							<AccountValidation
								isError={!isEmailValid}
								emailErrors={emailErrors}
								errorMessage={EMAIL_CHECK.formatValid}
								testID={'testID_account_validation'}
							/>
						)}
					</div>
				</div>

				<div className={classNames(classes.termsContainer, 'has-text-centered-tablet')}>
					<small className={classNames(classes.termsText, classes.termsContainerText)}>
						By clicking below to register, you acknowledge and agree to the{' '}
						<a className="has-text-link" data-testid={`terms_link`} href={config.terms_url} target="_blank">
							Terms of Service
						</a>
						{', including the User Agreement,  '}
						<a
							className="has-text-link"
							data-testid={`messaging_terms_url`}
							href={config.messaging_terms_url}
							target="_blank"
						>
							Messaging Terms
						</a>
						{', '}
						<a
							className="has-text-link"
							data-testid={`privace_policy_link`}
							href={config.privacy_policy_url}
							target="_blank"
						>
							Privacy Policy and Cookie Policy
						</a>
					</small>
				</div>

				<div id="reg-button-div">
					<Button
						style={{ width: '100%', boxSizing: 'border-box' }}
						variant="primary"
						onClick={this.signup}
						disabled={this.isDisabled() || this.state.isSubmitted}
						isLoading={this.props.isLoading}
					>
						{ButtonStrings.signUp}
					</Button>
				</div>
			</React.Fragment>
		);
	}
}
