import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Button } from '@scholly/elements';
import styles from '../offboarding.scss';
import { FlashContext } from '../../../../contexts/FlashContext';
import { submitBugReport } from '../../../../actions';
import { Routes, ScreenStrings } from '../../../../constants';

const AdditionalComments = ({ issue }) => {
	const history = useHistory();
	const { offboarding } = Routes;
	const [comment, setComment] = React.useState('');
	const flashContext = React.useContext(FlashContext);
	const submitBug = async () => {
		try {
			// @ts-ignore
			const result = await submitBugReport({ cancel_reason_id: '2', comment, issue });
			flashContext.display({ type: 'success', text: 'Successfully submitted bug report' });
			nextPage();
		} catch (error) {
			flashContext.display({ type: 'error', text: 'Server error' });
			console.log(error, '<-----error');
		}
	};

	const nextPage = async () => {
		history.push(`${offboarding.home}/${offboarding.accountType}`);
	};
	return (
		<div className={styles.pauseCardContainer}>
			<div className={styles.titleAdditionalCommentsScreen}>
				<div className={'mt-6'}>
					<div className={classNames(styles.pauseText, styles.pauseTitle)}>Additional Comments</div>
				</div>
			</div>
			<div className={classNames(styles.isFlex, styles.justifyContentCenter, 'mt-5')}>
				<div className={classNames(styles.pauseText, styles.pauseDescription, styles.commentsDescription)}>
					{ScreenStrings.account.additionalComments}
				</div>
			</div>
			<div className={styles.textAreaWrapper}>
				<textarea
					test-id="testID_additional_textbox"
					className={styles.textAreaStyles}
					placeholder="Commentary"
					onChange={(e) => {
						setComment(e.target.value);
					}}
				/>
			</div>
			<div className={classNames(styles.isFlex, styles.isFlexColumn, 'mt-5')}>
				<Button
					variant="primary"
					onClick={submitBug}
					disabled={!comment}
					className={classNames(styles.widthAuto)}
				>
					Send & Cancel Subscription
				</Button>
				<Button variant="secondary" onClick={nextPage} className={classNames('mt-5', styles.widthAuto)}>
					Skip & Cancel Subscription
				</Button>
			</div>
		</div>
	);
};
export default AdditionalComments;
