import { ScreenStrings } from './Strings';

export const PASSWORD_CHECKS = {
	lengthValid: ScreenStrings.auth.lengthValid,
	specialValid: ScreenStrings.auth.specialValid,
	upperCaseValid: ScreenStrings.auth.upperCaseValid,
	numberValid: ScreenStrings.auth.numberValid,
	isValueIdentical: ScreenStrings.auth.isValueIdentical,
};

export const SINGLE_PASSWORD_CHECKS = {
	lengthValid: ScreenStrings.auth.lengthValid,
	specialValid: ScreenStrings.auth.specialValid,
	upperCaseValid: ScreenStrings.auth.upperCaseValid,
	numberValid: ScreenStrings.auth.numberValid,
};

export const EMAIL_CHECK = {
	formatValid: ScreenStrings.auth.emailValid,
};
