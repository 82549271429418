import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { SchollyResponse } from '@scholly/core';

import { checkFreeHOC, FreeProps, ProfileProvider, useParameterState } from '../../contexts';
import { createUserParameterValues, getLocalUser, updateLocation, updateSchool } from '../../actions';

import MultiStep, { MultiStepRef, Step } from '../../components/common/MultiStep/MultiStep';
import OfferCompletionModal from '../../components/offer-completion-modal/offer-completion-modal';
import { SubHeader } from '../../components/layouts/SubHeader';
import { StepQuestion } from './components/profile-step';
import UpdateProfileWelcome from './components/updateProfileWelcome/updateProfileWelcome';

import { LIFETIME_CODE, Status, HTTPStatus, Routes, ScreenStrings } from '../../constants';
import { QuestionTypes } from '../../types';

import { isMobileDevice } from '../../helpers/checkIfDesktop';
import { sleep } from '../../helpers/Functions';

import styles from './onboarding.scss';

export type ProfileQuestionModel = {
	key: string;
	question: string;
	parameter_id: number;
	question_type: QuestionTypes;
	answers: any[];
	placeholder?: string;
};
const Onboarding: React.FC<FreeProps> = ({ isFree }) => {
	const multiRef = React.useRef<MultiStepRef>(null);
	const { parameterStringId, values, parameters, parametersLoadedStatus } = useParameterState();
	const [allQuestions, setAllQuestions] = useState([]);
	const [currentQuestion, setCurrentQuestion] = useState<ProfileQuestionModel | undefined>();
	const history = useHistory();
	const [displayOfferCompletionModal, setDisplayOfferCompletionModal] = useState(false);

	const [title, setTitle] = React.useState(ScreenStrings.onboarding.basic);

	const getStringId = () => {
		if (!currentQuestion) {
			return '';
		} else {
			if (parameterStringId.get(currentQuestion.parameter_id.toString())) {
				return currentQuestion ? parameterStringId.get(currentQuestion.parameter_id.toString()) : '';
			}
			return currentQuestion.parameter_id.toString();
		}
	};
	const string_id = getStringId();

	const handleSubmit = async (values, string_id, action) => {
		let next = false;

		sleep(0).then(() => {
			if (string_id === 'college') {
				updateSchool({ school: values[string_id]?.id })
					.then((res) => {
						next = handleThen(res);
					})
					.catch((error) => {
						console.warn(error);
						handleCatch();
					})
					.finally(() => handleFinish(next, action));
			} else if (string_id === 'state') {
				updateLocation({ place_id: values[string_id]?.place_id })
					.then((res) => {
						next = handleThen(res);
					})
					.catch((error) => {
						console.warn(error);
						handleCatch();
					})
					.finally(() => handleFinish(next, action));
			} else if (Array.isArray(values[string_id])) {
				//@ts-ignores
				values[string_id].forEach((val, idx) => {
					createUserParameterValues({ parameter_value_id: val })
						.then((res) => {
							if (res.status !== HTTPStatus.OK) {
								console.log(res.error);
							}
							if (idx === values[string_id].length - 1) {
								next = handleThen(res);
							}
						})
						.catch((error) => {
							console.warn(error);
							handleCatch();
						})
						.finally(() => handleFinish(next, action));
				});
			} else if (values[string_id]) {
				createUserParameterValues({ parameter_value_id: values[string_id] })
					.then((res) => {
						next = handleThen(res);
					})
					.catch((error) => {
						console.warn(error);
						handleCatch();
					})
					.finally(() => handleFinish(next, action));
			}
		});
		return true;
	};

	const handleCatch = () => {
		const currentQuestionIndex = allQuestions.findIndex((q) => q.key === currentQuestion.key);

		if (currentQuestionIndex !== allQuestions.length - 1) {
			setCurrentQuestion(allQuestions[currentQuestionIndex + 1]);
		} else {
			setCurrentQuestion(undefined);
		}
	};
	const handleThen = (res: SchollyResponse) => {
		const currentQuestionIndex = allQuestions.findIndex((q) => q.key === currentQuestion.key);

		if (currentQuestionIndex !== allQuestions.length - 1) {
			setCurrentQuestion(allQuestions[currentQuestionIndex + 1]);
		} else {
			setCurrentQuestion(undefined);
		}

		if (res.status === HTTPStatus.OK) {
			return true;
		} else if (res.status === HTTPStatus.FAIL) {
			console.log(res.error);
		}
		return false;
	};

	const handleFinish = (next: boolean, step: any) => {
		const currentQuestionIndex = allQuestions.findIndex((q) => q.key === currentQuestion.key);

		if (currentQuestionIndex === allQuestions.length - 1) {
			history.push(Routes.user.updateProfileSuccess);
		}
		if (next) {
			step.changeState({ profileStatus: Status.RESOLVED });
			end(step);
		}
	};

	const end = (step) => {
		if (multiRef.current && multiRef.current.btnLoading) {
			multiRef.current.setBtnLoading(false);
		}
	};

	const handleBack = () => {
		const currentQuestionIndex = allQuestions.findIndex((q) => q.key === currentQuestion?.key);

		if (currentQuestionIndex > 0) {
			setCurrentQuestion(allQuestions[currentQuestionIndex - 1]);
		} else {
			multiRef.current?.setStepNumber(multiRef.current?.stepNumber - 1);
		}
	};

	const isComplete = title === ScreenStrings.onboarding.complete;

	const handleSkip = () => {
		if (isComplete) {
			history.push(Routes.dashboard);
		} else {
			setTitle(ScreenStrings.onboarding.complete);
		}
	};

	const hideBackButton = multiRef.current?.stepNumber === 0 || isMobileDevice || isComplete;

	useEffect(() => {
		const fetchUser = async () => {
			const user = await getLocalUser();
			const registration_code = user?.merchant?.transactions.filter((t) => t.order_type === 'joincode')[0];

			const isOfferLifeTimeMember = registration_code ? registration_code?.code === LIFETIME_CODE : false;

			if (!localStorage.getItem('offerCompletionModal')) {
				if (isOfferLifeTimeMember) {
					setDisplayOfferCompletionModal(true);
					setTimeout(() => {
						localStorage.setItem('offerCompletionModal', 'already_displayed');
					}, 1000);
				}
			}
		};

		fetchUser();
	}, []);

	React.useEffect(() => {
		const sortOrder = [
			'user_type',
			'gender',
			'degree',
			'state',
			'school',
			'grade',
			'major',
			'enrollment_status',
			'gpa',
		];
		const questions = Object.entries(values)
			.map(([key, category]) => {
				const value: any = Object.values(category as object).map((value) => value);
				if (Array.isArray(value) && value.length > 0) {
					let type = QuestionTypes.SingleSelect;

					let display = parameters.find(
						(param: { string_id: any }) => param.string_id === value[0].string_id
					)?.display;

					let placeholder = '';
					switch (key) {
						case 'state':
							type = QuestionTypes.LocationSelectSearch;
							display = 'Where do you live?';
							placeholder = ' City, State';
							break;
						case 'race':
							type = QuestionTypes.MultiSelect;
							break;
						case 'gpa':
							type = QuestionTypes.Slider;
							break;
						case 'major':
							type = QuestionTypes.SingleSelectSearch;
							display = 'Confirm your area of study?';
							placeholder = 'Select a major';
							break;
						case 'gender':
							display = 'Confirm your identity';
							break;
						case 'grade':
							display = 'What year are you in?';
							break;
						case 'user_type':
							display = 'What’s your current status?';
							break;
						case 'degree':
							display = 'What year are you in?';
							type = QuestionTypes.SingleSelectSearch;
							placeholder = 'Select';
							break;
						case 'enrollment_status':
							display = 'Do you go to school full time or part time?';
							break;
						case 'misc':
						case 'citizen':
						case 'need_merit':
							return;
					}

					if (key === 'race') {
						return {
							key: 'school',
							question: 'What college/university are you attending?',
							parameter_id: 11,
							question_type: QuestionTypes.SchoolSelectSearch,
							answers: [],
							placeholder: 'Search',
						};
					}
					return {
						key,
						answers: value,
						parameter_id: value[0].parameter_id,
						question_type: type,
						question: display,
						placeholder,
					};
				}
			})
			.filter((v) => !!v)
			.sort((a, b) => {
				if (a?.key && b?.key) {
					const aIndex = sortOrder.indexOf(a.key);
					const bIndex = sortOrder.indexOf(b.key);

					return aIndex - bIndex;
				}
				return 0;
			});

		setAllQuestions(questions);
		setCurrentQuestion(questions[0]);
	}, [values, parametersLoadedStatus]);

	const handleWelcome = () => {
		if (multiRef.current && multiRef.current.next) {
			multiRef.current?.next(values);
		}
	};
	return (
		<ProfileProvider>
			<SubHeader
				title={ScreenStrings.updateProfile.header}
				hideBackButton={hideBackButton}
				onBack={handleBack}
				onSkip={handleSkip}
				className={classNames(styles.subHeader, isComplete && isMobileDevice && styles.subHeaderComplete)}
			/>

			<div className={styles.contentWrapper}>
				<MultiStep
					renderIf={!isComplete}
					ref={multiRef}
					initialValues={{}}
					handleBack={handleBack}
					totalSteps={allQuestions.length}
				>
					<Step key="1" name="welcome" onSubmit={handleWelcome}>
						<UpdateProfileWelcome />
					</Step>
					<Step key={`${string_id}_2`} name={string_id} onSubmit={handleSubmit}>
						<StepQuestion question={currentQuestion} multiRef={multiRef} />
					</Step>
				</MultiStep>
			</div>
			<OfferCompletionModal
				isOpen={displayOfferCompletionModal}
				handleClose={() => setDisplayOfferCompletionModal(false)}
			/>
		</ProfileProvider>
	);
};

export default checkFreeHOC(Onboarding);
