import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { Routes, Status, FLAGS } from '../../constants';

import { Feature, ScholarshipContext, UserContext } from '../../contexts';

import { ScholarshipFeedIndex } from '../../types';

import SortFilterOptions from '../../components/filter/sort-filter-options';
import ErrorBoundary from '../../components/misc/ErrorBoundary';
import InstantApply from '../../components/common/InstantApply';
import MatchBanner from '../../components/banners/match-banner';
import EarnCash from '../../components/banners/earn-cash';
import ScholarshipFeed from '../../components/scholarship-feed/scholarship-feed';

import styles from './scholarship.scss';
import { SearchDispatchTypes } from '../../reducers/SearchReducers';

const Scholarships: FC = (props) => {
	const history = useHistory();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [selectedScholarshipId, setselectedScholarshipId] = useState(null);

	const userContext = useContext(UserContext);
	const { sortType, sortScholarships, scholarship, filterDispatch, ...context } = useContext(ScholarshipContext);
	const [userLoaded, setUserLoaded] = useState(false);
	const [hideMatchBanner, setHideMatchBanner] = useState(false);

	const _navigate = () => history.push(Routes.categories);
	const updateIndex = (selectedIndex: number) => {
		if (selectedIndex === ScholarshipFeedIndex.SAVED) {
			history.replace(Routes.scholarships.saved);
		} else if (selectedIndex === ScholarshipFeedIndex.APPLIED) {
			history.replace(Routes.scholarships.applied);
		} else {
			history.replace(Routes.scholarships.feed);
		}
	};

	const sendError = (error, errorInfo) => {
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			Sentry.captureException(error);
		});
	};

	const onApply = () => {
		filterDispatch({ type: SearchDispatchTypes.SET_STATUS, payload: Status.PENDING });
	};

	const onBackToMatched = () => context.setselectedIndex(ScholarshipFeedIndex.MATCHED);

	useEffect(() => {
		if (userLoaded && userContext.subscription === null) {
			userContext.getAndSetSubscription();
		}
	}, [userContext, userLoaded]);

	useEffect(() => {
		setUserLoaded(userContext.isUserLoaded);
	}, [userContext.isUserLoaded]);

	useEffect(() => {
		const { state } = location;
		// @ts-ignore
		if (state && 'refresh' in state && state.refresh === false) {
			if (scholarship) setselectedScholarshipId(scholarship.id);
		}
		const initialSelectedIndex = parseInt(queryParams.get('index'));

		if (!isNaN(initialSelectedIndex)) {
			context.setselectedIndex(initialSelectedIndex);
		} else {
			context.setselectedIndex(ScholarshipFeedIndex.MATCHED);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		updateIndex(context.selectedIndex);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [context.selectedIndex]);

	useEffect(() => {
		if (userLoaded) {
			document.body.scrollIntoView();
		}
	}, [userLoaded]);

	return (
		<ErrorBoundary logErrorToService={sendError}>
			<div className={`${styles.makeDissapear} ${styles.scholarshipContainer} THIS-IS-A-DIV`}>
				<div className={styles.scholarshipLayoutContainer}>
					<div className={styles.scholarshipSidebar}>
						<div className={styles.innerSidebar}>
							{context.selectedIndex === ScholarshipFeedIndex.OFFERS && (
								<Feature name={FLAGS.OFFER_TAB}>
									<div className={styles.earnContainer}>
										<EarnCash />
									</div>
								</Feature>
							)}
							{!hideMatchBanner && (
								<MatchBanner className={styles.matchOnFeed} onClose={() => setHideMatchBanner(true)} />
							)}
							{context.selectedIndex !== ScholarshipFeedIndex.OFFERS && (
								<div className={styles.sortContainer}>
									<SortFilterOptions onSubmit={onApply} />
								</div>
							)}
						</div>
					</div>

					<ScholarshipFeed
						handleFooterPress={_navigate}
						onUpdatePress={() => history.replace(Routes.categories)}
						onBackToMatched={onBackToMatched}
						selectedScholarshipId={parseInt(selectedScholarshipId)}
					/>
				</div>
			</div>

			<InstantApply label={'Apply Now'} secondLabel={'Dismiss'} delay={1500} />
		</ErrorBoundary>
	);
};

export default Scholarships;
