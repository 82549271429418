import React, { useEffect, useMemo, useState } from 'react';
import styles from './styles.scss';
import classNames from 'classnames';

import { CustomModal } from '../../modal/Modal';
import { Button, SquareClose } from '@scholly/elements';
import { ScholarshipType } from '@scholly/scholly-types';
import { DropdownArrow } from '../icons/dropdown-arrow';

type ItemType = ScholarshipType & {
	progress?: string;
};

interface Props {
	placeholder?: string;
	item: ItemType;
	changeStatus?: (item: ItemType, p: { progress: string }) => void;
}

export const StatusDropdown = (props: Props) => {
	const { item, changeStatus, placeholder = 'Have you applied or won?' } = props;
	const [modalVisible, setModalVisible] = useState(false);
	const [status, setStatus] = useState(item.progress);

	const onChangeStatus = (status) => () => {
		setStatus(status);
		changeStatus(item, { progress: status });
		onClose();
	};

	useEffect(() => {
		setStatus(item.progress);
	}, [item.progress]);

	const onClose = () => {
		setModalVisible(false);
	};

	return (
		<div className={styles.statusContainer}>
			<div className={styles.title}>Status</div>
			<div className={styles.fakeInput} onClick={() => setModalVisible(true)}>
				<span className={classNames(styles.text)}>{status ? status.replace('_', ' ') : placeholder}</span>
				<DropdownArrow />
			</div>
			{modalVisible && (
				<CustomModal visible={modalVisible}>
					<div className={styles.modalContainer}>
						<div className={styles.modalOptions}>
							<Status onChangeProgress={onChangeStatus} selected={status} />
						</div>
						<div className={styles.modalCloseIcon}>
							<SquareClose clickHandler={onClose} />
						</div>
					</div>
				</CustomModal>
			)}
		</div>
	);
};

const Status = ({ onChangeProgress, selected }) => {
	const getVariant = useMemo(
		() => (value) => {
			return selected === value ? 'primary' : 'tetiary';
		},
		[selected]
	);
	return (
		<div className={styles.modalOptions}>
			<Button variant={getVariant('applied')} onClick={onChangeProgress('applied')}>
				Applied
			</Button>
			<Button variant={getVariant('won')} onClick={onChangeProgress('won')}>
				Won
			</Button>
			<Button variant={getVariant('not_awarded')} onClick={onChangeProgress('not_awarded')}>
				Not Awarded
			</Button>
		</div>
	);
};
