import * as React from 'react';

import AsyncSelect from 'react-select/async';
import { ScreenStrings } from '../../constants';
import { UserContext } from '../../contexts';
import customStyles from './lib/CustomStyles';
import { getLocation } from '../../actions';
import styles from './profile.scss';

type Props = {
	location: string;
	onChangeText: (value: string) => void;
	webStyle?: object;
	hasError?: boolean;
	onboarding?: boolean;
	noLabel?: boolean;
};

export const ProfileLocation = (props: Props) => {
	const user: any = React.useContext(UserContext);
	let location = props.location ? { description: props.location } : null;

	const locations = (val, cb) => {
		if (val.length < 2) return cb([]);
		getLocation(val).then((res) => {
			if (res) {
				let {
					data: { predictions },
				} = res;
				cb(predictions);
			}
		});
	};

	const save = async (item: any) => {
		if ('place_id' in item == false) return;
		try {
			await user.updateUserLocation(item);
		} catch (error) {
			console.log('Error', error);
		}
		props.onChangeText(item.description);
	};

	let defaultVal = !location ? true : Array.isArray(location) ? location : [location];

	return (
		<div className={styles.profileWrapper}>
			<h3>{props.noLabel ? '' : ScreenStrings.profile.liveIn}</h3>
			<div className={styles.profileContainer}>
				<AsyncSelect
					testID={`form_input_location`}
					cacheOptions
					defaultOptions={defaultVal || true}
					defaultValue={location || null}
					onChange={save}
					styles={customStyles(props)}
					value={location}
					loadOptions={locations}
					placeholder={ScreenStrings.profile.state}
					getOptionLabel={(option) => option.description}
					getOptionValue={(option) => option.place_id}
				/>
			</div>
		</div>
	);
};
