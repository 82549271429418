import * as React from 'react';
import * as _ from 'lodash';

import {
	LocationType,
	UserContextFunctionsType,
	UserContextType,
	UserGoalType,
	UserUpdateBirthday,
	UserUpdateEmailType,
	UserUpdateFirstLastNameType,
	UserUpdateGoal,
	UserUpdateIsParent,
	UserUpdateLocation,
	UserUpdateMerchantType,
	UserUpdatePasswordType,
} from '@scholly/scholly-types';
import {
	changePassword,
	completedGatherFlow,
	getABRef,
	getLocalUser,
	getUser,
	getUserGoals,
	getUserSubscription,
	setUser,
	updateLocation,
	updateParameterValues,
	updateSchool,
	updateUser,
} from '../actions/userActions';
import { commafy, missingReq, parseUserParamsData, unCommafy, userParamCreate } from '../helpers/Functions';

import { logout } from '../actions/authActions';
import { checkFreeHOC, FreeProps } from './FeatureContext';
import { SessionContext } from '../contexts';

import { STORAGE_KEYS } from '../constants/StorageKeys';
import { HTTPStatus, Status } from '../constants';
import { UserFormParamsType } from '../types';

type ValueType = {
	value: string;
};

type UserUpdateUserType = {
	user_type: string | any;
};

type UserUpdatePhoneNumberType = {
	phone_number: string | any;
};

type SValueType = {
	id: string;
	name: string;
};
type UserParameterCreateType = {
	parameters: {
		gender: ValueType;
		race: ValueType;
		citizen: ValueType;
		gpa: ValueType;
		grade: ValueType;
		degree: ValueType;
		enrollment_status: ValueType;
		major: ValueType;
		need_merit: ValueType;
	};
	schools: SValueType[];
	location?: Pick<LocationType, 'place_id'>;
};

export enum UserMerchantProcessor {
	ios = 'ios',
	android = 'android',
	stripe = 'stripe',
	paypall = 'paypall',
}

type UpdateUserInfoType = UserUpdateFirstLastNameType &
	UserUpdateUserType &
	UserUpdatePhoneNumberType &
	UserUpdateGoal &
	UserParameterCreateType & {
		goals: Omit<UserGoalType, 'id'>;
		birthday: string;
	};

type ContextType = UserStateType &
	UserContextFunctionsType &
	UserContextStateType & {
		userCompletedGatherFlow: () => void;
		updateUserInfo: (newProperties: UpdateUserInfoType) => void;
		toggleRefer: (bool: boolean) => void;
		setUserSubscription: (data: any) => void;
		clear: () => void;
		updateUserState: (newProperties: any) => void;
		loadUser: (user: any) => void;
		updateMerchantType: (newProperties: any) => void;
		updateUserGoal: () => void;
		updateUserGoalSaveSync: (amount: number) => void;
		updateUserGoalApplySync: (amount: number) => void;
		updateUserPhoneNumber: (number: any) => void;
		getUserABRef: () => void;
		getAndSetSubscription: () => void;
		user_id: number;
		merchant_processor: UserMerchantProcessor;
		syncUserGoalSave: (scholarshipsSaved: any) => void;
		syncUserGoalApplied: (scholarshipsApplied: any) => void;
		setOnboardingStatus: (value: number) => void;
		checkFreeAccountScreenShown: () => void;
		checkRefundScreenShown: () => void;
	};

export const UserContext = React.createContext<ContextType | null>(null);

export function withUserContext<P extends ContextType>(Component: React.ComponentClass): any {
	return function contextAwareComponent(props: Pick<P, Exclude<keyof P, keyof ContextType>>): any {
		return (
			<UserContext.Consumer>{(values: any): any => <Component {...props} {...values} />}</UserContext.Consumer>
		);
	};
}

type MerchantType = {
	type: string;
	joincode: any[];
	trial: any[];
	transactions: any[];
};

type UserStateType = UserContextType &
	UserFormParamsType &
	UserUpdateUserType &
	UserUpdateBirthday &
	Omit<UserGoalType, 'id'> & {
		school: SValueType;
		location: string;
		user_type: string | any;
		phone_number: string;
		misc: Array<any>;
		subscription: any;
		grade: string;
		major: [];
		user_id: number;
		merchant_processor: UserMerchantProcessor;
		merchant: MerchantType;
	};

export enum UserMerchantType {
	free = 'free',
	subscriber = 'subscriber',
	grandfathered = 'grandfathered',
	joincode = 'joincode',
	pending_cancellation = 'pending-cancellation',
	paused = 'paused',
	givescholly = 'givescholly',
}

type UserContextStateType = {
	isUserLoaded?: boolean;
	isPaidUser?: boolean;
	isOnboardingCompleted?: boolean;
	showFreeAccountActivated?: boolean;
	showRefundScreen?: boolean;
	missingReq?: boolean;
	userRefer?: boolean;
	subscription?: any;
	merchant_subscription_status: string;
	misc?: Array<any>;
	userLoadedStatus?: string;
	updateUserParameters?: (newProperties: object, nomisc?: boolean) => void;
};

type UserContextProps = {
	children: (state: UserContextStateType) => JSX.Element;
};

const paidUserTypes = [
	UserMerchantType.subscriber,
	UserMerchantType.givescholly,
	UserMerchantType.joincode,
	UserMerchantType.grandfathered,
	UserMerchantType.pending_cancellation,
];
const initialState = {
	user_id: null,
	user_email: '',
	first_name: '',
	last_name: '',
	birthday: '',
	profile_img: '',
	phone_number: '',
	merchant_type: '',
	merchant: {
		transction: [],
	},
	merchant_processor: '',
	merchant_subscription_status: '',
	user_type: null,
	major: [],
	gpa: '',
	grade: '',
	citizen: '',
	race: '',
	gender: '',
	need_merit: '',
	degree: '',
	enrollment_status: '',
	goal: '0',
	applied: '0',
	potential: '0',
	location: '',
	school: {
		id: '',
		name: '',
	},
	misc: [],
	isUserLoaded: false,
	isPaidUser: false,
	missingReq: false,
	// NOTE:TEMPORARY SOLUTION FOR NATIVE APP
	userLoadedStatus: Status.IDLE,
	userRefer: false,
	subscription: null,
	isOnboardingCompleted: false,
	showFreeAccountActivated: true,
	showRefundScreen: true,
};

class UserContextProvider extends React.Component<UserContextProps & FreeProps, UserContextStateType & UserStateType> {
	//@ts-ignore
	state: UserContextStateType & UserStateType = initialState;
	static contextType = SessionContext;
	async componentDidMount() {
		// * NOTE: Needed for mobile to getAndSetUser on AppStartup
		this.getAndSetUser();
		this.checkFreeAccountScreenShown();
		this.checkRefundScreenShown();
	}

	getAndSetUser = async () => {
		const res = await getUser().catch((e) => {
			if (('field' in e && e?.field === 'token') || ('message' in e && e?.message === 'Unauthorized')) {
				initialState.userLoadedStatus = Status.REJECTED;
			}
			//@ts-ignore
			this.setState(initialState);
		});

		if (res?.status === HTTPStatus.OK) {
			await setUser(res.data.user);
			this.loadUser(res.data.user);
		}
	};

	//@ts-ignore
	clear = () => this.setState(initialState);

	logoutUser = async () => {
		this.clear();
		await logout();
	};

	loadUser = (user: any) => {
		const hasSubscription =
			user.merchant !== undefined &&
			(user.merchant.type === UserMerchantType.subscriber ||
				user.merchant.type === UserMerchantType.pending_cancellation);
		// * NOTE: Location was removed from parseUserParamsData, api does not return location in `updateParamterValues()` caused error

		const hasPromos = user.promos !== undefined && user.promos.free_scholly !== undefined;

		const seenFreeAccountScreen = localStorage.getItem(STORAGE_KEYS.FREE_ACCOUNT_ACTIVATED + user.id);
		const seenRefundScreen = localStorage.getItem(STORAGE_KEYS.REFUND_SCREEN + user.id);
		let state = {
			profile_img: '',
			user_id: user.id,
			user_email: user.email,
			first_name: user.first_name,
			last_name: user.last_name,
			birthday: user.birthday,
			location: user.location.display !== null ? user.location.display : '',
			phone_number: user.phone_number ? user.phone_number : '',
			merchant: user.merchant,
			merchant_type: user.merchant ? user.merchant.type : '',
			merchant_processor: hasSubscription ? user.merchant.subscriptions[0].processor : '',
			goal: user.goals ? commafy(user.goals.goal) : '0',
			missingReq: missingReq(user),
			...parseUserParamsData(user),
			isPaidUser: paidUserTypes.includes(user.merchant?.type),
			isUserLoaded: true,
			merchant_subscription_status: hasSubscription ? user.merchant.subscriptions[0].status : '',
			showFreeAccountActivated: hasPromos && !seenFreeAccountScreen ? user.promos.free_scholly.show : false,
			showRefundScreen: seenRefundScreen,
			userLoadedStatus: Status.RESOLVED,
		};
		//@ts-ignore
		const isStatusPaused =
			user.merchant.subscriptions.find((item) => item.status === UserMerchantType.paused) || false;

		//@ts-ignore
		if (isStatusPaused) {
			state.merchant_subscription_status = UserMerchantType.paused;
		}

		//@ts-ignore
		this.setState(state);
	};

	updateProfileImage = async () => {};

	updateLocalUser = async (
		newProperties:
			| Pick<UserUpdateEmailType, 'email'>
			| UserUpdateFirstLastNameType
			| UserUpdateMerchantType
			| UserUpdateGoal
			| UserUpdateIsParent
			| UserUpdateUserType
			| UserUpdateBirthday
			| UserUpdatePhoneNumberType
	) => {
		const currentUser = await getLocalUser();
		const updatedUser = { ...currentUser, ...newProperties };

		await setUser(updatedUser);
		this.loadUser(updatedUser);
	};

	// ONLY USE WHEN UPDATING STATE NOT ON SIGN UP OR LOGIN USE SETUSER AND LOADUSER
	updateUserState = async (updatedUser) => {
		const currentUser = await getLocalUser();

		const [userDiff] = _.differenceWith([updatedUser], [currentUser], _.isEqual);

		await setUser(userDiff);

		//ONLY UPDATE PROFILE USER INFO, NOT USERLOADEDSTATUS, MERCHANTTYPE OR MERCHANTPROCESSOR
		this.setState({
			user_email: userDiff.email,
			first_name: userDiff.first_name,
			last_name: userDiff.last_name,
			birthday: userDiff.birthday,
			location: userDiff.location.display !== null ? userDiff.location.display : '',
			phone_number: userDiff.phone_number,
			goal: userDiff.goals ? commafy(userDiff.goals.goal) : '0',
			applied: userDiff.goals ? commafy(userDiff.goals.applied) : '0',
			potential: userDiff.goals ? commafy(userDiff.goals.potential) : '0',
			missingReq: missingReq(userDiff),
			...parseUserParamsData(userDiff),
			isUserLoaded: true,
		});
	};

	updateMerchantType = async (merchant_type) => {
		const currentUser = await getLocalUser();
		const updatedUser = { ...currentUser, merchant_type };

		await setUser(updatedUser);
		this.setState({ merchant_type: merchant_type, isPaidUser: paidUserTypes.includes(merchant_type) });
	};

	userCompletedGatherFlow = () => completedGatherFlow();

	updateUserInfo = async (newProperties: UpdateUserInfoType) => {
		const { first_name, last_name, birthday, phone_number, goals } = await getLocalUser();
		const currentUser = {
			first_name,
			last_name,
			birthday,
			phone_number,
			goal: goals ? goals.goal : '0',
		};
		// Changes
		const updatedUser = {
			first_name: newProperties.first_name,
			last_name: newProperties.last_name,
			birthday: newProperties.birthday,
			...(newProperties.goals && { goal: newProperties.goals.goal }),
			...(newProperties.phone_number && { phone_number: newProperties.phone_number }),
		};

		const [diffUserValues] = _.differenceWith([updatedUser], [currentUser], _.isEqual);

		const res = await updateUser(diffUserValues);

		const { user } = res.data;
		if (user) {
			await setUser(user);
			//ONLY UPDATE PROFILE USER INFO, NOT USERLOADEDSTATUS, MERCHANTTYPE, OR MERCHANTPROCESSOR
			this.setState({
				user_email: user.email,
				first_name: user.first_name,
				last_name: user.last_name,
				birthday: user.birthday,
				phone_number: user.phone_number,
				goal: user.goals ? commafy(user.goals.goal) : '0',
				applied: user.goals ? commafy(user.goals.applied) : '0',
				potential: user.goals ? commafy(user.goals.potential) : '0',
				// missingReq: missingReq(user),
				...parseUserParamsData(user),
				isUserLoaded: true,
			});
		}
	};

	updateUserEmail = async ({ email }: UserUpdateEmailType) => {
		await updateUser({ email });
		//  await this.updateLocalUser({ email });
		// * removed this.updateLocalUser to update email state directly,
		// * On Mobile after a user purchases a subscription, updateLocalUser would use user from localStorage
		// * this would send the user back to the paywall because merchant_type === free in localStorage
		this.setState({
			user_email: email,
		});
	};

	updateUserPassword = async ({ current_password, new_password }: UserUpdatePasswordType) => {
		await changePassword({ new_password, current_password });
	};

	updateUserPhoneNumber = async ({ phone_number }: UserUpdatePhoneNumberType) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		await updateUser({ phone_number }), await this.updateLocalUser({ phone_number });
	};

	updateFirstLastName = async ({ last_name, first_name }: UserUpdateFirstLastNameType) => {
		await updateUser({ last_name, first_name });
		await this.updateLocalUser({ last_name, first_name });
	};

	updateUserLocation = async ({ place_id }: UserUpdateLocation) => {
		const res = await updateLocation({ place_id });
		const { location } = res.data;
		this.setState({ location: location.display });
	};

	updateUserSchool = async (schools: SValueType[]) => {
		// TODO: this need to be returned the same way
		// WEB SELECT RETURNS SCHOOLS AS AN OBJECT IN AN ARRAY BUT QUERYLISTNATIVE RETURNS AN OBJECT
		const schoolArr = [];
		let selected;
		if (!Array.isArray(schools)) {
			schoolArr.push(schools);
			selected = { schools: schoolArr };
			await updateSchool(selected);
		} else {
			await updateSchool({ schools });
		}
	};

	updateUserParameters = async (newProperties: object) => {
		const currentUser = await getLocalUser();

		let updatedParams,
			currentParams = {};

		if (currentUser.parameters.length > 0) {
			currentParams = userParamCreate(currentUser.parameters);
		}

		updatedParams = {
			parameters: {
				...currentParams,
				...newProperties,
			},
		};

		const resParamsValues = await updateParameterValues(updatedParams);
		await setUser(resParamsValues.data.user);

		if (resParamsValues.data.user) {
			await setUser(resParamsValues.data.user);
			const { user } = resParamsValues.data;
			//* ONLY UPDATE PROFILE USER INFO, NOT USERLOADEDSTATUS, OR MERCHANTTYPE
			this.setState({
				user_email: user.email,
				first_name: user.first_name,
				last_name: user.last_name,
				birthday: user.birthday,
				gender: user.gender,
				phone_number: user.phone_number,
				goal: user.goals ? commafy(user.goals.goal) : '0',
				applied: user.goals ? commafy(user.goals.applied) : '0',
				potential: user.goals ? commafy(user.goals.potential) : '0',
				missingReq: missingReq(user),
				...parseUserParamsData(user),
				isUserLoaded: true,
			});
		}

		return true;
	};

	updateUserGoal = async () => {
		const resGoals = await getUserGoals();

		const { goal, applied, potential } = resGoals.data.goals;

		this.setState({
			goal: goal ? commafy(goal) : '0',
			applied: goal ? commafy(applied) : '0',
			potential: goal ? commafy(potential) : '0',
		});
	};

	updateUserGoalSaveSync = (amount) => {
		let potential = unCommafy(this.state.potential);
		let newPotential = potential + amount;

		this.setState({
			potential: commafy(newPotential.toString()),
		});
	};

	updateUserGoalApplySync = (amount) => {
		let applied = unCommafy(this.state.applied);
		let newApplied = applied + amount;

		this.setState({
			applied: commafy(newApplied.toString()),
		});
	};
	getAndSetSubscription = async () => {
		try {
			let { data } = await getUserSubscription();
			this.setUserSubscription(data);
			return data;
		} catch (error) {}
	};

	syncUserGoalSave = (scholarshipSaved: { amount: number }[]) => {
		let newPotential = 0;
		if (scholarshipSaved) {
			scholarshipSaved.forEach((saved: { amount: number }) => {
				newPotential += saved.amount;
			});
		}
		this.setState({
			potential: commafy(newPotential),
		});
	};

	syncUserGoalApplied = (scholarshipApplied: { amount: number }[]) => {
		let newApplied = 0;
		if (scholarshipApplied) {
			scholarshipApplied.forEach((saved: { amount: number }) => {
				newApplied += saved.amount;
			});
		}
		this.setState({
			applied: commafy(newApplied),
		});
	};

	// *NOTE: ONLY USED ON WEB
	getUserABRef = () => {
		getABRef('scholarship_apply').then((res) => {
			if (res.status === 'OK' && 'ref_data' in res.data)
				localStorage.setItem('scholarship_apply', res.data.ref_data);
		});
	};

	setUserSubscription = (subscription: any) => this.setState({ subscription });

	toggleRefer = (userRefer: boolean) => this.setState({ userRefer });

	setOnboardingStatus = (value: number) => {
		this.setState({
			isOnboardingCompleted: value === 1,
		});
	};

	checkFreeAccountScreenShown = () => {
		const seenFreeAccountScreen = localStorage.getItem(STORAGE_KEYS.FREE_ACCOUNT_ACTIVATED + this.state?.user_id);

		if (seenFreeAccountScreen == '1') {
			this.setState({ showFreeAccountActivated: false });
		}
	};

	checkRefundScreenShown = () => {
		const seenRefundScreen = localStorage.getItem(STORAGE_KEYS.REFUND_SCREEN + this.state?.user_id);

		if (seenRefundScreen == '1') {
			this.setState({ showRefundScreen: false });
		}
	};

	render() {
		return (
			<UserContext.Provider
				value={{
					...this.state,
					toggleRefer: this.toggleRefer,
					clear: this.clear,
					getAndSetUser: this.getAndSetUser,
					getAndSetSubscription: this.getAndSetSubscription,
					updateUserEmail: this.updateUserEmail,
					updateUserPassword: this.updateUserPassword,
					updateFirstLastName: this.updateFirstLastName,
					updateProfileImage: this.updateProfileImage,
					updateUserInfo: this.updateUserInfo,
					updateUserLocation: this.updateUserLocation,
					updateUserSchool: this.updateUserSchool,
					updateUserParameters: this.updateUserParameters,
					userCompletedGatherFlow: this.userCompletedGatherFlow,
					logout: this.logoutUser,
					updateUserState: this.updateUserState,
					loadUser: this.loadUser,
					updateMerchantType: this.updateMerchantType,
					updateUserGoal: this.updateUserGoal,
					updateUserGoalSaveSync: this.updateUserGoalSaveSync,
					updateUserGoalApplySync: this.updateUserGoalApplySync,
					updateUserPhoneNumber: this.updateUserPhoneNumber,
					setUserSubscription: this.setUserSubscription,
					getUserABRef: this.getUserABRef,
					syncUserGoalApplied: this.syncUserGoalApplied,
					syncUserGoalSave: this.syncUserGoalSave,
					setOnboardingStatus: this.setOnboardingStatus,
					checkFreeAccountScreenShown: this.checkFreeAccountScreenShown,
					checkRefundScreenShown: this.checkRefundScreenShown,
				}}
			>
				{/** @ts-ignore */}
				{this.props.children({
					missingReq: this.state.missingReq,
					misc: this.state.misc,
					updateUserParameters: this.updateUserParameters,
				})}
			</UserContext.Provider>
		);
	}
}

const UserProvider = checkFreeHOC(UserContextProvider);

export { UserProvider as UserContextProvider };

export const useUser = () => {
	const context = React.useContext<any>(UserContext);
	if (context === undefined) {
		console.warn('useUser must be used within a UserContextProvider');
	}
	return context;
};
