import React from 'react';
import { Button } from '@scholly/elements';
import classNames from 'classnames';
import styles from './offboarding/offboarding.scss';
import { Routes } from '../../constants';
import { useHistory } from 'react-router-dom';
import { CalendarIcon } from '../../components/common/icons/calendar';

const PauseAccountConfirmation = () => {
	const history = useHistory();
	const { settings, offboarding } = Routes;

	return (
		<div
			className={classNames(
				styles.pauseAccountConfirmationContainer,
				styles.alignItemsCenter,
				styles.justifyContentCenter
			)}
		>
			<div
				className={classNames(
					styles.isFlex,
					styles.justifyContentCenter,
					styles.pauseAccountConfirmationCalendarIcon
				)}
			>
				<CalendarIcon />
			</div>
			<div className={styles.pauseAccountConfirmationTitleContainer}>
				<div className={classNames(styles.pauseText, styles.pauseTitle)}>
					Are you sure you want to pause your account?
				</div>
			</div>
			<div className={classNames(styles.pauseText, styles.pauseAccountConfirmationDescription)}>
				This means you will no longer receive emails or updates
			</div>
			<div className={styles.pauseAccountConfirmationButtonContainer}>
				<Button
					variant="secondary"
					onClick={() => {
						history.push(settings);
					}}
					className={styles.pauseButton}
				>
					Not Now
				</Button>
				<Button
					variant="primary"
					onClick={() => history.push(offboarding.affiliateOffer)}
					className={styles.pauseButton}
				>
					Pause Account
				</Button>
			</div>
		</div>
	);
};
export default PauseAccountConfirmation;
