import * as React from 'react';
import { FilterBTT } from '@scholly/elements';

import { Feature, OfferContext, UserContext, checkFeature, useScholarship } from '../../contexts';
import { getUserFeaturedCampaign } from '../../actions';

import Tabs, { Tab } from '../tabs/tabs';
import ListFooter from '../scholarship/ListFooter';
import { CustomModal } from '../modal/Modal';
import { SuccessModal } from './components/ExclusiveSuccessApplied';
import ExclusiveCard from '../exclusive-card/exclusive-card';
import ScholarshipCard from '../ScholarshipCard';
import { WebLoader } from '../scholarship/AnimatedListLoader';

import { FLAGS, Status, Routes, SCHOLARSHIP_TAB } from '../../constants';
import { ScholarshipFeedIndex } from '../../types';
import { ScholarshipClickSourceIndex } from '../../types';

import subStyles from './scholarship-feed.scss';
import FilterScreen from '../filter/filter';
import { Offers } from '../offers/offers';

import EmptyList from '../scholarship/EmptyList';
import { ExclusiveOfferCard } from '../offers/exclusive-offers/ExclusiveOfferCard';

type ListRef = {};

type IProps = {
	// EmptyListComponent: JSX.Element;
	onScroll?: () => void;
	handleFooterPress: () => void;
	selectedScholarshipId?: number;
	onUpdatePress: () => void;
	onBackToMatched: () => void;
};

const ScholarshipFeed = React.forwardRef<ListRef, IProps>((props, ref) => {
	const { scholarships, scholarshipSaved, scholarshipApplied, appliedScholarship, status, matchedMeta, ...context } =
		useScholarship();
	const { displayedOffers } = React.useContext(OfferContext);
	const userContext = React.useContext(UserContext);
	const [exclusiveScholarships, setExclusiveScholarships] = React.useState([]);
	const [data, setData] = React.useState([]);
	const [applied, setApplied] = React.useState('');
	const [appliedSuccess, setAppliedSuccess] = React.useState(false);
	const [filterView, setFilterView] = React.useState(false);

	let list = React.useRef<HTMLDivElement>(null);

	const showExclusive = exclusiveScholarships?.length > 0;
	const showOffersInFeed = displayedOffers?.length > 0;
	const isOfferFeed = checkFeature(FLAGS.OFFER_IN_FEED);

	React.useImperativeHandle(ref, () => ({}));

	const getScholarshipCount = () => {
		return matchedMeta?.total_count + exclusiveScholarships?.length || 0;
	};

	const getOffersCount = () => {
		return displayedOffers?.length || 0;
	};

	const getScholarships = async () => {
		try {
			await context.getAndSetScholarships();
		} catch (error) {
			console.warn(error);
		}
	};

	// ComponentDidUpdate
	React.useEffect(() => {
		if (userContext.merchant_type !== '' && status === Status.IDLE) {
			getScholarships();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userContext.merchant_type]);

	React.useEffect(() => {
		getUserFeaturedCampaign().then((res) => {
			if (Array.isArray(res.data)) {
				setExclusiveScholarships(res.data);
			} else {
				setExclusiveScholarships(Object.values(res.data));
			}
		});
	}, []);

	React.useEffect(() => {
		if (status === Status.RESOLVED) {
			setData(scholarships);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, scholarships, context.selectedIndex]);

	const isEmpty = window.location.pathname !== Routes.scholarships.feed || scholarships.length === 0;

	React.useEffect(() => {
		userContext?.syncUserGoalSave(scholarshipSaved);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scholarshipSaved]);

	React.useEffect(() => {
		userContext?.syncUserGoalApplied(scholarshipApplied);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scholarshipApplied]);

	const handleQuickApply = async (scholarship) => {
		const updatedList = exclusiveScholarships.filter((item) => item.id !== scholarship.id);
		await context.handleEasyApply(scholarship);
		setApplied(scholarship.name);
		setAppliedSuccess(true);
		setExclusiveScholarships(updatedList);
	};

	if (context.showLoader || context.paginationLoading) {
		return (
			<div className={subStyles.feedContainer}>
				<WebLoader showExclusive={showExclusive} />
			</div>
		);
	}

	const handleCloseSuccessModal = () => {
		setAppliedSuccess(false);
	};

	const handleFilterBtnClick = () => setFilterView(true);

	const handleCloseFilterModal = () => setFilterView(false);

	const handleChangeTabIndex = (value: number) => {
		context.setselectedIndex(value);
	};

	const _renderProgressScholarship = (scholarship, index) => {
		const exclusiveImage = scholarship?.assets?.find((asset: any) => asset?.type === 'web')?.url;

		return scholarship?.in_app ? (
			<ExclusiveCard
				classname={subStyles.exclusiveContainer}
				image={exclusiveImage}
				title={scholarship?.display_name}
				scholarshipItem={scholarship}
				handleShare={() => {}}
				handleQuickApply={() => handleQuickApply(scholarship)}
				hideCheckbox
			/>
		) : (
			<ScholarshipCard
				key={scholarship.id}
				scholarshipItem={scholarship}
				source={ScholarshipClickSourceIndex.FEED}
				position={index + 1}
			/>
		);
	};

	const _renderWithExclusiveScholarship = (index, scholarship) => {
		const exclusiveIndex = index / 3;
		const exclusiveImage =
			Array.isArray(exclusiveScholarships) &&
			exclusiveScholarships[exclusiveIndex]?.assets?.find((asset: any) => asset?.type === 'web')?.url;

		return (
			<React.Fragment key={`${scholarship.id}-${index}`}>
				{exclusiveScholarships[exclusiveIndex] && (
					<ExclusiveCard
						classname={subStyles.exclusiveContainer}
						image={exclusiveImage}
						title={exclusiveScholarships[exclusiveIndex]?.display_name}
						scholarshipItem={exclusiveScholarships[exclusiveIndex]}
						handleShare={() => {}}
						handleQuickApply={() => handleQuickApply(exclusiveScholarships[exclusiveIndex])}
						hideCheckbox
						displayQuickApplyButton
					/>
				)}
				<ScholarshipCard
					scholarshipItem={scholarship}
					source={ScholarshipClickSourceIndex.FEED}
					position={index + 1}
				/>
			</React.Fragment>
		);
	};

	const _renderWithOffers = (index, scholarship) => {
		const offerIndex = index / 3 - 1;
		return (
			<React.Fragment key={`${scholarship.id}-${index}`}>
				{isOfferFeed && displayedOffers[offerIndex] && (
					<ExclusiveOfferCard isFromFeed offer={displayedOffers[offerIndex]} />
				)}
				<ScholarshipCard
					scholarshipItem={scholarship}
					source={ScholarshipClickSourceIndex.FEED}
					position={index + 1}
				/>
			</React.Fragment>
		);
	};

	return (
		//@ts-ignore
		<React.Fragment>
			<div data-testid={'scholarshipList'} ref={list} className={subStyles.feedContainer}>
				<Tabs>
					<Tab
						isSelected={context.selectedIndex === ScholarshipFeedIndex.MATCHED}
						onClick={() => handleChangeTabIndex(ScholarshipFeedIndex.MATCHED)}
						tabHeader={
							<span>
								<strong>{SCHOLARSHIP_TAB.ALL} </strong>({getScholarshipCount()})
							</span>
						}
					/>
					<Feature name={FLAGS.OFFER_TAB}>
						<Tab
							isSelected={context.selectedIndex === ScholarshipFeedIndex.OFFERS}
							onClick={() => handleChangeTabIndex(ScholarshipFeedIndex.OFFERS)}
							tabHeader={
								<span>
									<strong>{SCHOLARSHIP_TAB.OFFERS} </strong>({getOffersCount()})
								</span>
							}
							isHide={getOffersCount() === 0}
						/>
					</Feature>
					{/* <Tab
							isSelected={context.selectedIndex  === SCHOLARSHIP_TAB.QUICK_APPLY}
							onClick={() => setTabIndex(SCHOLARSHIP_TAB.QUICK_APPLY)}
							tabHeader={
								<span>
									<strong>{SCHOLARSHIP_TAB.QUICK_APPLY}</strong> (
									{Array.isArray(hostedScholarships) && hostedScholarships.length})
								</span>
							}
						/> */}
					<div className={subStyles.searchFilterContainer}>
						{/* <SearchBTT clickHandler={() => {}} /> */}
						{context.selectedIndex !== ScholarshipFeedIndex.OFFERS && (
							<FilterBTT clickHandler={handleFilterBtnClick} />
						)}
					</div>
				</Tabs>
				<div className={subStyles.scholarshipListContainer}>
					{context.selectedIndex === ScholarshipFeedIndex.MATCHED && (
						<React.Fragment>
							{Array.isArray(data) &&
								data
									.filter((item) => !(context.dismissed.get(item.id) || item.id === 'promo'))
									.map((scholarship, index) => {
										if (index % 3 === 0 && showExclusive) {
											return _renderWithExclusiveScholarship(index, scholarship);
										} else if (index && index % 3 === 0 && showOffersInFeed) {
											return _renderWithOffers(index, scholarship);
										} else {
											return _renderProgressScholarship(scholarship, index);
										}
									})}
							{data.length == 0 && (
								<EmptyList
									selectedIndex={context.selectedIndex}
									onUpdatePress={props.onUpdatePress}
									onBackToMatched={props.onBackToMatched}
									show={isEmpty}
								/>
							)}
						</React.Fragment>
					)}
					<Feature name={FLAGS.OFFER_TAB}>
						{context.selectedIndex === ScholarshipFeedIndex.OFFERS && <Offers />}
					</Feature>
				</div>
				{/* {context.selectedIndex  === SCHOLARSHIP_TAB.QUICK_APPLY && (
					<div>
						{Array.isArray(hostedScholarships) &&
							hostedScholarships.map((scholarship, index) => {
								return (
									<div className={subStyles.exclusiveTabItems} key={`${scholarship.id}-${index}`}>
										<ExclusiveCard
											classname={subStyles.exclusiveContainer}
											image={`${config.cdn}images/hosted-scholarships/${scholarship?.banner_img}`}
											title={scholarship?.name}
											scholarshipItem={scholarship}
											handleShare={() => {}}
											handleQuickApply={() => handleQuickApply(scholarship)}
											hideCheckbox
											displayQuickApplyButton
										/>
									</div>
								);
							})}
					</div>
				)} */}

				{context.selectedIndex === ScholarshipFeedIndex.MATCHED && (
					<ListFooter
						length={Array.isArray(data) && data.length}
						onPress={props.handleFooterPress}
						matchedMetaCount={matchedMeta?.total_count}
					/>
				)}
			</div>
			{appliedSuccess && (
				<CustomModal visible={appliedSuccess}>
					<SuccessModal onClose={handleCloseSuccessModal} name={applied} />
				</CustomModal>
			)}
			{filterView && (
				<CustomModal visible={filterView} disableBackgroundScroll={true}>
					<FilterScreen onClose={handleCloseFilterModal} />
				</CustomModal>
			)}
		</React.Fragment>
	);
});

export default ScholarshipFeed;
