import React from 'react';
import config from '../../../../config/config';
import styles from './login-slider.scss';
import { checkFeature } from '../../../../contexts';
import { FLAGS } from '../../../../constants';

const LoginSlider = () => {
	const sliderImage = checkFeature(FLAGS.SCHOLLY_FREE)
		? `${config.cdn}images/scholly-app-welcome-1.gif`
		: `${config.cdn}images/diverse.gif`;
	return (
		<div className={styles.loginSliderContainer}>
			<picture className={styles.loginSliderImage}>
				<source media="(min-width:1400px)" srcSet={sliderImage} width="60%" />
				<img src={sliderImage} alt="Scholly is now free! Start Earning Today!" width="80%" />
			</picture>
		</div>
	);
};

export default LoginSlider;
