import { ActionMap, CategoriesModel } from '../types';
import { Status } from '../constants';

export enum CategoriesTypes {
	SetCategories = 'SET_CATEGORIES',
	SelectCategories = 'SELECT_CATEGORIES',
	SelectSubCategories = 'SELECT_SUB_CATEGORIES',
	SetStatus = 'SET_STATUS',
	Error = 'ERROR',
	Started = 'STARTED',
	Success = 'SUCCESS',
	Reset = 'IDLE',
}

export type CategoriesContextState = {
	categories: CategoriesModel[];
	categoriesLoadedStatus: Status;
	selectedCategories: CategoriesModel[];
	selectedSubCategories: Array<string>;
	categoryIndex?: number;
	loadCategories: () => void;
};

type CategoriesPayload = {
	[CategoriesTypes.SetCategories]: {
		categories: CategoriesModel[];
		selectedSubCategories: Array<string>;
		selectedCategories: CategoriesModel[];
	};
	[CategoriesTypes.SelectCategories]: {
		selectedCategories: CategoriesModel;
	};
	[CategoriesTypes.SelectSubCategories]: {
		selectedSubCategories: string;
	};
	[CategoriesTypes.SetStatus]: {
		status: Status;
	};
};
type StatusActionPayload = {
	[CategoriesTypes.Error]: { status: Status };
	[CategoriesTypes.Reset]: { status: Status };
	[CategoriesTypes.Started]: { status: Status };
	[CategoriesTypes.Success]: { status: Status };
};

export type CategoriesActions = ActionMap<CategoriesPayload>[keyof ActionMap<CategoriesPayload>];

type StatusActions = ActionMap<StatusActionPayload>[keyof ActionMap<StatusActionPayload>];

export const categoriesReducer = (state: CategoriesContextState, actions: CategoriesActions | StatusActions) => {
	switch (actions.type) {
		case CategoriesTypes.SetCategories: {
			return {
				...state,
				categories: actions.payload.categories,
				selectedSubCategories: actions.payload.selectedSubCategories,
				selectedCategories: actions.payload.selectedCategories,
			};
		}
		// TODO ADD SELECTCATEGORIES AND SELECTSUBCATEGORIES
		// SUBCATEGORIES USES INCLUDE TO TOGGLE
		case CategoriesTypes.SelectCategories: {
			return {
				...state,
				//selectedCategories: actions.payload.selectedCategories,
			};
		}
		case CategoriesTypes.SelectSubCategories: {
			const returnItem = {
				...state,
				selectedSubCategories: state.selectedSubCategories.includes(actions.payload.selectedSubCategories)
					? [...state.selectedSubCategories.filter((i) => i !== actions.payload.selectedSubCategories)]
					: [...state.selectedSubCategories, actions.payload.selectedSubCategories],
			};
			return returnItem;
		}
		case CategoriesTypes.SetStatus: {
			return {
				...state,
				categoriesLoadedStatus: actions.payload.status,
			};
		}

		default:
			return state;
	}
};
