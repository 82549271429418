export const OPPORTUNITY_TYPE: {
	GROW_MONEY: string;
	SAVE_MONEY: string;
	MAKE_MONEY: string;
} = {
	GROW_MONEY: 'GROW_MONEY',
	SAVE_MONEY: 'SAVE_MONEY',
	MAKE_MONEY: 'MAKE_MONEY',
};

export const OPPORTUNITY_TYPE_COLORS: {
	GROW_MONEY: string;
	SAVE_MONEY: string;
	MAKE_MONEY: string;
} = {
	GROW_MONEY: '#3259F1',
	SAVE_MONEY: '#44AF81',
	MAKE_MONEY: '#2AB7D6',
};

export const UNQUALIFIED_GRAY = '#A7A9AB';

export const TIME_INDICATOR: { RED: string; YELLOW: string; GREEN: string } = {
	RED: '#FB113F',
	YELLOW: '#FFAD29',
	GREEN: '#00BA88',
};
