import React, { useEffect, useContext, useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { getABRef, useABTest } from '../../helpers/hooks/abhook';

import SignUpForm from './RegisterForm';
import { FLAGS, Status, Routes, ScreenStrings } from '../../constants';
import { Feature, UserContext, SessionContext, PromoContext } from '../../contexts';
import config from '../../config/config';
import styles from './authentication.scss';

interface Props extends RouteComponentProps {
	toggleScreen: () => void;
	styles?: object;
}

const Register = (props: Props) => {
	useABTest('scholarship_apply');
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [enterEvent, setEnterEvent] = useState(false);
	const [registerError, setRegisterError] = useState();
	const promoContext = useContext(PromoContext);
	const { loadUser } = useContext(UserContext);
	const { startRegisteredSession, setisAuthenticated } = useContext(SessionContext);

	const registerSubmit = async (email: string, password: string, birthday: string) => {
		if (isLoading) return;
		try {
			setIsLoading(true);

			const refData = getABRef('scholarship_apply');
			const promo = promoContext.code;

			const res = await startRegisteredSession({ email, password, birthday, promo, ...refData });

			loadUser(res);
			history.replace(Routes.user.create);
			setisAuthenticated(Status.RESOLVED);
		} catch (error) {
			if (error.message) {
				console.log(error.message);
				setRegisterError(error.message);
			} else if (Array.isArray(error)) {
				error.forEach((err) =>
					setRegisterError(
						err.message === ScreenStrings.login.emailInUseToReplace
							? ScreenStrings.login.emailInUse
							: err.message
					)
				);
			}
			setIsLoading(false);
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', (event) => {
			if (event.key === 'Enter') {
				setEnterEvent(true);
			}
		});
		document.addEventListener('keydown', (event) => {
			if (event.key === 'Enter') {
				setEnterEvent(true);
			}
		});
	}, []);
	const loginImage = `${config.cdn}images/happy-free-subscription-people.png`;

	return (
		<div className={styles.formContainer}>
			<div className={styles.formHeader}>
				<h1
					className="is-size-12 has-text-blue-darker has-text-centered has-text-weight-medium"
					style={{ lineHeight: '55px' }}
				>
					{ScreenStrings.signUp.subTitle}
				</h1>
			</div>
			<Feature name={FLAGS.SCHOLLY_FREE}>
				<div className={styles.subTitleContainer}>
					<h2 className={styles.subTitleTitle}>{ScreenStrings.login.subTitle}</h2>
					<picture className={styles.subTitleImage}>
						<source media="(min-width:650px)" srcSet={loginImage} />

						<img src={loginImage} alt="happy free subscription" width={'121'} />
					</picture>
				</div>
			</Feature>
			{/* NOTE: firefox block request and refresh page without preventDefault*/}

			<form
				onSubmit={(e) => {
					e.preventDefault();
				}}
			>
				<SignUpForm
					history={props.history}
					onSignUp={registerSubmit}
					isLoading={isLoading}
					enterEvent={enterEvent}
					setEnterEvent={setEnterEvent}
					registerError={registerError}
				/>
			</form>
			<div className="has-text-centered">
				<div className="is-flex has-text-black is-size-3 is-family-primary is-justify-content-center is-align-items-center mt-4">
					Have an account?
				</div>
				<div>
					<button
						onClick={props.toggleScreen}
						className={classNames(
							'button is-ghost has-text-pink is-size-5 p-0',
							styles.bulmaButtonOverwrite
						)}
					>
						Log In
					</button>
				</div>
			</div>
		</div>
	);
};

export default withRouter(Register);
