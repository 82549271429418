import * as React from 'react';
import * as _ from 'lodash';

import {
	ParameterValueType,
	UserUpdateBirthday,
	UserUpdateFirstLastNameType,
	UserUpdateGoal,
} from '@scholly/scholly-types';
import { ProfileStateContext, withParameterContext, updateUserInfo, updateUserParameters } from '../../contexts';
import { checkBasic, debounceClick, getLastAdultBirthday, whatswhats } from '../../helpers/Functions';
import { setUser, updateParameterValues, updateUser } from '../../actions/userActions';

import { Button } from '@scholly/elements';
import ContentLoader from 'react-content-loader';
import EducationForm from './spilt/EducationForm';

import PersonalForm from './spilt/PersonalForm';
import { Status, ScreenStrings } from '../../constants';
import { SubHeader } from '../../components/layouts/SubHeader';
import { isBelow13 } from '../../helpers/Validation';
import moment from 'moment';
import styles from '../onboarding/onboarding.scss';
import { UserFormParamsType } from '../../types';

type ParameterContextType = {
	parameter_values: {
		gender: Array<ParameterValueType>;
		race: Array<ParameterValueType>;
		citizen: Array<ParameterValueType>;
		gpa: Array<ParameterValueType>;
		grade: Array<ParameterValueType>;
		degree: Array<ParameterValueType>;
		enrollment_status: Array<ParameterValueType>;
		major: Array<ParameterValueType>;
		need_merit?: Array<ParameterValueType>;
	};
	isParametersLoaded: boolean;
};
type SValueType = {
	id: string;
	name: string;
};

type UserUpdateUserType = {
	user_type: string | any;
};

type ComponentType = UserUpdateFirstLastNameType &
	UserFormParamsType &
	UserUpdateGoal &
	UserUpdateUserType &
	UserUpdateBirthday;

type IProps = ParameterContextType & {
	next: () => void;
	display?: (data: object) => void;
	formStyles?: object;
	path: string;
	show: boolean;
};

type StateInputType = ComponentType & {
	school: SValueType;
	location: string;
};

type IStateType = {
	parameter_values: ParameterContextType | any;
	isDisabled: boolean;
	isDataLoading: boolean;
	missing: Array<string>;
	isLoading: boolean;
	isDataUpdated: boolean;
};

// TECH DEBT - revist
// TODO: Refactor Component

class SingleForm extends React.Component<IProps, IStateType> {
	static contextType = ProfileStateContext;
	state: IStateType = {
		parameter_values: {},
		isDisabled: true,
		isDataLoading: true,
		missing: [],
		isLoading: false,
		isDataUpdated: false,
	};

	componentDidMount() {
		this._getFormData();
		setTimeout(() => {
			this.setState({ ...this.state, isDisabled: true });
		}, 0);
	}

	enabled = () => {
		const user = this.context.data;
		return (
			user.first_name &&
			user.last_name &&
			user.birthday &&
			user.major !== 'no words' &&
			user.major?.length &&
			user.gpa &&
			user.degree &&
			user.enrollment_status &&
			user.citizen &&
			user.gender &&
			user.grade
			//this.state.phone_number.length > 9
		);
	};

	_getFormData = async () => {
		// @ts-ignore
		const parameter_values = this.props.values;
		const missing = whatswhats(this.context.data);
		//@ts-ignore
		this.context.data.location == 'N/A' && missing.push('location');
		// @ts-ignore
		this.setState({ parameter_values, missing, isDataLoading: false });
	};

	onChangeTextMultiple = (property: keyof StateInputType) => (value: any) => {
		this.setState({ ...this.state, isDisabled: false });
		value && this.context.changeState({ [property]: value } as Pick<StateInputType, keyof StateInputType>);
	};

	hasError = () => {
		const user = this.context.data;

		return !!(
			user.first_name &&
			user.last_name &&
			!isBelow13(user.birthday, null) &&
			user.school &&
			user.enrollment_status
		);
	};
	onChangeText =
		(property: keyof StateInputType) =>
		(value: any = '') => {
			this.setState({ ...this.state, isDisabled: false });
			this.context.changeState({ [property]: value } as Pick<StateInputType, keyof StateInputType>);
		};

	onNext = async () => {
		this.setState({ ...this.state, isDisabled: true, isLoading: true });
		try {
			const { parameter_values } = this.state;
			const user = this.context.data;
			const { citizen, degree, gender, gpa, major, race, enrollment_status, user_type } = parameter_values;

			if (!checkBasic(user)) {
				this.props.display({ text: ScreenStrings.profile.missingFields, type: 'error' });
				return;
			}

			const data = {
				first_name: user.first_name,
				last_name: user.last_name,
				birthday: user.birthday || moment(getLastAdultBirthday()).format('YYYY-MM-DD'),
				goals: { goal: user.goal },
			};

			const parameters = {
				citizen: { value: citizen?.find((p) => p?.value === user?.citizen)?.id },
				degree: { value: degree?.find((p) => p?.value === user?.degree)?.id },
				gender: { value: gender.find((p) => p.value === user.gender).id },
				grade: { value: user.grade.id },
				gpa: { value: gpa.find((p) => p.value === user.gpa).id },
				user_type: { value: user_type.find((p) => p.value === user.user_type).id },
				major: { value: [] },
				enrollment_status: { value: enrollment_status.find((p) => p.value === user.enrollment_status).id },
				race: { value: [] },
				misc: { value: _.uniq(user.misc.map((x) => x.id)) },
			};

			if (Array.isArray(user.major)) {
				user.major.forEach((v, k) => {
					parameters.major.value.push(major.find((p) => p.value === v.value).id);
				});
			}

			if (Array.isArray(user.race)) {
				user.race.forEach((v, k) => {
					parameters.race.value.push(race.find((p) => p.value === v.value).id);
				});
			}

			// @ts-ignore
			const resi = await updateUserInfo(data);
			const resParam = await updateUserParameters(parameters);

			await updateUser(resi);
			const res = await updateParameterValues(resParam);

			if (res.status == 'OK') {
				setUser(res.data.user);
				this.setState({ isLoading: false });
			}
			await this.props.next();
		} catch (e) {
			console.warn(e);
			this.setState({ ...this.state, isDisabled: false, isLoading: false });
			this.props.display({ text: ScreenStrings.profile.missingFields, type: 'error' });
		}
	};

	isLoading = () => {
		//@ts-ignore
		const { parametersLoadedStatus } = this.props;
		const { status } = this.context;

		return (
			parametersLoadedStatus === Status.IDLE ||
			parametersLoadedStatus === Status.PENDING ||
			status === Status.IDLE ||
			status === Status.REJECTED ||
			status === Status.PENDING
		);
	};

	render() {
		if (!this.props.show) return null;
		return (
			<div className={styles.profileContainer}>
				<SubHeader title="Profile" hideBackButton />
				{this.isLoading() && <ProfileFormLoader />}
				{!this.isLoading() && (
					<div className={styles.form}>
						<PersonalForm
							missing={this.state.missing}
							webStyle={styles}
							onChangeText={this.onChangeText}
							onChangeTextMultiple={this.onChangeTextMultiple}
							path={this.props.path}
							showError={true}
						/>
						<EducationForm
							missing={this.state.missing}
							webStyle={styles}
							onChangeText={this.onChangeText}
							onChangeTextMultiple={this.onChangeTextMultiple}
							path={this.props.path}
							noMarginTop
							showError={true}
						/>
						<Button
							onClick={debounceClick(this.onNext)}
							variant={'primary'}
							style={{ width: '100%', boxSizing: 'border-box' }}
							disabled={this.state.isDisabled || !this.hasError()}
						>
							Save
						</Button>
					</div>
				)}
			</div>
		);
	}
}

export default withParameterContext(SingleForm);

const ProfileFormLoader: React.FC = () => {
	const rx = 5;
	const ry = 5;
	const x = 0;
	return (
		<ContentLoader height={900} width={'60%'}>
			<rect x="25%" y="50" rx={rx} ry={ry} width="50%" height="36" />
			<rect x={x} y="100" rx={rx} ry={ry} width="100%" height="48" />
			<rect x={x} y="176" rx={rx} ry={ry} width="100%" height="48" />
			<rect x={x} y="252" rx={rx} ry={ry} width="100%" height="48" />
			<rect x={x} y="328" rx={rx} ry={ry} width="100%" height="48" />
			<rect x={x} y="404" rx={rx} ry={ry} width="100%" height="48" />
			<rect x={x} y="480" rx={rx} ry={ry} width="100%" height="48" />
			<rect x={x} y="556" rx={rx} ry={ry} width="100%" height="48" />
			<rect x={x} y="632" rx={rx} ry={ry} width="100%" height="48" />
			<rect x={x} y="708" rx={rx} ry={ry} width="100%" height="48" />
			<rect x={x} y="784" rx={rx} ry={ry} width="100%" height="48" />
			<rect x={x} y="860" rx={rx} ry={ry} width="100%" height="48" />
		</ContentLoader>
	);
};
