import React, { useContext, useEffect, useState, createContext } from 'react';
import { connectPromoCode, getPromoCode, getUserPromoCodes } from '../actions';
import { UserContext, UserMerchantType } from './UserContext';

export type PromoContextType = {
	code: string | null;
	discount: any | null;
	subscriptionPlan: any | null;
	setCode: React.Dispatch<any>;
	applyCode: () => boolean;
	getUserCodes: () => void;
	setOfferModalShowed: (value: boolean) => void;
	offerModalShowed: boolean;
};

type PromotionalContextProps = {
	children: JSX.Element;
};

export const PromoContext = createContext<PromoContextType>(null);

export const PromoContextProvider: React.FC<PromotionalContextProps> = (props) => {
	const [code, setCode] = useState(null);
	const [codes, setCodes] = useState([]);
	const [subscriptionPlan, setPlan] = useState(null);
	const [discount, setDiscount] = useState(null);
	const [id, setId] = useState(null);
	const [offerModalShowed, setOfferModalShowed] = useState(null);
	const user = useContext(UserContext);

	const getCode = () => {
		getPromoCode(code)
			.then((res) => {
				if (res.data.subscription_plan) setPlan(res.data.subscription_plan);
				setId(res.data.id);
				setDiscount(res.data.discount);
			})
			.catch((err) => console.log('err', err));
	};

	const applyCode = () => {
		if (user.merchant_type !== UserMerchantType.free) return false;
		connectPromoCode(id).catch((err) => {
			console.log('err', err);
			return false;
		});
		return true;
	};

	const getUserCodes = () => {
		if (user.merchant_type !== UserMerchantType.free) return;
		getUserPromoCodes()
			.then((res) => {
				if (res.data.length !== 0) setCodes(res.data);
			})
			.catch((err) => console.log('err', err));
	};

	useEffect(() => {
		if (code) getCode();
	}, [code]);

	useEffect(() => {
		if (codes.length !== 0) setCode(codes[0].code);
	}, [codes]);

	useEffect(() => {
		if (id && user.merchant_type === UserMerchantType.free) applyCode();
	}, [id, user.merchant_type]);

	useEffect(() => {
		if (user.merchant_type === UserMerchantType.subscriber) {
			if (code && code.includes('FRND') && 'friendbuy' in window) {
				if (user.subscription) {
					// @ts-ignore
					window['friendbuy'].push([
						'track',
						'order',
						{
							id: user.subscription.id,
							amount: user.subscription.recurring_price,
							email: user.user_email,
						},
					]);
					user.toggleRefer(true);
				}
			}
		}
	}, [user.merchant_type]);

	useEffect(() => {
		if ('promoCode' in localStorage) {
			setCode(localStorage.getItem('promoCode'));
		}
		return () => {
			localStorage.removeItem('promoCode');
		};
	}, []);

	return (
		<PromoContext.Provider
			value={{
				code,
				discount,
				subscriptionPlan,
				setCode,
				applyCode,
				getUserCodes,
				setOfferModalShowed,
				offerModalShowed,
			}}
		>
			{props.children}
		</PromoContext.Provider>
	);
};
