import * as React from 'react';

import { Colors, Typography } from '@scholly/scholly-ui';
import { Linking, StyleSheet, Text, TouchableOpacity } from 'react-native-web';

import { ScreenStrings } from '../../constants';
import subStyles from './scholarship.scss';
import { ScholarshipFeedIndex } from '../../types';

interface Props {
	onUpdatePress: () => void;
	selectedIndex: number;
	onBackToMatched: () => void;
	show?: boolean;
}

const EmptyList = (props: Props) => {
	const { selectedIndex } = props;

	if (!props.show) return <div />;

	const renderBackButton = () => {
		return (
			<div className={subStyles.emptyListBtnContainer}>
				<Typography variant="body" align="center" style={styles.textLink}>
					Back to{' '}
				</Typography>
				<TouchableOpacity onPress={props.onBackToMatched}>
					<Typography variant="body" align="center" style={styles.textLink}>
						matches
					</Typography>
				</TouchableOpacity>
			</div>
		);
	};
	return (
		<React.Fragment>
			<div className={subStyles.emptyListRoot}>
				<div className={subStyles.emptyListTextContainer}>
					{(() => {
						switch (selectedIndex) {
							case ScholarshipFeedIndex.MATCHED:
								return (
									<div style={{ maxWidth: 392 }}>
										<div style={{ marginBottom: 8 }}>
											<h3 style={{ color: Colors.greyishBrown }}>You're up to date 🎉</h3>
										</div>
										<div
											style={{
												marginBottom: 16,
											}}
										>
											<Text style={styles.textGrey}>
												Check back tomorrow for new matches,
												{'\n'}
												you can also{' '}
												<Text onPress={props.onUpdatePress} style={styles.textLink}>
													update your categories{' '}
												</Text>
												for more scholarships.
												{'\n'}If this is a mistake, please contact us at{' '}
												<Text
													onPress={() => Linking.openURL('mailto:info@myscholly.com')}
													style={styles.textLink}
												>
													info@myscholly.com
												</Text>
											</Text>
										</div>
									</div>
								);
							case ScholarshipFeedIndex.SAVED:
								return (
									<>
										<h4 className={subStyles.emptyTextLabel}>
											{ScreenStrings.scholarship.emptyListSaved}
										</h4>
										{renderBackButton()}
									</>
								);
							case ScholarshipFeedIndex.APPLIED:
								return (
									<>
										<h4 className={subStyles.emptyTextLabel}>
											{ScreenStrings.scholarship.emptyListApplied}
										</h4>
										{renderBackButton()}
									</>
								);
							default:
								return null;
						}
					})()}
				</div>
			</div>
		</React.Fragment>
	);
};

export default EmptyList;

const styles = StyleSheet.create({
	root: { flex: 1, justifyContent: 'space-evenly', alignItems: 'center', marginTop: 56 },
	imageStyles: { width: '85%', flex: 1, alignSelf: 'center' },
	textGrey: {
		color: Colors.grey,
		fontSize: 14,
		letterSpacing: -0.3,
		lineHeight: 20,
		fontFamily: 'Poppins',
		textAlign: 'center',
	},
	textLink: {
		color: Colors.greyishBrown,
		fontFamily: 'Poppins',
	},
	textContainer: { marginTop: 32 },
});
