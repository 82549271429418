import React from 'react';
import ContentLoader from 'react-content-loader';
import { useHistory } from 'react-router-dom';

import ScholarshipCard from '../../components/ScholarshipCard';
import { ViewMore } from '../../components/common';

import { PublicContext } from '../../contexts/PublicContext';

import { Status, Routes } from '../../constants';

import { ScholarshipClickSourceIndex } from '../../types';

import styles from './unauthentication.scss';

const Landing = () => {
	const history = useHistory();
	/**
	 * Context returns { status, scholarships, featured_scholarships }
	 * this is considered one list not two separate lists position props should reflect this
	 */
	const { featured_scholarships, scholarships, status } = React.useContext(PublicContext);

	const navigatTo = () => history.replace(Routes.register);

	return (
		<div className={styles.landingPage}>
			{status === Status.REJECTED && <Error />}
			{[Status.IDLE, Status.PENDING].includes(status) && <ListLoader />}
			{status === Status.RESOLVED && (
				<div>
					<h1>Explore thousands of scholarships with Scholly Search</h1>
					<div className={styles.listContainer} data-testid="landing">
						<h2>Featured Scholarships</h2>
						{featured_scholarships.map((scholarship, idx) => (
							<div className={styles.cardWrapper} key={scholarship.id}>
								<ScholarshipCard
									key={scholarship.id}
									scholarshipItem={scholarship}
									isUngated
									isLarge
									position={idx + 1}
									source={ScholarshipClickSourceIndex.LANDING}
								/>
							</div>
						))}
						<h2>More Scholarships</h2>
						{scholarships.map((scholarship, idx) => (
							<div className={styles.cardWrapper} key={scholarship.id}>
								<ScholarshipCard
									key={scholarship.id}
									scholarshipItem={scholarship}
									isUngated
									isLarge
									position={featured_scholarships.length + idx + 1}
									source={ScholarshipClickSourceIndex.LANDING}
								/>
							</div>
						))}

						<ViewMore
							onClick={navigatTo}
							text="Discover more scholarships effortlessly with Scholly Search"
							btnLabel="view more scholarships"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default Landing;

const ListLoader: React.FC = () => {
	return (
		<div className={styles.listContainer} data-testid="landing">
			<ContentLoader height={`100vh`} width={'50vw'} backgroundColor={'#d6d6d6'}>
				<rect x="0" y="40" rx="3" ry="3" width="100%" height="40" />
				<rect x="0" y="100" rx="3" ry="3" width="100%" height="40" />
				<rect x="0" y="182" rx="3" ry="3" width="100%" height="150" />
				<rect x="0" y="382" rx="3" ry="3" width="100%" height="150" />
				<rect x="0" y="582" rx="3" ry="3" width="100%" height="150" />
				<rect x="0" y="782" rx="3" ry="3" width="100%" height="150" />
			</ContentLoader>
		</div>
	);
};

const Error: React.FC = () => {
	return (
		<div>
			<h1>Server Error: An unexpected error has occurred on the server. Please try again later.</h1>
		</div>
	);
};
