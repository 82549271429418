import ReactGA from 'react-ga4';

/**
 * Calls the `event` function from the `ReactGA` library with the provided arguments.
 *
 * @param {Object} args - An object containing the following properties:
 *   - {string} action: The action associated with the event.
 *   - {string} category: The category associated with the event.
 *   - {string} [label]: Optional. The label associated with the event.
 *   - {number} [value]: Optional. The value associated with the event.
 *   - {boolean} [nonInteraction]: Optional. Specifies whether the event is a non-interaction event.
 *   - {'beacon' | 'xhr' | 'image'} [transport]: Optional. The transport method to use for sending the event.
 * @return {void}
 */
export const event = (args: {
	action: string;
	category: string;
	label?: string;
	value?: number;
	nonInteraction?: boolean;
	transport?: 'beacon' | 'xhr' | 'image';
}) => ReactGA.event(args);

// !! Not being used
export const set = (fieldsObject: object) => ReactGA.set(fieldsObject);

/**
 * Tracks a page view.
 *
 * @param {string} path - The path of the page being tracked.
 */
export const trackpageview = (path: string) => {
	if (this._lastPath !== path) {
		ReactGA.send({ hitType: 'pageview', page: path, title: document.title });
	}
	this._lastPath = path;
};

/**
 * Calls the `identify` method of the `ire` object in the global `window` scope.
 *
 * @param {string} customerId - The ID of the customer.
 * @param {string} customerEmail - The email of the customer.
 */
export const impactIdentify = (customerId: string, customerEmail: string) => {
	//@ts-ignore
	window.ire('identify', { customerId, customerEmail });
};

/**
 * Generates a conversion tracking event for the Impact Tracker.
 *
 * @param {object} data - The data object containing the necessary information for the conversion tracking event.
 * @return {void} This function does not return a value.
 */
export const impactTrackConversion = (data: object) => {
	//@ts-ignore
	window.ire('trackConversion', 24508, data, {
		verifySiteDefinitionMatch: true,
	});
};
