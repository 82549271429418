import React from 'react';
import { Button, SquareClose } from '@scholly/elements';
import styles from '../scholarship-feed.scss';
import SuccessIcon from '../../common/icons/success-icon';
import classnames from 'classnames';
import ButtonStrings from '../../../constants/ButtonStrings';

interface SuccessModalProps {
	onClose: () => void;
	name: string;
	onClick?: () => void;
	isShowButton?: boolean;
}
export const SuccessModal = ({ onClose, name, isShowButton, onClick }: SuccessModalProps) => {
	const handleClick = () => {
		if (onClick) {
			onClick();
		} else {
			onClose();
		}
	};
	return (
		<div className={styles.modalContainer}>
			<SuccessIcon />
			<div className={classnames(styles.modalText, 'pt-4')}>You ‘ve successfully {'\n'} applied to</div>
			<div className={styles.modalText}>{name}</div>
			<div className={styles.modalCloseIcon}>
				<SquareClose clickHandler={onClose} />
			</div>
			{isShowButton && (
				<Button variant={'primary'} className={styles.modalButton} onClick={handleClick}>
					{ButtonStrings.continue}
				</Button>
			)}
		</div>
	);
};
