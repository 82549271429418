import * as React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { FreeProps, UserContext, checkFreeHOC, SessionContext, PromoContext } from '../../contexts';

import LoginForm from '../../components/auth/LoginForm';
import { Status, Routes, ScreenStrings, STORAGE_KEYS } from '../../constants';

import styles from './authentication.scss';

interface Props extends FreeProps {
	toggleScreen: () => void;
	styles?: object;
}

const Login = (props: Props) => {
	const history = useHistory();
	const { loadUser, getUserABRef, ...userContext } = React.useContext(UserContext);
	const { startSession, setisAuthenticated } = React.useContext(SessionContext);
	const promoContext = React.useContext(PromoContext);
	const [errorMsg, setErrorMsg] = React.useState<{ field: string; message: string }>();
	const [isLoginValid, setIsLoginValid] = React.useState(true);
	const [isLoading, setIsLoading] = React.useState(false);

	const loginSubmit = async (email: string, password: string) => {
		try {
			setIsLoading(true);
			const promo = promoContext.code;
			const res = await startSession({ email, password, promo });

			onSubmit(res);
		} catch (error) {
			setIsLoading(false);
			if (Array.isArray(error)) setErrorMsg(error[0]);
			setIsLoginValid(false);
		}
	};

	const onSubmit = (user) => {
		let routeName = Routes.dashboard;

		loadUser(user);

		const seenFreeAccountScreen = localStorage.getItem(STORAGE_KEYS.FREE_ACCOUNT_ACTIVATED + user.id);

		if (props.isFree && !seenFreeAccountScreen && isRefundPage) {
			routeName = Routes.refund;
		}

		if (isRefundPage) {
			routeName = Routes.refund;
		}

		history.push(routeName);
		setisAuthenticated(Status.RESOLVED);
	};

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);

	const isRefundPage = urlParams.get('page') === 'refund';

	return (
		<div className={styles.authContentWrapper}>
			<div className={styles.formHeader}>
				<h1 className={classNames(styles.title, 'mb-8')}>
					{isRefundPage ? ScreenStrings.login.refundTitle : ScreenStrings.login.title}
				</h1>
			</div>

			<div className={styles.formContainer}>
				{/* NOTE: firefox block request and refresh page without preventDefault*/}
				<form
					className="mb-4"
					onSubmit={(e) => {
						e.preventDefault();
					}}
				>
					<LoginForm
						onLogin={loginSubmit}
						isLoginValid={isLoginValid}
						errorMsg={errorMsg}
						isLoading={isLoading}
						isRefundPage={isRefundPage}
					/>
				</form>
				<div className="pt-1 has-text-centered">
					<p className="has-text-centered is-size-3 has-text-black">Don't have an account?</p>

					<button
						className={classNames(
							'button is-ghost has-text-pink is-size-5 p-0',
							styles.bulmaButtonOverwrite
						)}
						onClick={props.toggleScreen}
					>
						Sign Up
					</button>
					<br />

					<button
						className={classNames(
							'button is-ghost has-text-pink is-size-5 p-0',
							styles.bulmaButtonOverwrite
						)}
						onClick={() => history.replace(Routes.resetpassword)}
					>
						Forgot Password?
					</button>
				</div>
			</div>
		</div>
	);
};

export default checkFreeHOC(Login);
