import React, { SVGProps } from 'react';

const CompletionIcon: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
	const { width, height } = props;
	return (
		<svg
			width={width || '150px'}
			height={height || '175px'}
			viewBox="0 0 150 175"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M72.944 23.519c26.292-3.688 51.935 16.721 57.275 45.581 5.34 28.86-11.649 55.237-37.944 58.923S40.338 111.301 35 82.443C29.662 53.585 46.648 27.205 72.944 23.52Z"
				fill="#3056E9"
			/>
			<path
				d="m101.445 125.701-4.241 1.586a43.178 43.178 0 0 1-9.16 2.32c-26.303 3.686-51.948-16.719-57.286-45.577-4.69-25.344 7.843-48.777 28.778-56.605l4.241-1.586C42.847 33.667 30.314 57.099 35 82.443c5.338 28.858 30.982 49.264 57.275 45.58a43.326 43.326 0 0 0 9.17-2.322Z"
				fill="#1A2E7D"
			/>
			<g opacity={0.3} stroke="#F7F7F7" strokeMiterlimit={10}>
				<path
					opacity={0.3}
					d="M36.92 54.915c11.567-10.152 23.823-19.9 35.06-30.245M86.864 24.814C70.222 41.032 50.521 55.444 33.9 71.673M98.122 28.298c-19.74 19.72-44.277 36.61-62.885 57.004M107.43 33.703c-21.725 21.72-49.283 40.01-68.345 63.295M115.232 40.602C92.34 63.075 63.465 82.015 44.798 106.98M121.64 48.808C98.365 70.931 69.804 89.936 51.993 115.28M126.591 58.275c-22.762 20.777-49.444 39.24-66.131 63.602M129.876 69.066c-21.102 18.483-44.43 35.656-59.69 57.591M81.498 129.247c13.233-17.863 31.716-32.629 49.558-47.784M128.66 96.776c-11.821 10.035-23.362 20.235-32.915 31.61"
				/>
			</g>
			<path
				d="m98.24 117.203-4.242 1.587a35.825 35.825 0 0 1-7.608 1.925c-21.82 3.058-43.1-13.876-47.53-37.822-3.89-21.031 6.508-40.475 23.878-46.971l4.241-1.587c-17.37 6.497-27.764 25.943-23.877 46.972 4.428 23.946 25.71 40.88 47.528 37.822a35.785 35.785 0 0 0 7.61-1.926Z"
				fill="#1A2E7D"
			/>
			<path
				d="m98.24 117.203-4.242 1.587a35.825 35.825 0 0 1-7.608 1.925c-21.82 3.058-43.1-13.876-47.53-37.822-3.89-21.031 6.508-40.475 23.878-46.971l4.241-1.587c-17.37 6.497-27.764 25.943-23.877 46.972 4.428 23.946 25.71 40.88 47.528 37.822a35.785 35.785 0 0 0 7.61-1.926Z"
				fill="#1A2E7D"
			/>
			<path
				d="M74.587 32.412c21.818-3.057 43.1 13.876 47.53 37.824 4.43 23.947-9.669 45.836-31.487 48.901-21.819 3.066-43.1-13.884-47.528-37.83-4.428-23.945 9.667-45.838 31.485-48.895Z"
				fill="#90C4E5"
			/>
			<path
				d="m104.72 53.42-4.241 1.584a3.01 3.01 0 0 1 1.483-1.213l4.242-1.586a3.01 3.01 0 0 0-1.484 1.212"
				fill="#858585"
			/>
			<path
				d="m83.962 85.894-4.242 1.587 20.759-32.475 4.239-1.587-20.756 32.475ZM86.37 94.056l-4.24 1.586a3.005 3.005 0 0 1-.624.158c-.765.1-1.54-.08-2.183-.505l4.242-1.587a3.201 3.201 0 0 0 2.183.506c.212-.03.42-.083.622-.158Z"
				fill="#F55273"
			/>
			<path
				d="m86.37 94.056-4.24 1.586a3.005 3.005 0 0 1-.624.158c-.765.1-1.54-.08-2.183-.505l4.242-1.587a3.201 3.201 0 0 0 2.183.506c.212-.03.42-.083.622-.158Z"
				fill="#F55273"
			/>
			<path d="m83.563 93.709-4.24 1.586-16.818-10.762 4.24-1.587L83.562 93.71Z" fill="#F55273" />
			<path
				d="M106.828 52.043a3.27 3.27 0 0 1 2.481.726c1.528 1.212 1.951 3.465.946 5.037L87.857 92.84a2.98 2.98 0 0 1-2.11 1.374 3.2 3.2 0 0 1-2.182-.505l-16.82-10.763c-1.622-1.038-2.258-3.251-1.42-4.94a3.006 3.006 0 0 1 2.287-1.684 3.216 3.216 0 0 1 2.18.505l14.168 9.067 20.76-32.475a2.963 2.963 0 0 1 2.108-1.376Z"
				fill="#F7F7F7"
			/>
			<path
				d="m62.746 78.068 4.241-1.586a3.053 3.053 0 0 0-1.66 1.524c-.84 1.689-.202 3.902 1.42 4.94l-4.238 1.587c-1.622-1.04-2.26-3.252-1.419-4.943.336-.7.929-1.245 1.656-1.522Z"
				fill="#F55273"
			/>
			<path
				d="m66.744 82.946-4.235 1.587c-1.622-1.04-2.26-3.252-1.419-4.943.337-.7.93-1.245 1.658-1.522l4.241-1.586a3.053 3.053 0 0 0-1.66 1.524c-.84 1.689-.202 3.902 1.42 4.94"
				fill="#F55273"
			/>
			<path d="m69.264 62.475 4.925-2.57 3.066.54-4.927 2.573-3.064-.543Z" fill="#FB113F" />
			<path d="m47.564 131.04 9.472 1.78-.766 6.327-9.47-1.78.764-6.327Z" fill="#39B54A" />
			<path d="M35.103 53.952h3.526l.029 2.024h-3.525l-.03-2.024Z" fill="#90C4E5" />
			<path
				d="m82.773 46.22-.435 1.563-.594-.043.438-1.564.591.045ZM29.828 113.219l7.044-.275-3.62 4.816-7.044.275 3.62-4.816Z"
				fill="#F55273"
			/>
			<path d="M99.224 38.727 96.86 41.6l-3.261-.663 2.363-2.871 3.26.662Z" fill="#39B54A" />
			<path d="m133.08 61.812-7.032-8.148 2.474.922 7.031 8.147-2.473-.921Z" fill="#FB113F" />
			<path d="m126.972 81.427 11.554-1.418-4.207 2.978-11.556 1.419 4.209-2.979Z" fill="#1A2E7D" />
			<path d="m51.792 49.242 7.73-3.066.05 9.655-7.73 3.064-.05-9.653Z" fill="#FFAD29" />
			<path d="m41.11 35.923-2.422-.721.98-5.25 2.425.72-.983 5.251Z" fill="#FB113F" />
			<path
				d="m110.794 35.551-1.498-3.815 1.433-3.825 1.498 3.817-1.433 3.823ZM106.702 78.212l7.31-4.728 1.305 2.348-7.309 4.729-1.306-2.349ZM54.912 16.848l9.568.45 2.275 1.573-9.57-.453-2.273-1.57ZM129.017 125.222l-4.325-8.546.246-2.755 4.324 8.549-.245 2.752Z"
				fill="#FFAD29"
			/>
			<path d="m97.915 12.27-2.722 2.932-4.73-6.032 2.724-2.93 4.728 6.03Z" fill="#FB113F" />
			<path d="m100.248 34.446.038-1.399 3.695-1.853-.039 1.4-3.694 1.852Z" fill="#1A2E7D" />
			<path d="m124.532 33.696 2.168 6.111-4.724 1.166-2.168-6.11 4.724-1.167Z" fill="#90C4E5" />
			<path d="m49.453 112.834-3.277-12.367 2.152-.627 3.277 12.365-2.152.629Z" fill="#FFAD29" />
			<path d="m19.519 97.35 3.453.918-1.423.43-3.453-.917 1.423-.43Z" fill="#F55273" />
			<path
				d="m118.337 78.461 4.842-2.41 9.312 2.155-4.844 2.413-9.31-2.158ZM99.216 133.451l4.841-2.411 9.313 2.156-4.844 2.413-9.31-2.158Z"
				fill="#FB113F"
			/>
			<path d="m108.837 96.736-4.242 9.726-7.875-.016 4.242-9.726 7.875.016Z" fill="#39B54A" />
		</svg>
	);
};

export default CompletionIcon;
