import React from 'react';
import styles from '../offboarding.scss';
import { useHistory } from 'react-router-dom';
import { Status, Routes } from '../../../../constants';
import { UserContext } from '../../../../contexts';
import { pauseSubscription } from '../../../../actions';
import { CalendarIcon } from '../../../../components/common/icons/calendar';
import classNames from 'classnames';
import { Button } from '@scholly/elements';

const PauseMembership = () => {
	const history = useHistory();
	const [selected, setSelected] = React.useState(0);
	const [status, setStatus] = React.useState(Status.IDLE);
	const { subscription, getAndSetUser, getAndSetSubscription } = React.useContext(UserContext);
	const { offboarding } = Routes;
	const pauseSub = async () => {
		try {
			setStatus(Status.PENDING);
			// @ts-ignore
			const success = await pauseSubscription({ duration: selected }, subscription.id);
			await getAndSetSubscription();
			await getAndSetUser();
			history.push(`${offboarding.home}/${offboarding.successfullyPaused}/${selected}`);
			setStatus(Status.RESOLVED);
		} catch (err) {
			setStatus(Status.REJECTED);
			console.log(err, '<---err');
		}
	};
	return (
		<div className={styles.pauseCardContainer}>
			<div className={classNames(styles.isFlex, styles.justifyContentCenter)}>
				<CalendarIcon />
			</div>
			<div style={{ marginTop: '36px' }}>
				<div className={classNames(styles.pauseText, styles.pauseTitle)}>Pause your membership</div>
			</div>
			<div style={{ marginTop: '24px' }}>
				<div className={classNames(styles.pauseText, styles.pauseDescription)}>
					Taking a break from your scholarship search? You can pause your membership at zero-cost! When you
					return, you'll have multiple-months of new scholarships waiting for you.
				</div>
			</div>
			<PauseCard
				style={{ marginTop: '24px' }}
				heading={'3-Month Pause'}
				subheading={`You won\'t be charged for the next 3-months`}
				checked={selected == 3}
				onClick={
					selected !== 3
						? () => {
								setSelected(3);
						  }
						: () => {
								setSelected(0);
						  }
				}
			/>
			<PauseCard
				heading={'6-Month Pause'}
				subheading={`Your plan will resume in 6-months`}
				checked={selected == 6}
				onClick={
					selected !== 6
						? () => {
								setSelected(6);
						  }
						: () => {
								setSelected(0);
						  }
				}
			/>
			<div style={{ display: 'grid', placeItems: 'center', marginTop: '48px' }}>
				<Button
					variant="primary"
					onClick={pauseSub}
					disabled={selected == 0 || status === Status.PENDING}
					className={styles.pauseButton}
				>
					Pause Membership
				</Button>
				<Button
					variant="secondary"
					onClick={() => {
						history.push(`${offboarding.home}/${offboarding.affiliateOffer}`);
					}}
					className={styles.pauseButton}
					style={{ marginTop: 20 }}
				>
					Not Now
				</Button>
			</div>
		</div>
	);
};
export default PauseMembership;

const PauseCard = ({ heading, subheading, checked, onClick, style }) => {
	return (
		<div
			test-id={`testID_pause_card_button_${heading.replace(/ /g, '_')}`}
			className={styles.pauseCard}
			style={style}
			onClick={onClick}
		>
			<div>
				<div className={styles.pauseRadio}>{checked ? <div className={styles.pauseRadioChecked} /> : null}</div>
			</div>

			<div>
				<div>
					<div className={classNames(styles.pauseText, styles.pauseCardTitle)}>{heading}</div>
				</div>
				<div>
					<div className={classNames(styles.pauseText, styles.pauseCardDescription)}>{subheading}</div>
				</div>
			</div>
		</div>
	);
};
//@ts-ignore
PauseCard.defaultProps = {
	style: {},
};
