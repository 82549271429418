import React, { CSSProperties, FC } from 'react';

interface ShareIconProps {
	onClick?: (val: any) => void;
	fill?: string;
	backgroundColor?: string;
	stroke?: string;
	svgStyle?: CSSProperties;
	styles?: CSSProperties;
}
const ShareIcon: FC<ShareIconProps> = (props) => {
	const { onClick, fill, backgroundColor, svgStyle, styles, stroke = '#A0A3BD' } = props;
	return (
		<div>
			<div className="content" onClick={onClick} style={styles}>
				<svg
					width="34"
					height="35"
					viewBox="0 0 34 35"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					style={svgStyle}
				>
					<rect y="0.960938" width="34" height="34" rx="6" fill={backgroundColor || ''} />
					<path
						d="M14 16.9609L7 9.96094H26.5M14 16.9609L16.5 26.9609L26.5 9.96094M14 16.9609L26.5 9.96094"
						stroke={stroke}
						fill={fill}
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
		</div>
	);
};

export default ShareIcon;
