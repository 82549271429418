export type ActionMap<M extends { [index: string]: any }> = {
	[Key in keyof M]: M[Key] extends undefined
		? {
				type: Key;
		  }
		: {
				type: Key;
				payload: M[Key];
		  };
};

export type Offer = {
	affiliate: Affiliate;
	affiliate_subtext: string;
	age_max: number;
	age_min: number;
	amount: number;
	category: string;
	created_time: string;
	cta: string;
	display: any;
	end_time: any;
	fine_print: string;
	id: number;
	incentive_amount: number;
	modal_copy: string;
	name: string;
	offer: string;
	onboarding_cta: string;
	onboarding_modal_copy: string;
	platform: string;
	qualify_copy: string;
	start_time: any;
	status: string;
	steps_to_complete: string;
	steps_to_complete_modal_copy: string;
	sweepstake_entries: string;
	type: string;
	url: string;
	user_events: UserEvents[];
};

export type PromoOffer = {
	affiliate: PromoAffiliate;
	display: string;
	amount: string;
	affiliate_subtext: string;
};

export type PromoAffiliate = {
	avatar_img: string;
	name: string;
};

export type Affiliate = {
	about: string;
	about_img: string;
	avatar_img: string;
	category: string;
	color: string;
	created_time: string;
	id: string;
	logo_img: string;
	meta_description: string;
	meta_title: string;
	name: string;
	slug: string;
	status: string;
	sub_text: string;
	sub_text2: string;
};

export type UserEvents = {
	created_time: string;
	name: string;
	status: string;
};

export type Activity = {
	affiliate: ActivityDetails[];
	amount: number;
	status: string;
};

export type ActivityDetails = {
	id: number;
	name: string;
	status: string;
	created_time: string;
	category: string;
	color: string;
	about: string;
	sub_text: string;
	sub_text2: string;
	logo_img: string;
	avatar_img: string;
	about_img: string;
	slug: string;
	meta_title: string;
	meta_description: string;
	offers: Offer[];
	events: Events[];
};

export type Events = {
	event_name: string;
	conversion: boolean;
	created_time: string;
};

export enum OfferStatus {
	NOT_MATCHED = 'not_matched',
	PENDING = 'pending',
	ACTIVE = 'active',
	COMPLETED = 'completed',
}
