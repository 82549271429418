import React, { CSSProperties, FC } from 'react';

interface SuccessIconProps {
	styles?: CSSProperties;
}

const SuccessIcon: FC<SuccessIconProps> = (props) => {
	const { styles } = props;

	return (
		<div>
			<div className="content" style={styles}>
				<svg xmlns="http://www.w3.org/2000/svg" width="200" height="201" viewBox="0 0 200 201" fill="none">
					<path
						d="M93.5174 30.3833C127.226 25.6553 160.101 51.8211 166.947 88.8211C173.794 125.821 152.013 159.637 118.302 164.363C84.5901 169.088 51.7152 142.925 44.8715 105.927C38.0279 68.9299 59.8059 35.1086 93.5174 30.3833Z"
						fill="#3056E9"
					/>
					<path
						d="M130.058 161.386L124.62 163.42C120.827 164.837 116.889 165.835 112.877 166.394C79.1546 171.119 46.2772 144.958 39.4335 107.961C33.4213 75.4688 49.4885 45.4273 76.3278 35.391L81.7658 33.3574C54.9317 43.3936 38.8645 73.4351 44.8715 105.927C51.7152 142.925 84.5927 169.085 118.302 164.363C122.318 163.802 126.261 162.804 130.058 161.386Z"
						fill="#1A2E7D"
					/>
					<g opacity="0.3">
						<path
							opacity="0.3"
							d="M47.3346 70.6346C62.1625 57.6191 77.8763 45.1216 92.2833 31.86"
							stroke="#F7F7F7"
							strokeMiterlimit="10"
						/>
						<path
							opacity="0.3"
							d="M111.364 32.0439C90.028 52.8367 64.771 71.3134 43.4607 92.1191"
							stroke="#F7F7F7"
							strokeMiterlimit="10"
						/>
						<path
							opacity="0.3"
							d="M125.797 36.5103C100.491 61.7926 69.0321 83.4455 45.1755 109.593"
							stroke="#F7F7F7"
							strokeMiterlimit="10"
						/>
						<path
							opacity="0.3"
							d="M137.731 43.4403C109.878 71.2875 74.5481 94.7356 50.1095 124.588"
							stroke="#F7F7F7"
							strokeMiterlimit="10"
						/>
						<path
							opacity="0.3"
							d="M147.734 52.2849C118.385 81.0957 81.3656 105.378 57.4337 137.383"
							stroke="#F7F7F7"
							strokeMiterlimit="10"
						/>
						<path
							opacity="0.3"
							d="M155.949 62.8056C126.109 91.1683 89.4928 115.534 66.6573 148.026"
							stroke="#F7F7F7"
							strokeMiterlimit="10"
						/>
						<path
							opacity="0.3"
							d="M162.297 74.9429C133.114 101.58 98.9061 125.251 77.5126 156.484"
							stroke="#F7F7F7"
							strokeMiterlimit="10"
						/>
						<path
							opacity="0.3"
							d="M166.508 88.7771C139.453 112.474 109.546 134.49 89.9813 162.611"
							stroke="#F7F7F7"
							strokeMiterlimit="10"
						/>
						<path
							opacity="0.3"
							d="M104.484 165.933C121.451 143.031 145.146 124.101 168.021 104.671"
							stroke="#F7F7F7"
							strokeMiterlimit="10"
						/>
						<path
							opacity="0.3"
							d="M164.949 124.303C149.794 137.168 134.997 150.246 122.75 164.829"
							stroke="#F7F7F7"
							strokeMiterlimit="10"
						/>
					</g>
					<path
						d="M125.948 150.492L120.51 152.526C117.36 153.703 114.089 154.531 110.756 154.995C82.7818 158.914 55.5008 137.205 49.8211 106.505C44.8326 79.5413 58.1639 54.6139 80.433 46.2848L85.871 44.2512C63.6019 52.5802 50.2758 77.5102 55.2591 104.471C60.9362 135.171 88.2198 156.881 116.192 152.961C119.525 152.497 122.797 151.669 125.948 150.492Z"
						fill="#1A2E7D"
					/>
					<path
						d="M125.948 150.492L120.51 152.526C117.36 153.703 114.089 154.531 110.756 154.995C82.7818 158.914 55.5008 137.205 49.8211 106.505C44.8326 79.5413 58.1639 54.6139 80.433 46.2848L85.871 44.2512C63.6019 52.5802 50.2758 77.5102 55.2591 104.471C60.9362 135.171 88.2198 156.881 116.192 152.961C119.525 152.497 122.797 151.669 125.948 150.492Z"
						fill="#1A2E7D"
					/>
					<path
						d="M95.6246 41.7848C123.597 37.8652 150.88 59.575 156.56 90.2771C162.24 120.979 144.164 149.041 116.192 152.971C88.2198 156.901 60.9362 135.171 55.2591 104.471C49.5821 73.7719 67.6525 45.7045 95.6246 41.7848Z"
						fill="#90C4E5"
					/>
					<path
						d="M134.257 68.7175L128.819 70.7486C129.266 70.0378 129.934 69.492 130.721 69.1942L136.159 67.1605C135.372 67.458 134.704 68.0038 134.257 68.7149"
						fill="#858585"
					/>
					<path
						d="M107.644 110.352L102.206 112.386L128.819 70.7512L134.254 68.7175L107.644 110.352Z"
						fill="#F55273"
					/>
					<path
						d="M110.73 120.816L105.295 122.85C105.036 122.945 104.768 123.013 104.495 123.052C103.514 123.18 102.52 122.95 101.697 122.404L107.135 120.37C107.958 120.916 108.952 121.146 109.933 121.018C110.205 120.979 110.473 120.912 110.73 120.816Z"
						fill="#F55273"
					/>
					<path
						d="M110.73 120.816L105.295 122.85C105.036 122.945 104.768 123.013 104.495 123.052C103.514 123.18 102.52 122.95 101.697 122.404L107.135 120.37C107.958 120.916 108.952 121.146 109.933 121.018C110.205 120.979 110.473 120.912 110.73 120.816Z"
						fill="#F55273"
					/>
					<path
						d="M107.132 120.37L101.697 122.404L80.1342 108.606L85.5696 106.572L107.132 120.37Z"
						fill="#F55273"
					/>
					<path
						d="M136.959 66.9532C137.525 66.8841 138.099 66.9309 138.646 67.0908C139.192 67.2508 139.701 67.5204 140.139 67.8833C142.098 69.4377 142.641 72.3263 141.353 74.3418L112.638 119.256C112.343 119.729 111.948 120.131 111.481 120.436C111.014 120.74 110.485 120.939 109.933 121.018C108.952 121.146 107.958 120.916 107.135 120.37L85.5696 106.572C83.491 105.241 82.6752 102.404 83.7509 100.238C84.0237 99.6659 84.4339 99.1695 84.9451 98.7931C85.4563 98.4167 86.0528 98.1718 86.6816 98.0802C87.6613 97.9543 88.6536 98.1842 89.4773 98.7278L107.641 110.352L134.257 68.7175C134.55 68.2439 134.944 67.8403 135.411 67.5354C135.878 67.2305 136.407 67.0318 136.959 66.9532Z"
						fill="#F7F7F7"
					/>
					<path
						d="M80.4433 100.319L85.8813 98.2849C84.9481 98.6415 84.1869 99.3403 83.7534 100.238C82.6752 102.404 83.4936 105.241 85.5722 106.572L80.1393 108.606C78.0608 107.275 77.2424 104.438 78.3206 102.269C78.7517 101.372 79.5114 100.674 80.4433 100.319Z"
						fill="#F55273"
					/>
					<path
						d="M85.5695 106.572L80.1393 108.606C78.0608 107.275 77.2424 104.438 78.3206 102.269C78.7523 101.372 79.513 100.673 80.4459 100.319L85.8839 98.2849C84.9507 98.6415 84.1895 99.3403 83.756 100.238C82.6778 102.404 83.4962 105.241 85.5747 106.572"
						fill="#F55273"
					/>
					<path
						d="M88.7999 80.3266L95.1135 77.0312L99.0446 77.7255L92.7284 81.0235L88.7999 80.3266Z"
						fill="#FB113F"
					/>
					<path
						d="M60.9795 168.231L73.1235 170.514L72.1413 178.625L60 176.343L60.9795 168.231Z"
						fill="#39B54A"
					/>
					<path d="M45.004 69.3997H49.5249L49.5613 71.9955H45.043L45.004 69.3997Z" fill="#90C4E5" />
					<path
						d="M106.12 59.4883L105.561 61.4909L104.8 61.4365L105.361 59.4313L106.12 59.4883Z"
						fill="#F55273"
					/>
					<path
						d="M38.2403 145.384L47.2716 145.031L42.6313 151.205L33.6 151.557L38.2403 145.384Z"
						fill="#F55273"
					/>
					<path d="M127.21 49.881L124.18 53.5623L120 52.7126L123.029 49.0312L127.21 49.881Z" fill="#39B54A" />
					<path
						d="M170.616 79.4768L161.6 69.0312L164.772 70.2126L173.785 80.6582L170.616 79.4768Z"
						fill="#FB113F"
					/>
					<path
						d="M162.785 104.625L177.597 102.806L172.204 106.625L157.389 108.444L162.785 104.625Z"
						fill="#1A2E7D"
					/>
					<path
						d="M66.3999 63.3613L76.3094 59.4313L76.3744 71.8095L66.4649 75.7369L66.3999 63.3613Z"
						fill="#FFAD29"
					/>
					<path
						d="M52.7048 46.2867L49.6 45.3619L50.8575 38.6313L53.9649 39.5536L52.7048 46.2867Z"
						fill="#FB113F"
					/>
					<path
						d="M142.044 45.809L140.124 40.9178L141.96 36.0137L143.881 40.9075L142.044 45.809Z"
						fill="#FFAD29"
					/>
					<path
						d="M136.798 100.503L146.17 94.4411L147.843 97.4515L138.471 103.514L136.798 100.503Z"
						fill="#FFAD29"
					/>
					<path
						d="M70.3999 21.8312L82.666 22.409L85.5837 24.4245L73.3151 23.8442L70.3999 21.8312Z"
						fill="#FFAD29"
					/>
					<path
						d="M165.407 160.772L159.862 149.816L160.177 146.284L165.721 157.244L165.407 160.772Z"
						fill="#FFAD29"
					/>
					<path
						d="M125.532 15.9618L122.043 19.7209L115.979 11.9877L119.471 8.23126L125.532 15.9618Z"
						fill="#FB113F"
					/>
					<path
						d="M128.523 44.3919L128.572 42.5992L133.309 40.2235L133.259 42.0188L128.523 44.3919Z"
						fill="#1A2E7D"
					/>
					<path
						d="M159.656 43.4313L162.436 51.2655L156.38 52.7603L153.6 44.9261L159.656 43.4313Z"
						fill="#90C4E5"
					/>
					<path
						d="M63.4012 144.889L59.2 129.034L61.9592 128.231L66.1605 144.084L63.4012 144.889Z"
						fill="#FFAD29"
					/>
					<path
						d="M25.0239 125.04L29.4512 126.216L27.6273 126.768L23.2 125.591L25.0239 125.04Z"
						fill="#F55273"
					/>
					<path
						d="M151.714 100.822L157.921 97.7313L169.86 100.496L163.65 103.589L151.714 100.822Z"
						fill="#FB113F"
					/>
					<path
						d="M127.2 171.322L133.407 168.231L145.346 170.995L139.136 174.089L127.2 171.322Z"
						fill="#FB113F"
					/>
					<path
						d="M139.535 124.252L134.097 136.721L124 136.7L129.438 124.231L139.535 124.252Z"
						fill="#39B54A"
					/>
				</svg>
			</div>
		</div>
	);
};

export default SuccessIcon;
