import React, { useEffect, useState } from 'react';

import { AttributeBox } from '@scholly/elements';

import styles from './onboarding.scss';

type Props = {
	onSelect: (value: Array<string>) => void;
	onDeselect: (value?: string) => void;
	answers: Array<any>;
	selected: Array<any>;
	title: string;
	placeholder: string;
};

export const MultiTextSelect: React.FC<Props> = (props) => {
	const { answers, title, onSelect } = props;
	const [elements, setElements] = useState([]);

	const handleChange = (id) => {
		const element = elements.find((el) => el.id === id);
		if (element) {
			element.isSelected = !element.isSelected;
			setElements([...elements]);
		}
		const selectedIds = elements.filter((el) => el.isSelected).map((answer) => answer.id);
		onSelect(selectedIds);
	};

	useEffect(() => {
		const elements = answers.map((answer) => {
			return {
				id: answer.id,
				text: answer.value,
				isSelected: false,
			};
		});
		setElements(elements);
	}, [answers]);

	return (
		<div className={styles.mainBox}>
			<div className={styles.w100}>
				<div className={`${styles.w100} ${styles.flexCenterCenter} `} style={{ marginBottom: 20 }}>
					<h2>{title}</h2>
				</div>
				<AttributeBox elements={elements} onChange={handleChange} style={{ maxWidth: '100%' }} />
			</div>
		</div>
	);
};
