import React from 'react';

interface ExternalLinkProps {
	height?: number | string;
	width?: number | string;
	color?: string;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({ width = '22', height = '22', color }) => {
	return (
		<div style={{ display: 'flex', flexShrink: 0 }}>
			<svg width={width} height={height} viewBox="0 0 22 22" fill="none">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.8333 4.79647C12.3271 4.79647 11.9167 4.38606 11.9167 3.8798C11.9167 3.37354 12.3271 2.96313 12.8333 2.96313H18.3333C18.5764 2.96313 18.8096 3.05971 18.9815 3.23162C19.1534 3.40353 19.25 3.63669 19.25 3.8798L19.25 9.3798C19.25 9.88606 18.8396 10.2965 18.3333 10.2965C17.8271 10.2965 17.4167 9.88606 17.4167 9.3798L17.4167 6.09283L8.89818 14.6113C8.5402 14.9693 7.9598 14.9693 7.60182 14.6113C7.24384 14.2533 7.24384 13.6729 7.60182 13.315L16.1203 4.79647H12.8333ZM2.75 6.6298C2.75 5.61728 3.57081 4.79647 4.58333 4.79647H9.16667C9.67293 4.79647 10.0833 5.20687 10.0833 5.71313C10.0833 6.2194 9.67293 6.6298 9.16667 6.6298H4.58333V17.6298H15.5833V13.0465C15.5833 12.5402 15.9937 12.1298 16.5 12.1298C17.0063 12.1298 17.4167 12.5402 17.4167 13.0465V17.6298C17.4167 18.6423 16.5959 19.4631 15.5833 19.4631H4.58333C3.57081 19.4631 2.75 18.6423 2.75 17.6298V6.6298Z"
					fill={color ? color : 'currentColor'}
				/>
			</svg>
		</div>
	);
};

export default ExternalLink;
