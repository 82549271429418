import React, { useContext, useState, useEffect } from 'react';
import Switch from 'react-switch';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from '@scholly/elements';

import { UserContext, UserMerchantType, SessionContext } from '../../contexts';
import { getLocalUser } from '../../actions';

import DeactivateAccountForm from './forms/DeactivateAccountForm';

import { Routes } from '../../constants';

import styles from './settings.scss';

const CloseAccount: React.FC<RouteComponentProps> = (props) => {
	const sessionContext = useContext(SessionContext);
	const userContext = useContext(UserContext);
	const [showDeactivate, setShowDeactivate] = useState(false);
	const [deactivate, setDeactivate] = useState(false);

	useEffect(() => {
		(async function get() {
			const user = await getLocalUser();

			if (userContext.merchant_type === UserMerchantType.joincode) {
				for (var i in user.merchant.transactions) {
					if (user.merchant.transactions[i].order_type === UserMerchantType.joincode) {
					}
				}
			}
		})();
	}, [userContext]);

	const closeDeactivateModal = async () => {
		await setShowDeactivate(false);
		leavePage();
	};

	const leavePage = () => {
		sessionContext.endSession();
		props.history.push(Routes.login);
	};

	const deactivateAccount = () => {
		setShowDeactivate(true);
		setDeactivate(!deactivate);
	};

	const closeAccount = () => props.history.push(`offboarding/${Routes.offboarding.closeAccount}`);

	return (
		<div className={styles.accordionContainer}>
			<div className="px-2">
				<div className="is-flex is-align-items-center">
					<Switch
						width={49}
						height={25}
						onColor={'#3259F1'}
						offColor={'#C9D4FB'}
						handleDiameter={21}
						checkedIcon={false}
						uncheckedIcon={false}
						onChange={() => deactivateAccount()}
						checked={deactivate}
					/>

					<div className={styles.toggleTextWrapper}>
						<p>Deactivate</p>
					</div>
				</div>

				<Button
					variant="secondary"
					className="px-0"
					onClick={() => closeAccount()}
					style={{ width: '100%', marginTop: 20 }}
				>
					Close Account
				</Button>
			</div>

			<DeactivateAccountForm
				visible={showDeactivate}
				onClose={() => {
					Promise.all([setShowDeactivate(false), setDeactivate(!deactivate)]);
				}}
				closeDeactivateModal={closeDeactivateModal}
			/>
		</div>
	);
};
export default withRouter(CloseAccount);
