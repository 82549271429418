import React, { useState } from 'react';
import { PortalWithState } from 'react-portal';
import {
	Hamburger,
	NavDashboard,
	NavFlag,
	NavProfile,
	NavScholarships,
	ProfileMenu,
	ProfileMenuItem,
	ProfileMenuItemText,
	Tick,
} from '@scholly/elements';

import { ScholarshipFeedIndex } from '../../types';

import config from '../../config/config';
import { event } from '../../helpers/AnalyticsHelper';
import styles from './navbar-icons.scss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { checkFeature, useScholarship, SessionContext } from '../../contexts';
import { LayoutContext, ScreenType } from '../layouts/Layout';
import { FLAGS, Routes } from '../../constants';

const NavbarIcons: React.FC = () => {
	const history = useHistory();
	const match = useRouteMatch();
	const { scholarshipApplied, scholarshipSaved, selectedIndex, setselectedIndex, hostedScholarshipApplied } =
		useScholarship();
	const { currentSize } = React.useContext(LayoutContext);

	const [profile, setProfile] = useState(false);
	const [notification, setNotifications] = useState(false);

	const clickProfile = (bool: boolean) => {
		setProfile(bool);
		notification && setNotifications(false);
	};

	const actionClick = (value: string) => () => {
		const regex = '^https?://';
		if (!value.match(regex)) history.push(value);
		else window.open(value, '_blank');
	};

	const handleOpenSaved = () => {
		setselectedIndex(ScholarshipFeedIndex.SAVED);
		event({ category: 'Saved', action: 'Clicks Saved Header button' });
		actionClick(Routes.scholarships.saved)();
	};

	const handleOpenApplied = () => {
		setselectedIndex(ScholarshipFeedIndex.APPLIED);
		event({ category: 'Applied', action: 'Clicks Applied Header button' });
		actionClick(Routes.scholarships.applied)();
	};

	const goToDashboard = () => {
		setselectedIndex(ScholarshipFeedIndex.MATCHED);
		actionClick(Routes.dashboard)();
	};
	const goToFeed = () => {
		setselectedIndex(ScholarshipFeedIndex.MATCHED);
		actionClick(Routes.scholarships.feed)();
	};
	return (
		<div className={styles.navbarIconContainer}>
			{currentSize === ScreenType.MOBILE && (
				<React.Fragment>
					<div onClick={goToDashboard} className={'m-auto'}>
						<NavDashboard isActive={match.path === Routes.dashboard} />
					</div>
					<div onClick={goToFeed} className={'m-auto'}>
						<NavScholarships isActive={match.path === Routes.scholarships.feed} />
					</div>
				</React.Fragment>
			)}
			<div onClick={handleOpenApplied} className={'m-auto'}>
				<Tick
					isActive={selectedIndex === ScholarshipFeedIndex.APPLIED}
					count={getLength([...scholarshipApplied, ...hostedScholarshipApplied])}
				/>
			</div>
			<div onClick={handleOpenSaved} className={'m-auto'}>
				<NavFlag isActive={selectedIndex === ScholarshipFeedIndex.SAVED} count={getLength(scholarshipSaved)} />
			</div>

			<ProfileButton active={profile} onClick={clickProfile} />
		</div>
	);
};

export default NavbarIcons;

function getLength(params: Array<any>) {
	return params.length === 0 ? undefined : params.length;
}

const ProfileButton = ({ active, onClick }) => {
	const { endSession } = React.useContext(SessionContext);
	const history = useHistory();
	const isProfileCategories = checkFeature(FLAGS.PROFILE);
	const actionClick = (value: string, closePortal) => () => {
		const regex = '^https?://';
		if (!value.match(regex)) history.push(value);
		else window.open(value, '_blank');
		closePortal();
	};

	// TODO: Have thr NavProfile and Hamburger function together

	return (
		<PortalWithState onOpen={() => onClick(true)} onClose={() => onClick(false)} closeOnOutsideClick closeOnEsc>
			{({ openPortal, isOpen, portal, closePortal }) => (
				<React.Fragment>
					<div className={styles.profileIcon} onClick={openPortal}>
						<NavProfile isActive={active} />
					</div>
					<div className={`${styles.hamburgerIcon} mt-1`}>
						<Hamburger clickHandler={openPortal} />
					</div>
					{active &&
						isOpen &&
						portal(
							<div className={styles.profileMenuWrapper}>
								<ProfileMenu>
									<ProfileMenuItem
										clickHandler={actionClick(Routes.user.myProfile, closePortal)}
										text={'Profile'}
										icon={'person'}
									/>
									<ProfileMenuItemText text={'Settings'} />
									<ProfileMenuItem
										clickHandler={actionClick(Routes.settings, closePortal)}
										text={'Account Details'}
										icon={'account'}
									/>
									{/* //! The below is excluded from Phase 1 */}
									{/* <ProfileMenuItem
										clickHandler={actionClick(Routes.settingNotification, closePortal)}
										text={'Notification'}
										icon={'ring'}
									/> */}
									{!isProfileCategories && (
										<ProfileMenuItem
											clickHandler={actionClick(Routes.categories, closePortal)}
											text={'Categories'}
											icon={'categories'}
										/>
									)}
									<ProfileMenuItem
										clickHandler={actionClick(config.faq_url, closePortal)}
										text={'FAQs'}
										icon={'faq'}
									/>

									<ProfileMenuItem
										clickHandler={actionClick(config.terms_url, closePortal)}
										text={'Terms and Conditions'}
										icon={'terms'}
									/>
									<ProfileMenuItem
										clickHandler={actionClick(config.privacy_policy_url, closePortal)}
										text={'Privacy Policy'}
										icon={'privacy'}
									/>
									<ProfileMenuItem clickHandler={endSession} text={'Logout'} icon={'logout'} />
								</ProfileMenu>
							</div>
						)}
				</React.Fragment>
			)}
		</PortalWithState>
	);
};
