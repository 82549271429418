import classNames from 'classnames';
import styles from './../offboarding.scss';
import React from 'react';
import { WalletIcon } from '../../../../components/common/icons';
import { Button } from '@scholly/elements';
import Checkmark from '../../../../components/checkmark/checkmark';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../constants';

const AffiliateOffer = () => {
	const history = useHistory();
	const { offboarding } = Routes;
	return (
		<div className={styles.affiliateOffer}>
			<div className={classNames(styles.alignItemsCenter, styles.justifyContentCenter)}>
				<div className={classNames(styles.isFlex, styles.justifyContentCenter)}>
					<WalletIcon />
				</div>
				<div className={classNames(styles.pauseText, styles.affiliateOfferTitle)}>
					Get a <strong>Free lifetime subscription</strong> to Scholly
				</div>
				<div className={classNames(styles.pauseText)}>
					Keep your exclusive membership access but never pay for Scholly again. To unlock, simply open a free
					saving account with our favorite “do-good” savings bank, Aspiration, today!
				</div>
				<div className={styles.affiliateOfferCheckmarkContainer}>
					<Checkmark text={'Get a free $10 sign-up bonus'} textStyle={styles.pauseText} />
					<Checkmark text={'Unlimited fee-free withdrawals at 55,000+ ATMs'} textStyle={styles.pauseText} />
					<Checkmark text={'Up to 10% cash back on debit card purchases'} textStyle={styles.pauseText} />
					<Checkmark text={'Deposits are FDIC insured'} textStyle={styles.pauseText} />
				</div>
				<div className={styles.affiliateOfferButtonContainer}>
					<Button
						variant="primary"
						onClick={() => {
							history.push(offboarding.aspiration);
							history.push(`${offboarding.home}/${offboarding.aspiration}`);
						}}
						className={styles.pauseButton}
					>
						Open a Savings Account
					</Button>
					<Button
						variant="secondary"
						onClick={() => history.push(`${offboarding.home}/${offboarding.pauseMembership}`)}
						className={styles.pauseButton}
					>
						Continue Pausing Account
					</Button>
				</div>
			</div>
		</div>
	);
};

export default AffiliateOffer;
