import React from 'react';
import classNames from 'classnames';
import styles from './loader.scss';
import { colors } from '@scholly/core';

export type Props = {
	/** hex color */
	color?: string;
	/** size in pixel */
	size?: number;
} & React.HTMLAttributes<HTMLDivElement>;

type SpinnerProps = Omit<Props, 'size'>;

export default function Spinner({ color = colors.gray.standard, className, style }: SpinnerProps) {
	const circles = [...Array(12)].map((_, index) => {
		return (
			<div key={index}>
				<div className={classNames(styles.divAfter)} style={{ background: color }}></div>
			</div>
		);
	});

	return (
		<div className={classNames(styles.spinner, className)} style={{ ...style }}>
			{circles}
		</div>
	);
}
