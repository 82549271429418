import { EmailValidationType, PasswordStrengthValidationType } from '@scholly/scholly-types';
import { MessageType, passwordStrengthValidation as passwordValidation } from '@scholly/scholly-ui';

import moment from 'moment';

type isEmailValid = {
	emailErrors: EmailValidationType;
	isValid: boolean;
};

const stringSimilarity = require('string-similarity');

const emailList = [
	'aol.com',
	'att.net',
	'comcast.net',
	'email.com',
	'facebook.com',
	'gmail.com',
	'gmx.com',
	'googlemail.com',
	'google.com',
	'hotmail.com',
	'hotmail.co.uk',
	'icloud.com',
	'mac.com',
	'me.com',
	'mail.com',
	'msn.com',
	'live.com',
	'outlook.com',
	'sbcglobal.net',
	'verizon.net',
	'yahoo.com',
	'yahoo.co.uk',
	'email.com',
	'ymail.com',
];

export type PasswordValidationType = Pick<
	PasswordStrengthValidationType,
	'lengthValid' | 'specialValid' | 'upperCaseValid' | 'numberValid'
> &
	Pick<Partial<PasswordStrengthValidationType>, 'isValueIdentical'>;

export const correctProvider = (email: string) => {
	const matches = stringSimilarity.findBestMatch(email.substring(email.lastIndexOf('@') + 1), emailList);
	if (matches.bestMatch.rating < 1 && matches.bestMatch.rating > 0.5)
		return `${email.substring(0, email.lastIndexOf('@'))}@${matches.bestMatch.target}`;
	return true;
};

// * DEPRECATED THIS HAS BEEN MOVED TO SCHOLLY_UI use => import {passwordStrengthValidation} from '@scholly/scholly-ui'
export const passwordStrengthValidation = (value: string, compareValue: string): PasswordValidationType => ({
	lengthValid: lengthValidation(value, 8),
	specialValid: containsSpecial(value),
	upperCaseValid: containsUpperCase(value),
	numberValid: containsNumber(value),
	isValueIdentical: compareValue === value,
});

export const emailValidation = (value: string): EmailValidationType => ({
	emailValid: validateEmail(value),
	providerValid: correctProvider(value),
});

export const validateEmail = (email: string): boolean => {
	const re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export const validatePhone = (value: string): boolean => {
	const re = /^(\(\d{3}\)|\d{3})[\s-]?\d{3}[\s-]?\d{4}$/;
	return re.test(value);
};
export const lengthValidation = (value: string = '', length: number): boolean => value.length >= length;
export const containsNumber = (value: string = ''): boolean => /(?=.*[0-9])/.test(value);
export const containsSpecial = (value: string = ''): boolean => /\W|_/.test(value);
export const containsLowerCase = (value: string = ''): boolean => /(?=.*[A-Z])/.test(value);
export const containsUpperCase = (value: string = ''): boolean => /(?=.*[A-Z])/.test(value);

export const isEmailValid = (email): isEmailValid => {
	const emailErrors = emailValidation(email);
	const isValid = Object.values(emailErrors).filter((value) => !value).length === 0;
	// LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

	return { emailErrors, isValid };
};

export const isPasswordValid = (password: string, reEnterPassword: string) => {
	// * passwordValidation renamed to prevent issues with files that use deprecated version
	const passwordErrors = passwordValidation(password, reEnterPassword);
	const isPasswordValid = Object.values(passwordErrors).every((value) => value === MessageType.SUCCESS);

	return isPasswordValid;
};

export const isBelow16 = (birthday: string, format = 'YYYY-MM-DD'): boolean => {
	if (birthday === '') return;

	const birthdayInstance = moment(birthday, format).toDate();
	const nowMoment = moment(new Date());

	const years = nowMoment.diff(birthdayInstance, 'years');
	return years < 16;
};

export const isBelow13 = (birthday: string, format = 'YYYY-MM-DD'): boolean => {
	if (birthday === '') return;

	const birthdayInstance = moment(birthday, format).toDate();
	const nowMoment = moment(new Date());

	const years = nowMoment.diff(birthdayInstance, 'years');
	return years < 13;
};
