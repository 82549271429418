import { Offer } from '../types/offers';

export const affiliateUrlParser = (offer: Offer, userId: number, additionalOfferUrl?: string) => {
	let url: any;

	if (additionalOfferUrl) {
		url = new URL(additionalOfferUrl.replace('USERID', userId.toString()));
	} else if (offer.url) {
		url = new URL(offer.url.replace('USERID', userId.toString()));
	}

	if (url && offer.platform === 'impact') {
		url.searchParams.append('SubId2', userId.toString());
		url.searchParams.append('SubId3', 'offers');
	} else if (url && offer.platform === 'tune') url.searchParams.append('aff_sub2', userId.toString());

	return url;
};
