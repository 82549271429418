import * as DATA from '../../../assets/json/dashboard.json';

import { Button } from '@scholly/elements';
import React from 'react';
import { ScholarshipContext, SessionContext } from '../../../contexts';
import { Status } from '../../../constants';
import { getDashboard } from '../../../actions';
import styles from './style.scss';
import useSWR from 'swr';

const options = {
	revalidateOnFocus: false,
	revalidateIfStale: false,
	dedupingInterval: 10000,
};

interface Resource {
	url: string;
	image?: any;
	title: string;
	latest: any[];
	horizontal?: boolean;
	type?: string;
}

export const Resources = () => {
	const [resource, setResource] = React.useState<Resource>();
	const [sections, setSections] = React.useState<Resource[]>();
	const { isAuthenticated } = React.useContext(SessionContext);
	const pullr = isAuthenticated === Status.RESOLVED;
	const { data } = useSWR(['dashbaord', pullr], getDashboard, options);
	const { sortType, sortScholarships, scholarship, ...context } = React.useContext(ScholarshipContext);

	const setData = React.useCallback(async () => {
		const res = data;
		try {
			setSections(res.data);
		} catch (error) {
			console.log(error);
		}
	}, [data]);

	React.useEffect(() => {
		if (data && data.status === 'OK') {
			setData();
		} else {
			const changed = DATA.data.map((d) => {
				return { title: d.title, data: d.latest, horizontal: d.type === 'recent_winners', type: d.type };
			});
			//@ts-ignore
			setSections(changed);
		}
	}, [data, setData]);

	React.useEffect(() => {
		if (sections?.length > 0 && sections.find((s) => s.type === 'posts')) {
			const resource = sections.find((s) => s.type === 'posts');
			if (resource.latest) {
				setResource(resource.latest[0]);
			}
		}
	}, [sections]);

	const getImageUrl = () => {
		return (
			resource?.image?.[0]?.url ||
			'https://images.unsplash.com/photo-1497633762265-9d179a990aa6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80'
		);
	};

	const handleClick = () => {
		window.open(resource?.url, '_blank');
	};
	return (
		<div>
			{resource && (
				<div className={styles.container}>
					<span className={styles.title}>Resources</span>
					<img src={getImageUrl()} style={{ width: 277 }} alt="" />
					<span className={styles.description}>{resource.title}</span>
					<Button variant={'tetiary'} style={{ width: '83%', marginBottom: 24 }} onClick={handleClick}>
						Learn More
					</Button>
				</div>
			)}
		</div>
	);
};
