import * as React from 'react';

import { SVGProps } from 'react';

const Profile = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg width="40px" height="40px" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<circle cx={20} cy={20.958} r={20} fill="#F5F6FA" />
			<path
				d="m11.048 29.958.235-1.817c.166-1.28.939-2.408 2.146-2.866 1.591-.604 4.022-1.317 6.619-1.317 2.597 0 5.027.713 6.618 1.317 1.207.458 1.98 1.585 2.146 2.866l.236 1.817M20.048 19.958a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
				stroke="#3259F1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Profile;
