import React from 'react';

import { Footer } from '@scholly/elements';
import ExternalLink from '../../../assets/Icons/ExternalLink';

import config from '../../../config/config';
import { DrawerStrings, Legal } from '../../../constants';

import styles from './ungated-footer.scss';

const UngatedFooter = () => {
	const _getYear = new Date().getFullYear() + ' ';
	return (
		<Footer.Wrapper className={styles.footerWrapper}>
			<Footer.InnerContainer className={styles.footerContainer}>
				<Footer.Section className={styles.footerLeftSection}>
					<Footer.Logo
						onClick={() => (window.location.href = config.homepage)}
						className={styles.footerLogo}
						src={`${config.cdn}images/scholly-by-sallie-white.png`}
						alt={DrawerStrings.scholly.heading}
						title={DrawerStrings.scholly.heading}
					/>
					<div className={styles.footerLinkWrapper}>
						<Footer.LinkContainer className={styles.footerLinkContainer}>
							<Footer.Link className={styles.footerLink} href={`${config.homepage}/about/`}>
								About Us
							</Footer.Link>
							<Footer.Link className={styles.footerLink} href={config.partner_with_us_url}>
								Partner with Us
							</Footer.Link>
							<Footer.Link className={styles.footerLink} href={config.testimonials_url}>
								Testimonials
							</Footer.Link>
							<Footer.Link className={styles.footerLink} href={config.blog}>
								Blog
							</Footer.Link>
							<Footer.Link className={styles.footerLink} href={config.press_url}>
								Press
							</Footer.Link>
							<Footer.Link className={styles.footerLink} href={`${config.homepage}/careers/`}>
								Careers
							</Footer.Link>
							<Footer.Link className={styles.footerLink} href={`mailto:tiffany@myscholly.com`}>
								Submit A Scholarship
							</Footer.Link>
							<Footer.Link className={styles.footerLink} href={`${config.homepage}/contact-us/`}>
								Contact Us
							</Footer.Link>
						</Footer.LinkContainer>
						<Footer.LinkContainer className={styles.footerLinkContainer}>
							<Footer.Link className={styles.footerLink} href={config.faq_url}>
								FAQ
							</Footer.Link>
							<Footer.Link className={styles.footerLink} href={config.terms_and_conditions_url}>
								Terms of Service
							</Footer.Link>
							<Footer.Link className={styles.footerLink} href={config.scholly_nitro_privacy_policy}>
								Protect Your Privacy
							</Footer.Link>
							<Footer.Link className={styles.footerLink} href={config.do_not_sell_url}>
								Do Not Sell or Share My Personal Information
							</Footer.Link>
						</Footer.LinkContainer>
					</div>
					<Footer.Text className={styles.footerText}>
						&copy; {_getYear}
						{DrawerStrings.scholly.footer}
					</Footer.Text>
				</Footer.Section>
				<Footer.Section className={styles.footerSocialWrapper}>
					<Footer.SocialIconContainer className={styles.footerSocialIconWrapper}>
						<Footer.SocialIcon
							src={`${config.cdn}images/social/facebook-white-3x.png`}
							alt={DrawerStrings.scholly.heading}
							title={DrawerStrings.scholly.heading}
							href={config.social.facebook}
						/>
						<Footer.SocialIcon
							src={`${config.cdn}images/social/instagram-white-3x.png`}
							alt={DrawerStrings.scholly.heading}
							title={DrawerStrings.scholly.heading}
							href={config.social.instagram}
						/>
						<Footer.SocialIcon
							src={`${config.cdn}images/social/twitter-white-3x.png`}
							alt={DrawerStrings.scholly.heading}
							title={DrawerStrings.scholly.heading}
							href={config.social.twitter}
						/>
					</Footer.SocialIconContainer>

					<Footer.StoreIconContainer className={styles.footerStoreIcon}>
						<Footer.StoreIcon
							src={`${config.cdn}images/app-store-download.png`}
							alt={DrawerStrings.scholly.heading}
							title={DrawerStrings.scholly.heading}
							href={config.app_store}
							height={33}
							width={100}
						/>

						<Footer.StoreIcon
							src={`${config.cdn}images/google-play-download.png`}
							alt={DrawerStrings.scholly.heading}
							title={DrawerStrings.scholly.heading}
							href={config.play_store}
							height={30}
							width={100}
						/>
					</Footer.StoreIconContainer>
				</Footer.Section>
			</Footer.InnerContainer>
			<div className={styles.legalSection}>
				<ExternalLink />
				<p>{Legal.ExternalLink}</p>
			</div>
		</Footer.Wrapper>
	);
};

export default UngatedFooter;
