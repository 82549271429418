import * as React from 'react';
import { UserUpdateFirstLastNameType } from '@scholly/scholly-types';
import { Input } from '@scholly/elements';

import { ScreenStrings } from '../../constants';

import styles from './profile.scss';

type Props = UserUpdateFirstLastNameType & {
	onChangeText: (property: keyof UserUpdateFirstLastNameType) => (value: string) => void;
	hasError?: (value: string, input: any) => boolean;
};

const ProfileFirstLastName = (props: Props) => {
	return (
		<div className={styles.profileWrapper}>
			<div className="">
				<h3>{ScreenStrings.profile.eminem}</h3>
			</div>
			<div className={styles.profileContainer}>
				<div>
					<div className={styles.profileLabel}>{ScreenStrings.profile.firstName}</div>
					<Input
						className={styles.profileInput}
						variant="text"
						name="first_name"
						// testID={`form_input_first_name`}
						autoFocus={true}
						// placeholder={ScreenStrings.profile.firstName}
						value={props.first_name}
						// @ts-ignore
						onChange={props.onChangeText('first_name')}
						hasError={props.hasError('first_name', props.first_name)}
					/>
				</div>
				<div>
					<div className={styles.profileLabel}>{ScreenStrings.profile.lastName}</div>
					<Input
						className={styles.profileInput}
						// testID={`form_input_last_name`}
						// placeholder={ScreenStrings.profile.lastName}
						name="last_name"
						value={props.last_name}
						variant="text"
						// @ts-ignore
						onChange={props.onChangeText('last_name')}
						hasError={props.hasError('last_name', props.last_name)}
					/>
				</div>
			</div>
		</div>
	);
};

export default ProfileFirstLastName;
