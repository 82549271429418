import React, { useContext } from 'react';
import { Button } from '@scholly/elements/';

import { Feature, OfferContext, UserContext } from '../../contexts';
import Affiliate from '../affiliate/index';
import config from '../../config/config';
import ApplyToAffiliate from '../apply-to-affiliate/apply-to-affiliate';
import classNames from 'classnames';
import subStyles from '../scholarship/scholarship.scss';
import styles from './styles.scss';
import { FLAGS } from '../../constants';
import ButtonStrings from '../../constants/ButtonStrings';

const LIST_SIZE = 6;

export const Offers = () => {
	const { displayedOffers } = React.useContext(OfferContext);
	const [offer, setOffer] = React.useState(false);
	const [showAll, setShowAll] = React.useState(false);
	const session = useContext(UserContext);

	const handleShowAll = () => {
		setShowAll(!showAll);
	};

	const onClickInfo = (slug: string, name: string) => () => {
		window.open(config.offers_url + slug, '_blank');
	};

	const onClickApply = (offer, name: string) => () => {
		setOffer(offer);
	};

	const handleSLMCardActionClick = () => {
		window.open(
			'https://apply.salliemae.com/W2WPortal?NavPoint=APPLY&LoanFinder=N&RefID=1009052000&dtd_cell=SMLRSOPANLNLEFOTAGZ1005N010160&utm_source=scholly&utm_medium=aff&utm_campaign=actaff_schly_u_u_u_u_u_b&utm_content=AppAN&TPID=' +
				session.user_id,
			'_blank'
		);
	};

	const handleSLMCardInfoClick = () => {
		window.open(
			'https://www.salliemae.com/landing/student-loans/?dtd_cell=SMLRSOPANLNLEFOTAGZ1005N010160&utm_source=scholly&utm_medium=aff&utm_campaign=actaff_schly_u_u_u_u_u_b&utm_content=AppLM&TPID=' +
				session.user_id,
			'_blank'
		);
	};

	return (
		<div className={styles.offersLandingWrapper}>
			<section className={styles.ctaSectionWrapper}>
				<p className={styles.ctaBodyDesktop}>
					Start earning cash with Scholly by discovering our trusted partners.
				</p>

				<div className={styles.affiliateCardsContainer}>
					<>
						<Feature name={FLAGS.SALLIE_CARD}>
							<Affiliate
								key={9989}
								affiliateName="Sallie Mae"
								avatarImg="salliemae-avatar.png"
								offerAffiliateSubtext="Student Loans"
								status={'active'}
								color={'134F82'}
								guaranteedCashAmount="Get the money you need to pay for your education"
								amountDisplay="display"
								isQualifiedFor={true}
								entryString={'College Loan'}
								offerCta="Apply Now"
								infoCta="Learn More"
								opportunityType={'MAKE_MONEY'}
								onClickApply={() => {
									handleSLMCardActionClick();
								}}
								onClickInfo={() => {
									handleSLMCardInfoClick();
								}}
							/>
						</Feature>
						{Array.isArray(displayedOffers) &&
							displayedOffers.map((offer, index) => {
								const {
									id,
									affiliate,
									affiliate_subtext,
									amount,
									incentive_amount,
									status,
									display,
									start_time,
									end_time,
									offer_cta,
									offer: mainOffer,
									category,
								} = offer || {};

								const { avatar_img, color, slug, display: affiliate_display } = affiliate || {};

								if (!showAll && index >= LIST_SIZE) return <></>;

								return (
									<Affiliate
										key={id}
										affiliateName={affiliate_display}
										avatarImg={avatar_img}
										offerAffiliateSubtext={affiliate_subtext}
										status={status}
										color={color}
										guaranteedCashAmount={mainOffer}
										amountDisplay={display}
										isQualifiedFor={status !== 'not_matched'}
										startTime={start_time}
										endTime={end_time}
										entryAmount={amount + incentive_amount}
										offerCta={offer_cta}
										opportunityType={category}
										onClickApply={onClickApply(offer, affiliate_display)}
										onClickInfo={onClickInfo(slug, affiliate_display)}
									/>
								);
							})}
					</>
				</div>
			</section>

			{!showAll && displayedOffers?.length > LIST_SIZE && (
				<div
					className={classNames(
						'mb-8',
						subStyles.isFlex,
						subStyles.alignItemsCenter,
						subStyles.justifyContentCenter
					)}
				>
					<Button onClick={handleShowAll} variant={'secondary'} style={{ width: 327 }}>
						{ButtonStrings.viewAllOffers}
					</Button>
				</div>
			)}
			<ApplyToAffiliate
				offer={offer}
				isOpen={!!offer}
				close={() => setOffer(false)}
				onClickGoToAffiliate={() => {
					setOffer(false);
				}}
			/>
		</div>
	);
};
