import { Button, Calendar26px, Input, InputType, Letter26px, Lock26px } from '@scholly/elements';
import { Typography, Colors } from '@scholly/scholly-ui';
import { EmailValidationType } from '@scholly/scholly-types';
import * as React from 'react';
import { View } from 'react-native';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Switch from 'react-switch';
import moment from 'moment';
import classNames from 'classnames';

import { BrandingContext } from '../../contexts';

import { emailValidation, passwordStrengthValidation, validateEmail } from '../../helpers/Validation';
import { Routes, ScreenStrings, ButtonStrings } from '../../constants';

import style from './branding.scss';

type IProps = {
	loading: boolean;
	onSignUp: (data: any) => void;
	display: (obj: any) => void;
	isSubmitted: boolean;
};

type IState = {
	errorMsg: string | null;
	emailErrors: EmailValidationType;
	isPasswordValid: boolean;
	isEmailValid: boolean;
	isCheckEmailValid: boolean;
	isEmailTouched: boolean;
	checked: boolean;
	isShowPasswordHint: boolean;
	show_password: boolean;
	passwordMessageType: 'hint' | 'error';
};

type StateTextType = {
	email: string;
	password: string;
	repassword: string;
	birthday: string;
	ref_data?: string;
	ref_data2?: string;
	opt_in?: string;
	opt_in_display?: string;
	join_code?: string;
};

export default class RegisterForm extends React.Component<IProps, IState & StateTextType> {
	static contextType = BrandingContext;
	state: IState & StateTextType = {
		email: '',
		password: '',
		repassword: '',
		birthday: '',
		ref_data: '',
		ref_data2: '',
		opt_in: '0',
		opt_in_display: '',
		join_code: '',
		errorMsg: null,
		show_password: false,
		isPasswordValid: false,
		isEmailValid: true,
		isEmailTouched: false,
		isCheckEmailValid: false,
		isShowPasswordHint: false,
		passwordMessageType: 'hint',
		checked: false,
		emailErrors: {
			emailValid: false,
			providerValid: true,
		},
	};

	isBelow16(birthday) {
		if (birthday === '') return;

		const birthdayInstance = moment(birthday, 'YYYY-MM-DD').toDate();
		const nowMoment = moment(new Date());

		const years = nowMoment.diff(birthdayInstance, 'years');

		return years < 16;
	}

	handlePasswordFocus = () => {
		this.setState({ isShowPasswordHint: true });
	};

	handlePasswordBlur = () => {
		this.setState({ isShowPasswordHint: false, isPasswordValid: true });
	};

	handleEmailBlur = () => {
		this.setState({ isEmailTouched: true });
	};

	isDisabled = () => {
		let bool = !validateEmail(this.state.email) || !this.state.password || !this.state.birthday;
		return bool || (this.context.require_registration_code && !this.state.join_code);
	};

	signup = () => {
		if (!this.isPasswordValid() || !this.isEmailValid()) return;
		let incomplete = false;
		const { email, password, birthday, ref_data, ref_data2, join_code, opt_in } = this.state;
		const branding = this.context;

		let data = {
			email,
			password,
			birthday,
		};

		if (ref_data && typeof ref_data === 'object') {
			//@ts-ignore
			Object.assign(data, { ref_data: ref_data.value });
		}
		if (branding.ref_data2 && ref_data2 !== '') {
			data['ref_data2'] = ref_data2;
		}
		if (branding.require_registration_code && join_code !== '') {
			data['join_code'] = join_code;
		} else {
			if (branding.registration_code) {
				data['join_code'] = branding.registration_code.code;
			}
		}

		if (branding.opt_in || branding.opt_in_display) {
			data['opt_in'] = opt_in;
			data['ref'] = 'microsite';
			data['ref_data'] = branding.id;
		}

		if (branding.dropdown_options && typeof !data['ref_data']) {
			incomplete = true;
		}
		if (branding.ref_data2 && !data['ref_data2']) {
			incomplete = true;
		}
		if (branding.require_registration_code && !data['join_code']) {
			incomplete = true;
		}
		if (incomplete) {
			this.setState({ errorMsg: 'Oh No! Looks like some fields are missing.' });
			return;
		}

		if ('join_code' in data && branding.registration_code.code !== data['join_code']) {
			this.props.display({
				type: 'error',
				text: 'Scholly Code does not match.',
				shouldShowAsHeader: true,
			});
			return;
		}

		this.props.onSignUp(data);
	};

	onChangeText = (property: keyof StateTextType) => (value: any) => {
		this.setState({ [property]: value } as Pick<StateTextType, keyof StateTextType>, () => {
			if (!this.state.isPasswordValid) this.isPasswordValid();
			if (property == 'email') this.isEmailValid();
		});
	};

	isPasswordValid = (): boolean => {
		const passwordErrors = passwordStrengthValidation(this.state.password, this.state.password);
		const isPasswordValid = Object.values(passwordErrors).filter((value) => !value).length === 0;
		this.setState({ isPasswordValid });
		return isPasswordValid;
	};

	isEmailValid = (): boolean => {
		const emailErrors = emailValidation(this.state.email);
		const isEmailValid = Object.values(emailErrors).filter((value) => !value).length === 0;
		this.setState({ emailErrors, isEmailValid });
		return isEmailValid;
	};

	setShow = (property: keyof { show_password: boolean; show_repassword: boolean }) => (bool: any) =>
		this.setState({ [property]: bool } as Pick<IState, keyof IState>);

	onChange = (bool: any) => this.setState({ checked: bool, opt_in: bool ? '1' : '0' });

	render() {
		const { email, password, birthday, ref_data, ref_data2, join_code, checked, errorMsg } = this.state;
		const { isSubmitted, loading } = this.props;
		const branding = this.context;

		let customDropdownOptions = [];
		if (branding.dropdownOptions) {
			branding.dropdownOptions.forEach((s: string) => {
				customDropdownOptions.push({ value: s });
			});
		}

		return (
			<div className={style.cont}>
				{branding && <div className={style.subText} dangerouslySetInnerHTML={{ __html: branding.subtext }} />}

				<View style={{ flexDirection: 'column', width: '100%' }}>
					{errorMsg && (
						<div className={style.error}>
							<p className="has-text-centered mb-1">{errorMsg}</p>
						</div>
					)}
					<div className="mb-5 mt-5">
						{/* DOB */}
						<Input
							placeholder="Date of Birth *"
							name="birthday"
							variant="text"
							value={birthday}
							className={classNames(style.w100)}
							onChange={this.onChangeText('birthday')}
							Icon={<Calendar26px />}
							validationCriteria={{
								inputVal: birthday,
								type: InputType.Birthday,
							}}
							staticPlaceholder
						/>
					</div>
					<div
						className="mb-5"
						onBlur={() => this.setState({ isCheckEmailValid: true }, () => this.isEmailValid())}
					>
						<Input
							placeholder={ScreenStrings.auth.email}
							name="email"
							variant="email"
							value={email}
							className={classNames(style.w100)}
							onChange={this.onChangeText('email')}
							Icon={<Letter26px />}
							staticPlaceholder
						/>
						{/* <EmailInput
								testID={'branding-email'}
								placeholder={ScreenStrings.auth.email}
								value={email}
								onBlur={this.handleEmailBlur}
								onChangeText={this.onChangeText('email')}
								isError={
									(isEmailTouched && !isEmailValid)
								}
							/> */}
					</div>
					<div className="mb-5">
						<Input
							placeholder={ScreenStrings.auth.password}
							name="password"
							variant="password"
							value={password}
							className={classNames(style.w100)}
							onChange={this.onChangeText('password')}
							Icon={<Lock26px />}
							validationCriteria={{
								testID: 'testID_password_validation',
								inputVal: password,
								type: InputType.Password,
							}}
							staticPlaceholder
						/>
						{/* <PasswordInput
								handlePassword={this.onChangeText('password')}
								value={password}
								placeholder={ScreenStrings.auth.password}
								onFocus={this.handlePasswordFocus}
								onBlur={this.handlePasswordBlur}
								isError={passwordMessageType === 'error' && !isPasswordValid}
								showValidation={isShowPasswordHint}
								validationCriteria={{
									messages: SINGLE_PASSWORD_CHECKS,
									validationTestID: 'testID_password_validation',
									passwordVal: password,
									repasswordVal: password,
									type: passwordMessageType,
								}}
							/> */}
					</div>

					{branding.dropdown_label && (
						<div className="mb-5">
							<Select
								placeholder={branding.dropdown_label}
								value={ref_data || null}
								styles={customStyles}
								getOptionLabel={(option) => option.value}
								getOptionValue={(option) => option.value}
								options={customDropdownOptions}
								onChange={this.onChangeText('ref_data')}
							/>
						</div>
					)}
					{branding.ref_data2 && (
						<div className="mb-5">
							<Input
								placeholder={branding.ref_data2}
								name="ref_data2"
								variant="text"
								value={ref_data2}
								className={classNames(style.w100)}
								onChange={this.onChangeText('ref_data2')}
								staticPlaceholder
							/>
						</div>
					)}

					{branding.require_registration_code && (
						<div className="mb-5">
							<Input
								placeholder={ScreenStrings.account.schollyCode}
								name="join_code"
								variant="text"
								value={join_code}
								className={classNames(style.w100)}
								onChange={this.onChangeText('join_code')}
								staticPlaceholder
							/>
						</div>
					)}
					{branding.opt_in_display && (
						<div className="my-1 mb-5" style={{ display: 'flex', justifyContent: 'left' }}>
							<Switch
								width={34}
								height={20}
								onColor={'#3259f1'}
								checkedIcon={false}
								uncheckedIcon={false}
								onChange={this.onChange}
								checked={checked}
							/>

							<div
								className={style.caption2}
								dangerouslySetInnerHTML={{ __html: branding.opt_in_display }}
							/>
						</div>
					)}

					<Button
						style={{ width: '100%', boxSizing: 'border-box' }}
						variant="primary"
						onClick={this.signup}
						disabled={this.isDisabled() || isSubmitted}
						isLoading={loading}
						type="button"
					>
						{ButtonStrings.signUp}
					</Button>
				</View>

				<div className="my-1" style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }}>
					<Link to={Routes.login}>
						<Typography variant="caption2" family="medium" style={{ opacity: 1 }}>
							Already have an account?
						</Typography>
					</Link>
				</div>
			</div>
		);
	}
}

const dot = (color = 'transparent') => ({
	alignItems: 'center',
	display: 'flex',
});

const customStyles = {
	singleValue: (styles) => ({ ...styles, ...dot() }),
	control: () => ({
		backgroundColor: Colors.white,
		display: 'flex',
		color: '#a0a3bd',
		borderRadius: 6,
		fontSize: 14,
		border: 0,
		padding: '5px 0',
		boxShadow: '0px 4px 11px rgb(0 0 0 / 8%)',
		borderColor: Colors.white,
		cursor: 'pointer',
	}),
	placeholder: (base) => ({
		...base,
		color: '#a0a3bd',
	}),
	container: (styles, { isFocused, hasValue }) => {
		return {
			...styles,
			border: isFocused
				? `2px solid #3259F1
			`
				: hasValue
				? '1px solid #a0a3bd'
				: 'none',
			borderRadius: 6,
			boxShadow: '0px 4px 11px rgb(0 0 0 / 8%)',
		};
	},
	indicatorSeparator: (styles) => {
		return {
			...styles,
			display: 'none',
		};
	},
	dropdownIndicator: (styles, { isFocused, isSelected }) => {
		return {
			...styles,
			transform: isFocused || isSelected ? 'rotate(180deg)' : null,
			transition: 'all .2s ease',
			color: '#000',
		};
	},
	option: (styles, { isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: isDisabled ? null : isSelected ? Colors.white : isFocused ? Colors.whiteTwo : null,
			color: isDisabled ? '#ccc' : isSelected ? '#000' : Colors.greyishBrown,
			cursor: isDisabled ? 'not-allowed' : 'default',
			fontSize: 14,
			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled && isSelected && Colors.greyWithOpacity,
			},
			':hover': {
				background: '#3259F1',
				borderRadius: '8px',
				color: '#fff',
			},
		};
	},
};
