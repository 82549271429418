import classNames from 'classnames';
import React, { FC } from 'react';
import SavedIcon from '../../../components/common/icons/saved-icon';
import ShareIcon from '../../../components/common/icons/share-icon';

import style from './style.scss';
import moment from 'moment';
import config from '../../../config/config';

interface ImageProps {
	image: string;
	checked?: boolean;
	handleSave: () => void;
	handleShare: () => void;
	title: string;
	deadline: string | number;
}

export const CardDetailImage: FC<ImageProps> = (props) => {
	const { image, handleSave, handleShare, title, deadline } = props;
	const leftDate = moment(deadline).fromNow().replace('in ', '') + ' Left';
	return (
		<div className={classNames(style.imageContainer)}>
			<div
				style={{ backgroundImage: `url(${config.cdn}images/hosted-scholarships/${image})` }}
				className={style.topSection}
			>
				<div className={style.iconContainer}>
					<div className={style.badge}>{leftDate}</div>
					<div className={style.rightIconsContainer}>
						<ShareIcon backgroundColor={'#fff'} onClick={handleShare} />
						<SavedIcon backgroundColor={'#fff'} onClick={handleSave} />
					</div>
				</div>
			</div>
			<div className={style.bottomSection}>
				<div className={style.bottomSectionWrapper}>
					<div className={style.cardTitleContainer}>
						<h2 className={style.cardTitle}>{title}</h2>
					</div>
				</div>
			</div>
		</div>
	);
};
