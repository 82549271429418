import React from 'react';
const ImageHolder = ({ src }) => {
	return (
		<div style={{ width: '100%' }}>
			<div style={{ width: '20%', margin: '0 auto' }}>
				<img style={{ width: '100%', display: 'block', maxWidth: '100%' }} src={src} alt="" />
			</div>
		</div>
	);
};
export default ImageHolder;
