import * as React from 'react';
import { Button, ErrorMessage } from '@scholly/elements';

import { UserContext } from '../../contexts';

import { EmailInput } from '../inputs/Email';
import { PasswordInput } from '../inputs/Password';

import { ScreenStrings, ButtonStrings } from '../../constants';

import { validateEmail } from '../../helpers/Validation';

import authStyles from './styles.scss';
import styles from '../../pages/authentication/authentication.scss';

type IProps = {
	isLoginValid: boolean;
	errorMsg: { field: string | null; message: string };
	onLogin: (e: string, p: string) => void;
	isLoading?: boolean;
	isRefundPage?: boolean;
};

const LoginForm: React.FC<IProps> = (props) => {
	const { clear } = React.useContext(UserContext);
	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');
	const { errorMsg, isRefundPage } = props;
	const [isSubmitted, setIsSubmitted] = React.useState(false);

	React.useEffect(() => {
		setIsSubmitted(false);
	}, [email, password]);

	const login = () => {
		clear();
		props.onLogin(email, password);
		setIsSubmitted(true);
	};

	const isDisabled = () => (checkAutoFill() ? false : !validateEmail(email) || !password);

	const checkAutoFill = () => {
		try {
			return (
				document.getElementById('email')?.matches(':-internal-autofill-selected') &&
				document.getElementById('password')?.matches(':-internal-autofill-selected')
			);
		} catch (e) {
			return false;
		}
	};

	const getErrorMessage = () => {
		if (errorMsg.message === 'Email not found' || errorMsg.message === 'Password does not match') {
			return ScreenStrings.auth.errorLoginData;
		}
		return errorMsg.message;
	};

	return (
		<div className={styles.loginFormWrapper}>
			<div className={styles.authFormFieldWrapper}>
				{errorMsg?.field && errorMsg?.message && (
					<ErrorMessage className={authStyles.loginError}>{getErrorMessage()}</ErrorMessage>
				)}
				<EmailInput
					onChangeText={setEmail}
					value={email}
					testID={'email'}
					placeholder={ScreenStrings.auth.email}
					isError={!!errorMsg?.message}
				/>

				<PasswordInput
					value={password}
					handlePassword={setPassword}
					placeholder={ScreenStrings.auth.password}
					testID={'password'}
					isError={!!errorMsg?.message}
				/>
			</div>

			{isRefundPage && (
				<p className={styles.refundTerms}>
					&#42;If you are eligible for a refund, to maintain eligibility, you must log in before January 31,
					2024
				</p>
			)}
			<Button
				style={{ width: '100%', boxSizing: 'border-box' }}
				variant="primary"
				onClick={login}
				disabled={isDisabled() || (isSubmitted && props.isLoading)}
				isLoading={props.isLoading}
			>
				{ButtonStrings.signIn}
			</Button>
		</div>
	);
};

export default LoginForm;
