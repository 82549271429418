import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import { Button, Cal, Divider, PiggyBank } from '@scholly/elements';

import { ScholarshipContext, UserContext, progressTypeToApplied } from '../../contexts';
import { FlashContext } from '../../contexts/FlashContext';
import { sendScholarshipFeedback } from '../../actions';

import { event } from '../../helpers/AnalyticsHelper';

import { CardDetailImage } from './components/Image';
import { CardHeader } from './components/CardHeader';
import { CardNavigation } from './components/CardNavigation';
import { CustomModal } from '../../components/modal/Modal';
import { Effort } from '../../components/common/Effort/Effort';
import { Resources } from '../../components/common/Resources/Resources';
import { ScholarshipLoader } from './ScholarshipLoader';
import { StatusDropdown } from '../../components/common/StatusDropdown/StatusDropdown';
import RedirectScholarship from './RedirectScholarship';
import { Feedback } from './Feedback';

import { Routes, EventStrings, ScreenStrings } from '../../constants';
import { ScholarshipStatus } from '../../types';

import styles from './scholarship.scss';

interface RouteParams {
	slug: string;
}

const Scholarship: React.FC<RouteComponentProps<RouteParams>> = ({ history, location, match }) => {
	const [slug, setSlug] = useState('');
	const [scholarship, setScholarship] = useState(null);
	const [dismissTitle, setDismissTitle] = useState(null);
	const [isModalVisible, setModalVisible] = useState(false);
	const [isDismiss, setDismiss] = useState(false);
	const [isExclusive, setIsExclusive] = useState(true);
	const [isShowRedirectModal, setIsShowRedirectModal] = useState(false);

	const userContext = React.useContext(UserContext);
	const flash = React.useContext(FlashContext);
	const scholarshipContext = React.useContext(ScholarshipContext);

	const openScholarship = () => {
		event({
			category: 'View Application',
			action: `Views ${scholarship.name}`,
		});
		setIsShowRedirectModal(true);
	};
	const _renderDate = () => {
		if (progressTypeToApplied.includes(scholarship.progress)) {
			return `Applied on: ${moment(scholarship.apply_time).format('MM/DD/YYYY')}`;
		}

		return moment(scholarship.deadline).format('MM/DD/YYYY');
	};

	const handleSave = () => {
		if (scholarship.progress !== 'saved') {
			handleChangeProgress(scholarship, { progress: 'saved' });
		} else {
			scholarshipContext.removeSavedScholarship(scholarship);
			delete scholarship.progress;
		}
	};

	const handleChangeProgress = async (scholarship, params) => {
		if (scholarship && params) {
			scholarshipContext.updateScholarship(scholarship, params);
			setScholarship({ ...scholarship, ...params });
		}
	};
	const _handleFeedbackReason = async (itemId: string, reasonId: number) => {
		// @ts-ignore
		location.state.showApplyModal = false;
		try {
			await sendScholarshipFeedback({
				scholarship_id: itemId,
				scholarship_did_not_apply_reason_id: reasonId,
			});
			flash.display({ text: 'All set! Feedback was sent', type: 'success', disableScrollTop: true });
		} catch (error) {
			flash.display({ text: error.message, type: 'error', disableScrollTop: true });
		}
		userContext.getAndSetUser();
	};

	const _handleRemoveReason = async (itemId, reasonId) => {
		flash.display({ text: 'All set! Feedback was sent', type: 'success', disableScrollTop: true });
		try {
			await scholarshipContext.removeMatchedScholarship(
				{
					scholarship_id: itemId,
					scholarship_remove_reason_id: reasonId,
				},
				true
			);
			setTimeout(() => {
				history.push(Routes.scholarships.feed);
			}, 700);
		} catch (error) {
			flash.display({ text: error.message, type: 'error', disableScrollTop: true });
		}
	};

	const openDismissModal = (visible: boolean, isDismiss?: boolean) => {
		if (isDismiss) {
			event({
				category: 'Dismiss (Scholarship Page)',
				action: 'Dismisses Scholarship',
			});
			setDismiss(isDismiss);
			setDismissTitle(ScreenStrings.scholarship.dismissScholarship);
		} else {
			setDismiss(false);
			setDismissTitle(ScreenStrings.scholarship.didYouApply);
		}
		setModalVisible(visible);
	};

	const goBack = () => {
		const { state } = location;
		event({
			category: 'Back Button (Scholarship Page)',
			action: 'Go back to Scholarships Feed',
		});
		// @ts-ignore
		if (state && state.prevLocation) {
			// @ts-ignore
			history.replace(state.prevLocation, { refresh: false });
		} else {
			history.replace(Routes.scholarships.feed, { refresh: false });
		}
	};
	const _renderAmount = (item: any) => {
		let amount = `$${item.amount
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
			.replace(/\.00$/, '')}`;
		if (item.reward_type == '2' && (item.amount === 0 || item.amount == '0.00')) {
			amount = 'Varies';
		}

		if (item.reward_type === '1') {
			amount = 'Full Ride';
		}

		return amount;
	};

	const handleCloseModal = () => {
		setIsShowRedirectModal(false);
		setModalVisible(false);
	};

	const handleNextScholarship = () => {
		const scholarships = scholarshipContext.scholarships.filter((scholarship) => !!scholarship.slug);
		const currentIndex = scholarships.findIndex((item) => item.slug === slug);
		const item = scholarships[currentIndex + 1];

		if (item?.slug) {
			openNewScholarship(item);
		} else if (!scholarshipContext.matchedMeta.links.next) {
			openNewScholarship(scholarships[0]);
		}

		if (item?.read_status === ScholarshipStatus.UNREAD || item?.read_status === ScholarshipStatus.NEW) {
			scholarshipContext.setScholarshipRead(item?.id);
		}

		if (currentIndex === scholarships.length - 1) {
			scholarshipContext.paginateScholarships();
		}
	};

	const openNewScholarship = (item) => {
		setScholarship(item);
		setSlug(item.slug);
		history.push(`/scholarships/${item.slug}`, item);
	};

	useEffect(() => {
		window.scroll({ top: 0 });
	}, []);

	useEffect(() => {
		if (typeof location.state === 'object') {
			const { state } = location;
			setScholarship(state);
			// @ts-ignore
			if (state.showApplyModal && state.progress !== 'applied') {
				setTimeout(() => {
					openDismissModal(true, false);
				}, 400);
			}
		}

		const { slug } = match.params;
		setSlug(slug);
		if (scholarship !== location.state) {
			scholarshipContext.getScholarship(slug);
			setScholarship(location.state);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		const { slug } = match.params;
		setSlug(slug);
		if (scholarshipContext.scholarship?.slug !== slug && typeof scholarship === 'undefined') {
			scholarshipContext.getScholarship(slug);
		}
		if (scholarshipContext.scholarship?.slug === slug) {
			setScholarship(scholarshipContext.scholarship);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scholarshipContext.scholarship]);

	if (!scholarship) return <ScholarshipLoader isExclusive={isExclusive && scholarship?.banner_img} />;

	return (
		<React.Fragment>
			<div className={classNames(styles.root)}>
				<div className={styles.container}>
					<CardNavigation handleNext={handleNextScholarship} handleBack={goBack} />
					<div className={styles.contentWrapper}>
						<div className={styles.mainContainer}>
							{isExclusive && scholarship.banner_img ? (
								<CardDetailImage
									handleSave={() => {}}
									handleShare={() => {}}
									image={scholarship?.banner_img}
									title={scholarship?.name}
									deadline={scholarship?.deadline}
								/>
							) : (
								<CardHeader
									title={scholarship.name}
									handleSave={handleSave}
									progress={scholarship.progress}
								/>
							)}
							<div className={styles.card}>
								<div className={styles.cardInnerHeader}>
									<div className={styles.contentBlock}>
										<div className={styles.iconContainer}>
											{/* @ts-ignore */}
											<PiggyBank />
										</div>
										<div>
											<p className={styles.cardHeaderLabel}>Award</p>
											<p className={styles.cardHeaderFigure}>{_renderAmount(scholarship)}</p>
										</div>
									</div>
									<div className={styles.contentBlock}>
										<div className={styles.iconContainer}>
											{/* @ts-ignore */}
											<Cal />
										</div>
										<div className={styles.cardHeaderLabel}>
											<p className={styles.cardHeaderLabel}>Deadline</p>
											<p className={styles.cardHeaderFigure}>{_renderDate()}</p>
										</div>
									</div>
									<div className={styles.contentBlock}>
										<div className={styles.cardHeaderLabel}>
											<p className={styles.cardHeaderLabel}>Effort</p>
											<Effort effortScore={scholarship?.effort} />
										</div>
									</div>
								</div>

								<div className={styles.cardInnerBody}>
									<Button
										disabled={progressTypeToApplied.includes(scholarship.progress)}
										className={styles.applyButton}
										onClick={openScholarship}
									>
										Apply
									</Button>
									<div className={styles.cardDescription}>
										<h3 className={styles.cardTitle}>About</h3>
										<span>{scholarship.description}</span>
									</div>

									<div className={styles.tablet}>
										<Divider width={'100%'} marginTop={'20'} marginBottom={'46'} />
									</div>
									<div className={styles.tabletStatus}>
										<StatusDropdown
											changeStatus={handleChangeProgress}
											item={scholarship}
											placeholder={scholarship.progress}
										/>
									</div>
									<div className={styles.tablet}>
										<Divider width={'100%'} marginTop={'46'} marginBottom={'46'} />
									</div>
								</div>
							</div>
						</div>

						<div className={styles.sideContainer}>
							<div className={styles.sideStatusContainer}>
								<StatusDropdown changeStatus={handleChangeProgress} item={scholarship} />
							</div>

							<Resources />
						</div>
					</div>
				</div>
				<CustomModal visible={isShowRedirectModal}>
					{!isModalVisible && isShowRedirectModal && (
						<RedirectScholarship
							onClose={handleCloseModal}
							onShowFeedbackModal={() => setModalVisible(true)}
						/>
					)}
					{isModalVisible && (
						<Feedback
							item={scholarship}
							handleSave={() => handleSave()}
							handleApplied={handleChangeProgress}
							handleRemove={_handleRemoveReason}
							handleFeedback={_handleFeedbackReason}
							onClose={handleCloseModal}
						/>
					)}
				</CustomModal>
			</div>
		</React.Fragment>
	);
};

export default withRouter(Scholarship);
