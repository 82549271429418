import React from 'react';
import Switch from 'react-switch';

import { checkFeature, useFeatures } from '../../contexts';

import { SubHeader } from '../../components/layouts/SubHeader';

import { FLAGS } from '../../constants/Features';

import styles from './settings.scss';

const ToggleRow = ({ feature }) => {
	const { toggleFeature } = useFeatures();

	const onChange = () => toggleFeature(feature);
	return (
		<div className="mb-4 is-flex is-align-items-center">
			<Switch
				width={49}
				height={25}
				onColor={'#3259F1'}
				offColor={'#C9D4FB'}
				handleDiameter={21}
				checkedIcon={false}
				uncheckedIcon={false}
				onChange={onChange}
				checked={checkFeature(feature)}
			/>

			<div className={styles.toggleTextWrapper}>
				<p>{humanize(feature)}</p>
			</div>
		</div>
	);
};

const Debug = ({ onBack }) => {
	return (
		<>
			<SubHeader title="Debug Menu" className={styles.subheader} onBack={onBack} />
			<div className={styles.settingsDiv}>
				<div className={styles.title}>Features</div>

				<div className="pl-4">
					{Object.entries(FLAGS).map(([key, value]) => (
						<ToggleRow feature={value} />
					))}
				</div>
			</div>
		</>
	);
};

export default Debug;

function humanize(str) {
	var i,
		frags = str.split('_');
	for (i = 0; i < frags.length; i++) {
		frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
	}
	return frags.join(' ');
}
