import React from 'react';
import classNames from 'classnames';

import styles from './layout.scss';

interface Props {
	title?: string;
	skip?: boolean;
	onSkip?: () => void;
	onBack?: () => void;
	hideBackButton?: boolean;
	primaryColorBg?: boolean;
	className?: string;
	isFreeAccountScreen?: boolean;
}

// TODO:
// TECH DEBT
// Refactor Styling and classes
// Subheader should have its own directory with js/ts file and style sheet

export const SubHeader = (props: Props): JSX.Element => {
	const { title, skip, onSkip, hideBackButton, primaryColorBg, className, isFreeAccountScreen } = props;

	return (
		<div
			className={classNames(styles.subheaderContainer, className, {
				'has-background-primary': primaryColorBg,
				[styles.noActionButtons]: hideBackButton && !skip,
			})}
		>
			<div className={classNames(styles.subheaderFirst, { 'is-hidden-mobile': hideBackButton })}></div>

			<div
				className={classNames(
					styles.onboardingTitle,
					styles.subheaderMiddle,
					{
						[styles.subheaderWBkButton]: !hideBackButton,
					},
					isFreeAccountScreen && styles.onboardingTitleComplete
				)}
			>
				{/* If a "description" element it added, it should be placed here with the above div set to flex column */}
				{title}
			</div>

			<div className={classNames(styles.subheaderLast, { 'is-hidden-mobile': !skip })}>
				{skip && (
					<p onClick={onSkip} className={styles.onboardingSkip}>
						Skip
					</p>
				)}
			</div>
		</div>
	);
};
